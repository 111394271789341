import React from 'react'
import { type TabDataType } from '../../BalanceChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { ProgressComponent, useTheme } from '../../../../../components';
import { Stack } from '@mui/material';
import { type BalanceChartDataInterface } from '../../../../../redux/reducers/profile';
import style from './CustomTabPanel.module.css'

interface CustomTabPanelPropsType {
  children?: React.ReactNode
  balanceChartData: BalanceChartDataInterface | null
  currencyTabHeight: number
}

export const CustomTabPanel = (props: CustomTabPanelPropsType): JSX.Element => {
  const { balanceChartData, currencyTabHeight } = props;
  const { theme } = useTheme()

  // !!! can't use css var(--CFD-theme-Custom-Green) with LineChart directly
  const greenColor = theme === 'light' ? '#116A2C' : '#57D570'
  const textColor = theme === 'light' ? '#000' : '#fff'
  const shadedAreaColor = `rgba(${parseInt(greenColor.slice(1, 3), 16)}, ${parseInt(greenColor.slice(3, 5), 16)}, ${parseInt(greenColor.slice(5, 7), 16)}, 0.16)`

  if (balanceChartData === null) {
    return <ProgressComponent type='circular' />
  }

  const month = balanceChartData.XAxis
  const series = prepareSeries(balanceChartData, greenColor)
  const slotProps = getSlotProps(textColor)
  const sxStyles = getSxStyles(textColor, shadedAreaColor)

  return (
    <div>
      <div className={style.chartWrapper}>
        <Stack direction="column" spacing={0} alignItems="center" sx={{ width: '100%', height: `${currencyTabHeight}px`, minHeight: '210px' }}>
          <LineChart
            margin={{ top: 10, bottom: 80, left: 30, right: 60 }}
            series={series}
            xAxis={[{ scaleType: 'point', data: month }]}
            yAxis={[{ id: 'linearAxis', scaleType: 'linear', data: month }]}
            rightAxis="linearAxis"
            slotProps={slotProps}
            sx={sxStyles}
          />
        </Stack>
      </div>
    </div>
  );
}

const prepareSeries = (tabData: TabDataType, greenColor: string): any => {
  const series = tabData.chart.map((chart, key) => {
    const newChart = {
      ...chart,
      connectNulls: true,
      area: true,
      showMark: true
    }

    if (key === tabData.chart.length - 1) {
      return {
        ...newChart,
        color: greenColor
      }
    }

    return newChart
  })
  return series
}

const getSlotProps = (textColor: string): any => {
  return {
    legend: {
      direction: 'row',
      position: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      labelStyle: {
        fontSize: 14,
        fill: textColor
      },
      itemMarkWidth: 9,
      itemMarkHeight: 9,
      markGap: 8,
      itemGap: 10
    }
  }
}

const getSxStyles = (textColor: string, shadedAreaColor: string): any => {
  return {
    // make market mark circle
    '.MuiChartsLegend-mark': {
      rx: 15,
      ry: 15
    },
    // fill color for underLine area
    '.MuiAreaElement-root': {
      fill: shadedAreaColor
    },
    // line color
    '.MuiLineElement-root, .MuiMarkElement-root': {
      strokeWidth: 3
      // stroke: greenColor
    },
    // fill dots inside with color
    '.MuiMarkElement-root:not(.MuiMarkElement-highlighted)': {
      // fill: greenColor
    },
    '.MuiMarkElement-highlighted': {
      stroke: 'none'
    },
    // chart markup styles below
    '& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel': {
      strokeWidth: 0.5,
      fill: textColor
    },
    '& .MuiChartsAxis-right .MuiChartsAxis-tickLabel': {
      strokeWidth: 0.5,
      fill: textColor
    },
    '& .MuiChartsAxis-bottom .MuiChartsAxis-line': {
      stroke: textColor,
      strokeWidth: 0.4
    },
    '& .MuiChartsAxis-right .MuiChartsAxis-line': {
      stroke: textColor,
      fill: textColor,
      strokeWidth: 0.4
    },
    '.MuiChartsAxis-left': {
      display: 'none'
    },
    '.MuiChartsAxis-tick': {
      stroke: textColor + ' !important'
      // fill: textColor
    }
  }
}
