import React, {
  forwardRef
} from 'react'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';

import styles from './FilePickerButtonComponent.module.css'

interface InterfaceFilePickerButtonComponent {
  handleFileChange: (file: File | null) => void
  handleDocsPicker: (ref: HTMLInputElement | null) => void
  setFileUrl: (el: string) => void
  id: string
}

export const FilePickerButtonComponent = forwardRef<HTMLInputElement, InterfaceFilePickerButtonComponent>(function FilePickerButton (props, ref) {
  const { handleFileChange, handleDocsPicker, id, setFileUrl } = props;

  const onAddFileClick = (): void => {
    if ((ref !== null) && 'current' in ref) {
      if (ref.current !== null) {
        ref.current.value = '';
      }
      handleDocsPicker(ref.current);
    }
  };

  const handlerChange = ({ target: { files } }: File | any): void => {
    const file: File = ((files !== null) && files.length > 0) ? files[0] : null;
    handleFileChange(file);
    if (file !== null) {
      const reader = new FileReader();

      reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
        const src = loadEvent.target?.result;
        setFileUrl(src as string);
      };

      reader.readAsDataURL(file);
    } else {
      handleFileChange(null);
      setFileUrl('');
    }
  }

  return (
    <div className={ styles.filePickerContainer }>
      <input
        type="file"
        id={ id }
        onChange={ handlerChange }
        accept="image/*,.png,.jpeg,.gif,.web"
        className={ styles.hidenDocsPicker }
        ref={ ref }
      />
      <button
        className={ styles.addFileBtn }
        onClick={ onAddFileClick }
      >
        <AttachFileOutlinedIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)', transform: 'rotate(45deg)' }}/>
      </button>
    </div>
  )
})
