export const getUniqueByKey = (arr: any[], key: string): any => {
  const seen = new Set();

  return arr.filter((item: any) => {
    if (!seen.has(item[key])) {
      seen.add(item[key]);
      return true;
    }
    return false;
  });
}
