import React, { useEffect, type FC } from 'react'
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { DividerComponent, ProgressComponent, TabListComponent } from '../../../../components';
import { CustomTabPanel } from './CustomTabPanel/CustomTabPanel';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
  getProfileBalanceChartData, getProfileSelectedTab, getProfileTabsData,
  selectProfileIsBalanceChartDataLoading, selectProfileIsBalanceChartErrorOccur
} from '../../../../redux/selectors/profile';
import { getBalanceChartData, setBalanceChartTab } from '../../../../redux/reducers/profile';
import style from './CurrencyTabs.module.css'

interface ICurrencyTabs {
  currencyTabHeight: number
}

export const CurrencyTabs: FC<ICurrencyTabs> = ({ currencyTabHeight }) => {
  const dispatch = useAppDispatch()

  const tabsData = useAppSelector(getProfileTabsData);
  const selectedTab = useAppSelector(getProfileSelectedTab);
  const balanceChartData = useAppSelector(getProfileBalanceChartData);

  const isBalanceChartDataLoading: boolean = useAppSelector(selectProfileIsBalanceChartDataLoading)
  const isBalanceChartErrorOccur: boolean = useAppSelector(selectProfileIsBalanceChartErrorOccur)

  const [localTabId, setLocalTabId] = React.useState(0);

  useEffect(() => {
    dispatch(getBalanceChartData())
  }, [selectedTab])

  if (isBalanceChartErrorOccur) {
    return <div>Some error happened</div>
  }

  if (tabsData === null || isBalanceChartDataLoading) {
    return (
      <div className={style.loaderWrapper}>
        <ProgressComponent type='circular' />
      </div>
    )
  }

  const handleChange = (event: React.SyntheticEvent, newTabId: number): void => {
    dispatch(setBalanceChartTab(tabsData[newTabId]));
    setLocalTabId(newTabId);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabListComponent value={localTabId} onChange={handleChange} >
          {
            tabsData.map(({ currency, symbol, amount, type }, key) => {
              return <Tab key={key} value={key} label={`${type} ${currency} ${symbol}${amount}`} />
            })
          }
        </TabListComponent>
      </Box>
      <DividerComponent />
      <CustomTabPanel balanceChartData={balanceChartData} currencyTabHeight={ currencyTabHeight }/>
    </Box>
  );
}
