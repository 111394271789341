import React, { useState, type FC, type MouseEvent, useEffect, useMemo } from 'react';
import { t } from 'i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { MobileFooter } from './MobileFooter/MobileFooter';
import { socket } from '../../web/socket'
import { toFixed, getCurrencySymbol, calculatePercentage } from '../../helpers/util';
import { FooterChatComponent } from '../LiveChatComponent/component/FooterChatComponent';
import { getMessage, type MessagesInterface, getChatMessages } from '../../redux/reducers/chat';
import { useWindowSize } from '../../hooks/useWindowSize';

import style from './Footer.module.css';

interface InterfaceFooter {
  setOpenMenu: (el: boolean) => void
}

const soundMessageReceive = new Audio('/Sounds/MessageDelivered.mp3');

export const Footer: FC<InterfaceFooter> = ({ setOpenMenu }) => {
  const dispatch = useAppDispatch();
  const [pnl, setPnl] = useState<number>(0);
  const [margin, setMargin] = useState<number>(0);
  const [openLiveChat, setOpenLiveChat] = useState<boolean>(false);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const { _id: userId } = useAppSelector((state) => state.user);
  const { showFooter } = useAppSelector((state) => state.showFooter);
  const { uniqueId } = useAppSelector((state) => state.cfd);
  const { headerRates } = useAppSelector((state) => state.currencyRates);
  const accounts = useAppSelector((state) => state.accounts);
  const chat = useAppSelector((state) => state.chat);
  const [screenWidth] = useWindowSize();
  const isMobile = screenWidth <= 599;

  const unreadMessageCount = unreadMessages < 9 ? unreadMessages.toString() : '9+'

  const selectedAccount = useMemo(() => (
    accounts.find((account) => account.isActive) ?? null
  ), [accounts]);

  const symbolIcon = useMemo(() => (getCurrencySymbol(selectedAccount?.cfdAccountCurrency.symbol ?? '')), [selectedAccount])

  const equity = (selectedAccount?.balance ?? 0) + pnl + (selectedAccount?.credit ?? 0);
  const freeMargin = equity - margin;
  const marginLevel: number = calculatePercentage(Number(equity), Number(margin));

  const handleChatBtn = (event: MouseEvent<HTMLButtonElement>): void => {
    setOpenLiveChat((prevState) => !prevState)
  }

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (userId !== undefined && selectedAccount !== null) {
      const accountId = selectedAccount._id;
      const symbol = selectedAccount.cfdAccountCurrency.symbol
      socket.emit('getActivePnL', { userId, uniqueId, accountId });
      socket.emit('getActiveMargin', { userId, uniqueId, accountId });

      socket.on(`reciveActivePnL&${uniqueId}&${accountId}`, (pnl) => {
        const convertedPnl = Number(pnl) * (headerRates?.[symbol] ?? 1);

        setPnl(convertedPnl);
      });

      socket.on(`reciveActiveMargin&${uniqueId}&${accountId}`, (currentMargin) => {
        setMargin(Number(currentMargin));
      });
    }

    return () => {
      if (userId !== undefined && selectedAccount !== null) {
        socket.off(`reciveActivePnL&${uniqueId}&${selectedAccount._id}`);
        socket.off(`reciveActiveMargin&${uniqueId}&${selectedAccount._id}`);
        setPnl(0);
        setMargin(0);
      }
    }
  }, [uniqueId, userId, selectedAccount])

  useEffect(() => {
    const getChat = (): void => {
      if (userId !== undefined) dispatch(getChatMessages(userId));
    };
    getChat();
  }, [userId]);

  useEffect(() => {
    if (chat._id !== undefined && userId !== undefined) {
      if (socket !== undefined && socket.connected) {
        socket.on(`getChatMessage/${chat._id}/${chat.user}`, (response: MessagesInterface) => {
          soundMessageReceive.play().catch((error) => {
            console.error('Failed to play sound:', error);
          });
          dispatch(getMessage(response));
        });
      }
    }

    return () => {
      socket.off(`getChatMessage/${chat._id}/${chat.user}`);
    };
  }, [chat, userId]);

  useEffect(() => {
    if (chat?.messages?.length > 0 && userId !== undefined) {
      const count = chat.messages.reduce((accumValue, { sender, isRead }) => {
        if (sender !== userId) {
          if (!isRead) {
            return accumValue + 1;
          }
        }
        return accumValue;
      }, 0);
      setUnreadMessages(count);
    }
  }, [chat?.messages, userId]);

  return (
    <>
      { !isMobile
        ? (
        <footer className={ style.footerWrapper }>
          <h4 className={ style.footerTitle }>© {currentYear} {process.env.REACT_APP_BRAND_NAME}</h4>
          <div className={ style.footerInfoWrapper }>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('labels.balance')}</p>
              <p className={ style.footerInfoValue }>{`${symbolIcon} ${toFixed(selectedAccount?.balance ?? 0, 2)}`}</p>
            </div>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('header.labels.credit')}</p>
              <p className={ style.footerInfoValue }>{`${symbolIcon} ${toFixed(selectedAccount?.credit ?? 0, 2)}`}</p>
            </div>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('header.labels.equity')}</p>
              <p className={ style.footerInfoValue }>
                {`${symbolIcon} ${toFixed(equity, 2)}`}
              </p>
            </div>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('labels.margin')}</p>
              <p className={ style.footerInfoValue }>
                {`${symbolIcon} ${toFixed(margin, 2)}`}
              </p>
            </div>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('labels.margin_level')}</p>
              <p className={ style.footerInfoValue }>
                {`${toFixed(marginLevel, 2)}%`}
              </p>
            </div>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('labels.free_margin')}</p>
              <p className={ style.footerInfoValue }>
                {`${symbolIcon} ${toFixed(freeMargin, 2)}`}
              </p>
            </div>
            <div className={ style.footerInfoCell }>
              <p className={ style.footerInfoTitle }>{t('header.labels.pnl')}</p>
              <p className={ style.footerInfoValue } style={{ color: pnl >= 0 ? 'var(--CFD-theme-Custom-Green)' : 'var(--CFD-theme-Custom-Red)' }}>
                {`${symbolIcon} ${toFixed(pnl, 2)}`}
              </p>
            </div>
          </div>
          <div className={ style.footerContainer }>
            <FooterChatComponent onClickHandler={ handleChatBtn } open={ openLiveChat } unreadMessageCount={ unreadMessageCount } setOpen={ setOpenLiveChat }/>
          </div>
        </footer>
          )
        : (
            showFooter && (
          <MobileFooter
            openLiveChat={ openLiveChat }
            setOpenLiveChat={ setOpenLiveChat }
            handleChatBtn={ handleChatBtn }
            setOpenMenu={ setOpenMenu }
            unreadMessageCount={ unreadMessageCount }
          />
            )
          )}
    </>
  );
}
