import React from 'react';
import { type SortStatusType } from '../../pages/Accounts/AccountsTable/StyledAccountsTableHead/StyledAccountsTableHead';

export const FilterTableIcon = ({ status = 'idle' }: { status: SortStatusType | null }): JSX.Element => {
  const topArrColor = status === 'desc' ? 'var(--CFD-theme-System-Primary)' : 'var(--CFD-theme-System-Outline)';
  const bottomArrColor = status === 'asc' ? 'var(--CFD-theme-System-Primary)' : 'var(--CFD-theme-System-Outline)';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ cursor: 'pointer' }}>
      <path d="M5.3335 10.2669L8.00016 12.9335L10.6668 10.2669H5.3335Z" fill={topArrColor} />
      <path d="M5.3335 6.00019L8.00016 3.33353L10.6668 6.00019H5.3335Z" fill={bottomArrColor} />
    </svg>
  );
}
