import React, { type FC, useState, type MouseEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { ButtonComponent, TransactionModalContext, WithdrawalMethodsComponent, useTheme } from '../../components';
import { WithdrawalMethodIcon } from '../icons/WithdrawalMethodIcon';
import { LanguageOptions } from './LanguageOptions/LanguageOptions';
import { AuthData } from '../../auth/AuthWrapper';
import { useWindowSize } from '../../hooks/useWindowSize';
import { setShowFooter } from '../../redux/reducers/showFooter';
import { t } from 'i18next';
import { selectLanguage, selectLanguages } from '../../redux/selectors/language';
import { setLanguage } from '../../redux/reducers/language';
import { useTranslation } from 'react-i18next';
import { socket } from '../../web/socket'
import style from './MenuSettingsComponent.module.css';
import { useBodyOverflow } from '../../hooks/useBodyOverflow';

export interface InterfaceLanguageOption { language: string, code: string, icon: any }

interface InterfaceMenuSettingsComponent {
  setOpenMenu: (el: boolean) => void
}

export const MenuSettingsComponent: FC<InterfaceMenuSettingsComponent> = ({ setOpenMenu }) => {
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();
  const [screenWidth] = useWindowSize();

  const [openLanguage, setOpenLanguage] = useState<boolean>(false);
  const [openWithdrawals, setOpenWithdrawals] = useState<boolean>(false);
  const accounts = useAppSelector((state) => state.accounts);
  const { isAlertMessage } = useAppSelector((state) => state.alertMessage);
  const { email, _id: userId } = useAppSelector((state) => state.user);
  const activeLanguage = useAppSelector(selectLanguage);
  const languages = useAppSelector(selectLanguages);

  const isMobile = screenWidth <= 600;
  useBodyOverflow(isMobile);

  const [localLang, setLocalLang] = useState(activeLanguage)

  const handleLanguageOptionsChange = (langCode: string): void => {
    setLocalLang(langCode)
  }

  const handleSaveLanguageBtn = (): void => {
    i18n.changeLanguage(localLang)
    dispatch(setShowFooter(true))
    dispatch(setLanguage(localLang))
  }

  const handleLanguageBtn = (): void => {
    dispatch(setShowFooter(false))
    setOpenLanguage(true);
  }

  const handleGoBacKFromLanguageSelect = (): void => {
    dispatch(setShowFooter(true))
    setOpenLanguage(false);
  }

  const handleCloseLanguageSelect = (): void => {
    dispatch(setShowFooter(true))
    setOpenMenu(false);
  }

  const activeLanguageData = languages.find((l) => l.code === activeLanguage) ?? languages.find((l) => l.code === 'en')

  const { logout } = AuthData();

  const selectedAccount = useMemo(() => {
    return accounts.find((account) => account.isActive) ?? null
  }, [accounts])

  const logOutBtn = (): void => {
    if (userId === undefined) return;

    const isSessionToken = sessionStorage.getItem('token');

    if (!isSessionToken) {
      socket.emit('stopStreaming', { userId });
    }
    logout(userId);
  }

  const withdrawalMethodsBtn = (): void => {
    setOpenWithdrawals(true);
    if (isMobile && openWithdrawals) setOpenMenu(false);
  }

  const stopPropagation = (event: MouseEvent): void => {
    event.stopPropagation();
  };

  const profileBtnHandler = (): void => {
    navigate('/profile');
    if (isMobile) setOpenMenu(false);
  }

  const accountsBtnHandler = (): void => {
    navigate('/accounts');
    if (isMobile) setOpenMenu(false);
  }

  const themeToggleHandler = (): void => {
    toggleTheme();
  }

  return (
    <>
      <div className={ style.background } onClick={() => { setOpenMenu(false); } }>
        <div
          className={ style.menuWrapper }
          style={{ top: isAlertMessage ? '120px' : '72px' }}
          onClick={ stopPropagation }
        >
          { !openLanguage
            ? <>
          <div className={ style.menuHeader }>
            <p className={ style.headerTitle }>{t('header.setting_modal.title')}</p>
            <button className={ style.btnContainer } onClick={() => { setOpenMenu(false); }}>
              <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
            </button>
          </div>
          <div className={ style.menuBody }>
            <div className={ style.withdrawBtnContainer }>
              <ButtonComponent
                customInlineStyles={{ backgroundColor: 'var(--CFD-theme-System-Primary)', color: 'var(--CFD-theme-System-OnPrimary)' }}
                onClick={ withdrawalMethodsBtn }
              >
                <span className={ style.btnTextContainer }>
                  <WithdrawalMethodIcon />
                  <p>{t('header.setting_modal.withdrawal_button_text')}</p>
                </span>
              </ButtonComponent>
            </div>
            <div className={ style.mainMenuContainer }>
              <p className={ style.mainMenuText }>{t('labels.menu')}</p>
              <div className={ style.mainMenu }>
                <button className={ style.menuCell } onClick={ profileBtnHandler }>
                  <PersonOutlineIcon style={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                  <p className={ style.menuCellText }>{t('labels.profile')}</p>
                </button>
                <button className={ `${style.menuCell} ${style.borderTop}`} onClick={ accountsBtnHandler }>
                  <AccountBalanceWalletOutlinedIcon style={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                  <p className={ style.menuCellText }>{t('labels.account')}</p>
                </button>
              </div>
            </div>
            <div className={ style.mainMenuContainer }>
              <p className={ style.mainMenuText }>{activeLanguageData?.language}</p>
              <div className={ style.mainMenu }>
                <button className={ style.languageContainer } onClick={ handleLanguageBtn }>
                  <span className={ style.languageTextContainer }>
                    <img className={ style.languageIcon } src={activeLanguageData?.icon} alt="#" />
                    <p className={ style.menuCellText }>{activeLanguageData?.language}</p>
                  </span>
                  <ArrowRightOutlinedIcon style={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                </button>
              </div>
            </div>
            { isMobile && <div className={ style.mainMenuContainer }>
              <p className={ style.mainMenuText }>{t('labels.appearance')}</p>
              <div className={ style.mainMenu }>
                <button className={ style.menuCell } onClick={ themeToggleHandler }>
                  { theme === 'light'
                    ? <DarkModeIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}/>
                    : <LightModeIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}/> }
                  <p className={ style.menuCellText }>{theme === 'light' ? 'Dark' : 'Light'}</p>
                </button>
              </div>
            </div>}
            <div className={ style.mainMenuContainer }>
              <p className={ style.mainMenuText }>{ email }</p>
              <div className={ style.mainMenu }>
                <button className={ style.menuCell } onClick={ logOutBtn }>
                  <LogoutOutlinedIcon style={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                  <p className={ style.menuCellText }>{t('labels.log_out')}</p>
                </button>
              </div>
            </div>
          </div>
          </>
            : <>
              <div className={ style.menuHeader }>
                <button className={ style.btnContainer } onClick={ handleGoBacKFromLanguageSelect }>
                  <ArrowBackOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                </button>
                <p className={ style.headerTitle }>{t('labels.language')}</p>
                <button className={ style.btnContainer } onClick={ handleCloseLanguageSelect }>
                  <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                </button>
              </div>
              <div className={ style.menuBody }>
                <LanguageOptions onChange={handleLanguageOptionsChange} />
              </div>
              <div className={ style.footer }>
                <div className={ style.btnFooterContainer }>
                  <ButtonComponent onClick={handleSaveLanguageBtn} variant='outlined'>{t('labels.save')}</ButtonComponent>
                </div>
              </div>
            </>
          }
        </div>
      </div>
      <TransactionModalContext
        open={ openWithdrawals }
        setOpen={ setOpenWithdrawals }
        component={
          <WithdrawalMethodsComponent setOpen={ setOpenWithdrawals } selectedAccount={selectedAccount} />
        }
      />
    </>
  )
}
