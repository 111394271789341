import { apiHelper } from './apiHelper';

export const languageAPI = {
  async getLanguages (): Promise<Array<{ _id: string, name: string, code: string }>> {
    const response = await apiHelper({ method: 'get', path: '/language/' });
    const result: { success?: boolean, languages: any[] } = response.status === 200 ? response.data : [];

    if (!(result?.success ?? false)) {
      throw new Error('Some error happened | languageAPI -> getLanguages');
    }

    return result.languages;
    ;
  }
};

export const getLanguagesCodes = async (): Promise<string[]> => {
  const languagesData: Array<{ _id: string, name: string, code: string }> = await languageAPI.getLanguages()
  return languagesData.map((lang) => lang.code)
};
