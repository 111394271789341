import React, { createContext, useContext, useState } from 'react'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Alert, ThemeProvider, createTheme, Box, Typography } from '@mui/material'
import style from './Snackbar.module.css'

type ActionCallbackType = (() => void) | undefined
export type SeverityType = 'warning' | 'success' | 'error' | 'info' | undefined
export type VerticalPositionType = 'bottom' | 'top'
export type HorizontalPositionType = 'right' | 'left' | 'center'
export interface PositionType { vertical: VerticalPositionType, horizontal: HorizontalPositionType }
type AutoCloseType = boolean | undefined
type CloseTimeoutType = number | undefined

export interface HandleOpenInterface {
  message: JSX.Element | string
  actionText: string
  position?: PositionType
  handleActionBtn?: ActionCallbackType
  header?: string
  severity?: SeverityType
  modalType?: string
  autoClose?: AutoCloseType
  closeTimeout?: CloseTimeoutType
}

interface SnackbarInterface {
  handleOpen: (props: HandleOpenInterface) => void
  handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void
}

const SnackbarContext = createContext<SnackbarInterface>({
  handleOpen: (props) => {},
  handleClose: (event, reason) => {}
});

/**
 * Usage Example
 *
 * const { handleOpen } = useSnackbar()
 *
 */
export const useSnackbar = (): SnackbarInterface => useContext(SnackbarContext);

export const SnackbarWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [severityOpen, setSeverityOpen] = useState<boolean>(false);
  const [severityStatus, setSeverityStatus] = useState<SeverityType>('info');
  const [MessageComponent, setMessageComponent] = useState<string | JSX.Element>('');
  const [actionButtonText, setActionButtonText] = useState<string>('');
  const [header, setHeader] = useState('')
  const [actionBtnCallback, setActionBtnCallback] = useState<ActionCallbackType>(undefined);
  const [vertical, setVertical] = useState<VerticalPositionType>('top');
  const [horizontal, setHorizontal] = useState<HorizontalPositionType>('right');
  const [type, setType] = useState<string>('default');
  const [closeTimeout, setCloseTimeout] = useState<CloseTimeoutType>(6000);

  const handleOpen = ({
    message,
    actionText,
    position,
    handleActionBtn,
    severity,
    autoClose,
    closeTimeout,
    header,
    modalType = 'default'
  }: HandleOpenInterface): void => {
    setType(modalType);
    setMessageComponent(message)
    setActionButtonText(actionText)
    setActionBtnCallback(() => handleActionBtn)
    setHeader(header ?? '')
    setVertical(position?.vertical ?? 'top')
    setHorizontal(position?.horizontal ?? 'right')

    if (autoClose !== undefined) {
      const timeout = autoClose ? (closeTimeout ?? 6000) : 999999999
      setCloseTimeout(timeout)
    } else {
      setCloseTimeout(6000)
    }

    if (severity !== undefined) {
      setSeverityStatus(severity)
      setSeverityOpen(true)
      setOpen(false)
    } else {
      setSeverityOpen(false)
      setOpen(true)
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }
    setSeverityOpen(false);
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      {
        actionButtonText !== null && actionButtonText !== '' && (
          <Button className={style.snackbarActionBtn} size="small" onClick={actionBtnCallback ?? handleClose}>
            {actionButtonText}
          </Button>
        )
      }
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const theme = createTheme({
    components: {
      MuiSnackbar: {
        styleOverrides: {
          root: {
            '& .MuiPaper-root': {
              backgroundColor: 'var(--CFD-theme-System-Inversesurface)',
              color: 'var(--CFD-theme-System-Inverseonsurface)'
            }
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: 'var(--CFD-theme-System-Inverseonsurface)'
          }
        }
      }
    }
  });

  const getSnackbarByType = (type: string): JSX.Element => {
    const typeToSnackbar: Record<string, JSX.Element> = {
      default: (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={closeTimeout}
          onClose={handleClose}
          message={MessageComponent}
          action={action}
        />
      ),
      transaction: (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={closeTimeout}
          onClose={handleClose}
          action={action}
        >
          <Box sx={{ width: '100%', bgcolor: 'background.paper', p: 2, boxShadow: 1 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', letterSpacing: '0.1px' }} component="div">
                <CheckCircleOutlineIcon sx={{ color: 'var(--CFD-theme-System-TertiaryContainer)', marginRight: '5px' }} />
                {header}
              </Typography>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography variant="body2" sx={{ mt: 1, letterSpacing: '0,1px', fontSize: '14px' }}>
              {MessageComponent}
            </Typography>
          </Box>
        </Snackbar>
      )
    }

    return typeToSnackbar[type];
  }

  return (
    <SnackbarContext.Provider value={{ handleOpen, handleClose }}>
      <ThemeProvider theme={theme}>
        { getSnackbarByType(type) }
      </ThemeProvider>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={severityOpen}
        autoHideDuration={closeTimeout}
        onClose={handleClose}
        message={MessageComponent}
        action={action}
      >
        <Alert
          onClose={handleClose}
          severity={severityStatus}
          variant="filled"
          sx={{ width: '100%' }}
        >
        {MessageComponent}
        </Alert>
      </Snackbar>

      {children}
    </SnackbarContext.Provider>
  );
}
