import React, { type ReactNode, type FC, useState, useEffect } from 'react';

import { ModalContext } from '../../components';
import { HeaderModalAssetsSubscribe } from './Header/HeaderModalAssetsSubscribe';
import { SectionModalAssetsSubscribe } from './Section/SectionModalAssetsSubscribe';
import { FooterModalAssetsSubscribe } from './Footer/FooterModalAssetsSubscribe';
import { type SavingOption, type Saving } from '../../interfaces/SavingInterface';
import { useAppSelector } from '../../hooks/redux';
import { type TSavingSection } from '../../pages/Savings/interface/savingsPageInterfaces';
import { useBodyOverflow } from '../../hooks/useBodyOverflow';

interface ModalAssetsSubscribeInterface {
  open: boolean
  setOpen: (el: boolean) => void
  icon?: ReactNode
  saving: Saving
  defaultSelectedAsset: string
  setSection?: (el: TSavingSection) => void
}

interface ModalAssetsSubscribeReqData {
  selectedAsset?: string
  currencyId: string
  currencySymbol: string
  releaseTime?: Date
  investedAmount?: number
  userId?: string
  selectedOption: SavingOption
  cfdAccountId?: string
}

export const ModalAssetsSubscribe: FC<ModalAssetsSubscribeInterface> = ({ open, setOpen, icon = <></>, saving, defaultSelectedAsset, setSection = () => {} }) => {
  useBodyOverflow(open);
  const [step, setStep] = useState<number>(1);
  const { _id: userId } = useAppSelector((state) => state.user);

  const [defaultSelectedOption] = saving.options.filter((item) => item._id === defaultSelectedAsset);
  const [reqData, setReqData] = useState<ModalAssetsSubscribeReqData>({
    currencyId: saving.currencyId,
    currencySymbol: saving.currency.symbol,
    selectedAsset: defaultSelectedAsset,
    selectedOption: defaultSelectedOption
  });

  useEffect(() => {
    setReqData({
      ...reqData,
      selectedAsset: defaultSelectedAsset,
      selectedOption: defaultSelectedOption
    })
  }, [defaultSelectedAsset, defaultSelectedOption])

  const handleChangeReqData = ({ field, value }: { field: string, value: any }): void => {
    setReqData({
      ...reqData,
      [field]: value
    })
  }

  useEffect(() => {
    if (userId !== undefined) {
      const userIdObj = { userId }
      setReqData((prevValue) => ({ ...prevValue, ...userIdObj }))
    }
  }, [userId, open])

  const handleClose = (value: boolean): void => {
    setOpen(value);

    setReqData({
      currencyId: saving.currencyId,
      currencySymbol: saving.currency.symbol,
      selectedAsset: defaultSelectedAsset,
      selectedOption: defaultSelectedOption
    })
  }

  return (
    <ModalContext
      open={ open }
      setStep={ setStep }
      setOpen={ handleClose }
      headerComponent={ <HeaderModalAssetsSubscribe setOpen={ handleClose } setStep={ setStep }/> }
      sectionComponent={ <SectionModalAssetsSubscribe step={ step } icon={ icon } saving={saving} reqData={reqData} handleChangeReqData={handleChangeReqData} open={ open }/> }
      footerComponent={ <FooterModalAssetsSubscribe step={ step } setStep={ setStep } formData={reqData} setOpen={ setOpen } setSection={setSection}/> }
    />
  )
}
