import React, { type FC, useContext } from 'react';
import { DialogContext } from '../context/DialogContextProvider';
import { ButtonComponent } from '../../../components';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import styles from './modals.module.css';
import { useAppSelector } from '../../../hooks/redux';

interface IContentProps {
  position: number
  tradeType: number
  symbol: string
}

interface IFooterProps {
  onClick: () => void
}

export const StopModalContent: FC<IContentProps> = ({ position, tradeType, symbol }): JSX.Element => {
  return (
      <div className={styles.stopDialogContent}>
          <div className={styles.iconRow}>
            <WarningAmberIcon sx={{ color: 'var(--CFD-theme-System-Secondary)' }}/>
          </div>
          <div className={styles.textColumn}>
            <h2>Are you sure you want to close?</h2>
            <span>{`The position ${tradeType === 1 ? 'BUY' : 'SELL'} ${position} ${symbol}`}</span>
          </div>
      </div>
  );
}

export const StopModalFooter: FC<IFooterProps> = ({ onClick }): JSX.Element => {
  const { hideDialog } = useContext(DialogContext);
  const isModalLoading = useAppSelector(state => state.modal.isLoading);
  return (
        <div className={styles.dialogFooter}>
            <ButtonComponent btnstyle='primary' variant="text" onClick={hideDialog}>Cancel</ButtonComponent>
            <ButtonComponent btnstyle='primary' variant="text" loading={isModalLoading} onClick={() => { onClick(); }}>Confirm</ButtonComponent>
        </div>
  );
}
