import React, { type FC, useState, useEffect, useMemo } from 'react';
import { getFiatCurrencies } from '../../../../redux/reducers/currencies';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ButtonComponent, SelectComponent, AssetAmountInputComponent } from '../../../../components';
import style from './CardPRMDepositFormComponent.module.css';
import { createPRMPayment } from '../../../../redux/reducers/paymentService';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { toFixed } from '../../../../helpers/util';
import { t } from 'i18next';

interface InterfaceCardPRM {
  selectedAccount: IAccount | null
}

export const CardPRMDepositFormComponent: FC<InterfaceCardPRM> = ({ selectedAccount }) => {
  const dispatch = useAppDispatch();

  const [currencySelected, setCurrencySelected] = useState<any>('');
  const [amount, setAmount] = useState<string>('');
  const currencies = useAppSelector((state) => state.currencies);
  const paymentServices = useAppSelector((state) => state.paymentServices);

  const currentService = paymentServices.find(({ name }) => name === 'Card (PRM)');

  const options = useMemo(() => {
    const paymentCurrencies = currentService?.currencies !== undefined ? currentService.currencies : []

    return paymentCurrencies.map((symbol) => {
      const currCurrency = currencies.find((currency) => currency.symbol === symbol);

      return { label: currCurrency?.name ?? '', value: currCurrency?._id ?? '', symbol: currCurrency?.symbol }
    })
  }, [currencies, currentService]);

  const selectedCurrencyObj = useMemo(() => (
    currencies.find(({ _id }) => _id === currencySelected)
  ), [currencySelected]);

  useEffect(() => {
    dispatch(getFiatCurrencies());
  }, []);

  const handlePay = (): void => {
    if (selectedAccount === null || currentService === undefined) return;

    dispatch(createPRMPayment({ accountId: selectedAccount._id, amount: toFixed(Number(amount), 2), currency: selectedCurrencyObj?.iso_4217 ?? '840', paymentId: currentService?._id ?? '' }))
  }

  const formComplete = (currencySelected.length > 0 && amount.length > 0)

  return (
  <>
    <div className={ style.wrapper }>
      <div className={ style.formContainer }>
        <div className={ style.inputContainer }>
          <label htmlFor='selectCurrency' className={ style.label }>{t('labels.currency')}</label>
          <SelectComponent options={ options } value={ currencySelected } onChange={({ target: { value } }) => { setCurrencySelected(value); } } id='selectCurrency'/>
        </div>
        <div className={ style.inputContainer }>
          <label htmlFor='amount' className={ style.label }>{t('labels.amount')}</label>
            <AssetAmountInputComponent
              disabled={currencySelected.length === 0}
              value={ amount }
              setValue={ setAmount }
              id='amount'
              currency={selectedCurrencyObj?.symbol ?? ''}
              maxBtn={ false }
              placeholder='0.00'
            />
        </div>
      </div>
    </div>
    <footer className={ style.footer }>
      <div className={ style.btnContainer }>
        <ButtonComponent onClick={() => { handlePay(); } } disabled={ !formComplete }>
          <span className={ style.btnText }>
            {t('labels.pay')}
          </span>
        </ButtonComponent>
      </div>
    </footer>
  </>
  )
}
