import React, { type ReactNode, forwardRef, type CSSProperties } from 'react'
import Fab, { type FabProps } from '@mui/material/Fab'
import { styled } from '@mui/material/styles'

interface CustomFabProps extends FabProps {
  btnstyle?: 'surface' | 'tertiary' | 'inverse'
}

interface StateLayerProps extends React.PropsWithChildren {
  btnstyle?: 'surface' | 'tertiary' | 'inverse'
  size?: 'small' | 'medium' | 'large'
  variant?: 'extended' | 'classic'
}

interface FabComponentProps {
  children?: ReactNode
  btnstyle?: 'surface' | 'tertiary' | 'inverse'
  variant?: 'extended' | 'classic'
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
  onClick?: () => void
}

interface ColorPalette {
  surface: { containter: object, 'state-layer': object }
  tertiary: { containter: object, 'state-layer': object }
  inverse: { containter: object, 'state-layer': object }
}

const colorPalette: ColorPalette = {
  surface: {
    containter: {
      backgroundColor: 'var(--CFD-theme-Surface-4)',
      color: 'var(--CFD-theme-System-Primary)',

      '&:hover': {
        backgroundColor: 'var(--CFD-theme-Surface-4)'
      },
      '&:active': {
        backgroundColor: 'var(--CFD-theme-Surface-4)'
      }
    },
    'state-layer': {
      '&:hover': {
        backgroundColor: 'var(--CFD-theme-State-layers-primary-opacity_08)'
      },
      '&:active': {
        backgroundColor: 'var(--CFD-theme-State-layers-primary-opacity_12)'
      }
    }
  },
  tertiary: {
    containter: {
      backgroundColor: 'var(--CFD-theme-System-TertiaryContainer)',
      color: 'var(--CFD-theme-System-OnTertiaryContainer)',

      '&:hover': {
        backgroundColor: 'var(--CFD-theme-System-TertiaryContainer)'
      },
      '&:active': {
        backgroundColor: 'var(--CFD-theme-System-TertiaryContainer)'
      }
    },
    'state-layer': {
      '&:hover': {
        backgroundColor: 'var(--CFD-theme-State-layers-on-tertiary-container-opacity_08)'
      },
      '&:active': {
        backgroundColor: 'var(--CFD-theme-State-layers-on-tertiary-container-opacity_12)'
      }
    }
  },
  inverse: {
    containter: {
      backgroundColor: 'var(--CFD-theme-System-Inversesurface)',
      color: 'var(--CFD-theme-System-Inverseonsurface)',

      '&:hover': {
        backgroundColor: 'var(--CFD-theme-System-Inversesurface)'
      },
      '&:active': {
        backgroundColor: 'var(--CFD-theme-System-Inversesurface)'
      }
    },
    'state-layer': {
      '&:hover': {
        backgroundColor: 'var(--CFD-theme-State-layers-inverse-on-surface-opacity_08)'
      },
      '&:active': {
        backgroundColor: 'var(--CFD-theme-State-layers-inverse-on-surface-opacity_12)'
      }
    }
  }
}

const CustomFab = styled(Fab)<CustomFabProps>(({ btnstyle = 'surface', variant = 'extended' }) => {
  let style: object = {
    padding: '0px',
    height: '40px',
    width: '40px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',

    '&.MuiFab-sizeMedium': {
      height: '56px',
      width: '56px'
    },

    '&.MuiFab-sizeLarge': {
      height: '96px',
      width: '96px',
      borderRadius: '16px'
    }
  }

  if (variant === 'extended') {
    style = {
      padding: '0px',
      display: 'flex',
      height: '48px',
      minWidth: '80px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '6px',
      boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)'
    }
  }

  const palette = colorPalette[btnstyle].containter;

  return {
    ...style, ...palette
  };
});

const FabStateLayer = styled('div')<StateLayerProps>(({ btnstyle = 'surface', size = 'small', variant = 'extended' }) => {
  const style: CSSProperties = {
    padding: '16px 20px 16px 16px',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '6px',
    boxSizing: 'border-box',
    textTransform: 'capitalize',
    fontWeight: '500',
    fontFamily: " 'Arial Regular', Arial, sans-serif "
  }

  if (variant === 'classic') {
    style.padding = '8px'

    if (size === 'medium') {
      style.padding = '16px'
    }

    if (size === 'large') {
      style.padding = '30px'
      style.borderRadius = '16px';
    }
  }

  const palette = colorPalette[btnstyle]['state-layer'];
  return {
    ...style, ...palette
  }
})

export const FabComponent = forwardRef<HTMLButtonElement, FabComponentProps>((props, ref) => {
  const { children, variant = 'classic', size = 'small', btnstyle = 'surface', ...rest } = props;

  return (
    <CustomFab
      variant={variant === 'classic' ? 'circular' : variant}
      size={size}
      btnstyle={btnstyle}
      {...rest}
    >
      <FabStateLayer size={size} btnstyle={btnstyle} variant={variant}>
        {children}
      </FabStateLayer>
    </CustomFab>
  );
});

FabComponent.displayName = 'FabComponent'
