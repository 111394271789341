import React, { type FC } from 'react';

import { Step1SectionModalAutoTrader } from '../Steps/Step1/Step1SectionModalAutoTrader';
import { Step2SectionModalAutoTrader } from '../Steps/Step2/Step2SectionModalAutoTrader';

interface InterfaceSectionModalAutoTrader {
  step: number
}

export const SectionModalAutoTrader: FC<InterfaceSectionModalAutoTrader> = ({ step }) => {
  if (step === 1) {
    return (
      <Step1SectionModalAutoTrader />
    )
  }
  return (
    <Step2SectionModalAutoTrader />
  )
}
