import React, { type FC } from 'react';

export const ProfileIcon: FC = () => {
  const fillColor = 'var(--CFD-theme-System-OnSecondaryContainer)';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M21.1 21.1827V21.2778H21.0972V21.7278C21.0972 21.9442 20.9304 22.1 20.7453 22.1H3.35186C3.16672 22.1 3 21.9442 3 21.7278V21.1827C3 17.6352 5.32334 14.6444 8.4764 13.7043L9.3368 13.4477L8.61635 12.9119C7.14311 11.8163 6.17899 10.0388 6.17899 8.03105C6.17899 4.69461 8.82294 2 12.05 2C15.2771 2 17.921 4.69461 17.921 8.03105C17.921 10.0388 16.9569 11.8163 15.4836 12.9119L14.7639 13.4472L15.6233 13.7042C18.7769 14.6473 21.1 17.6354 21.1 21.1827ZM3.70655 21.1856C3.70655 17.3093 6.78355 14.1379 10.5913 14.1379H13.5115C17.3193 14.1379 20.3963 17.3093 20.3963 21.1856V21.3586H3.70655V21.1856ZM6.8827 8.03397C6.8827 5.12854 9.1891 2.74723 12.05 2.74723C14.9109 2.74723 17.2173 5.12854 17.2173 8.03397C17.2173 10.9394 14.9109 13.3207 12.05 13.3207C9.1891 13.3207 6.8827 10.9394 6.8827 8.03397Z" fill={fillColor} />
    </svg>
  )
}
