import React, { type FC, type ChangeEvent } from 'react'

import { InputAdornment, TextField, styled } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

interface SearchWithMenuInputInterface {
  value: string
  setValue: (el: string) => void
  sizeInput?: 'small' | 'medium' | undefined
  menuIcon?: boolean
}

const CustomizedInput = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    height: '48px',
    borderRadius: '26px',
    backgroundColor: 'var(--CFD-theme-Surface-4)',
    border: '1px solid var(--CFD-theme-System-Outline)',
    outline: 'none',
    '&.Mui-focused': {
      border: '1px solid var(--CFD-theme-System-Outline)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'none'
    }
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant)'
  },
  '& .MuiInputBase-root .MuiInputBase-input': {
    padding: '4px',
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left'
  }
}))

export const SearchWithMenuInputComponent: FC<SearchWithMenuInputInterface> = ({
  value,
  setValue,
  sizeInput
}) => {
  let fontSize: 'small' | 'medium' = 'medium';
  if (sizeInput === 'small') fontSize = 'small';

  const hanleClear = (e: any): void => {
    if (setValue != null) setValue('');
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (setValue != null) setValue(event.target.value);
  };

  return (
    <>
      <CustomizedInput
        value={ value }
        onChange={ handleChange }
        sx={{
          '& input': {
            backgroundColor: 'transparent',
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px inherit inset',
              WebkitTextFillColor: 'var(--CFD-theme-System-OnSurfaceVariant)',
              WebkitBackgroundClip: 'text'
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" >
                { value.length > 0
                  ? <CloseOutlinedIcon sx={{ cursor: 'pointer' }} onClick={ hanleClear } fontSize={ fontSize }/>
                  : <SearchOutlinedIcon sx={{ cursor: 'pointer' }} fontSize={ fontSize }/>}
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
                <MenuOutlinedIcon />
          </InputAdornment>)
        }}
      />
    </>
  )
}
