import React, { type FC, useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TableHead } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { StyledTableCell } from '../StyledTableCell/StyledTableCell';
import { StyledTableRow } from '../StyledTableRow/StyledTableRow';
import { SavingsElementHeight, formatPreatyDate } from '../../helper';
import { EmptyTableComponent, ModalRedeem } from '../../../../components';
import { AccountsTableHeader } from './AccountsTableHeader/AccountsTableHeader';
import { AccountsTableCell } from './AccountsTableCell/AccountsTableCell';
import { type IGetSavingsInvestmentQuery, getSavingInvestmentsAccounts } from '../../../../redux/reducers/savingInvestment';
import {
  type ITableContainerProps, type IFilterQueryAccountsTable, type TStatusFilter, type SortStatusTypeAndNull, type SortStatusType
} from '../../interface/savingsPageInterfaces';
import { TableHead as CustomTableHead } from './components/TableHead/TableHead';
import { MobileTableHead } from './components/MobileTableHead/MobileTableHead';
import { type SavingInvestment } from '../../../../interfaces';

export const AccountsTable: FC<ITableContainerProps> = ({ setSection, pageContentHeight, screenWidth }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const savingAccounts = useAppSelector((state) => state.savingAccounts);
  const { _id: userId } = useAppSelector((state) => state.user);
  const { savingsInvestmentsData } = useAppSelector((state) => state.savingInvestments);
  const [showTimeInSelect, setShowTimeInSelect] = useState<string>(t('savings_page.table_body.accounts_tab.accordion_details.timeline_labels.created_date_lower'));
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState<TStatusFilter | null>(null);
  const [lockedPeriodFilter, setLockedPeriodFilter] = useState<string | null>(null);
  const [isFlexibleValue, setIsFlexibleValue] = useState<string | null>(null);
  const [filterQuery, setFilterQuery] = useState<IFilterQueryAccountsTable>({});
  const [expandedRow, setExpandedRow] = useState<any>(null);
  const [flagReload, setFlagReload] = useState<boolean>(false);
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<SortStatusTypeAndNull>(null);
  const [selectedInvestment, setSelectedInvestment] = useState<SavingInvestment | null>(null);
  const [openRedeemModal, setOpenRedeemModal] = useState<boolean>(false);
  const [savingAccountId, setSavingAccountId] = useState<string>('');

  const accountTableHeaderStorage = 'Savings_Page/AccountTable';
  const sortTableStorage = 'Savings_Page/Sort/AccountTable';
  const isTable = (screenWidth <= 1239);
  const isFlexible = (isFlexibleValue === 'flexible');

  const setStorageFilterData = (): void => {
    const rawFilterStorageData = localStorage.getItem(accountTableHeaderStorage);
    const storageFilterData = rawFilterStorageData ? JSON.parse(rawFilterStorageData) : {};
    if (storageFilterData) {
      setIsFlexibleValue(storageFilterData?.isFlexible as string ?? null);
      setSelectedAccount(storageFilterData?.currencyId as string);
      setShowTimeInSelect(storageFilterData?.createdDate
        ? formatPreatyDate(storageFilterData?.createdDate as string)
        : t('savings_page.table_body.accounts_tab.accordion_details.timeline_labels.created_date_lower'))
      setStatusFilter(storageFilterData?.status as TStatusFilter ?? null);
      setLockedPeriodFilter(storageFilterData?.period as string ?? null);
      setFilterQuery(storageFilterData as IFilterQueryAccountsTable);
    }
  }

  const setStorageSortData = (): void => {
    const rawStorageSortData = localStorage.getItem(sortTableStorage);
    const storageSortData = rawStorageSortData ? JSON.parse(rawStorageSortData) : {};
    if (storageSortData) {
      const filterStorageArray = Object.entries(storageSortData as Record<string, unknown>);
      if (filterStorageArray.length > 0) {
        const [key, value] = filterStorageArray[0];
        setSortField(key);
        setSortOrder(value as SortStatusType)
      } else {
        setSortField('investment')
        setSortOrder('idle');
      }
    }
  }

  const getDataFromLocalStorage = (): void => {
    setStorageFilterData();
    setStorageSortData();
    setFlagReload(true);
  }

  useEffect(() => {
    if ((!selectedAccount) &&
        (savingAccounts.length > 0) && flagReload) {
      setSelectedAccount(savingAccounts[0]?.currencyId);
      const flexible = (isFlexibleValue === null) ? 'flexible' : 'locked';
      setIsFlexibleValue(flexible);
      setFilterQuery(prevState => ({ ...prevState, currencyId: savingAccounts[0]?.currencyId, isFlexible: flexible }));
    }
  }, [savingAccounts, selectedAccount, flagReload, isFlexibleValue])

  useEffect(() => {
    getDataFromLocalStorage();
  }, [])

  const fetchData = (userId: string, queryFilter: IGetSavingsInvestmentQuery, sortField: string, sortOrder: string): void => {
    const sortRequestObj = { [sortField]: sortOrder };
    const filter = { sortField, sortOrder, ...queryFilter }
    localStorage.setItem(accountTableHeaderStorage, JSON.stringify(filterQuery));
    localStorage.setItem(sortTableStorage, JSON.stringify(sortRequestObj))
    dispatch(getSavingInvestmentsAccounts({ userId, query: filter }));
  }

  useEffect(() => {
    if (userId !== undefined) {
      if (flagReload &&
        (selectedAccount !== null) &&
        (isFlexibleValue !== null) &&
        (sortField !== null) &&
        (sortOrder !== null)
      ) {
        if (isFlexible) {
          const { period, status, ...newObj } = filterQuery;
          fetchData(userId, newObj, sortField, sortOrder);
        } else {
          fetchData(userId, filterQuery, sortField, sortOrder);
        }
      }
    }
  }, [userId, filterQuery, isFlexibleValue, selectedAccount, flagReload, sortField, sortOrder]);

  return (
    <>
      <ModalRedeem
        open={ openRedeemModal }
        setOpen={ setOpenRedeemModal }
        selectedInvestment={ selectedInvestment }
        setSection={ setSection }
        savingAccountId={ savingAccountId }
        isFlexible={ isFlexible }
      />
      <AccountsTableHeader
        selectedAccount={ selectedAccount }
        setSelectedAccount={ setSelectedAccount }
        setFilterQuery={ setFilterQuery }
        segmentBtnValue={ isFlexibleValue }
        setSegmentBtnValue={ setIsFlexibleValue }
        selectLabelCreatedDate={ showTimeInSelect }
        setSelectLabel={ setShowTimeInSelect }
        filterQuery={ filterQuery }
        statusSelectFilter={ statusFilter }
        setStatusSelectFilter={ setStatusFilter }
        lockedPeriodSelectFilter={ lockedPeriodFilter }
        setLockedPeriodSelectFilter={ setLockedPeriodFilter }
      />
      <TableContainer
        sx={{
          maxHeight: `${pageContentHeight}px`,
          minHeight: `${pageContentHeight}px`,
          paddingBottom: '0',
          borderRadius: '4px',
          backgroundColor: 'var(--CFD-theme-Surface-1) !important'
        }}
      >
        <Table stickyHeader aria-label="customized table">
          <TableHead sx={{ backgroundColor: 'var(--CFD-theme-Surface-1) !important' }}>
            <StyledTableRow sx={{ width: '100%' }} >
              <StyledTableCell colSpan={ 100 } sx={{ borderBottom: '1px solid var(--CFD-theme-System-OutlineVariant)', padding: '8px 16px' }}>
                {
                  !isTable
                    ? (
                      <CustomTableHead
                        isFlexible={ isFlexible }
                        sortField={ sortField }
                        setSortField={ setSortField }
                        sortOrder={ sortOrder }
                        setSortOrder={ setSortOrder }
                      />
                      )
                    : (
                        <MobileTableHead
                          isFlexible={ isFlexible }
                          sortField={ sortField }
                          setSortField={ setSortField }
                          sortOrder={ sortOrder }
                          setSortOrder={ setSortOrder }
                          screenWidth={ screenWidth }
                        />
                      )
                }

              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {
            (savingsInvestmentsData.length > 0)
              ? (
                  <TableBody sx={{ height: '100%', backgroundColor: 'var(--CFD-theme-Surface-1) !important' }} >
                    { savingsInvestmentsData.map((item: SavingInvestment) => (
                      <StyledTableRow
                       key={ item._id }
                       sx={{
                         boxSizing: 'border-box',
                         display: 'flex',
                         flexDirection: 'row',
                         alignItems: 'center',
                         width: '100%',
                         backgroundColor: 'var(--CFD-theme-Surface-1) !important'
                       }}
                       >
                        <StyledTableCell colSpan={ 100 } sx={{ backgroundColor: 'var(--CFD-theme-Surface-1) !important' }}>
                          <AccountsTableCell
                            row={ item }
                            expanded={ expandedRow === item._id }
                            onClick={ () => { item._id === expandedRow ? setExpandedRow(null) : setExpandedRow(item._id) } }
                            setSection={ setSection }
                            isFlexible={ isFlexible }
                            isTable={ isTable }
                            setSelectedInvestment={ setSelectedInvestment }
                            setOpenRedeemModal={ setOpenRedeemModal }
                            setSavingAccountId={ setSavingAccountId }
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    )) }
                  </TableBody>
                )
              : (
                  <EmptyTableComponent columnCount={ 100 } height={ `${pageContentHeight - SavingsElementHeight.PaddingTop - SavingsElementHeight.AccountsTableFirstRow}px` }/>
                )
          }
        </Table>
      </TableContainer>
    </>
  );
}
