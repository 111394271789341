import React from 'react';
import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from './styles.module.css';

interface DividerInterface {
  width?: number
  height?: number
  orientation?: 'horizontal' | 'vertical'
  subHeader?: string
}

interface ContainerInterface {
  width?: string
  height?: string
}

export const DividerComponent: React.FC<DividerInterface> = ({ width = 100, height = 100, orientation = 'horizontal', subHeader = '' }) => {
  const CustomizedDivider = styled(Divider)(() => ({
    backgroundColor: 'var(--CFD-theme-System-OutlineVariant)',
    width: orientation === 'horizontal' ? '100%' : '0',
    height: orientation === 'vertical' ? '100%' : '0'
  }));

  const setContainerStyles = (): ContainerInterface => {
    const styles: ContainerInterface = {};

    if (orientation === 'horizontal') {
      styles.width = `${width}%`;
      styles.height = 'fit-content';
    }

    if (orientation === 'vertical') {
      styles.height = `${height}%`;
      styles.width = '1px';
    }

    return styles;
  }

  return (
    <div style={setContainerStyles()}>
        <CustomizedDivider orientation={orientation} />
        { (orientation === 'horizontal' && subHeader.length > 0) ? <div className={styles.subHeader}>{subHeader}</div> : null }
    </div>
  )
}
