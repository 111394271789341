import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '../store';
import { profileAPI } from '../api/profileApi';

export interface ProfileSettingsState {
  isIdCardVerified: boolean
  isCreditCardVerified: boolean
  isSelfieVerified: boolean
  isAddressVerified: boolean
  verificationStatus: string
  kycVerificationDate: string
  isGoogleTwoFactorAuthenticationEnabled: boolean
  twoFactorAuthDateAdded: string
  firstName: string
  lastName: string
  country: string
  fullAddress: string
  phoneNumber: string
  isOccurErrorOnSettingsForm: boolean
}

const initialState: ProfileSettingsState = {
  isIdCardVerified: false,
  isCreditCardVerified: false,
  isSelfieVerified: false,
  isAddressVerified: false,
  verificationStatus: 'On Inspect',
  kycVerificationDate: '',
  isGoogleTwoFactorAuthenticationEnabled: false,
  twoFactorAuthDateAdded: '',
  firstName: '',
  lastName: '',
  country: '',
  fullAddress: '',
  phoneNumber: '',
  isOccurErrorOnSettingsForm: false
};

const profileSettingsSlice = createSlice({
  name: 'profileSettings',
  initialState,
  reducers: {
    settingsVerifyIdCard (state, action: PayloadAction<boolean>) {
      state.isIdCardVerified = action.payload;
    },
    settingsVerifyCreditCard (state, action: PayloadAction<boolean>) {
      state.isCreditCardVerified = action.payload;
    },
    settingsVerifySelfie (state, action: PayloadAction<boolean>) {
      state.isSelfieVerified = action.payload;
    },
    settingsVerifyAddress (state, action: PayloadAction<boolean>) {
      state.isAddressVerified = action.payload;
    },
    settingsSetVerificationStatus (state, action: PayloadAction<string>) {
      state.verificationStatus = action.payload;
    },
    settingsSetKycVerificationDate (state, action: PayloadAction<string>) {
      state.kycVerificationDate = action.payload;
    },
    settingsEnableGoogleTwoFactorAuthentication (state, action: PayloadAction<boolean>) {
      state.isGoogleTwoFactorAuthenticationEnabled = action.payload;
    },
    settingsSetTwoFactorAuthDateAdded (state, action: PayloadAction<string>) {
      state.twoFactorAuthDateAdded = action.payload;
    },
    settingsUpdateFirstName (state, action: PayloadAction<string>) {
      state.firstName = action.payload;
    },
    settingsUpdateLastName (state, action: PayloadAction<string>) {
      state.lastName = action.payload;
    },
    settingsUpdateFullAddress (state, action: PayloadAction<string>) {
      state.fullAddress = action.payload;
    },
    settingsUpdateCountry (state, action: PayloadAction<string>) {
      state.country = action.payload;
    },
    settingsUpdatePhoneNumber (state, action: PayloadAction<string>) {
      state.phoneNumber = action.payload;
    },
    setIsOccurErrorOnSettingsForm (state, action: PayloadAction<boolean>) {
      state.isOccurErrorOnSettingsForm = action.payload;
    }
  }
});

export const {
  settingsVerifyIdCard,
  settingsVerifyCreditCard,
  settingsVerifySelfie,
  settingsVerifyAddress,
  settingsSetVerificationStatus,
  settingsSetKycVerificationDate,
  settingsEnableGoogleTwoFactorAuthentication,
  settingsSetTwoFactorAuthDateAdded,
  settingsUpdateFirstName,
  settingsUpdateLastName,
  settingsUpdateFullAddress,
  settingsUpdateCountry,
  settingsUpdatePhoneNumber,
  setIsOccurErrorOnSettingsForm
} = profileSettingsSlice.actions;

export default profileSettingsSlice.reducer;

interface UserPasswordPropsInterface {
  oldPassword: string
  newPassword: string
  handleOpen: any
}

interface UserInfoPropsInterface {
  firstName: string
  lastName: string
  phone: string
  fullAddress: string
  countryCode: string
  handleOpen: any
}

export const setActualUserInfo = createAsyncThunk(
  'profile/setActualUserInfo',
  async (_: undefined, { dispatch, getState }) => {
    try {
      const { user: { firstName, lastName, countryCode, phone, fullAddress } } = getState() as RootState

      dispatch(settingsUpdateFirstName(firstName ?? ''))
      dispatch(settingsUpdateLastName(lastName ?? ''))
      dispatch(settingsUpdateFullAddress(fullAddress ?? ''))
      dispatch(settingsUpdateCountry(countryCode ?? ''))
      dispatch(settingsUpdatePhoneNumber(phone ?? ''))
    } catch (error: any) {
      console.dir(error);
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  'profile/updateUserInfo',
  async ({ firstName, lastName, phone, countryCode, handleOpen, fullAddress }: UserInfoPropsInterface, { dispatch, getState }) => {
    try {
      const { user: { _id } } = getState() as RootState

      const userId: string = _id ?? ''

      if (userId === '') {
        throw new Error('User id is not selected')
      }

      const message: string = await profileAPI.updateUserInfo(userId, {
        firstName, lastName, phone, countryCode, fullAddress
      })

      handleOpen({ message, actionText: '', severity: 'success' })
    } catch (error: any) {
      if (error.response !== undefined) {
        handleOpen({
          message: error.response.data.message,
          actionText: '',
          severity: 'error'
        })
      } else {
        console.dir(error);
      }
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  'profile/updateUserPassword',
  async ({ oldPassword, newPassword, handleOpen }: UserPasswordPropsInterface, { dispatch, getState }) => {
    try {
      const { user: { _id } } = getState() as RootState

      const userId: string = _id ?? ''

      if (userId === '') {
        throw new Error('User id is not selected')
      }

      const message: string = await profileAPI.updateUserPassword(userId, oldPassword, newPassword)

      handleOpen({ message, actionText: '', severity: 'success' })
    } catch (error: any) {
      if (error.response !== undefined) {
        handleOpen({
          message: error.response.data.message,
          actionText: '',
          severity: 'error'
        })
      } else {
        console.dir(error);
      }
    }
  }
);
