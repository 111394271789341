import React, { useEffect, useState } from 'react'
import { ProfileHeader } from './ProfileHeader/ProfileHeader'
import { BalanceChart } from './BalanceChart/BalanceChart'
import { TradingChart } from './TradingChart/TradingChart'
import { Settings } from './Settings/Settings'
import { Statistic } from './Statistic/Statistic'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { getTabsData } from '../../redux/reducers/profile'
import { ProfileElementHeight } from './helper';
import { useWindowSize } from '../../hooks/useWindowSize';
import s from './Profile.module.css'

export const Profile: React.FC = () => {
  const dispatch = useAppDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, screenHeight] = useWindowSize();
  const [calcHeight, setCalcHeight] = useState<number>(0);

  const accounts = useAppSelector((state) => state.accounts)

  useEffect(() => {
    if (accounts.length !== 0) {
      dispatch(getTabsData())
    }
  }, [accounts])

  useEffect(() => {
    if (screenHeight > 0) {
      const tableHeight = screenHeight - ProfileElementHeight.TableHeader - ProfileElementHeight.Gap + ProfileElementHeight.StatisticHeight + (ProfileElementHeight.Gap * 2)
      const allPageContentHeight = tableHeight - ProfileElementHeight.Footer - ProfileElementHeight.Header - ProfileElementHeight.PaddingTop;

      setCalcHeight(allPageContentHeight);
    }
  }, [screenHeight])

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.header}>
          <ProfileHeader />
        </div>
        <div className={s.mainContent}>
          <div className={s.chartsWrapper}>
            <Statistic />
            <div className={s.tradingChart}>
              <TradingChart calcHeight={ calcHeight }/>
            </div>
          </div>
          <div className={s.settings}>
            <div className={s.balanceChart}>
              <BalanceChart calcHeight={ calcHeight }/>
            </div>
            <Settings calcHeight={ calcHeight } />
          </div>
        </div>
      </div>
    </>
  )
}
