import React, { type HTMLAttributes, type FC } from 'react';
import { ButtonComponent } from '../../../../../components';
import styles from './FooterModal2FA.module.css'

type TStep2FooterModal2FA = HTMLAttributes<HTMLElement> & {
  code2FA: string[]
  isCodeCorrectLength: boolean
  login: any
  loginData: any
  setIsOpen: any
}

export const FooterModal2FA: FC<TStep2FooterModal2FA> = ({ code2FA, isCodeCorrectLength, login, loginData, setIsOpen }) => {
  const handleClick = async (): Promise<void> => {
    const verifyCode = code2FA.join('')

    await login({
      ...loginData,
      one_time_password: verifyCode,
      pincode: verifyCode
    })
  }

  return (
    <footer className={styles.dialogFooter}>
      <div className={styles.dialogFooterButton}>
        <ButtonComponent onClick={() => { handleClick() }} disabled={!isCodeCorrectLength}>
          Submit
        </ButtonComponent>
      </div>
    </footer>
  )
}
