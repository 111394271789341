import type { RootState } from '../../redux/store';

export interface LanguageObjectType {
  language: string
  code: string
  icon: any
}

export const selectLanguage = (state: RootState): string => state.language.language;
export const selectLanguages = (state: RootState): LanguageObjectType[] => state.language.languages;
