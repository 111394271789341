import React, {
  type HTMLAttributes,
  type FC,
  useRef
} from 'react';
import { CodeInput2FA } from '../../../2FACodeInput/CodeInput2FA';
import { useAppSelector } from '../../../../../hooks/redux';
import { selectIsError2FA } from '../../../../../redux/selectors/twoFactorAuthentication';
import styles from './Step2SectionModal2FA.module.css';
import { t } from 'i18next';

interface IStep2SectionModal2FA {
  code: string[]
  setCode: (el: string[]) => void
}

type TStep2SectionModal2FA = IStep2SectionModal2FA & HTMLAttributes<HTMLElement>

export const Step2SectionModal2FA: FC<TStep2SectionModal2FA> = ({ code, setCode }) => {
  const isError2FA = useAppSelector(selectIsError2FA)
  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

  return (
    <section className={styles.sectionStep2}>
      <h3 className={ styles.sectionStep2Title }>{t('profile_page.2fa_modal.section.step2.title')}</h3>
      <CodeInput2FA code={code} setCode={setCode} ref={inputsRef} errorInput={isError2FA}/>
      <p className={styles.sectionStep2Text}>{t('profile_page.2fa_modal.section.step2.label')}</p>
    </section>
  )
}
