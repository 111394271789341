import { useLayoutEffect } from 'react';

export const useBodyOverflow = (isOpen: boolean): void => {
  useLayoutEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);
};
