import React, { type FC, useState, type MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { type TFunction } from 'i18next';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useAppSelector, useAppDispatch } from '../../../../../hooks/redux';
import { SegmentedButton, SelectWithDatePickerComponent, ButtonComponent, SimpleSelectComponent } from '../../../../../components';
import { getAccountIconBySymbol, toFixed } from '../../../../../helpers/util';
import { type SavingAccountInterface } from '../../../../../interfaces';
import { getTotalSavingsInvestmentsAmount } from '../../../../../redux/reducers/savingInvestment';
import { statusSelectOptions } from '../../../helper';
import {
  type IFilterQueryAccountsTable, type IAccountsTableHeader,
  type IButtonSegmentPeriodType, type ILockedPeriodOptions, type TStatusSelect
} from '../../../interface/savingsPageInterfaces';
import { getSavingOptions } from '../../../../../redux/reducers/savingOptions';

import style from './AccountsTableHeader.module.css';

function renderSegmentTitle (t: TFunction, isFlexible: string | null): IButtonSegmentPeriodType[] {
  const style = { width: '180px' };
  const propsButtonsData: IButtonSegmentPeriodType[] = [
    {
      id: 'Flexible',
      render: <span style={ style }>{ t('savings_page.duration_types.Flexible') }</span>,
      selected: isFlexible === 'flexible'
    },
    {
      id: 'Locked',
      render: <span style={ style }>{ t('savings_page.table_body.accounts_tab.accordion_details.statuses.locked') }</span>,
      selected: isFlexible === 'locked'
    }
  ];

  return propsButtonsData;
}

export const AccountsTableHeader: FC<IAccountsTableHeader> = ({
  selectedAccount,
  setSelectedAccount,
  filterQuery,
  setFilterQuery,
  segmentBtnValue,
  setSegmentBtnValue,
  selectLabelCreatedDate,
  setSelectLabel,
  statusSelectFilter,
  setStatusSelectFilter,
  lockedPeriodSelectFilter,
  setLockedPeriodSelectFilter
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const cfd = useAppSelector(state => state.cfd);
  const savingAccounts = useAppSelector((state) => state.savingAccounts);
  const savingOption = useAppSelector((state) => state.savingOptions);
  const { savingsInvestmentsTotalAmount: totalAmounts } = useAppSelector((state) => state.savingInvestments);
  const { _id: userId } = useAppSelector((state) => state.user);
  const [isBalanceVisible, setIsBalanceVisible] = useState<boolean>(true);
  const [savingOptionSelect, setSavingOptionSelect] = useState<ILockedPeriodOptions[]>([]);

  const createDateTranslate = t('savings_page.table_body.accounts_tab.accordion_details.timeline_labels.created_date_lower');

  const isResetDisabledBtn = (segmentBtnValue === 'flexible'
    ? (selectLabelCreatedDate === createDateTranslate)
    : (selectLabelCreatedDate === createDateTranslate && statusSelectFilter === null && lockedPeriodSelectFilter === null));

  useEffect(() => {
    dispatch(getSavingOptions());
  }, []);

  useEffect(() => {
    if (savingOption.length > 0) {
      const savingsOptionsSelect = savingOption.map(({ label, period, durationType }) => ({ label, value: `${period} ${durationType}` }));
      setSavingOptionSelect(savingsOptionsSelect)
    }
  }, [savingOption]);

  useEffect(() => {
    if (userId) dispatch(getTotalSavingsInvestmentsAmount(userId));
  }, [userId]);

  const balanceVisibility = (event: MouseEvent<SVGSVGElement>): void => {
    setIsBalanceVisible((prevState: boolean) => !prevState)
  }

  const tabButtonHandler = (account: SavingAccountInterface): void => {
    setSelectedAccount(account?.currencyId);
    setFilterQuery((prevState: any) => ({ ...prevState, currencyId: account?.currencyId }));
  }

  const segmentBtnHandler = (id: string): void => {
    setSegmentBtnValue(id.toLocaleLowerCase());
    setFilterQuery((prevState: IFilterQueryAccountsTable) => ({ ...prevState, isFlexible: id.toLowerCase() }));
  }

  const resetBtnHandler = (): void => {
    setSelectLabel(createDateTranslate);
    setStatusSelectFilter(null);
    setLockedPeriodSelectFilter(null);
    const newObj = {
      currencyId: filterQuery?.currencyId,
      isFlexible: filterQuery?.isFlexible
    };
    setFilterQuery(newObj);
  }

  const statusSelectHandler = (el: TStatusSelect): void => {
    if (el !== null) {
      setStatusSelectFilter(el);
      setFilterQuery((prevState: IFilterQueryAccountsTable) => ({ ...prevState, status: el }));
    }
  }

  const periodSelectHandler = (el: string): void => {
    if (el !== null) {
      setLockedPeriodSelectFilter(el);
      setFilterQuery((prevState: IFilterQueryAccountsTable) => ({ ...prevState, period: el }));
    }
  }

  return (
    <>
      <div className={ style.headerWrapper }>
        <div className={ style.header }>
          {
            savingAccounts.map((account) => (
              <button
                key={account._id}
                className={ selectedAccount === account?.currencyId ? `${style.headerAccountsWrapper} ${style.active}` : `${style.headerAccountsWrapper}`}
                onClick={() => { tabButtonHandler(account); }}
              >
              <img src={ getAccountIconBySymbol(account?.currency?.symbol ?? '') } />
              <p className={ style.headerAccountsParagraph }>{`${account?.currency?.symbol ?? ''} ${toFixed(account?.balance, 2) ?? ''}`}</p>
            </button>
            ))
          }
        </div>
      </div>
      <div className={ style.headerBalanceWrapper }>
        <div className={ style.totalBalanceWrapper }>
          <div className={ style.totalBalance }>
            <p className={ style.totalBalanceTitle }>{ t('labels.total_ballance') }</p>
            { isBalanceVisible
              ? <VisibilityIcon onClick={ balanceVisibility } style={{ cursor: 'pointer' }}/>
              : <VisibilityOffIcon onClick={ balanceVisibility } style={{ cursor: 'pointer' }}/> }
          </div>
          <div className={ style.totalBalanceAmountWrapper }>
            { isBalanceVisible &&
              <>
                <p className={ style.totalBalanceAmount }>{ toFixed(cfd.totalBalance, 2) }</p>
                <p className={ style.totalBalanceAmount }>USD</p>
                <p className={ style.totalBalanceCurrency }>United States Dollar</p>
              </>
            }
          </div>
        </div>
        <div className={ style.totalBalanceInfoWrapper }>
          <div className={ style.totalBalanceTotalAmount }>
            <p className={ style.totalBalanceTotalAmountTitle }>{ t('labels.total_invested') }</p>
            <p className={ style.totalBalanceTotalAmountCurrency }>
              { toFixed(totalAmounts.totalInvested, 2) }
            </p>
          </div>
          <div className={ `${style.totalBalanceTotalAmount} ${style.borderYVector}` }>
            <p className={ style.totalBalanceTotalAmountTitle }>{ t('labels.average_percentage') }</p>
            <p className={ style.totalBalanceTotalAmountCurrency }>
              {`${toFixed(totalAmounts.averagePercentage, 2)}%`}
            </p>
          </div>
          <div className={ style.totalBalanceTotalAmount }>
            <p className={ style.totalBalanceTotalAmountTitle }>{ t('labels.cumulative_total') }</p>
            <p className={ style.totalBalanceTotalAmountCurrency }>
              {`${toFixed(totalAmounts.cumulativeTotal, 2)}USD`}
            </p>
          </div>
        </div>
      </div>
      <div className={ style.optionsWrapper }>
        <div className={ style.optionsSegmentBtnWrapper }>
          <SegmentedButton
            propsButtonsData={ renderSegmentTitle(t, segmentBtnValue) }
            onChange={ segmentBtnHandler }
          />
        </div>
        <div className={ style.optionsFilterWrapper }>
          <div className={ style.optionsFilters }>
            <div className={ style.datePickerWrapper }>
              <SelectWithDatePickerComponent
                setFilterQuery={ setFilterQuery }
                setSelectLabel={ setSelectLabel }
                selectLabel={ selectLabelCreatedDate }
                filterPointer='createdDate'
              />
            </div>
            { (segmentBtnValue === 'locked')
              ? (
                  <>
                    <div className={ style.selectStatusWrapper }>
                      <SimpleSelectComponent
                        placeholder={ t('labels.status') }
                        height='44px'
                        value={ statusSelectFilter ?? '' }
                        setValue={ statusSelectHandler }
                        options={ statusSelectOptions }
                      />
                    </div>
                    <div className={ style.selectLockedPeriodWrapper }>
                      <SimpleSelectComponent
                        placeholder={ t('savings_page.subscribe_modal.Locked_period') }
                        height='44px'
                        value={ lockedPeriodSelectFilter ?? '' }
                        setValue={ periodSelectHandler }
                        options={ savingOptionSelect }
                      />
                    </div>
                  </>
                )
              : null }
          </div>
          <div className={ style.optionsBtnWrapper }>
            <ButtonComponent
              btnstyle='primary'
              variant='text'
              disabled={ isResetDisabledBtn }
              onClick={ resetBtnHandler }
            >{ t('labels.reset') }</ButtonComponent>
          </div>
        </div>
      </div>
    </>
  );
}
