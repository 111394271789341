import React, { type FC } from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { getAccountIconBySymbol } from '../../helpers/util';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import { type IAccount } from '../../redux/reducers/accounts';

import styles from './CurrencySelect.module.css';

interface CurrencySelectInterface {
  id?: string
  labelId?: string
  currency: string
  setCurrency: (el: string) => void
  accounts?: IAccount[]
}

const CustomizedMenuItem = styled(MenuItem)(({ selected }) => ({
  color: 'var(--CFD-theme-System-OnSurface)',
  minWidth: '170px',
  width: '100%',
  fontFamily: 'Arial',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  padding: '8px 12px',
  backgroundColor: 'var(--CFD-theme-System-SecondaryContainer)',
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-Surface-5) !important'
  },
  '&.Mui-selected': {
    backgroundColor: 'var(--CFD-theme-Surface-5) !important',
    color: 'var(--CFD-theme-System-OnSurface) !important'
  }
}));

export const CurrencySelect: FC<CurrencySelectInterface> = ({ id, labelId, currency, setCurrency, accounts }) => {
  const handleChange = ({ target: { value } }: SelectChangeEvent): void => {
    setCurrency(value);
  };

  return (
    <>
      <FormControl fullWidth sx={{ height: '32px', marginLeft: '10px' }}>
        <Select
          labelId={ labelId }
          id={ id }
          value={ currency }
          onChange={ handleChange }
          MenuProps={{ MenuListProps: { disablePadding: true } }}
          sx={{
            height: '32px',
            fontSize: '14px',
            fontßtyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            letterSpacing: '0.1px',
            border: 'none',
            outline: 'none',
            color: 'var(--CFD-theme-System-OnSurfaceVariant)',
            '.MuiPaper-root': {
              backgroundColor: 'var(--CFD-theme-System-SecondaryContainer)'
            },
            '&:focus': {
              border: 'none',
              outline: 'none'
            },
            '& .MuiSelect-select': {
              '&:focus': {
                backgroundColor: 'transparent'
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          }}
        >
          {accounts?.map((account) => (
            <CustomizedMenuItem
              key={account._id}
              value={account._id}
              sx={{
                backgroundColor: currency === account._id ? 'var(--CFD-theme-Surface-5)' : 'var(--CFD-theme-System-SecondaryContainer)'
              }}
            >
              <div className={styles.currencyContainer}>
                <img src={ getAccountIconBySymbol(account?.cfdAccountCurrency?.symbol ?? '') } className={styles.currencyIcon}/>
                <p className={styles.currencyTitle}>{ `${account.type.name} ${account.cfdAccountCurrency.symbol}` }</p>
              </div>
            </CustomizedMenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
