import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../redux/store';

export const selectUserId = (state: RootState): string => state.user._id ?? '';
export const selectUserFirstName = (state: RootState): string => state.user.firstName ?? 'Adam Large';
export const selectUserLastName = (state: RootState): string => state.user.lastName ?? 'User Name';
export const selectUserToken = (state: RootState): string => state.user.token ?? '';
export const selectUserEmail = (state: RootState): string => state.user.email ?? 'name@gmail.com';
export const selectUserPhone = (state: RootState): string => state.user.phone ?? '12343564574';
export const selectUserSecret = (state: RootState): string => state.user.secret ?? '';
export const selectUserQrCode = (state: RootState): string => state.user.qrCode ?? '';
export const selectUserProfileImage = (state: RootState): string | null => state.user.profileImage ?? null;

export const selectIsUserDataAvailable = createSelector([selectUserId], (userId: string) => userId !== '')
