import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { calculateDaysPassed } from '../../../../helper';
import { type IAccardionMainContent } from '../../../../interface/savingsPageInterfaces';
import { TimeLeftSquares } from '../../../../../../components';
import { useTimeLeft } from '../../../../../../hooks/useTimeLeft';

import style from './MobileAccardionMainContent.module.css';

export const MobileAccardionMainContent: FC<IAccardionMainContent> = ({
  investedAmount, currencySymbol, isFlexible, period, pct, createdAt, releaseTime, isReleased, cumulativeTotal
}) => {
  const { t } = useTranslation();
  const timeLeft = useTimeLeft({ releaseTime, isReleased });

  return (
    <div className={ style.accardionCellSummary }>
      <div className={ style.accardionTopCellSummary }>
        <div className={ style.accardionCellWrapper }>
          <p className={ style.accardionCellText }>{ investedAmount }</p>
          <p className={ style.accardionCellText }>{ currencySymbol}</p>
        </div>
        {
          (isFlexible ?? false)
            ? (
                <div className={ style.accardionCellStatusWrapper }>
                  <div className={ style.statusActive }>
                    <p className={ style.accardionStatusCellText }>
                      {t('savings_page.table_body.accounts_tab.accordion_details.statuses.active')}
                    </p>
                  </div>
                </div>
              )
            : (
                <div className={ style.accardionCellStatusWrapper }>
                  <div className={ isReleased ? `${style.statusUnlocked}` : `${style.statusLocked}` }>
                    <p className={ style.accardionStatusCellText }>
                      { isReleased
                        ? t('savings_page.table_body.accounts_tab.accordion_details.statuses.unlocked')
                        : t('savings_page.table_body.accounts_tab.accordion_details.statuses.locked')
                      }
                    </p>
                  </div>
                </div>
              )
        }
      </div>
      <div className={ style.accardionTopBottomSummary }>
        <div className={ style.accardionCellRateWrapper }>
          <div className={ style.accardionCellWrapper }>
            <p className={ style.accardionCellText }>{ pct }</p>
            <p className={ style.accardionCellText }>%</p>
          </div>
          <p className={ style.accardionCellTextWrapper }>
            { t('savings_page.table_body.accounts_tab.accordion_details.rate_title') }
          </p>
        </div>
        <div className={ style.accardionCellCunmulWrapper }>
          <div className={ style.accardionCumulWrapper }>
            <p
              className={
                !(isFlexible ?? false)
                  ? `${style.accardionCellCumalitivTotalText}`
                  : `${style.accardionCellCumalitivTotalText}`
              }
            >
              { cumulativeTotal }
            </p>
            <p className={ style.accardionCumulWrapper }>{ currencySymbol }</p>
          </div>
          <div className={ style.accardionCumulWrapper }>
            <p className={ style.accardionCellTextWrapper }>
              { t('savings_page.table_head.accounts_tab.total_title') }
            </p>
          </div>
        </div>
        {
          (isFlexible ?? false)
            ? (
              <div className={ style.accardionPeriodWrapper }>
                <div className={ style.accardionPeriod }>
                  <p className={ `${style.accardionCellText}` }>{ calculateDaysPassed(createdAt as string) }</p>
                  <p className={ style.accardionCellText }>{ t('labels.days') }</p>
                </div>
                <p className={ style.accardionCellTextWrapper }>
                    { t('savings_page.table_body.accounts_tab.accordion_details.period_title') +
                        ' ' + t('savings_page.table_body.accounts_tab.accordion_details.investment_title') }
                </p>
              </div>
              )
            : (
                <div className={ style.accardionCellRelese }>
                  <div className={ style.accardionCellReleseTimer }>
                    <TimeLeftSquares timeLeft={ timeLeft }/>
                  </div>
                </div>
              )
        }
      </div>
    </div>
  )
}
