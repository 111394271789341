import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';
import { type Saving } from '../../interfaces/SavingInterface';

const initialState: Saving[] = [];

export const getSavings = createAsyncThunk(
  'savings/get',
  async (currencyName?: string) => {
    let url = '/saving';
    if (currencyName != null) url += `?currencyName=${currencyName}`;
    const response = await apiHelper({ method: 'get', path: `${url}` });

    return response.data.savings;
  }
);

export const savingsSlice = createSlice({
  name: 'savings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSavings.fulfilled, (state, action: PayloadAction<Saving[]>) => {
      return action.payload;
    });
  }
});

export default savingsSlice.reducer;
