import React from 'react';
import { Stepper } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StepperInterface {
  activeStep?: number
  children: React.ReactNode
}

const CustomizedStepper = styled(Stepper)(() => ({
  ' .MuiStep-root': {
    padding: '20px',

    '&.Mui-disabled': {
      backgroundColor: 'transparent'
    },

    ' .MuiStepLabel-root': {
      padding: 0,

      ' .MuiStepLabel-label': {
        color: 'var(--CFD-theme-System-OnSurface)',
        fontFamily: 'Arial',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '28px'
      },

      ' .Mui-disabled': {
        color: 'var(--CFD-theme-System-OnSurfaceVariant)'
      },

      ' .Mui-completed': {
        color: 'var(--CFD-theme-System-OnSurfaceVariant)'
      }
    },

    ' .MuiStepContent-root': {
      marginLeft: '15px',
      marginTop: '36px',
      borderColor: 'var(--CFD-theme-System-OutlineVariant)'
    },

    '&.Mui-completed': {
      ' .MuiStepContent-root': {
        margin: 0
      },

      '& .MuiSvgIcon-root': {
        color: 'var(--CFD-theme-System-Tertiary)',
        opacity: 1,

        ' .MuiStepIcon-text': {
          fill: 'var(--CFD-theme-System-OnTertiary)'
        }
      }
    }
  },

  ' .MuiStepLabel-iconContainer': {
    marginRight: '32px',

    ' .MuiSvgIcon-root': {
      width: '32px',
      height: '32px',
      minWidth: '32px',
      minHeight: '32px',
      color: 'var(--CFD-theme-System-OnSurface)',
      opacity: 0.38,

      ' .MuiStepIcon-text': {
        fill: 'var(--CFD-theme-System-Surface)',
        textAlign: 'center',
        fontFamily: 'Arial',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '28px'
      }
    },

    '&.Mui-active': {
      ' .MuiSvgIcon-root': {
        color: 'var(--CFD-theme-System-Tertiary)',
        opacity: 1,

        ' .MuiStepIcon-text': {
          fill: 'var(--CFD-theme-System-OnTertiary)'
        }
      }
    }
  },

  ' .MuiStepConnector-root': {
    display: 'none'
  }
}));

export const StepperComponent: React.FC<StepperInterface> = ({ activeStep = 0, children }) => {
  return (
    <CustomizedStepper activeStep={activeStep} orientation="vertical">
        {children}
    </CustomizedStepper>
  )
}
