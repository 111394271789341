import React, { type FC, useEffect, useMemo } from 'react';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

import { type SavingInvestment } from '../../../../../interfaces';
import { CurrencySelect } from '../../../../../components';
import { ApproximetlyEqualIcon } from '../../../../icons/ApproximetlyEqualIcon';
import { InfoIcon } from '../../../../icons/InfoIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { getCurrencyRates } from '../../../../../redux/reducers/currencyRates';
import { calcSavingFlexibleEarnings, calculateSavingFlexibleLockPeriod } from '../../../../../helpers/util';

import style from './Step1SectionModalRedeem.module.css';

interface InterfaceStep1SectionModalRedeem {
  currency: string
  setCurrency: (el: string) => void
  selectedInvestment: SavingInvestment | null
  isFlexible: boolean | null
}

export const Step1SectionModalRedeem: FC<InterfaceStep1SectionModalRedeem> = ({ selectedInvestment, currency, setCurrency, isFlexible }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const accounts = useAppSelector((state) => state.accounts);
  const { rates } = useAppSelector(state => state.currencyRates);

  const selectedAccount = useMemo(() => accounts.find(item => item._id === currency), [accounts, currency]);

  const currencyRate = useMemo(() => {
    if (selectedInvestment != null) {
      return rates?.[selectedInvestment?.currency.symbol] ?? 0
    }
    return 0
  }, [rates]);

  useEffect(() => {
    if (selectedAccount !== undefined && selectedAccount !== null) {
      dispatch(getCurrencyRates(selectedAccount.cfdAccountCurrency.symbol))
    }
  }, [selectedAccount])

  if (selectedInvestment !== null) {
    const lockedPeriodInDays = calculateSavingFlexibleLockPeriod(selectedInvestment.createdAt);
    const earnings = calcSavingFlexibleEarnings(selectedInvestment.investedAmount, selectedInvestment.pct, lockedPeriodInDays, 2)

    return (
      <section className={ style.sectionWrapper }>
        <div className={ style.detailsWrapper }>
          <div className={ style.details }>
            <p className={ style.detailsText1 }>{ t('savings_page.table_body.accounts_tab.accordion_details.investment_title') }</p>
            <p className={ style.detailsText }>{ selectedInvestment.investedAmount }</p>
          </div>
          <div className={ style.details }>
            <p className={ style.detailsText1 }>{ t('savings_page.subscribe_modal.Locked_period') }</p>
            <p className={ style.detailsText }>{`${lockedPeriodInDays} day(s)`}</p>
          </div>
          <div className={ style.details }>
            <p className={ style.detailsText1 }>{ t('savings_page.table_body.accounts_tab.accordion_details.rate_title') }</p>
            <p className={ style.detailsText }>{`${selectedInvestment?.pct} %`}</p>
          </div>
          <div className={ style.details }>
            <p className={ style.detailsText1 }>{ t('savings_page.table_body.accounts_tab.accordion_details.earnings_title') }</p>
            <p className={ style.detailsText }>{`${earnings} ${selectedInvestment.currency.symbol}`}</p>
          </div>
        </div>
        <div className={ style.redemptionAmountContainer }>
          <p className={ style.redemptionAmountTitle }>{ `${t('labels.redemption_amount')}:` }</p>
          <p className={ style.redemptionAmount }>
            {`${(isFlexible ?? false) ? earnings : selectedInvestment.finalEarnings} ${selectedInvestment.currency.symbol}`}
          </p>
        </div>
        <div className={ style.redemptiomToContainer }>
          <p className={ style.redemptiomToTitle }>{ `${t('labels.redeem_to')}:` }</p>
          <div className={ style.redemptiomToContentContainer }>
            <div className={ style.wallet }>
              <DoneOutlinedIcon fontSize='small' sx={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}/>
              <CurrencySelect currency={ currency } setCurrency={ setCurrency } accounts={accounts}/>
            </div>
            <div className={ style.amountWalletContainer }>
              <div className={ style.amountContainer }>
                <ApproximetlyEqualIcon />
                <p className={ style.walletAmount }>
                  {`${(isFlexible ?? false) ? (Number(earnings) / currencyRate).toFixed(3) : (selectedInvestment.finalEarnings / currencyRate).toFixed(3)} ${selectedAccount?.cfdAccountCurrency.symbol}`}
                </p>
              </div>
              <div className={ style.infoIconContainer }>
                <InfoIcon />
              </div>
            </div>
          </div>
        </div>
        <div className={ style.redemptiomInfo }>
          <InfoOutlinedIcon sx={{ color: 'var(--CFD-theme-System-Inverseonsurface)' }}/>
          <p className={ style.selectWalletInfoContentText }>
            { t('redeemModal_text_section_one.text') }
          </p>
        </div>
      </section>
    )
  }

  return <></>
}
