import React, { type FC } from 'react';
import { type IconInterface } from '../../interfaces';

export const ChartIcon: FC<IconInterface> = ({ width, height, color }) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width={width ?? 24} height={height ?? 24} viewBox="0 0 18 18" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M5.25 11.4375C5.56066 11.4375 5.8125 11.6893 5.8125 12V13.5C5.8125 13.8107 5.56066 14.0625 5.25 14.0625C4.93934 14.0625 4.6875 13.8107 4.6875 13.5V12C4.6875 11.6893 4.93934 11.4375 5.25 11.4375Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'}/>
  <path fillRule="evenodd" clipRule="evenodd" d="M9 10.6875C9.31066 10.6875 9.5625 10.9393 9.5625 11.25V13.5C9.5625 13.8107 9.31066 14.0625 9 14.0625C8.68934 14.0625 8.4375 13.8107 8.4375 13.5V11.25C8.4375 10.9393 8.68934 10.6875 9 10.6875Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'}/>
  <path fillRule="evenodd" clipRule="evenodd" d="M12.75 9.1875C13.0607 9.1875 13.3125 9.43934 13.3125 9.75V13.5C13.3125 13.8107 13.0607 14.0625 12.75 14.0625C12.4393 14.0625 12.1875 13.8107 12.1875 13.5V9.75C12.1875 9.43934 12.4393 9.1875 12.75 9.1875Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'}/>
  <path d="M4.5 8.0625C4.18934 8.0625 3.9375 8.31435 3.9375 8.625C3.9375 8.93565 4.18934 9.1875 4.5 9.1875C7.74156 9.1875 10.4239 8.17945 12.0992 5.77086L12.2092 6.15492C12.2948 6.45357 12.6063 6.62631 12.905 6.54075C13.2036 6.45519 13.3763 6.14373 13.2908 5.84508L13.0522 5.01223C13.0066 4.85328 12.9571 4.67535 12.8918 4.53079C12.8167 4.36434 12.6978 4.1864 12.4836 4.06464C12.2771 3.94721 12.0676 3.93129 11.8942 3.93921C11.7338 3.94653 11.5455 3.97854 11.3592 4.00926L10.4084 4.16609C10.1019 4.21665 9.89445 4.50612 9.945 4.81263C9.99555 5.11915 10.285 5.32665 10.5916 5.27609L11.1344 5.18654C9.72258 7.16835 7.46445 8.0625 4.5 8.0625Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'}/>
  <path fillRule="evenodd" clipRule="evenodd" d="M3.31618 3.31618C3.71597 2.91639 4.25818 2.68496 5.16592 2.56291C6.08985 2.43869 7.30472 2.4375 9 2.4375C10.6953 2.4375 11.9101 2.43869 12.8341 2.56291C13.7418 2.68495 14.284 2.91639 14.6838 3.31617C15.0836 3.71596 15.3151 4.25819 15.4371 5.16592C15.5613 6.08985 15.5625 7.30472 15.5625 9C15.5625 10.6953 15.5613 11.9101 15.4371 12.8341C15.3151 13.7418 15.0836 14.2841 14.6838 14.6839C14.284 15.0836 13.7418 15.3151 12.8341 15.4371C11.9101 15.5613 10.6953 15.5625 9 15.5625C7.30472 15.5625 6.08985 15.5613 5.16592 15.4371C4.25819 15.3151 3.71597 15.0836 3.31619 14.6839C2.91641 14.2841 2.68495 13.7418 2.56291 12.8341C2.43869 11.9101 2.4375 10.6953 2.4375 9C2.4375 7.30472 2.43869 6.08985 2.56291 5.16592C2.68496 4.25818 2.9164 3.71596 3.31618 3.31618ZM9.04288 1.3125H8.95712C7.31409 1.31249 6.02358 1.31248 5.01602 1.44794C3.98353 1.58676 3.16433 1.87704 2.52068 2.52068C1.87703 3.16434 1.58676 3.98353 1.44794 5.01602C1.31248 6.02358 1.31249 7.31409 1.3125 8.95712V9.04288C1.31249 10.6859 1.31248 11.9764 1.44794 12.984C1.58676 14.0165 1.87705 14.8357 2.52069 15.4794C3.16434 16.123 3.98353 16.4132 5.01602 16.5521C6.02358 16.6875 7.31409 16.6875 8.95712 16.6875H9.04288C10.6859 16.6875 11.9764 16.6875 12.984 16.5521C14.0165 16.4132 14.8357 16.123 15.4794 15.4793C16.123 14.8357 16.4132 14.0165 16.5521 12.984C16.6875 11.9764 16.6875 10.6859 16.6875 9.04288V8.95712C16.6875 7.31409 16.6875 6.02358 16.5521 5.01602C16.4132 3.98353 16.123 3.16433 15.4793 2.52067C14.8357 1.87703 14.0165 1.58676 12.984 1.44794C11.9764 1.31248 10.6859 1.31249 9.04288 1.3125Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'}/>
</svg>
  )
}
