import React, { useState, useEffect } from 'react';
import { calculateTimeLeft } from '../pages/Savings/helper';
import { formatDate } from '../helpers/util';
import { type ILockedPeriodOptions } from '../pages/Savings/interface/savingsPageInterfaces';

interface IUseTimeLeft {
  releaseTime: string | Date
  isReleased: boolean
}

export const useTimeLeft = ({ releaseTime, isReleased }: IUseTimeLeft): ILockedPeriodOptions[] => {
  const [timeLeft, setTimeLeft] = useState<ILockedPeriodOptions[]>(() => calculateTimeLeft(formatDate(new Date(releaseTime)), isReleased));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft(formatDate(new Date(releaseTime)), isReleased));
    }, 60000);

    return () => { clearInterval(interval); };
  }, [releaseTime, isReleased]);

  return timeLeft;
}
