import React, { type FC } from 'react';
import { type IconInterface } from '../../interfaces';

export const PlusIcon: FC<IconInterface> = ({ width, height, color }) => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
            <path d="M20 13H13V20H11V13H4V11H11V4H13V11H20V13Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'} />
        </svg>
  )
}
