import React, { type FC } from 'react';
import { type IconInterface } from '../../interfaces';

export const RevertIcon: FC<IconInterface> = ({ width, height, color }) => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 24} height={height ?? 24} viewBox="0 0 18 18" fill="none">
            <g opacity="0.38" clipPath="url(#clip0_2202_42535)">
                <path d="M9 4.50049V6.75049L12 3.75049L9 0.750488V3.00049C5.685 3.00049 3 5.68549 3 9.00049C3 10.178 3.345 11.273 3.93 12.1955L5.025 11.1005C4.6875 10.478 4.5 9.75799 4.5 9.00049C4.5 6.51799 6.5175 4.50049 9 4.50049ZM14.07 5.80549L12.975 6.90049C13.305 7.53049 13.5 8.24299 13.5 9.00049C13.5 11.483 11.4825 13.5005 9 13.5005V11.2505L6 14.2505L9 17.2505V15.0005C12.315 15.0005 15 12.3155 15 9.00049C15 7.82299 14.655 6.72799 14.07 5.80549Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'}/>
            </g>
            <defs>
                <clipPath id="clip0_2202_42535">
                <rect width="18" height="18" fill="white" transform="translate(0 0.000488281)"/>
                </clipPath>
            </defs>
        </svg>
  )
}
