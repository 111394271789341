import React, { type FC } from 'react';

import style from './TimeLeftSquares.module.css';

export interface ITimeLeftSquares {
  timeLeft: Array<{ value: string, label: string }>
}

export const TimeLeftSquares: FC<ITimeLeftSquares> = ({ timeLeft }) => {
  return (
    <>
      {
        timeLeft?.map(({ value, label }, index) => {
          const prettyValue = value === 'NaN' ? '' : value;
          if (index === 1) {
            return (<div key={ label } className={ style.accardionCellTimeLeftHoursWrapper }>
                <div className={ style.accardionCellTimeLeftHoursCell }>
                  <p className={ style.accardionCellTimeLeftDots }>:</p>
                  <div className={ style.accardionCellTimeLeft }>
                    <p className={ style.accardionCellTimeLeftText }>{ prettyValue }</p>
                  </div>
                  <p className={ style.accardionCellTimeLeftDots }>:</p>
                </div>
                <p className={ style.accardionCellTimeLeftTitle }>{ label }</p>
              </div>)
          }
          return (
              <div className={ style.accardionCellTimeLeftWrapper } key={ label }>
                <div className={ style.accardionCellTimeLeft }>
                  <p className={ style.accardionCellTimeLeftText }>{ prettyValue}</p>
                </div>
                <p className={ style.accardionCellTimeLeftTitle }>{ label }</p>
              </div>
          )
        })
      }
    </>
  )
}
