import React, { type SetStateAction, type Dispatch, forwardRef, useMemo, useImperativeHandle, type RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../Auth.module.css';
import { StepContent } from '@mui/material';
import { type OptionInterface } from '../../../../interfaces';
import { InputComponent, DatePicker, SelectComponent, ButtonComponent } from '../../../../components';

interface IPersonalInfoStep {
  firstName: string
  lastName: string
  country: OptionInterface | null | string
  options: OptionInterface[]
  birth: string | null
  setFirstName: Dispatch<SetStateAction<string>>
  setLastName: Dispatch<SetStateAction<string>>
  setCountry: Dispatch<SetStateAction<OptionInterface | null | string>>
  setBirth: Dispatch<SetStateAction<string | null>>
  nextStep: (stepNumber: number) => void
  ref: RefObject<IPersonalInfoRef>
}

export interface IPersonalInfoRef {
  isStepFinished: () => boolean
}

const PersonalInfoStep = forwardRef<IPersonalInfoRef, IPersonalInfoStep>((
  {
    firstName,
    lastName,
    options,
    country = '',
    birth,
    nextStep,
    setFirstName,
    setLastName,
    setCountry,
    setBirth
  }, ref
): JSX.Element => {
  const { t } = useTranslation();

  const today = new Date();
  const minDate = new Date(today.getFullYear() - 19, today.getMonth(), today.getDate());
  const maxDate = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate());

  const isBirthDateValid = (birth: string | null): boolean => {
    if (birth === null || birth === '') return false;
    const birthDate = new Date(birth);
    return birthDate <= minDate && birthDate >= maxDate;
  };

  const isStepFinished: boolean = useMemo((): boolean => {
    const isCountryPresent = (): boolean => {
      if (country === null) {
        return false;
      } else if (typeof country === 'object') {
        return country.value.length > 0;
      } else if (typeof country === 'string') {
        return country.length > 0;
      }

      return false;
    }

    return (
      firstName.length > 0 &&
      lastName.length > 0 &&
      isCountryPresent() &&
      isBirthDateValid(birth)
    );
  }, [firstName, lastName, country, birth]);

  useImperativeHandle(ref, () => ({
    isStepFinished (): boolean {
      return isStepFinished
    }
  }));

  return (
    <StepContent>
      <div className={styles.containerBody__column}>
        <div className={styles.containerBody}>
          <label htmlFor="firstName">{t('login_page.first_name_field')}</label>
          <div className={styles.inputContainer}>
            <InputComponent id="firstName" value={firstName} setValue={setFirstName} />
          </div>
          <div className={styles.emptyBox}></div>
        </div>
        <div className={styles.containerBody}>
          <label htmlFor="lastName">{t('login_page.last_name_field')}</label>
          <div className={styles.inputContainer}>
            <InputComponent id="lastName" value={lastName} setValue={setLastName} />
          </div>
          <div className={styles.emptyBox}></div>
        </div>
        <div className={styles.containerBody}>
          <label htmlFor="birth">{t('login_page.date_of_birth_field')}</label>
          <div className={styles.inputContainer}>
            <DatePicker
              value={birth}
              onChange={setBirth}
              labelAvailable={false}
              minDate={maxDate}
              maxDate={minDate}
            />
          </div>
          <div className={styles.emptyBox}></div>
        </div>
        <div className={styles.containerBody}>
          <label htmlFor="country">{t('login_page.country_field')}</label>
          <div className={styles.inputContainer}>
            <SelectComponent
              type='search'
              id="country"
              options={options}
              value={country as string}
              onSearch={setCountry}
            />
          </div>
          <div className={styles.emptyBox}></div>
        </div>
        <div className={styles.containerBodyBtn}>
          <div className={styles.emptyBoxLeft}></div>
          <div className={styles.containerBtn}>
            <ButtonComponent
              variant="contained"
              disabled={!isStepFinished}
              onClick={() => { nextStep(1); }}
            >
              {t('labels.next')}
            </ButtonComponent>
          </div>
          <div className={styles.emptyBox}></div>
        </div>
      </div>
    </StepContent>
  )
});

PersonalInfoStep.displayName = 'PersonalInfoStep';

export { PersonalInfoStep };
