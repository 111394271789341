import React, { type FC } from 'react';

interface InterfaceMasterCardIcon {
  active?: boolean
}

export const MasterCardIcon: FC<InterfaceMasterCardIcon> = ({ active }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="24" viewBox="0 0 40 24" fill="none">
      <g opacity={ (active ?? false) ? '1' : '0.4' }>
      <ellipse opacity="0.6" cx="11.9033" cy="12" rx="11.9033" ry="12" fill={ (active ?? false) ? 'var(--CFD-theme-System-OnSurface)' : 'var(--CFD-theme-System-OnSurfaceVariant)'} />
      <ellipse opacity="0.6" cx="24.0966" cy="12" rx="11.9033" ry="12" fill={ (active ?? false) ? 'var(--CFD-theme-System-OnSurface)' : 'var(--CFD-theme-System-OnSurfaceVariant)'} />
      </g>
    </svg>
  )
}
