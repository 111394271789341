import React, { type DialogHTMLAttributes, type MouseEvent, type FC, type ReactPortal } from 'react';
import { createPortal } from 'react-dom';

import style from './ModalImageView.module.css';

interface InterfaceModalImageView {
  open: boolean
  setOpen: (el: boolean) => void
  image: string
}

type TModalImageView = InterfaceModalImageView & DialogHTMLAttributes<HTMLDialogElement>;

const modalRoot = document.getElementById('modal-image-view');

export const ModalImageView: FC<TModalImageView> = ({
  setOpen,
  open,
  image,
  ...rest
}): ReactPortal | null => {
  const handleBackgroundClick = (): void => {
    setOpen(false);
  };

  const stopPropagation = (e: MouseEvent): void => {
    e.stopPropagation();
  };

  if (modalRoot !== null && open) {
    return createPortal(
      <div
        className={ style.modalBackground }
        onClick={ handleBackgroundClick }
      >
        <dialog
          className={ style.dialogWrapper }
          open={ open }
          onClick={ stopPropagation }
          {...rest}
        >
          <img className={ style.image } src={ image } alt='Full screen Image'/>
        </dialog>
      </div>,
      modalRoot
    );
  }
  return null;
};
