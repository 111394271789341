import React, { useState, type FC } from 'react';
import { ModalContext } from '../../../../components';
import { HeaderModal2FA } from './Header/HeaderModal2FA';
import { SectionModal2FA } from './Section/SectionModal2FA';
import { FooterModal2FA } from './Footer/FooterModal2FA';

interface IModal2FAPinCode {
  open: boolean
  setOpen: (el: boolean) => void
  login: any
  loginData: any
}

export const Modal2FAPinCode: FC<IModal2FAPinCode> = ({ open, setOpen, login, loginData }) => {
  const [code2FA, setCode2FA] = useState<string[]>(Array(6).fill(''));
  const [isCodeCorrectLength, setIsCodeCorrectLength] = useState(false)

  return (
    <ModalContext
      setOpen={setOpen}
      open={open}
      isNotMobileFullScreen
      headerComponent={ <HeaderModal2FA setIsOpen={setOpen} /> }
      sectionComponent = { <SectionModal2FA code2FA={code2FA} setCode2FA={setCode2FA} setIsCodeCorrectLength={setIsCodeCorrectLength} /> }
      footerComponent={ <FooterModal2FA code2FA={code2FA} isCodeCorrectLength={isCodeCorrectLength} login={login} loginData={loginData} setIsOpen={setOpen} /> }
    />
  )
}
