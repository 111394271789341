import React, { type FC, type PropsWithChildren } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import style from './AddWithdrawalMethodSection.module.css';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { t } from 'i18next';

interface InterfaceAddWithdrawalMethodSection {
  setOpen: (el: boolean) => void
  setAddWithdrawalMethod: (el: boolean) => void
  selectedAccount: IAccount | null
}

export const AddWithdrawalMethodSection: FC<PropsWithChildren<InterfaceAddWithdrawalMethodSection>> = ({
  setOpen,
  setAddWithdrawalMethod,
  selectedAccount,
  children
}) => {
  const handleCloseModal = (): void => {
    setOpen(false);
  }

  const handleBack = (): void => {
    setAddWithdrawalMethod(false);
  }
  return (
    <>
      <header className={ style.header }>
        <div className={ style.headerContentWrapper }>
          <div className={ style.headerContentTitleWrapper }>
            <button className={ style.headerBtn } onClick={ handleBack }>
              <ArrowBackIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
            </button>
            <h2 className={ style.headerContentTitle }>{t('labels.withdrawal')} {selectedAccount?.cfdAccountCurrency.symbol ?? ''}</h2>
          </div>
          <button className={ style.headerBtn } onClick={ handleCloseModal }>
            <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
          </button>
        </div>
      </header>
      <div className={ style.bodywrapper }>
        { children }
      </div>
    </>
  )
}
