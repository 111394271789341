import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonComponent } from '../../../../components';

import style from './ConfirmMobileTransactionsBetweenAccounts.module.css';
import { toFixed } from '../../../../helpers/util';

interface InterfaceConfirmMobileTransactionsBetweenAccounts {
  fromValue: number
  toValue: number
  fromSymbol: string
  toSymbol: string
  onSubmit: () => Promise<void>
  isLoading: boolean
}

export const ConfirmMobileTransactionsBetweenAccounts: FC<InterfaceConfirmMobileTransactionsBetweenAccounts> = ({ fromSymbol, fromValue, toValue, toSymbol, onSubmit, isLoading }) => {
  const { t } = useTranslation();

  const confirmBtnHandler = (): void => {
    onSubmit();
  }
  return (
    <>
      <div className={ style.transferWrapper }>
        <div className={ style.transferInfoWrapper }>
          <div className={ style.transferInfo }>
            <p className={ style.transferInfoTitle }>{ t('labels.transfer') }</p>
            <div className={ style.amountContainer }>
              <span className={ style.fromAmount }>{`${fromValue} ${fromSymbol}`}</span>
              <span className={ style.toAmount }>{`= ${toFixed(toValue, 4)} ${toSymbol}`}</span>
            </div>
          </div>
          <div className={ style.rateWrapper }>
            <p className={ style.rateTitle }>{ t('labels.exchange_rate') }</p>
            <div className={ style.rateAmountContainer }>
              <span className={ style.fromRate }>{`${fromValue} ${fromSymbol} =`}</span>
              <span className={ style.toRate }>{`${toFixed(toValue, 4)} ${toSymbol}`}</span>
            </div>
            {/* <p className={ style.rateText }>Exchange Fee: 0%</p> */}
          </div>
        </div>
      </div>
      <footer className={ style.footerWrapper }>
        <div className={ style.btnWrapper }>
          <ButtonComponent onClick={ confirmBtnHandler } loading={ isLoading } >
            { t('labels.confirm') }
          </ButtonComponent>
        </div>
      </footer>
    </>
  )
}
