import React, { type FC, useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getBanks } from '../../../../redux/reducers/withdrawalMethods';
import { getCurrencyRates } from '../../../../redux/reducers/currencyRates';
import { createExternalTransaction, getTransactionsHistory } from '../../../../redux/reducers/transactionsHistory';
import { toFixed } from '../../../../helpers/util';
import { handleError } from '../../../../helpers';
import AddIcon from '@mui/icons-material/Add';

import {
  AssetAmountInputComponent,
  ButtonComponent,
  useSnackbar
} from '../../../../components';
import { SavedBankWithdrawalOptions } from './SavedBankWithdrawalOptions/SavedBankWithdrawalOptions';
import { SuccessWithdrawalSection } from '../SuccessWithdrawalSection/SuccessWithdrawalSection';

import style from './BankWithdrawalSection.module.css';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { type PayloadInterface } from '../../../../interfaces';
import { t } from 'i18next';

export interface TypeBankOption { swift: string, iban: string, bankName: string }

interface InterfaceBankWithdrawSection {
  setOpen: (el: boolean) => void
  setStep: (el: number) => void
  step: number
  setAddWithdrawalMethod: (el: boolean) => void
  selectedAccount: IAccount | null
}

export const BankWithdrawSection: FC<InterfaceBankWithdrawSection> = ({ setOpen, setStep, step, setAddWithdrawalMethod, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const { handleOpen: handleOpenSnackbar } = useSnackbar();
  const [bankSelect, setBankSelect] = useState<string>('');
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
  const [amount, setAmount] = useState<string>('');

  const { banks } = useAppSelector((state) => state.withdrawalMethods);
  const { _id: userId } = useAppSelector((state) => state.user);
  const { rates: currencyRates } = useAppSelector((state) => state.currencyRates);

  const selectedBank = useMemo(() => {
    return banks.find(({ _id }) => _id === bankSelect) ?? null;
  }, [bankSelect])

  useEffect(() => {
    if (userId === undefined) return;

    dispatch(getBanks(userId));
  }, []);

  useEffect(() => {
    if (selectedAccount !== null) dispatch(getCurrencyRates(selectedAccount.cfdAccountCurrency.symbol));
  }, [selectedAccount]);

  const handleAddWithdrawalMethod = (): void => {
    setAddWithdrawalMethod(true);
  }

  const handleWithdrawlClick = async (): Promise<void> => {
    if (userId === undefined || selectedBank === null || selectedAccount === null) return;

    setIsBtnLoading(true);

    const data = {
      userId,
      currencyId: selectedAccount.cfdAccountCurrency.currencyId,
      userAccountId: selectedAccount._id,
      sendToIban: selectedBank.iban,
      sendToAddress: selectedBank.bankAddress,
      sendToAccountNumber: selectedBank.accountNumber,
      sendToSwiftCode: selectedBank.swiftCode,
      amount: toFixed((Number(amount) / Number((currencyRates?.[selectedBank.currencyId?.symbol] ?? 0))), 2),
      amountFromAccount: amount,
      type: 'Bank'
    };

    const response = await dispatch(createExternalTransaction(data));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      handleError(payload, handleOpenSnackbar);
      setIsBtnLoading(false);

      return;
    }

    dispatch(getTransactionsHistory({ userId, accountId: selectedAccount._id }))
    setStep(2);
  }
  const handleCloseWithdrawal = (): void => {
    setOpen(false);
  }

  const formComplete = (amount.length > 0 && Number(amount) > 0)

  return (
    <>
      { (step === 1)
        ? <>
            <div className={ style.wrapper }>
              <div className={ style.cardWrapper }>
                <div className={ style.cardOptions }>
                  <p className={ style.cardWrapperText }>1. {t('labels.select_bank')}</p>
                  { (banks.length !== 0) && <div className={ style.addCardBtnContainer }>
                    <ButtonComponent
                      onClick={ handleAddWithdrawalMethod }
                      variant='outlined'
                      customInlineStyles={{ border: 'none', padding: '8px' }}
                    >
                      <AddIcon style={{ color: 'var(--CFD-theme-System-Tertiary)' }}/>
                      {t('labels.add_bank')}
                    </ButtonComponent>
                  </div> }
                </div>
                { (banks.length !== 0)
                  ? <div className={ style.savedCardsWrapper }>
                      <p className={ style.savedCardsText }>{t('labels.saved_bank')}</p>
                      <SavedBankWithdrawalOptions selectedOptions={ bankSelect } setSelectedOptions={ setBankSelect } options={ banks }/>
                    </div>
                  : <div className={ style.infoContainer }>
                      <p className={ style.infoText }>{t('withdrawal_modal.add_bank_form_modal.section.subtitle')}</p>
                      <div className={ style.infoBtn }>
                        <ButtonComponent
                          variant='outlined'
                          onClick={ handleAddWithdrawalMethod }
                          customInlineStyles={{ border: 'none', padding: '8px', color: 'var(--CFD-theme-System-Inverse-primary)' }}
                        >
                          {t('withdrawal_modal.add_bank_form_modal.section.action_button_text')}
                        </ButtonComponent>
                      </div>
                    </div>
                }
              </div>
              {
                bankSelect.length > 0 && (
                  <div className={ style.cardWrapper }>
                    <p className={ style.cardWrapperText }>2. {t('labels.amount')}</p>
                    <div className={ style.inputWrapper }>
                      <label htmlFor='amount' className={ style.label }>{t('labels.amount')}</label>
                      <AssetAmountInputComponent
                      value={ amount }
                      setValue={ setAmount }
                      id='amount'
                      currency={selectedBank?.currencyId.symbol ?? ''}
                      maxBtn={ false }
                      placeholder='0.00'
                      />
                    </div>
                  </div>
                )
              }
            </div>
            <footer className={ style.footer }>
              <div className={ style.btnContainer }>
                <ButtonComponent onClick={() => { handleWithdrawlClick(); } } disabled={ !formComplete } loading={isBtnLoading}>
                  <span className={ style.btnText }>
                    {t('withdrawal_modal.add_bank_form_modal.footer.action_button_text')}
                  </span>
                </ButtonComponent>
              </div>
            </footer>
          </>
        : <SuccessWithdrawalSection
         title={t('withdrawal_modal.success_title')}
         amount={`${toFixed(Number(amount), 2)} ${selectedBank?.currencyId.symbol ?? ''}`}
         withdrawalTo={selectedBank?.iban ?? ''}
         onClick={ handleCloseWithdrawal }
         />
      }
    </>
  )
}
