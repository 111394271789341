import { type ILockedPeriodOptions, type IStatusSelectOptions } from './interface/savingsPageInterfaces';

export enum SavingsElementHeight {
  PaddingTop = 32,
  PaddingBottom = 4,
  Footer = 64,
  Header = 72,
  Gap = 32,
  PageHeader = 56,
  HeaderSavingsTable = 131,
  MobileHeaderSavingsTable = 121,
  HeaderAccountsTable = 252,
  BigTableHeaderAccounts = 348,
  SmallTableHeaderAccounts = 424,
  MobileTableHeaderAccounts = 420,
  HistoryTableHeader = 129,
  SmallTabHistoryTableHeader = 139,
  MediumMobileTableHistoryHeader = 185,
  MobileTableHistoryHeader = 231,
  AccountsTableFirstRow = 56,
  BorderTable = 2,
}

export const formatPreatyDate = (inputDate: string | Date): string => {
  const date = new Date(inputDate);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

export const statusSelectOptions: IStatusSelectOptions[] = [
  { value: 'all', label: 'All' },
  { value: 'locked', label: 'Locked' },
  { value: 'unlocked', label: 'Unlocked' }
]

export const calculateDaysPassed = (dateString: string): number => {
  const inputDate = new Date(dateString);
  const currentDate = new Date();
  const inputTime = inputDate.getTime();
  const currentTime = currentDate.getTime();
  const timeDifference = currentTime - inputTime;
  const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  const resultDayPassed = daysPassed > 0 ? daysPassed : 0;

  return resultDayPassed;
}

export const calculateTimeLeft = (dateString: string = '', isReleased: boolean): ILockedPeriodOptions[] => {
  if (isReleased) {
    return [
      { value: '00', label: 'Days' },
      { value: '00', label: 'Hours' },
      { value: '00', label: 'Minutes' }
    ];
  }
  const inputDate = new Date(dateString.replace(' ', 'T') + 'Z');
  const currentDate = new Date();
  const inputTime = inputDate.getTime();
  const currentTime = currentDate.getTime();
  const timeDifference = inputTime - currentTime;

  if (timeDifference < 0) {
    return [
      { value: '00', label: 'Days' },
      { value: '00', label: 'Hours' },
      { value: '00', label: 'Minutes' }
    ];
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  const formattedTimeLeftArray = [
    { value: `${String(days).padStart(2, '0')}`, label: 'Days' },
    { value: `${String(hours).padStart(2, '0')}`, label: 'Hours' },
    { value: `${String(minutes).padStart(2, '0')}`, label: 'Minutes' }
  ];

  return formattedTimeLeftArray;
}

export function setHistoryTableHeight (sizeTableObject: Record<string, boolean>): number {
  const {
    isHistorySmallTable,
    isMobile,
    threeSelectInRowHistoryTableHeader,
    twoSelectInRowHistoryTableHeader,
    oneSelectInRowHistoryTableHeader
  } = sizeTableObject;
  if (!isHistorySmallTable && !isMobile) return SavingsElementHeight.HistoryTableHeader;
  if (threeSelectInRowHistoryTableHeader) return SavingsElementHeight.SmallTabHistoryTableHeader;
  if (twoSelectInRowHistoryTableHeader) return SavingsElementHeight.MediumMobileTableHistoryHeader;
  if (oneSelectInRowHistoryTableHeader) return SavingsElementHeight.MobileTableHistoryHeader;
  return SavingsElementHeight.HistoryTableHeader;
}

export function setAccountsTableHeight (sizeTableObject: Record<string, boolean>): number {
  const {
    isBigTable,
    isMobile,
    isSmallTable
  } = sizeTableObject;
  if (!isBigTable && !isSmallTable && !isMobile) return SavingsElementHeight.HeaderAccountsTable;
  if (isBigTable) return SavingsElementHeight.BigTableHeaderAccounts;
  if (isSmallTable) return SavingsElementHeight.SmallTableHeaderAccounts;
  if (isMobile) return SavingsElementHeight.MobileTableHeaderAccounts;
  return SavingsElementHeight.HeaderAccountsTable;
}
