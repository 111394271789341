import React, { type FC } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { setShowFooter } from '../../../../redux/reducers/showFooter';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';

import style from './HeaderMobileTransactionsBetweenAccounts.module.css';

interface InterfaceHeaderMobileTransactionsBetweenAccounts {
  setOpen: (el: boolean) => void
}

export const HeaderMobileTransactionsBetweenAccounts: FC<InterfaceHeaderMobileTransactionsBetweenAccounts> = ({ setOpen }) => {
  const dispatch = useAppDispatch();
  const [screenWidth] = useWindowSize();
  const { showFooter } = useAppSelector((state) => state.showFooter);

  const isMobile = screenWidth <= 599;

  const handleBtnClick = (): void => {
    if (isMobile && !showFooter) dispatch(setShowFooter(true));
    setOpen(false);
  }
  return (
    <header className={ style.header }>
      <div className={ style.contentWrapper }>
        <h2 className={ style.contentTitle }>Transfer between accounts</h2>
        <button className={ style.btn } onClick={ handleBtnClick }>
          <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
        </button>
      </div>
    </header>
  )
}
