import React, { type FC, type ChangeEvent, type KeyboardEvent } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { ProgressComponent } from '../../ProgressComponent/ProgressComponent';

import style from './FooterLiveChatComponent.module.css';
import { t } from 'i18next';

interface InterfaceFooterLiveChatComponent {
  sendMessageValue: string
  inputChangeHandler: (event: ChangeEvent<HTMLInputElement>, id: string) => void
  handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void
  messageValid: boolean
  sendMessageHandler: () => void
  isBtnLoading: boolean
  id: string

}

export const FooterLiveChatComponent: FC<InterfaceFooterLiveChatComponent> = ({
  sendMessageValue,
  inputChangeHandler,
  handleKeyDown,
  messageValid,
  sendMessageHandler,
  id,
  isBtnLoading
}) => {
  return (
    <>
     <input
        className={ style.inputSendMessage }
        placeholder={t('liveChat.input_placeholder')}
        value={ sendMessageValue }
        onChange={(e) => { inputChangeHandler(e, id); } }
        onKeyDown={ handleKeyDown }
      />
      <button
        className={ style.footerBtn }
        disabled={ !messageValid || isBtnLoading }
        style={{ opacity: messageValid ? '1' : '0.3' }}
        onClick={ sendMessageHandler }
      >
        {
          isBtnLoading ? <ProgressComponent type='circular' sx={{ width: '30px !important', height: '30px !important' }} /> : <SendIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}/>
        }
      </button>
    </>
  )
}
