import React, { type HTMLAttributes, type FC } from 'react';

import { Step1FooterModal2FA } from '../Steps/Step1/Footer/Step1FooterModal2FA';
import { Step2FooterModal2FA } from '../Steps/Step2/Footer/Step2FooterModal2FA';
import { useAppSelector } from '../../../hooks/redux';
import { selectStep2FA } from '../../../redux/selectors/twoFactorAuthentication';
import styles from './FooterModal2FA.module.css';

type TFooterModal2FA = HTMLAttributes<HTMLElement>;

export const FooterModal2FA: FC<TFooterModal2FA> = () => {
  const step = useAppSelector(selectStep2FA)

  if (step === 1) {
    return (
      <Step1FooterModal2FA />
    )
  };

  if (step === 2) {
    return (
      <Step2FooterModal2FA />
    )
  };

  return (
    <footer className={styles.dialogFooter}></footer>
  )
}
