import React, { type FC, useState, useEffect } from 'react';

import { HeaderMobileTransactionsBetweenAccounts } from './HeaderMobileTransactionsBetweenAccounts/HeaderMobileTransactionsBetweenAccounts';
import { SectionMobileTransactionsBetweenAccounts } from './SectionMobileTransactionsBetweenAccounts/SectionMobileTransactionsBetweenAccounts';
import { ConfirmMobileTransactionsBetweenAccounts } from './ConfirmMobileTransactionsBetweenAccounts/ConfirmMobileTransactionsBetweenAccounts';
import { SuccessMobileTransactionsBetweenAccounts } from './SuccessMobileTransactionsBetweenAccounts/SuccessMobileTransactionsBetweenAccounts';
import { getAccounts, transferAmount, type IAccount } from '../../../redux/reducers/accounts';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import { type PayloadInterface } from '../../../interfaces';
import { handleError } from '../../../helpers';
import { useSnackbar } from '../../../components';
import { setShowFooter } from '../../../redux/reducers/showFooter';
import { useWindowSize } from '../../../hooks/useWindowSize';

import style from './MobileTransactionsBetweenAccounts.module.css';

interface InterfaceMobileTransactionsBetweenAccounts {
  setOpen: (el: boolean) => void
}

export const MobileTransactionsBetweenAccounts: FC<InterfaceMobileTransactionsBetweenAccounts> = ({ setOpen }) => {
  const dispatch = useAppDispatch();
  const { _id: userId } = useAppSelector((state) => state.user);
  const { handleOpen: handleOpenSnackbar } = useSnackbar();
  const [fromAccount, setFromAccount] = useState<IAccount | null>(null);
  const [toAccount, setToAccount] = useState<IAccount | null>(null);
  const [toAccountValue, setToAccountValue] = useState<number>(0);
  const [fromAccountValue, setFromAccountValue] = useState<number>(0);
  const [step, setStep] = useState<number>(1);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [screenWidth] = useWindowSize();
  const { showFooter } = useAppSelector((state) => state.showFooter);

  const isMobile = screenWidth <= 599;

  useEffect(() => {
    if (isMobile && showFooter) dispatch(setShowFooter(false));
  }, []);

  const handleTransfer = async (): Promise<void> => {
    if (userId !== undefined && fromAccount !== null && toAccount !== null) {
      setIsBtnLoading(true);

      const response = await dispatch(transferAmount({ userId, amount: fromAccountValue, fromAccountId: fromAccount._id, toAccountId: toAccount._id }));
      const payload = response.payload as PayloadInterface;

      if (payload.isError) {
        handleError(payload, handleOpenSnackbar);
        setIsBtnLoading(false);

        return;
      }

      await dispatch(getAccounts(userId));

      const openParams = {
        message: payload.data,
        actionText: '',
        autoClose: true
      }

      handleOpenSnackbar(openParams);
      setIsBtnLoading(false);
      setStep(3);
    }
  }

  return (
    <div className={ style.mainWrapper }>
      <HeaderMobileTransactionsBetweenAccounts setOpen={ setOpen }/>
      <div className={ style.bodywrapper }>
        { step === 1 && <SectionMobileTransactionsBetweenAccounts
          setStep={ setStep }
          fromAccount={ fromAccount }
          setFromAccountValue={ setFromAccountValue }
          setFromAccount={ setFromAccount }
          toAccount={ toAccount }
          toAccountValue={ toAccountValue }
          fromAccountValue={ fromAccountValue }
          setToAccount={ setToAccount }
          setToAccountValue={ setToAccountValue }
        /> }
        { step === 2 && <ConfirmMobileTransactionsBetweenAccounts
          fromSymbol={ fromAccount?.cfdAccountCurrency.symbol ?? '' }
          toSymbol={ toAccount?.cfdAccountCurrency.symbol ?? '' }
          fromValue={ fromAccountValue }
          toValue={ toAccountValue }
          onSubmit={ handleTransfer }
          isLoading={ isBtnLoading }
        /> }
        { step === 3 && <SuccessMobileTransactionsBetweenAccounts
          fromSymbol={ fromAccount?.cfdAccountCurrency.symbol ?? '' }
          toSymbol={ toAccount?.cfdAccountCurrency.symbol ?? '' }
          fromValue={ fromAccountValue }
          toValue={ toAccountValue }
          setOpen={ setOpen }
        />
        }
      </div>
    </div>
  )
}
