import React, { type FC } from 'react';

export const TransferIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g opacity="0.38">
        <path fillRule="evenodd" clipRule="evenodd" d="M21 16.5C22.1046 16.5 23 15.6046 23 14.5V4.5C23 3.4 22.1 2.5 21 2.5L9 2.5C7.89543 2.5 7 3.39543 7 4.5V6.5H3C1.9 6.5 1 7.4 1 8.5L1 19.5C1 20.6 1.9 21.5 3 21.5H17C18.1 21.5 19 20.6 19 19.5V16.5H21ZM17 6.5C18.1 6.5 19 7.4 19 8.5V14.5H21V4.5L9.5 4.5V6.5H17ZM3 8.5L3 19.5H17V8.5H3Z" fill="var(--CFD-theme-System-OnSurface)"/>
        <path d="M6 13.2H10.808V10.8L14 14L10.808 17.2V14.8H6V13.2Z" fill="var(--CFD-theme-System-OnSurface)"/>
        </g>
    </svg>
  )
}

export const TransferTetriaryIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M21 16.5C22.1046 16.5 23 15.6046 23 14.5V4.5C23 3.4 22.1 2.5 21 2.5L9 2.5C7.89543 2.5 7 3.39543 7 4.5V6.5H3C1.9 6.5 1 7.4 1 8.5L1 19.5C1 20.6 1.9 21.5 3 21.5H17C18.1 21.5 19 20.6 19 19.5V16.5H21ZM17 6.5C18.1 6.5 19 7.4 19 8.5V14.5H21V4.5L9.5 4.5V6.5H17ZM3 8.5L3 19.5H17V8.5H3Z" fill="var(--CFD-theme-System-OnTertiary)"/>
      <path d="M6 13.2H10.808V10.8L14 14L10.808 17.2V14.8H6V13.2Z" fill="var(--CFD-theme-System-OnTertiary)"/>
    </svg>
  )
}
