import React, { type FC, type ChangeEvent, useState } from 'react';
import { RadioGroup, FormControlLabel, FormControl, styled, Typography } from '@mui/material';
import { RadioButtonComponent } from '../../../components';
import { selectLanguage, selectLanguages } from '../../../redux/selectors/language';
import { useAppSelector } from '../../../hooks/redux';
import style from './LanguageOptions.module.css';

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box',
  borderRadius: '6px'
}))

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center'
}))

export const LanguageOptions: FC<any> = ({ onChange }) => {
  const activeLanguage = useAppSelector(selectLanguage);
  const languages = useAppSelector(selectLanguages);

  const [localLang, setLocalLang] = useState(activeLanguage)

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
    setLocalLang(value)
    onChange(value)
  };

  return (
    <StyledFormControl>
      <StyledRadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={localLang}
        onChange={handleChange}
      >
        {languages.map((el) => (
          <FormControlLabel
            sx={{
              display: 'flex',
              border: 'none',
              borderRadius: '6px',
              paddingTop: '8px',
              paddingLeft: '16px',
              paddingBottom: '8px',
              paddingRight: '24px',
              marginLeft: '0',
              marginRight: '0',
              width: '100%',
              backgroundColor: 'var(--CFD-theme-Surface-3)',
              marginTop: '16px',
              '&:hover': {
                backgroundColor: 'var(--CFD-theme-State-layers-on-surface-variant-opacity_08)'
              }
            }}
            key={el.code}
            value={el.code}
            label={
              <Typography component='div' sx={{ flexGrow: 1, textAlign: 'left', width: '100%' }}>
                <div className={ style.languageContainer }>
                  <div className={ style.language }>
                    {
                      el.icon !== null
                        ? <img className={style.languageIcon} src={el.icon} />
                        : <el.icon style={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                    }
                    <p className={ style.languageText }>
                      {el.language}
                    </p>
                  </div>
                </div>
              </Typography>
            }
            control={ <RadioButtonComponent checked={localLang === el.code} /> }
            labelPlacement="start"
          />
        ))}
      </StyledRadioGroup>
    </StyledFormControl>
  )
}
