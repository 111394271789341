import React, { useEffect, type FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { getUserByToken } from '../redux/reducers/user';
import { type RouteLayoutInterface } from '../interfaces';
import { NavigationRail, Header, Footer } from '../components';
import { useWindowSize } from '../hooks/useWindowSize';

import styles from '../App.module.css';

export const PrivateLayout: FC<RouteLayoutInterface> = ({ isUserAuth, children }): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [screenWidth] = useWindowSize();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const isAlertMessage = useAppSelector((state) => state.alertMessage.isAlertMessage);
  const location = useLocation();
  const isAlertMessageShown = (isAlertMessage && location.pathname.includes('/trade'));

  const isMobile = screenWidth < 600;

  useEffect(() => {
    if (!isUserAuth) {
      navigate('/login');
      return;
    }

    dispatch(getUserByToken())
  }, [isUserAuth]);

  return (
    <div className={styles.pageContainer}>
      <NavigationRail />
      <Header openMenu={ openMenu } setOpenMenu={ setOpenMenu }/>
      <div
        className={styles.contentContainer}
        style={{
          padding: isMobile
            ? (isAlertMessageShown ? '66px 0' : '72px 0 64px 0')
            : '72px 0 64px 80px'
        }}
      >
        {children}
      </div>
      <Footer setOpenMenu={ setOpenMenu }/>
    </div>
  );
}
