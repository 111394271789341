import React, { type ReactNode, type FC, useEffect, useState } from 'react';

import style from './TimelineProgressComponent.module.css';

interface InterfaceTimelineProgressComponent {
  amountOfTimelines: number
  heigth: number
  percentageOfFill: number
  paddingBetweenTimeline: number
  timeLines: Array<{ label: string, value?: string }>
}

export const TimelineProgressComponent: FC<InterfaceTimelineProgressComponent> = ({
  amountOfTimelines,
  heigth,
  percentageOfFill,
  paddingBetweenTimeline,
  timeLines
}) => {
  const [dots, setDots] = useState<unknown>([]);
  const fillableHeight = heigth - 9;
  const containerHeight = heigth + 9;

  const progressFillHeight = (fillableHeight * percentageOfFill) / 100;

  const createSpans = (amount: number, timelineArray: Array<{ label: string, value?: string }>): unknown[] => {
    const spans = [];
    const avarageHeight = (heigth / amount)
    for (let i = 0; i <= amount; i++) {
      if (i === 0) {
        spans.push(
          <div className={ style.dotsWrapper } style={{ paddingLeft: `${paddingBetweenTimeline}px` }} key={ timelineArray[i].label }>
            <span className={ style.dot } style={{ top: '0px' }}></span>
            <div className={ style.contentWrapper } style={{
              width: `calc(100% - ${paddingBetweenTimeline}px)`,
              justifyContent: 'space-between',
              top: '0px'
            }}>
              <p className={ style.label }>{timelineArray[i].label}</p>
              { (timelineArray[i].value != null) ? <p className={ style.value }>{timelineArray[i].value}</p> : null}
            </div>
          </div>
        );
      } else {
        spans.push(
          <div className={ style.dotsWrapper } style={{ paddingLeft: `${paddingBetweenTimeline}px` }} key={ timelineArray[i].label }>
            <span className={ style.dot } style={{ top: `${avarageHeight * i}px` }}></span>
            <div
              className={ style.contentWrapper }
              style={{ width: `calc(100% - ${paddingBetweenTimeline}px)`, justifyContent: 'space-between', top: `${avarageHeight * i}px` }}
            >
              <p className={ style.label }>{timelineArray[i].label}</p>
              { (timelineArray[i].value != null) ? <p className={ style.value }>{timelineArray[i].value}</p> : null}
            </div>
        </div>
        );
      }
    }
    return spans;
  };

  useEffect(() => {
    setDots(createSpans(amountOfTimelines, timeLines));
  }, [amountOfTimelines, timeLines])

  return (
    <div className={ style.wrapper } style={{ height: `${containerHeight}px` }} >
      <span className={ style.lineBody } style={{ height: `${fillableHeight}px` }}></span>
      { dots as ReactNode }
      <span className={ style.lineFill } style={{ height: `${progressFillHeight}px` }}></span>
    </div>
  );
};
