import React, { type FC, useState, type SyntheticEvent } from 'react';
import { TableBody, TableCell, TableRow, styled, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import { type ITransactionHistory } from '../../../../../redux/reducers/transactionsHistory';
import { EmptyTableComponent } from '../../../../../components';
import { formatDate } from '../../../../../helpers/util';

import style from './MobileStyledAccountsTableBody.module.css';

const MobileStyledTableRow = styled(TableRow)(() => ({
  backgroundColor: 'var(--CFD-theme-Surface-1)',
  color: 'var(--CFD-theme-System-OnSurface)'
}));

const MobileStyledTableCell = styled(TableCell)(() => ({
  backgroundColor: 'var(--CFD-theme-Surface-1)',
  color: 'var(--CFD-theme-System-OnSurface)',
  padding: '0',
  borderBottom: '1px solid var(--CFD-theme-System-OutlineVariant)'
}));

const MobileStyledAccordion = styled(Accordion)(() => ({
  backgroundColor: 'var(--CFD-theme-Surface-2)'
}));

const MobileStyledAccordionSummary = styled(AccordionSummary)(() => ({
  backgroundColor: 'var(--CFD-theme-Surface-2)',
  '& .MuiAccordionSummary-content': {
    margin: 0
  },
  '&.Mui-expanded': {
    minHeight: '40px',
    '& .MuiAccordionSummary-content': {
      margin: 0,
      minHeight: '20px'
    }
  }
}));

const MobileStyledAccordionDetails = styled(AccordionDetails)(() => ({
  backgroundColor: 'var(--CFD-theme-System-OutlineVariant)',
  paddingTop: '0'
}));

interface InterfaceMobileStyledAccountsTableBody {
  rows: ITransactionHistory[]
  setAmount: (row: ITransactionHistory) => string
}

export const MobileStyledAccountsTableBody: FC<InterfaceMobileStyledAccountsTableBody> = ({ rows, setAmount }) => {
  const [expanded, setExpanded] = useState<string | boolean>(false);

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      { rows.length > 0
        ? (<TableBody>
            {rows.map((row) => (
              <MobileStyledTableRow key={ row._id }>
              <MobileStyledTableCell colSpan={1000}>
                <MobileStyledAccordion expanded={expanded === row._id } onChange={handleChange(row._id)}>
                  <MobileStyledAccordionSummary
                    aria-controls={ row._id }
                    id={ row._id }
                    sx={{ backgroundColor: `${expanded === row._id ? 'var(--CFD-theme-System-OutlineVariant)' : 'var(--CFD-theme-Surface-2)'}` }}
                  >
                    <div className={ style.rowWrapper }>
                      <p className={ style.rowWrapperTitle }>{ formatDate(new Date(row.createdAt)) }</p>
                      <p className={ (Number(setAmount(row)) > 0) ? `${style.rowWrapperTitle} ${style.textGreen}` : `${style.rowWrapperTitle}`}>
                        { setAmount(row) }
                      </p>
                    </div>
                  </MobileStyledAccordionSummary>
                  <MobileStyledAccordionDetails >
                    <div className={ style.accordionWrapper }>
                      <div className={ style.accordionRow }>
                        <p className={ style.accordionMainTitle }>ID</p>
                        <p className={ style.accordionMainText }>
                          { row.customId }
                        </p>
                      </div>
                      <div className={ style.accordionRow }>
                        <p className={ style.accordionMainTitle }>Type</p>
                        <p className={ style.accordionMainText }>
                          { row.type }
                        </p>
                      </div>
                      <div className={ style.accordionRow }>
                        <p className={ style.accordionMainTitle }>Asset</p>
                        <p className={ style.accordionMainText }>
                          { row.asset }
                        </p>
                      </div>
                      <div className={ style.accordionRow }>
                        <p className={ style.accordionMainTitle }>Status</p>
                        <p className={ style.accordionMainText } style={{ color: `${row.statusColor}` }}>
                          { row.status }
                        </p>
                      </div>
                    </div>
                  </MobileStyledAccordionDetails>
                </MobileStyledAccordion>
              </MobileStyledTableCell>
            </MobileStyledTableRow>
            ))}
          </TableBody>)
        : (<EmptyTableComponent columnCount={2} />) }
   </>
  )
}
