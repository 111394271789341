import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import style from './HeaderModalRedeem.module.css';

interface InterfaceHeaderModalRedeem {
  setOpen: (el: boolean) => void
  setStep: (el: number) => void
}

export const HeaderModalRedeem: FC<InterfaceHeaderModalRedeem> = ({ setOpen, setStep }) => {
  const { t } = useTranslation();
  const handleBtnClick = (): void => {
    setOpen(false)
    setStep(1);
  }
  return (
    <header className={ style.dialogHeader }>
      <div className={ style.dialogHeaderContainer }>
        <div className={ style.dialogHeaderContent }>
          <h3 className={ style.dialogHeaderTitle }>
            { t('savings_page.table_body.accounts_tab.accordion_details.button.redeem') }
          </h3>
        </div>
        <div className={ style.dialogHeaderBtnContainer }>
          <CloseOutlinedIcon onClick={ handleBtnClick } sx={{ color: 'var(--CFD-theme-System-OnSurface)', cursor: 'pointer' }}/>
        </div>
      </div>
    </header>
  )
}
