import React, { type FC } from 'react';
import { type INew } from '../../../../../redux/reducers/news';
import styles from './NewItem.module.css'

interface INewItemProps {
  cfdNew: INew
}

export const NewItem: FC<INewItemProps> = ({ cfdNew }) => {
  const cutDate = (date: string): string => {
    const to = date.indexOf('T');

    return date.slice(0, to);
  }

  return (
    <a href={cfdNew.link} target='_blank' className={`${styles.wrapper} newItemWrapper`} rel="noreferrer">
        <span className={styles.container}>
            <span className={styles.time}>{cutDate(cfdNew.date ?? '')}</span>
            <h3 className={styles.title}>{cfdNew.title}</h3>
            <p className={styles.content}>{cfdNew.content}</p>
        </span>
    </a>

  )
}
