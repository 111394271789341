import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './axiosBaseQuery';

export const withdrawalTypesApi = createApi({
  reducerPath: 'withdrawalTypesApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getWithdrawalTypes: builder.query({
      query: () => ({
        method: 'GET',
        path: '/withdrawal-types'
      })
    })
  })
});

export const { useGetWithdrawalTypesQuery } = withdrawalTypesApi;
