import React, { type FC, useEffect, useState } from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { type SortStatusType, type SortStatusTypeAndNull, type IFilterQueryHistoryTable, type ITableContainerProps } from '../../interface/savingsPageInterfaces';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getSavingInvestmentsHistory } from '../../../../redux/reducers/savingInvestment';
import { TableHeadHistory } from './components/TableHeadHistory/TableHeadHistory';
import { EmptyTableComponent } from '../../../../components';
import { SavingsElementHeight, formatPreatyDate } from '../../helper';
import { HistoryTableCell } from './HistoryTableCell/HistoryTableCell';
import { HistoryTableHeader } from './HistoryTableHeader/HistoryTableHeader';
import { MobileTableHeadHistory } from './components/MobileTableHeadHistory/MobileTableHeadHistory';
import { type ISavingHistoryInvestment } from '../../../../interfaces';

export const HistoryTable: FC<ITableContainerProps> = ({ pageContentHeight, screenWidth }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { savingsHistoryInvestmentData } = useAppSelector((state) => state.savingInvestments);
  const { _id: userId } = useAppSelector((state) => state.user);
  const [showTimeInSelect, setShowTimeInSelect] = useState<string>(t('labels.redeem_date'));
  const [savingAccountFilter, setSavingAccountFilter] = useState<string | null>(null);
  const [cfdAccountFilter, setCfdAccountFilter] = useState<string | null>(null);
  const [filterQuery, setFilterQuery] = useState<IFilterQueryHistoryTable>({});
  const [flagReload, setFlagReload] = useState<boolean>(false);
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<SortStatusTypeAndNull>(null);

  const historyTableHeaderStorage = 'Savings_Page/HistoryTable';
  const sortTableStorage = 'Savings_Page/Sort/HistoryTable';

  const isTable = (screenWidth <= 725);

  const setStorageFilterData = (): void => {
    const rawFilterStorageData = localStorage.getItem(historyTableHeaderStorage);
    const storageFilterData = rawFilterStorageData ? JSON.parse(rawFilterStorageData) : {};
    if (storageFilterData) {
      setCfdAccountFilter(storageFilterData?.redeemToAccount as string ?? null);
      setSavingAccountFilter(storageFilterData?.savingAccount as string ?? null);
      setShowTimeInSelect(storageFilterData?.redeemDate
        ? formatPreatyDate(storageFilterData?.redeemDate as string)
        : t('labels.redeem_date'));
      setFilterQuery(storageFilterData as IFilterQueryHistoryTable);
    }
  }

  const setStorageSortData = (): void => {
    const rawStorageSortData = localStorage.getItem(sortTableStorage);
    const storageSortData = rawStorageSortData ? JSON.parse(rawStorageSortData) : {};
    if (storageSortData) {
      const filterStorageArray = Object.entries(storageSortData as Record<string, unknown>);
      if (filterStorageArray.length > 0) {
        const [key, value] = filterStorageArray[0];
        setSortField(key);
        setSortOrder(value as SortStatusType)
      } else {
        setSortField('createdAt')
        setSortOrder('idle');
      }
    }
  }

  const getDataFromLocalStorage = (): void => {
    setStorageFilterData();
    setStorageSortData();
    setFlagReload(true);
  }

  useEffect(() => {
    getDataFromLocalStorage();
  }, [])

  useEffect(() => {
    if (userId !== undefined) {
      if (flagReload && (sortField !== null) && (sortOrder !== null)) {
        localStorage.setItem(historyTableHeaderStorage, JSON.stringify(filterQuery));
        localStorage.setItem(sortTableStorage, JSON.stringify({ [sortField]: sortOrder }))
        dispatch(getSavingInvestmentsHistory({ userId, query: { sortField, sortOrder, ...filterQuery } }))
      }
    }
  }, [userId, filterQuery, flagReload, sortField, sortOrder]);

  return (
    <>
      <HistoryTableHeader
        setFilterQuery={ setFilterQuery }
        setShowTimeInSelect={ setShowTimeInSelect }
        showTimeInSelect={ showTimeInSelect }
        savingAccountFilter={ savingAccountFilter }
        setSavingAccountFilter={ setSavingAccountFilter }
        cfdAccountFilter={ cfdAccountFilter }
        setCfdAccountFilter={ setCfdAccountFilter }
      />
      <TableContainer
        sx={{
          maxHeight: `${pageContentHeight}px`,
          minHeight: `${pageContentHeight}px`,
          paddingBottom: '0',
          borderRadius: '4px',
          backgroundColor: 'var(--CFD-theme-Surface-1) !important'
        }}
      >
        <Table stickyHeader aria-label="customized table" sx={{ width: '100%', height: '100%', backgroundColor: 'var(--CFD-theme-Surface-1) !important' }}>
          {
            !isTable
              ? (
                  <TableHeadHistory
                    sortField={ sortField }
                    setSortField={ setSortField }
                    sortOrder={ sortOrder }
                    setSortOrder={ setSortOrder }
                  />
                )
              : (
                  <MobileTableHeadHistory
                    sortField={ sortField }
                    setSortField={ setSortField }
                    sortOrder={ sortOrder }
                    setSortOrder={ setSortOrder }
                  />
                )
          }
          {
            (savingsHistoryInvestmentData.length > 0)
              ? (
                  <TableBody sx={{ height: '100%', backgroundColor: 'var(--CFD-theme-Surface-1) !important' }} >
                    { savingsHistoryInvestmentData.map((item: ISavingHistoryInvestment) => (
                      <HistoryTableCell row={ item } key={ item._id } isTable={ isTable } />
                    )) }
                  </TableBody>
                )
              : (
                  <EmptyTableComponent columnCount={ 100 } height={ `${pageContentHeight - SavingsElementHeight.PaddingTop - SavingsElementHeight.AccountsTableFirstRow}px` }/>
                )
          }
        </Table>
      </TableContainer>
    </>
  );
}
