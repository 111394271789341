import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHead } from '@mui/material';

import { FilterTableIcon } from '../../../../../../components/icons/FilterTableIcon';
import { type SortStatusTypeAndNull, type ITableHeadHistory } from '../../../../interface/savingsPageInterfaces';
import { StyledTableRow } from '../../../StyledTableRow/StyledTableRow';
import { StyledTableCell } from '../../../StyledTableCell/StyledTableCell';

import style from './MobileTableHeadHistory.module.css';

export const MobileTableHeadHistory: FC<ITableHeadHistory> = ({ sortField, setSortField, sortOrder, setSortOrder }) => {
  const { t } = useTranslation();

  const filterButtonHandler = (id: string): void => {
    setSortField(id);
    setSortOrder(sortField === id ? (sortOrder === 'desc' ? 'asc' : 'desc') : 'asc');
  }

  const cumulativeTotalSortStatus: SortStatusTypeAndNull = sortField === 'cumulativeTotal' ? sortOrder : 'idle';

  return (
    <TableHead>
      <StyledTableRow sx={{ width: '100%' }}>
        <StyledTableCell colSpan={ 100 } sx={{ backgroundColor: 'var(--CFD-theme-Surface-1) !important' }}>
          <div className={ style.wrapper }>
            <div className={ style.btnContainer }>
              <p className={ style.tableHeadersTitle }>
                { `${t('labels.savings')}  ${t('labels.account_lower')}` }
              </p>
            </div>
            <div className={ style.btnContainer } onClick={() => { filterButtonHandler('cumulativeTotal'); } }>
              <p className={ style.tableHeadersTitle }>
                { t('savings_page.table_head.accounts_tab.total_title') }
              </p>
              <button className={ style.cellTextBtnIcon }>
                <FilterTableIcon status={ cumulativeTotalSortStatus } />
              </button>
            </div>
          </div>
        </StyledTableCell>
      </StyledTableRow>
    </TableHead>
  )
}
