import React, { type FC } from 'react';
import { type IconInterface } from '../../interfaces';

export const EuroIcon: FC<IconInterface> = ({ width, height, color }) => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 24} height={height ?? 24} viewBox="0 0 18 18" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.1875 7.5C3.1875 7.18934 3.43934 6.9375 3.75 6.9375H9.75C10.0607 6.9375 10.3125 7.18934 10.3125 7.5C10.3125 7.81066 10.0607 8.0625 9.75 8.0625H3.75C3.43934 8.0625 3.1875 7.81066 3.1875 7.5Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.1875 10.5C3.1875 10.1893 3.43934 9.9375 3.75 9.9375H9.75C10.0607 9.9375 10.3125 10.1893 10.3125 10.5C10.3125 10.8107 10.0607 11.0625 9.75 11.0625H3.75C3.43934 11.0625 3.1875 10.8107 3.1875 10.5Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0961 2.8125C7.7719 2.8125 5.8125 4.86793 5.8125 7.5V10.5C5.8125 13.1321 7.7719 15.1875 10.0961 15.1875C11.6315 15.1875 12.9978 14.2998 13.7584 12.9323C13.9094 12.6608 14.2519 12.5631 14.5234 12.7141C14.7949 12.8651 14.8926 13.2076 14.7416 13.4791C13.8062 15.1608 12.0865 16.3125 10.0961 16.3125C7.06749 16.3125 4.6875 13.6669 4.6875 10.5V7.5C4.6875 4.33309 7.06749 1.6875 10.0961 1.6875C12.0865 1.6875 13.8062 2.83918 14.7416 4.52088C14.8926 4.79237 14.7949 5.13487 14.5234 5.28588C14.2519 5.43689 13.9094 5.33922 13.7584 5.06773C12.9978 3.70019 11.6315 2.8125 10.0961 2.8125Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'}/>
        </svg>
  )
}
