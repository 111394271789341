import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
  isLoading: boolean
}

const initialState: ModalState = {
  isLoading: false
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalLoading (state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    }
  }
});

export const { setModalLoading } = modalSlice.actions;
export default modalSlice.reducer;
