import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type PayloadInterface } from '../../interfaces';
import { apiHelper } from '../api/apiHelper';
import { t } from 'i18next';
import { type HandleOpenInterface } from '../../components';

export interface MessagesInterface {
  chatId: string
  content: string
  createdAt: string
  isRead: boolean
  sender: string
  fileName: string
  updatedAt: string
  _id: string
  tempMessageId?: string
}

interface UpdateMessagePayload {
  tempId: string
  data: MessagesInterface
}

interface ChatInterface {
  _id: string
  crmUser: string
  user: string
  allowedCRMUsers: any[]
  createdAt: string
  messages: MessagesInterface[]
  updatedAt: string
  __v: number
  isUserCanSendAMessage: boolean
}

interface ResponseChatInterface {
  success: boolean
  chat: ChatInterface
}

interface ReadChatInterface {
  success: boolean
}

const initialState: ChatInterface = {
  _id: '',
  allowedCRMUsers: [],
  createdAt: '',
  crmUser: '',
  messages: [],
  updatedAt: '',
  user: '',
  __v: 0,
  isUserCanSendAMessage: false
};

export const readChat = createAsyncThunk(
  'chat/read',
  async (data: { chatId: string, content?: string, sender: string }, { rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'put', path: '/chat/read', data });

      return response.data;
    } catch (error: any) {
      const { data } = error.response;

      const errorPayload: PayloadInterface = {
        error: data.message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const getChatMessages = createAsyncThunk(
  'chat/get',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await apiHelper({
        method: 'get',
        path: `/chat/user-chat/${id}`,
        data: ''
      });

      return response.data
    } catch (error: any) {
      const { data } = error.response;

      const errorPayload: PayloadInterface = {
        error: data.message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    updateMessageByTempId (state, action: PayloadAction<UpdateMessagePayload>) {
      state.messages = state.messages.map(message =>
        message.tempMessageId === action.payload.tempId
          ? action.payload.data
          : message
      );
    },
    deleteMessageByTempId (state, action: PayloadAction<string>) {
      const tempId = action.payload;
      state.messages = state.messages.filter(message => message.tempMessageId !== tempId);
    },
    getMessage: (state, action: PayloadAction<MessagesInterface>) => {
      state.messages.push(action.payload)
    },
    setChat: (state, action: PayloadAction<ChatInterface>) => {
      Object.assign(state, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(readChat.fulfilled, (state, action: PayloadAction<ReadChatInterface>) => {
      state.messages.forEach(message => {
        if (state.user !== message.sender) {
          message.isRead = true
        }
      });
    });
    builder.addCase(getChatMessages.fulfilled, (state, action: PayloadAction<ResponseChatInterface>) => {
      Object.assign(state, { ...action.payload.chat, isUserCanSendAMessage: true });
    });
  }
});

export const { getMessage, updateMessageByTempId, deleteMessageByTempId, setChat } = chatSlice.actions;

const generateTempId = (): string => (Math.random() * 100).toFixed()

interface SendMessagePropsType {
  data: FormData
  handleOpen: (props: HandleOpenInterface) => void
}

export const sendMessage = createAsyncThunk(
  'chat/send',
  async ({ data, handleOpen }: SendMessagePropsType, { dispatch, rejectWithValue }) => {
    const temporaryId = generateTempId();

    try {
      const chatId = data.get('id') as string;
      const content = data.get('content') as string;
      const sender = data.get('sender') as string;
      const file = data.get('file') as string;
      const timeNow = (new Date()).toISOString();

      const temporaryMessage = {
        tempMessageId: temporaryId,
        chatId,
        content,
        sender,
        isRead: false,
        fileName: file !== null ? t('liveChat.file_uploading') : '',
        createdAt: timeNow,
        updatedAt: timeNow
      }

      dispatch(getMessage(temporaryMessage as MessagesInterface))

      const response = await apiHelper({
        method: 'post',
        path: `/chat/message/${chatId}`,
        data
      });

      if (response?.data?.success === true) {
        if (response?.data?.newChatId !== undefined) {
          const chatResponse = await apiHelper({
            method: 'get',
            path: `/chat/user-chat/${response.data.message.sender}`
          });
          if (chatResponse?.data?.success === true) {
            dispatch(setChat(chatResponse.data.chat as ChatInterface))
          }
        } else {
          dispatch(updateMessageByTempId({
            tempId: temporaryId,
            data: response.data.message
          }))
        }
      }
    } catch (error: any) {
      const { data } = error.response;

      dispatch(deleteMessageByTempId(temporaryId));

      handleOpen({
        message: data.message,
        actionText: '',
        severity: 'error'
      });

      const errorPayload: PayloadInterface = {
        error: data.message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export default chatSlice.reducer;
