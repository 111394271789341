import React, { type FC } from 'react';
import { RadioGroup, FormControlLabel, FormControl, styled } from '@mui/material';
import { t } from 'i18next';

import { RadioButtonComponent } from '../../../../components';
import style from './AssetsOptions.module.css';
import { type AssetsOptionProps } from '../../interface/savingsPageInterfaces';

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box'
}))

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  alignItems: 'center',
  boxSizing: 'border-box',
  paddingLeft: '16px',
  gap: '20px',
  paddingBottom: '10px',
  maxHeight: '70px',
  overflowX: 'auto',
  overflowY: 'hidden',
  maxWidth: '100%',
  paddingTop: '10px',
  '@media (max-width: 1240px)': {
    paddingTop: '0'
  }
}))

export const AssetsOptions: FC<AssetsOptionProps> = ({ options = [{ _id: 'test', label: 'label', percent: '0' }], selectedAsset, handleChange }) => {
  return (
    <StyledFormControl>
      <StyledRadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={ selectedAsset }
        onChange={ ({ target: { value } }) => { handleChange(value); } }
      >
        {options.map((item) => (
          <FormControlLabel
            sx={{
              border: `1px solid ${selectedAsset === item.label ? 'var(--CFD-theme-System-Tertiary)' : 'var(--CFD-theme-System-OutlineVariant)'}`,
              borderRadius: '6px',
              minWidth: '100px',
              width: '110px',
              height: '48px',
              marginRight: '25px',
              '&:hover': {
                backgroundColor: 'var(--CFD-theme-State-layers-on-surface-variant-opacity_08)'
              }
            }}
            key={item._id}
            value={item._id}
            control={<RadioButtonComponent />}
            label={<div className={ style.assetsCheckContainer }>
              <p className={ style.assetsCheckContainerTitle }>{ t(`savings_page.duration_types.${item.label}`) }</p>
              <p className={ style.assetsCheckContainerAmount }>{ item.percent }%</p>
            </div>}
          />
        ))}
      </StyledRadioGroup>
    </StyledFormControl>
  )
}
