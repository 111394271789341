export enum AccountsElementHeight {
  HeaderSection = 72,
  PaddingTop = 32,
  TableHeader = 64,
  TableSubHeader = 254,
  TableHeadRow = 54,
  TableHeaderRow = 57,
  TablePadding = 32,
  TransferAccordion = 72,
  Footer = 64,
  Gap = 20,
  Header = 72,
  MobileSubHeader = 364
}

export const modalsAssetsSpacing = {
  Crypto: 168,
  Card: 130,
  Bank: 20,
  Other: 50
}
