import React, { type FC } from 'react';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { BinIcon } from '../../../../components/icons/BinIcon';
import { kycVerificationSelector } from '../../../../redux/selectors/kycVerification';
import { useAppSelector } from '../../../../hooks/redux';
import style from './VerificationContent.module.css';
import { t } from 'i18next';
import { profileSettingsSelector } from '../../../../redux/selectors/profileSettings';

interface InterfaceVerificationContent {
  handleEditIcon: () => void
  handleBinIcon: () => void
}

export const VerificationContent: FC<InterfaceVerificationContent> = ({ handleBinIcon, handleEditIcon }) => {
  const {
    verificationStatus: statusVerification,
    kycVerificationDate: verificationDate
  } = useAppSelector(profileSettingsSelector);
  const { userVerifyStatus: kycVerifyStatus } = useAppSelector(kycVerificationSelector);
  const verifiedStatus = kycVerifyStatus === 'verified'

  const statusTextColor = verifiedStatus ? style.successDocs : style.unSuccessDocs
  const statusBgColor = verifiedStatus ? style.successBackground : style.errorBackground

  return (
    <div className={ style.settings2FAVariantWrapper }>
      <div className={ style.settings2FAVariantContainer }>
        <div className={ style.settings2FATitleContainer }>
          <div className={ style.settingVerificationTitleContainer }>
            <h5 className={ style.settingsVerificationTitle }>{t('profile_page.settings.complete_profile.titles.identity_verifications')}</h5>
            <div className={ `${style.settingVerificationStatusContainer} ${statusTextColor} ${statusBgColor}` }>
              { t(`profile_page.settings.complete_profile.verification_content.statuses.${statusVerification}`) }
            </div>
          </div>
          <div className={ style.settings2FAIconContainer }>
            <CreateOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)', cursor: 'pointer' }} onClick={ handleEditIcon }/>
            <BinIcon onClick={ handleBinIcon }/>
          </div>
        </div>
        <div className={ style.settingsVerificationBtn }>
          <p className={ style.settingsVerificationParagraph }>{t('labels.added')}: { verificationDate }</p>
        </div>
      </div>
    </div>
  )
}
