import React, { type FC } from 'react'
import style from './BalanceChart.module.css'
import { CurrencyTabs } from './CurrencyTabs/CurrencyTabs'
import { ProfileElementHeight } from '../helper';
import { t } from 'i18next'

export type TabsDataType = TabDataType[]
export interface TabDataType {
  currency: string
  symbol: string
  amount: number
  XAxis: string[]
  chart: ChartData[]
}

interface ChartData {
  data: Array<number | null>
  label: string
  connectNulls?: boolean
  area?: boolean
  showMark?: boolean
  color?: string
}

interface IBalanceChart {
  calcHeight: number
}

export const BalanceChart: FC<IBalanceChart> = ({ calcHeight }) => {
  const balanceTableHeight = ((calcHeight / 2) - (ProfileElementHeight.Gap / 2))
  const currencyTabHeight = (balanceTableHeight - ProfileElementHeight.BalanceChartHeader -
    ProfileElementHeight.BalanceChartMargin - ProfileElementHeight.BalanceChartMargin - ProfileElementHeight.BalanceChartFooter);

  return (
    <div className={style.wrapper}
      style={{ height: `${balanceTableHeight}px` }}
    >
      <div className={style.title}>
        {t('profile_page.labels.balance_chart')}
      </div>
      <div className={style.content}>
        <CurrencyTabs currencyTabHeight={ currencyTabHeight }/>
      </div>
    </div>
  )
}
