import React, { type ReactElement, type FC } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { ButtonComponent } from '../../components';

import styles from './TooltipComponent.module.css';

type TypePlacement = 'bottom' | 'left' | 'right' | 'top' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start';

interface InterfaceTooltip {
  title: string
  content: string
  btnFirstTitle?: string
  btnSecondTitle?: string
  children: ReactElement<any, any>
  onClickFirstBtn?: () => void
  onClickSecondBtn?: () => void
  arrow?: boolean
  disableFocusListener?: boolean
  disableHoverListener?: boolean
  disableTouchListener?: boolean
  followCursor?: boolean
  placement?: TypePlacement
}

interface InterfaceSmallTooltip {
  content: string
  arrow?: boolean
  children: ReactElement<any, any>
  disableFocusListener?: boolean
  disableHoverListener?: boolean
  disableTouchListener?: boolean
  followCursor?: boolean
  placement?: TypePlacement
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: '312px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '8px',
    paddingTop: '20px',
    backgroundColor: 'var(--CFD-theme-Surface-3)',
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    textAlign: 'start',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'var(--CFD-theme-Surface-3)'
  }
}));

export const TooltipComponent: FC<InterfaceTooltip> = ({
  title,
  content,
  children,
  btnFirstTitle,
  btnSecondTitle,
  onClickFirstBtn,
  onClickSecondBtn,
  arrow = true,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
  followCursor = false,
  placement = 'bottom'
}) => {
  return (
    <>
        <HtmlTooltip title={
            <>
            <h3 className={ styles.tooltiTitle }>{ title }</h3>
            <div className={ styles.tooltipTextContainer }>
                <p className={ styles.tooltipText }>{ content }</p>
            </div>
            <div className={ styles.tooltipBtnContainer }>
                { ((btnFirstTitle != null) && (onClickFirstBtn != null)) && <ButtonComponent variant="text" onClick={ onClickFirstBtn }>{ btnFirstTitle }</ButtonComponent>}
                { ((btnSecondTitle != null) && (onClickSecondBtn != null)) && <ButtonComponent variant="text" onClick={ onClickSecondBtn }>{ btnSecondTitle }</ButtonComponent>}
            </div>
            </>
        }
        arrow={ arrow }
        disableFocusListener={ disableFocusListener }
        disableHoverListener={ disableHoverListener }
        disableTouchListener={ disableTouchListener }
        followCursor={ followCursor }
        placement={ placement }
        >
            { children }
        </HtmlTooltip>
    </>
  );
}

const HtmlSmallTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '50px',
    minHeight: '20px',
    borderRadius: '12px',
    alignItems: 'flex-start',
    backgroundColor: 'var(--CFD-theme-System-Inversesurface)',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'var(--CFD-theme-System-Inversesurface)'
  }
}));

export const TooltipSmallComponent: FC<InterfaceSmallTooltip> = ({
  content,
  arrow = true,
  children,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
  followCursor = false,
  placement = 'bottom'
}) => {
  return (
    <HtmlSmallTooltip
      title={
        <>
          <p className={ styles.smallTooltiText }>{ content }</p>
        </>
      }
      arrow={ arrow }
      disableFocusListener={ disableFocusListener }
      disableHoverListener={ disableHoverListener }
      disableTouchListener={ disableTouchListener }
      followCursor={ followCursor }
      placement={ placement }
    >
        { children }
    </HtmlSmallTooltip>
  );
}
