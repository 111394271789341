import React, { type FC, useState, useEffect, type ReactElement, useLayoutEffect } from 'react';
import { type TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import HistoryIcon from '@mui/icons-material/History';

import { type ButtonLargeDataType, SegmentedLarge } from '../../components';
import { CoinIcon } from '../../components/icons/CoinsIcon';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useWindowSize } from '../../hooks/useWindowSize';
import { SavingsElementHeight, setAccountsTableHeight, setHistoryTableHeight } from './helper';
import { getSavingAccounts } from '../../redux/reducers/savingAccounts';
import { SavingsTable } from './Tables/SavingsTable/SavingsTable';
import { AccountsTable } from './Tables/AccountsTable/AccountsTable';
import { getCurrencyRates } from '../../redux/reducers/currencyRates';
import { HistoryTable } from './Tables/HistoryTable/HistoryTable';
import { type ITableContainerProps, type TSavingSection } from './interface/savingsPageInterfaces';

import style from './Savings.module.css';

function renderSegmentTitle (t: TFunction, currentSection: TSavingSection): ButtonLargeDataType[] {
  const storageTable = localStorage.getItem(currentSection) ?? 'Savings';
  const segmentSavingPageOption: ButtonLargeDataType[] = [
    {
      id: 'Savings',
      render: <span className={ style.headerSegmentBtnTitle }>
        <span className={ style.headerSegmentBtnIcon }>
          <CoinIcon />
        </span>
        { t('savings_page.tabs_titles.savings_tab') }
      </span>,
      selected: (storageTable === 'Savings')
    },
    {
      id: 'Accounts',
      render: <span className={ style.headerSegmentBtnTitle }>
        <span className={ style.headerSegmentBtnIcon }>
          <AccountBalanceWalletOutlinedIcon
            sx={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}
          />
        </span>
        { t('savings_page.tabs_titles.accounts_tab') }
      </span>,
      selected: (storageTable === 'Accounts')
    },
    {
      id: 'History',
      render: <span className={ style.headerSegmentBtnTitle }>
        <span className={ style.headerSegmentBtnIcon }>
          <HistoryIcon
            sx={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}
          />
        </span>
        { t('savings_page.tabs_titles.history_tab') }
      </span>,
      selected: (storageTable === 'History')
    }
  ];

  return segmentSavingPageOption;
}

const SavingsPageNodes = {
  Savings: SavingsTable,
  Accounts: AccountsTable,
  History: HistoryTable
};

export const Savings: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { _id: userId } = useAppSelector((state) => state.user);
  const activeAccount = useAppSelector(state => state.accounts).find(item => item.isActive);
  const [screenWidth, screenHeight] = useWindowSize();
  const [pageContentHeight, setPageContentHeight] = useState<number>(0);
  const [section, setSection] = useState<TSavingSection | null>(null);

  const savingsPageStorage = 'Savings-Page/segment-button'

  const isMobile = (screenWidth <= 650);
  const isSmallTable = (screenWidth > 650 && screenWidth < 905);
  const isBigTable = (screenWidth >= 905 && screenWidth <= 1240);
  const isHistorySmallTable = (screenWidth > 650 && screenWidth < 725);
  const isHistoryBigTable = (screenWidth >= 725 && screenWidth <= 905);
  const threeSelectInRowHistoryTableHeader = (screenWidth >= 705 && screenWidth <= 905);
  const twoSelectInRowHistoryTableHeader = (screenWidth >= 425 && screenWidth < 705);
  const oneSelectInRowHistoryTableHeader = (screenWidth >= 320 && screenWidth < 425);

  const segmentBtnHandler = (selectedButton: ButtonLargeDataType): void => {
    if ((selectedButton?.id) != null) {
      localStorage.setItem(savingsPageStorage, selectedButton.id)
      setSection(selectedButton.id as TSavingSection)
    }
  }

  useLayoutEffect(() => {
    if (screenHeight > 0 && screenWidth > 0) {
      const minimalTableHeight = 300;
      let tableHeader = SavingsElementHeight.HeaderSavingsTable;
      if (section === 'Accounts') {
        tableHeader = setAccountsTableHeight({ isBigTable, isSmallTable, isMobile });
      }

      if (section === 'History') {
        tableHeader = setHistoryTableHeight({
          isHistorySmallTable,
          isMobile,
          threeSelectInRowHistoryTableHeader,
          twoSelectInRowHistoryTableHeader,
          oneSelectInRowHistoryTableHeader
        });
      }

      if (section === 'Savings') {
        if (isMobile) tableHeader = SavingsElementHeight.MobileHeaderSavingsTable;
      }

      const tableHeight = tableHeader + SavingsElementHeight.Gap + SavingsElementHeight.PageHeader + SavingsElementHeight.BorderTable;
      const pageElementHeight = SavingsElementHeight.PaddingTop + SavingsElementHeight.Footer + SavingsElementHeight.Header;
      const tableContentHeight = screenHeight - tableHeight - pageElementHeight;

      const height = tableContentHeight > minimalTableHeight ? tableContentHeight : minimalTableHeight;
      setPageContentHeight(height);
    }
  }, [screenHeight, screenWidth, section]);

  useEffect(() => {
    if (userId !== undefined) {
      dispatch(getSavingAccounts(userId));
    }
  }, [userId]);

  useEffect(() => {
    const storageTable = localStorage.getItem(savingsPageStorage) ?? 'Savings';
    setSection((storageTable as TSavingSection));
    if (activeAccount !== undefined) {
      dispatch(getCurrencyRates(activeAccount.cfdAccountCurrency.symbol));
    }
  }, [])

  const renderSelectedSavingsTable = (): ReactElement => {
    if (section !== null) {
      const Component = SavingsPageNodes[section];

      let props: ITableContainerProps = { setSection, section, pageContentHeight, screenWidth, isMobile };

      if (section === 'History') {
        props = { ...props, isHistorySmallTable, isHistoryBigTable }
      }

      return <Component { ...props }/>;
    }
    return <></>
  };

  return (
    <main className={ style.wrapper }>
        <header className={ style.header }>
          <SegmentedLarge propsButtonsData={ renderSegmentTitle(t, savingsPageStorage as TSavingSection) } onChange={ segmentBtnHandler }/>
        </header>
        <section className={ style.pageSection }>
          { renderSelectedSavingsTable() }
        </section>
    </main>
  )
}
