import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css'
import { DefaultLogo } from '../../components/icons/DefaultLogo';
import PageNotFoundLightImg from '../../assets/images/pageNotFoundLight.svg'
import PageNotFoundDarkImg from '../../assets/images/pageNotFoundDark.svg'
import { ButtonComponent, useTheme } from '../../components'

export const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { theme } = useTheme();

  const handleClick = (): void => {
    navigate('/trade?asset=EURUSD')
  }

  return (
    <div className={styles.pageWrapper}>

        <div className={styles.header}>
            <DefaultLogo />
        </div>

        <div className={styles.contentContainer}>
            <img src={theme === 'dark' ? PageNotFoundDarkImg : PageNotFoundLightImg} alt="PageNotFoundImg" className={styles.notFoundImage} />

            <div className={styles.contentWrapper}>
                <p className={styles.contentText}>{t('not_found_page.text_content')}</p>

                <div className={styles.buttonWrapper}>
                    <ButtonComponent onClick={handleClick}>
                        {t('not_found_page.button_text')}
                    </ButtonComponent>
                </div>
            </div>
        </div>

    </div>
  )
}
