import React, { useState, type FC } from 'react';

import { ModalContext } from '../ModalContext/ModalContext';
import { HeaderModalVerifications } from './Header/HeaderModalVerifications';
import { SectionModalVerifications } from './Section/SectionModalVerifications';
import { FooterModalVerifications } from './Footer/FooterModalVerifications'
import { useBodyOverflow } from '../../hooks/useBodyOverflow';
import { useAppSelector } from '../../hooks/redux';
import { selectKycTypes } from '../../redux/selectors/kycTypes';
import { type KycType } from '../../redux/reducers/kycTypes';

interface IModalVerifications {
  open: boolean
  setOpen: (el: boolean) => void
}

type TModalVerifications = IModalVerifications

export const ModalVerifications: FC<TModalVerifications> = ({ open, setOpen }) => {
  useBodyOverflow(open);
  const [isFrontSideDocsPicked, setIsFrontSideDocsPicked] = useState<boolean>(false);
  const [isBackSideDocsPicked, setIsBackSideDocsPicked] = useState<boolean>(false);
  const [isFrontSideCardPicked, setIsFrontSideCardPicked] = useState<boolean>(false);
  const [isBackSideCardPicked, setIsBackSideCardPicked] = useState<boolean>(false);
  const [isSelfiePicked, setIsSelfiePicked] = useState<boolean>(false);
  const [isAddressPicked, setIsAddressPicked] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const requiredKycTypes = useAppSelector(selectKycTypes);

  const getCurrentKycType = (index: number): KycType => {
    return requiredKycTypes[index];
  };

  return (
    <ModalContext
      setOpen={setOpen}
      open={open}
      headerComponent={
        <HeaderModalVerifications currentIndex={currentIndex} totalSteps={requiredKycTypes.length} currentKycType={getCurrentKycType(currentIndex)} setIsOpen={setOpen} />
      }
      sectionComponent={
        <SectionModalVerifications
          currentKycType={ getCurrentKycType(currentIndex) }
          setFrontSideDocsPicked={ setIsFrontSideDocsPicked }
          setBackSideDocsPicked={ setIsBackSideDocsPicked }
          setFrontSideCardPicked={ setIsFrontSideCardPicked }
          setBackSideCardPicked={ setIsBackSideCardPicked }
          setSelfiePicked={ setIsSelfiePicked }
          setAddressPicked={ setIsAddressPicked }
        />
      }
      footerComponent={
        <FooterModalVerifications
          currentIndex={currentIndex}
          totalSteps={requiredKycTypes.length}
          frontSideDocsPicked={ isFrontSideDocsPicked }
          backSideDocsPicked={ isBackSideDocsPicked }
          frontSideCardPicked={ isFrontSideCardPicked }
          backSideCardPicked={ isBackSideCardPicked }
          selfiePicked={ isSelfiePicked }
          addressPicked={ isAddressPicked }
          setCurrentIndex={setCurrentIndex}
        />
      }
    />
  )
}
