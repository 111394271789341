import React, { type FC, type MouseEvent, useState } from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useAppSelector } from '../../../../hooks/redux';
import { DepositIcon } from '../../../../components/icons/DepositIcon';
import { WithdrawIcon } from '../../../../components/icons/WithdrawIcon';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { getCurrencySymbol, toFixed } from '../../../../helpers/util';
import { t } from 'i18next';
import { TransferTetriaryIcon } from '../../../../components/icons/TransferIcon';
import { AccountTypeIcon } from './AccountTypeIcon/AccountTypeIcon';
import style from './AccountsTableHeader.module.css';

interface InterfaceAccountsTableHeader {
  setOpenDeposit: (el: boolean) => void
  setOpenWithdrawal: (el: boolean) => void
  setOpenTransactions: (el: boolean) => void
  selectedAccount: IAccount | null
}

export const AccountsTableHeader: FC<InterfaceAccountsTableHeader> = ({ setOpenDeposit, setOpenWithdrawal, selectedAccount, setOpenTransactions }) => {
  const accounts = useAppSelector((state) => state.accounts);
  const [isBalanceVisible, setIsBalanceVisible] = useState<boolean>(true);
  const { investedBalance } = useAppSelector((state) => state.cfd);

  const depositBtnHandler = (account: MouseEvent<HTMLButtonElement>): void => {
    setOpenDeposit(true);
  }

  const witdrawBtnHandler = (event: MouseEvent<HTMLButtonElement>): void => {
    setOpenWithdrawal(true);
  }

  const transactionsBtnHandler = (event: MouseEvent<HTMLButtonElement>): void => {
    setOpenTransactions(true);
  }

  const balanceVisibility = (event: MouseEvent<SVGSVGElement>): void => {
    setIsBalanceVisible((prevState: boolean) => !prevState)
  }

  return (
      <>
        {
          selectedAccount !== null && (
            <div className={ style.headerWrapper }>
              <div className={ style.header }>
                <div className={ style.optionsContainer }>
                  <div className={ style.optionsStatusContainer }>
                    <AccountTypeIcon
                      selectedAccount={selectedAccount}
                    />
                    {
                      selectedAccount.isActive && (
                        <div className={ style.selectedAccount }>
                          <p className={ style.selectedAccountText }>{t('labels.selected_account')}</p>
                        </div>
                      )
                    }
                  </div>
                  <div className={ style.balanceContainer }>
                      <p className={ style.balanceText }>
                        {t('labels.balance')}
                      </p>
                      { isBalanceVisible
                        ? <VisibilityIcon onClick={ balanceVisibility } style={{ cursor: 'pointer' }}/>
                        : <VisibilityOffIcon onClick={ balanceVisibility } style={{ cursor: 'pointer' }}/> }
                  </div>
                  <div className={ style.sumContainer }>
                      { isBalanceVisible
                        ? <>
                      <p className={ style.sumText }>{toFixed(selectedAccount.balance, 2)}</p>
                      <p className={ style.sumText }>{selectedAccount.cfdAccountCurrency.symbol}</p>
                      </>
                        : null }
                  </div>
                  <div className={ style.options }>
                        <div className={ style.credit }>
                          <p className={ style.creditInvestedText }>{t('labels.credit')}</p>
                          <p className={ style.amountCreditInvestedText }>{`${getCurrencySymbol(selectedAccount.cfdAccountCurrency.symbol)} ${toFixed(selectedAccount.credit, 2)}`}</p>
                        </div>
                        <div className={ style.invested }>
                          <p className={ style.creditInvestedText }>{t('labels.invested')}</p>
                          <p className={ style.amountCreditInvestedText }>{`${getCurrencySymbol(selectedAccount.cfdAccountCurrency.symbol)} ${toFixed(investedBalance, 2)}`}</p>
                        </div>
                  </div>
                </div>
                <hr className={ style.line }/>
                <div className={ style.optionsBtnsContainer }>
                  <div className={ style.depositBtnsContainer }>
                    <button className={ style.btn } onClick={ depositBtnHandler }>
                      <DepositIcon />
                    </button>
                    <p className={ style.btnText }>{t('labels.deposit')}</p>
                  </div>
                  { accounts.length > 1 && <div className={ style.transferBtnsContainer }>
                    <button className={ style.btn } onClick={ transactionsBtnHandler }>
                      <TransferTetriaryIcon />
                    </button>
                    <p className={ style.btnText }>{t('labels.transfer')}</p>
                  </div> }
                  <div className={ style.withdrawBtnsContainer }>
                    <button className={ style.btn } onClick={ witdrawBtnHandler }>
                      <WithdrawIcon />
                    </button>
                    <p className={ style.btnText }>{t('labels.withdraw')}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </>
  )
}
