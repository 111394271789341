import React, { type FC, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import style from './SegmentedLarge.module.css'

export interface ButtonLargeDataType {
  selected?: boolean | undefined
  disabled?: boolean | undefined
  render: JSX.Element | string
  id?: string
  [key: string]: any
}

interface Props {
  propsButtonsData: ButtonLargeDataType[]
  onChange?: (selectedButton: ButtonLargeDataType) => void
}

export const SegmentedLarge: FC<Props> = ({ propsButtonsData, onChange }) => {
  const [buttonsData, setButtonsData] = useState<ButtonLargeDataType[] | []>([]);

  useEffect(() => {
    setButtonsData(propsButtonsData);
  }, [propsButtonsData])

  const setSelectedStatus = (index: number): void => {
    const newData = buttonsData.map((button, key) => {
      button.selected = key === index;
      return button;
    });
    if (onChange !== undefined) onChange(buttonsData[index])
    setButtonsData(newData);
  }

  return (
    <div className={style.wrapper}>
      {
        buttonsData.map((buttonData, key) => {
          const { render, selected, disabled } = buttonData;

          return (
            <SegmentBtn
              key={key}
              value=""
              disabled={disabled}
              selected={selected}
              onClick={() => { setSelectedStatus(key); }}
            >
              {render}
            </SegmentBtn>
          );
        })
      }
    </div>
  )
}

interface TSegmentButtonProps {
  selected: boolean | undefined
  disabled?: boolean | undefined
  onClick?: () => void
  value: any
  children: any
}

const getBaseStyles = (selected: boolean | undefined): any => {
  return {
    border: 'none',
    borderRadius: '50px',
    cursor: 'pointer',
    width: '100%',
    whiteSpace: 'nowrap',
    transition: 'all 0.1s ease-out',

    display: 'flex',
    padding: '6px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: '8px',
    flex: '1 0 0',

    background: selected ?? false ? 'var(--CFD-theme-System-SecondaryContainer)' : 'transparent',
    color: selected ?? false ? 'var(--CFD-theme-System-OnSecondaryContainer)' : 'var(--CFD-theme-System-OnSurface)',

    '&:hover': {
      background: selected ?? false ? 'var(--CFD-theme-System-SecondaryContainer)' : 'var(--CFD-theme-State-layers-on-secondary-container-opacity_08)'
    },

    '&:disabled': {
      background: 'var(--CFD-theme-State-layers-on-secondary-container-opacity_12)'
    },

    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px'
  };
}

const SegmentBtn = ({ selected, children, ...rest }: TSegmentButtonProps): JSX.Element => {
  const baseStyles = getBaseStyles(selected);
  const SegmentBtn = styled('button')(() => ({ ...baseStyles }))

  return <SegmentBtn {...rest}>{children}</SegmentBtn>
}
