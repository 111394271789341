import React, {
  type ChangeEvent,
  type KeyboardEvent,
  forwardRef
} from 'react'

import styles from './CodeInput2FA.module.css'
import { t } from 'i18next'

interface ICodeInput2FA {
  code: string[]
  errorInput: boolean
  setCode: (el: string[]) => void
}

export const CodeInput2FA = forwardRef<Array<HTMLInputElement | null>, ICodeInput2FA>(function CodeInput2FA (props, ref) {
  const { code, errorInput, setCode } = props;

  const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>): void => {
    const inputChar = event.target.value.slice(-1);
    const newCode = [...code];
    newCode[index] = inputChar;
    setCode(newCode);

    if (event.target.value.length === 1 && index < 5) {
      if (ref !== null && typeof ref === 'object' && ref.current !== null) {
        ref.current[index + 1]?.focus();
      }
    }

    if (ref != null && typeof ref === 'object' && ref.current !== null && ref.current !== undefined && !errorInput) {
      const element = ref.current[index];
      if (element !== null) {
        element.style.border = '2px solid #B7C4FF';
      }
    }
  };

  const handleKeyDown = (index: number, event: KeyboardEvent): void => {
    if (event.key === 'Backspace' && index > 0 && code[index] === '') {
      if (ref !== null && typeof ref === 'object' && ref.current !== null) {
        ref.current[index - 1]?.focus();

        if (ref.current[index] !== null && !errorInput) {
          const currentElement = ref.current[index];
          if (currentElement != null) {
            currentElement.style.border = '2px solid #B7C4FF';
          }
        }
      }
    }
  };

  const assignRef = (element: HTMLInputElement, index: number): void => {
    if (typeof ref === 'object' && ref !== null && 'current' in ref) {
      (ref.current as HTMLInputElement[])[index] = element;
    }
  };

  return (
    <div className={styles.inputContainer}>
        <h4 className={styles.inputTitle}>{t('profile_page.2fa_modal.section.step2.subtitle')}</h4>
        <div className={styles.inputCellContainer}>
        {code.map((codeNumber, codeIndex) => (
          <input
            key={codeIndex}
            type="text"
            maxLength={1}
            minLength={1}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            value={codeNumber}
            className={styles.inputCell}
            onChange={(event) => { handleInputChange(codeIndex, event); }}
            onInput={ (event) => { handleInputChange(codeIndex, event as ChangeEvent<HTMLInputElement>); } }
            onKeyDown={(event) => { handleKeyDown(codeIndex, event); }}
            ref={(el: HTMLInputElement) => { assignRef(el, codeIndex); }}
            style={{
              border: errorInput ? '2px solid #FFB4AB' : '2px solid #89899A'
            }}
            autoFocus={codeIndex === 0}
          />
        ))}
        </div>
    </div>
  )
})
