import React, { type FC } from 'react';
import { type IconInterface } from '../../interfaces';

export const PieChartIcon: FC<IconInterface> = ({ width, height, color }) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width={width ?? 24} height={height ?? 24} viewBox="0 0 18 18" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M6.55762 2.39977C6.68209 2.68441 6.55225 3.01605 6.26762 3.14052C2.12905 4.95029 1.14534 10.6265 4.29681 13.7486L4.29681 13.7486C7.50601 16.9281 13.1326 15.738 14.8566 11.6523C14.9774 11.3661 15.3073 11.232 15.5935 11.3527C15.8798 11.4735 16.0139 11.8035 15.8931 12.0897C13.8649 16.8963 7.28979 18.2975 3.50503 14.5478C-0.201425 10.8758 0.938044 4.24324 5.81688 2.10976C6.10151 1.9853 6.43316 2.11514 6.55762 2.39977Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'}/>
  <path fillRule="evenodd" clipRule="evenodd" d="M9.60981 2.61722C9.62265 2.60797 9.65406 2.58698 9.66767 2.57857L9.60981 2.61722Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'}/>
  <path fillRule="evenodd" clipRule="evenodd" d="M15.0557 9.88564C15.5743 9.78572 16.006 9.56586 16.3364 9.10784L16.4196 8.9833C16.6961 8.53555 16.7728 8.10492 16.6969 7.63347C16.6319 7.22904 16.451 6.7927 16.2567 6.32386L16.2365 6.27509C15.8245 5.28043 15.2206 4.37661 14.4593 3.61531C13.698 2.85403 12.7943 2.25014 11.7995 1.83812L11.751 1.81802C11.2821 1.62366 10.8457 1.44278 10.4412 1.37769C9.96972 1.30182 9.5389 1.37864 9.09116 1.65513C9.05517 1.67738 9.00143 1.71326 8.96679 1.73823C8.50877 2.06862 8.28893 2.50035 8.189 3.01889C8.0995 3.48328 8.09955 4.06004 8.09961 4.72409L8.09962 4.77517L8.09961 6.39724C8.09959 7.08384 8.09957 7.65536 8.16054 8.10884C8.22484 8.5871 8.36608 9.01739 8.71167 9.36297C9.05725 9.70856 9.48755 9.8498 9.9658 9.9141C10.4193 9.97507 10.9908 9.97505 11.6774 9.97503L13.3505 9.97503C14.0146 9.97509 14.5914 9.97514 15.0557 9.88564ZM15.4472 8.35909C15.4392 8.37208 15.4183 8.40327 15.409 8.41614C15.2823 8.59187 15.1315 8.68896 14.8279 8.74746C14.482 8.81412 14.0146 8.81652 13.2844 8.81652H11.7015C10.9657 8.81652 10.4704 8.81533 10.1007 8.76562C9.7472 8.7181 9.59388 8.63569 9.49217 8.53397C9.39045 8.43226 9.30804 8.27894 9.26051 7.92544C9.21081 7.55575 9.20962 7.06046 9.20962 6.32463V4.74167C9.20962 4.01152 9.21202 3.54416 9.27867 3.19827C9.33717 2.89474 9.43416 2.74396 9.60981 2.61722C9.62265 2.60797 9.65406 2.58698 9.66767 2.57857C9.88471 2.44459 10.0419 2.42182 10.2474 2.45489C10.5061 2.49651 10.8197 2.62268 11.354 2.84398C12.2122 3.19944 12.992 3.72049 13.6489 4.37733C13.9537 4.68217 14.2293 5.01348 14.4726 5.36683C14.7536 5.77484 14.9916 6.21222 15.1822 6.67213C15.4034 7.2064 15.5296 7.52007 15.5712 7.77871C15.6043 7.9843 15.5813 8.14197 15.4472 8.35909Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'}/>
</svg>
  )
}
