import React, { type FC } from 'react';

export const AccountsIcon: FC = () => {
  const fillColor = 'var(--CFD-theme-System-OnSecondaryContainer)';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M23.2249 10.9271V6.6432C23.2249 5.73674 22.5025 4.99998 21.6138 4.99998H4.46398L19.7633 2.84353C20.0027 2.81041 20.2462 2.88492 20.4288 3.04634C20.6114 3.20777 20.7169 3.44369 20.7169 3.69204C20.7169 3.77896 20.7494 3.8576 20.7941 3.92383C20.8671 4.03144 20.9848 4.11008 21.1228 4.11008C21.35 4.11008 21.5326 3.92383 21.5326 3.69204C21.5326 3.20363 21.3257 2.74005 20.9645 2.4172C20.6033 2.09436 20.1204 1.94949 19.6496 2.01571L1.85458 4.53227C0.92932 4.66058 0.202908 5.3932 0.0365235 6.30379C0.0121745 6.41555 0 6.5273 0 6.6432V20.3559C0 21.2624 0.722354 21.9992 1.61109 21.9992H21.6097C22.4985 21.9992 23.2208 21.2624 23.2208 20.3559V16.072C23.6754 15.8485 23.9959 15.3725 23.9959 14.822V12.1689C23.9959 11.6184 23.6794 11.1465 23.2208 10.9189L23.2249 10.9271ZM22.4092 20.3601C22.4092 20.8071 22.0521 21.1713 21.6138 21.1713H1.61109C1.17281 21.1713 0.815692 20.8071 0.815692 20.3601V6.64734C0.815692 6.58525 0.823808 6.5273 0.835982 6.46935C0.917146 6.10926 1.23368 5.83608 1.61109 5.83608H21.6097C22.048 5.83608 22.4051 6.20031 22.4051 6.64734V10.7864H16.3706C15.6199 10.7864 15.0071 11.4114 15.0071 12.1771V14.8303C15.0071 15.596 15.6199 16.221 16.3706 16.221H22.4051V20.3642L22.4092 20.3601ZM23.1843 14.8303C23.1843 15.0745 23.0301 15.2732 22.8191 15.3518C22.7623 15.3725 22.7014 15.3891 22.6405 15.3891H16.3788C16.0785 15.3891 15.8309 15.1407 15.8309 14.8303V12.1771C15.8309 11.8708 16.0785 11.6184 16.3788 11.6184H22.6405C22.7054 11.6184 22.7623 11.6349 22.8191 11.6556C23.0301 11.7343 23.1843 11.9329 23.1843 12.1771V14.8303Z" fill={fillColor} />
    </svg>
  )
}
