import React, { type HTMLAttributes, type FC } from 'react';
import { ButtonComponent, useSnackbar } from '../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { selectIsCodeCorrect2FA, selectVerifyCode2FA } from '../../../../../redux/selectors/twoFactorAuthentication';
import { verify2FA } from '../../../../../redux/reducers/twoFactorAuthentication';
import { t } from 'i18next';
import styles from './Step2FooterModal2FA.module.css';

type TStep2FooterModal2FA = HTMLAttributes<HTMLElement>;

export const Step2FooterModal2FA: FC<TStep2FooterModal2FA> = () => {
  const dispatch = useAppDispatch()
  const { handleOpen } = useSnackbar()

  const isCodeCorrect = useAppSelector(selectIsCodeCorrect2FA)
  const verifyCode = useAppSelector(selectVerifyCode2FA)

  const handleClick = (): void => {
    dispatch(verify2FA({
      code: verifyCode,
      handleOpen
    }))
  }

  return (
    <footer className={styles.dialogFooter}>
      <div className={styles.dialogFooterButton}>
        <ButtonComponent onClick={handleClick} disabled={!isCodeCorrect}>
          {t('labels.submit')}
        </ButtonComponent>
      </div>
    </footer>
  )
}
