import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext({ theme: 'light', toggleTheme: () => {} });

export const useTheme = (): { theme: string, toggleTheme: () => void } => useContext(ThemeContext);

const getPreferredTheme = (): 'dark' | 'light' => {
  const isDarkTheme = window?.matchMedia('(prefers-color-scheme: dark)').matches;
  return isDarkTheme ? 'dark' : 'light';
};

export const ThemeProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [theme, setTheme] = useState(localStorage.getItem('app-theme') ?? getPreferredTheme());

  useEffect(() => {
    const root = document.documentElement;
    const newTheme = theme === 'dark' ? 'dark' : 'light';
    root.setAttribute('data-theme', newTheme);
    localStorage.setItem('app-theme', newTheme);
  }, [theme]);

  const toggleTheme = (): void => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
