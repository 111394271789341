import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type PayloadInterface } from '../../interfaces';
import { apiHelper } from '../api/apiHelper';
const FINISHED_STATUSES = ['completed', 'stopped'];

interface IAssetInfo {
  _id: string
  label: string
  alias: string
  primary: string
  secondary: string
  lotStep: number
  lotSize: number
  leverage: number
  symbol: string
  primaryId: string
  secondaryId: string
  precision: number
  scale: number
  commission: number
  swapLong: number
  swapShort: number
  brokerSymbol: string
}

export interface IOrder {
  _id?: string
  userId?: string
  primary?: string
  secondary?: string
  marketId?: string
  accountId?: string
  isTPSLSet?: boolean
  tradeType?: number
  position?: number
  tradeStartPrice?: number
  takeProfitPrice?: number
  stopLossPrice?: number
  userInvestedAmount?: number
  leverage?: number
  exitPrice?: number
  status?: string
  commission?: number
  swap?: number
  endCommission?: number
  endSwap?: number
  timeOpened?: string
  brokerSymbol?: string
  liquidationPrice?: number
  createdAt?: string
  updatedAt?: string
  customId?: number
  closedAt?: string
  tradeEndPrice?: number
  setting?: IAssetInfo
  type?: any
}

const initialState: IOrder[] = [];

interface GetOrdersPropsType {
  userId: string
  status: string
  accountId: string
  sort?: null | {
    direction: string
    field: string
  }
}

export const getOrders = createAsyncThunk(
  'orders/get',
  async ({ userId, status, accountId, sort = null }: GetOrdersPropsType) => {
    let path = `/cfd-order?userId=${userId}&status=${status}&accountId=${accountId}`

    if (sort !== null) {
      path += `&sort=${JSON.stringify(sort)}`
    }

    if (status === 'completed,stopped') {
      path += '&sort={"direction":"desc","field":"closedAt"}'
    }

    const response = await apiHelper({ method: 'get', path });

    return response.data.orders;
  }
);

export const createOrder = createAsyncThunk(
  'order/create',
  async (orderData: IOrder, { rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'post', path: '/cfd-order', data: orderData });

      return response.data.order;
    } catch (error: any) {
      const { data } = error.response;

      const errorPayload: PayloadInterface = {
        error: data.message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const editOrder = createAsyncThunk(
  'order/edit',
  async ({ id, orderData }: { id: string, orderData: IOrder }, { rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'patch', path: `/cfd-order/${id}`, data: orderData });

      return response.data.order;
    } catch (error: any) {
      const { data } = error.response;

      const errorPayload: PayloadInterface = {
        error: data.message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const stopOrder = createAsyncThunk(
  'order/close',
  async ({ id, closePrice }: { id: string, closePrice: number }, { rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'post', path: `/cfd-order/stop/${id}`, data: { actualPrice: closePrice } });

      return response.data.order;
    } catch (error: any) {
      const { data } = error.response;

      const errorPayload: PayloadInterface = {
        error: data.message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const orderslice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setUpdatedOrder (state, action: PayloadAction<IOrder>) {
      return state.map((order) => {
        if (order._id === action.payload._id) return action.payload;

        return order;
      })
    },
    addNewOrder (state, action: PayloadAction<IOrder>) {
      const [order] = state;

      if (order !== undefined && !FINISHED_STATUSES.includes(order.status ?? '')) {
        return [action.payload, ...state];
      }

      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.fulfilled, (state, action: PayloadAction<IOrder[]>) => {
      return action.payload;
    });
    builder.addCase(createOrder.fulfilled, (state, action: PayloadAction<IOrder>) => {
      const [order] = state;

      if (order !== undefined && !FINISHED_STATUSES.includes(order.status ?? '')) {
        state = [action.payload, ...state];
      }
    });
    builder.addCase(editOrder.fulfilled, (state, action: PayloadAction<IOrder>) => {
      state = state.map((order) => {
        if (order._id === action.payload._id) {
          order.takeProfitPrice = action.payload.takeProfitPrice;
          order.stopLossPrice = action.payload.stopLossPrice;
          order.isTPSLSet = action.payload.isTPSLSet;
        }

        return order;
      })
    });
    builder.addCase(stopOrder.fulfilled, (state, action: PayloadAction<IOrder>) => {
      state = state.filter((order) => order._id !== action.payload._id);
    });
  }
});

export const { setUpdatedOrder, addNewOrder } = orderslice.actions;
export default orderslice.reducer;
