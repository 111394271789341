import React, { type FC, useState, useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface ButtonDataType {
  selected?: boolean | undefined
  render: JSX.Element | string
  id: string
  [key: string]: any
}

interface Props {
  propsButtonsData: ButtonDataType[]
  onChange: (selectedButton: string) => void
}

const style = { width: 'unset' };

export const SegmentedButton: FC<Props> = ({ propsButtonsData, onChange }) => {
  const [buttonsData, setButtonsData] = useState<ButtonDataType[] | []>([]);

  useEffect(() => {
    setButtonsData(propsButtonsData);
  }, [propsButtonsData])

  const setSelectedStatus = (index: number, id: string): void => {
    const newData = buttonsData.map((button, key) => {
      button.selected = key === index;
      return button;
    });
    if (onChange !== undefined) onChange(id)
    setButtonsData(newData);
  }

  return (
    <ToggleButtonGroup
      exclusive
      aria-label="text alignment"
      sx={{
        height: '100%',
        width: '100% !important'
      }}
    >
      {
        buttonsData.map((buttonData, key) => {
          const { render, selected, id } = buttonData;

          if (key === 0) {
            return (
              <StartSegmentBtn
                key={id}
                value=""
                selected={selected}
                onClick={() => { setSelectedStatus(key, id); }}
              >
                {render}
              </StartSegmentBtn>
            );
          }

          if (key === buttonsData.length - 1) {
            return (
              <EndSegmentBtn
                key={key}
                value=""
                selected={selected}
                onClick={() => { setSelectedStatus(key, id); }}
              >
                {render}
              </EndSegmentBtn>
            );
          }

          return (
            <MiddleSegmentBtn
              key={key}
              value=""
              selected={selected}
              onClick={() => { setSelectedStatus(key, id); }}
            >
              {render}
            </MiddleSegmentBtn>
          );
        })
      }
    </ToggleButtonGroup>
  )
}

interface TSegmentButtonProps {
  selected: boolean | undefined
  onClick?: () => void
  value: any
  children: any
}

const getBaseStyles = (selected: boolean | undefined): any => {
  return {
    border: 'none',
    cursor: 'pointer',
    height: '100%',
    whiteSpace: 'nowrap',
    transition: 'all 0.1s ease-out',
    minWidth: '100px',
    display: 'flex',
    fontSize: '14px',
    lineHeight: '20px',
    padding: '6px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: '8px',
    flex: '1 0 0',

    background: selected ?? false ? 'var(--CFD-theme-System-SecondaryContainer)' : 'transparent',
    color: selected ?? false ? 'var(--CFD-theme-System-OnSecondaryContainer)' : 'var(--CFD-theme-System-OnSurface)',

    '&:hover': {
      background: selected ?? false ? 'var(--CFD-theme-System-SecondaryContainer)' : 'var(--CFD-theme-State-layers-on-secondary-container-opacity_08)'
    },

    '&:disabled': {
      background: 'var(--CFD-theme-State-layers-on-secondary-container-opacity_12)'
    }
  };
}

const StartSegmentBtn = ({ selected, children, ...rest }: TSegmentButtonProps): JSX.Element => {
  const baseStyles = getBaseStyles(selected);
  const LeftButton = styled('button')(() => ({
    ...baseStyles,
    borderTop: '1px solid var(--CFD-theme-System-Outline)',
    borderBottom: '1px solid var(--CFD-theme-System-Outline)',
    borderLeft: '1px solid var(--CFD-theme-System-Outline)',
    borderTopLeftRadius: '25px',
    borderBottomLeftRadius: '25px',
    height: '100%'
  }))

  return (
    <LeftButton {...rest}>
      {selected ?? false ? <CheckIcon /> : null}
      <p style={style}>{children}</p>
    </LeftButton>
  )
}

const MiddleSegmentBtn = ({ selected, children, ...rest }: TSegmentButtonProps): JSX.Element => {
  const baseStyles = getBaseStyles(selected);
  const MiddleButton = styled('button')(() => ({
    ...baseStyles,
    borderTop: '1px solid var(--CFD-theme-System-Outline)',
    borderBottom: '1px solid var(--CFD-theme-System-Outline)',
    borderLeft: '1px solid var(--CFD-theme-System-Outline)',
    height: '100%'
  }))

  return (
    <MiddleButton {...rest}>
      {selected ?? false ? <CheckIcon /> : null}
      <p style={style}>{children}</p>
    </MiddleButton>
  )
}

const EndSegmentBtn = ({ selected, children, ...rest }: TSegmentButtonProps): JSX.Element => {
  const baseStyles = getBaseStyles(selected);
  const EndButton = styled('button')(() => ({
    ...baseStyles,
    border: '1px solid var(--CFD-theme-System-Outline)',
    borderTopRightRadius: '25px',
    borderBottomRightRadius: '25px',
    height: '100%'
  }))

  return (
    <EndButton {...rest}>
      {selected ?? false ? <CheckIcon /> : null}
      <p style={style}>{children}</p>
    </EndButton>
  )
}
