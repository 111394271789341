import React, { type FC } from 'react';
import { type IAccount } from '../../../../../redux/reducers/accounts';
import { getAccountTypeIconByName } from '../../../../../helpers/util';

import style from './AccountTypeIcon.module.css';

interface InterfaceAccountTypeIcon {
  selectedAccount: IAccount
}

export const AccountTypeIcon: FC<InterfaceAccountTypeIcon> = ({ selectedAccount }) => {
  return (
      <>
        <div className={ style.status }>
          {
            selectedAccount.type.name !== 'Standard' &&
            (
              <img src={getAccountTypeIconByName(selectedAccount.type.name)} alt='type' />
            )
          }
          <p className={ style.statusText }>{selectedAccount.type.name}</p>
        </div>
      </>
  )
}
