import type { RootState } from '../../redux/store';
import { type TabInterface, type PieChartDataInterface, type BalanceChartDataInterface } from '../reducers/profile';

export const selectProfileCompletePercentage = (state: RootState): number => state.profile.completePercentage;

export const getProfileTabsData = (state: RootState): TabInterface[] | null => state.profile.tabsData;
export const getProfileSelectedTab = (state: RootState): TabInterface | null => state.profile.selectedTab;

export const getProfileBalanceChartData = (state: RootState): BalanceChartDataInterface | null => state.profile.balanceChartData;
export const selectProfileIsBalanceChartDataLoading = (state: RootState): boolean => state.profile.isBalanceChartDataLoading;
export const selectProfileIsBalanceChartErrorOccur = (state: RootState): boolean => state.profile.isBalanceChartErrorOccur;

export const selectProfilePieData = (state: RootState): PieChartDataInterface[] | null => state.profile.pieData;
export const selectProfileIsPieChartLoading = (state: RootState): boolean => state.profile.isPieChartDataLoading;
export const selectProfileIsPieChartErrorOccur = (state: RootState): boolean => state.profile.isPieChartErrorOccur;
