import React, { type FC, useState, useEffect, useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
  AssetAmountInputComponent,
  ButtonComponent,
  useSnackbar
} from '../../../../components';
import { createExternalTransaction, getTransactionsHistory } from '../../../../redux/reducers/transactionsHistory';
import { getCards } from '../../../../redux/reducers/withdrawalMethods';
import { getCurrencyRates } from '../../../../redux/reducers/currencyRates';
import { SavedCardWithdrawalOptions } from './SavedCardWithdrawalOptions/SavedCardWithdrawalOptions';
import { SuccessWithdrawalSection } from '../SuccessWithdrawalSection/SuccessWithdrawalSection';
import style from './CardWithdrawalSection.module.css';
import { type PayloadInterface } from '../../../../interfaces';
import { handleError } from '../../../../helpers';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { toFixed } from '../../../../helpers/util';
import { t } from 'i18next';

export interface TypeCardOption { currency: string, value: string }

interface InterfaceCardWithdrawSection {
  setOpen: (el: boolean) => void
  setStep: (el: number) => void
  step: number
  setAddWithdrawalMethod: (el: boolean) => void
  selectedAccount: IAccount | null
}

export const CardWithdrawSection: FC<InterfaceCardWithdrawSection> = ({ setOpen, setStep, step, setAddWithdrawalMethod, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const { handleOpen: handleOpenSnackbar } = useSnackbar();
  const [cardSelect, setCardSelect] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const { _id: userId } = useAppSelector((state) => state.user);
  const { cards } = useAppSelector((state) => state.withdrawalMethods);
  const { rates: currencyRates } = useAppSelector((state) => state.currencyRates);

  useEffect(() => {
    if (userId === undefined) return;

    dispatch(getCards(userId));
  }, [userId]);

  useEffect(() => {
    if (selectedAccount !== null) dispatch(getCurrencyRates(selectedAccount.cfdAccountCurrency.symbol));
  }, [selectedAccount]);

  const selectedCard = useMemo(() => {
    return cards.find(({ _id }) => _id === cardSelect) ?? null;
  }, [cardSelect])

  const handleAddWithdrawalMethod = (): void => {
    setAddWithdrawalMethod(true);
  }

  const handleWithdrawlClick = async (): Promise<void> => {
    if (userId === undefined || selectedCard === null || selectedAccount === null) return;

    setIsBtnLoading(true);

    const data = {
      userId,
      currencyId: selectedAccount.cfdAccountCurrency.currencyId,
      userAccountId: selectedAccount._id,
      sendToAddress: selectedCard.card,
      amount: toFixed((Number(amount) / Number((currencyRates?.[selectedCard.currencyId?.symbol] ?? 0))), 2),
      amountFromAccount: amount,
      type: 'Card'
    };

    const response = await dispatch(createExternalTransaction(data));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      handleError(payload, handleOpenSnackbar);
      setIsBtnLoading(false);

      return;
    }

    dispatch(getTransactionsHistory({ userId, accountId: selectedAccount._id }));
    setStep(2);
  }
  const handleCloseWithdrawal = (): void => {
    setOpen(false);
  }

  const formComplete = (amount.length > 0 && Number(amount) > 0 && cardSelect.length > 0)

  return (
    <>
      { (step === 1)
        ? <>
            <div className={ style.wrapper }>
              <div className={ style.cardWrapper }>
                <div className={ style.cardOptions }>
                  <p className={ style.cardWrapperText }>1. {t('labels.select_card')}</p>
                  { (cards.length !== 0) && <div className={ style.addCardBtnContainer }>
                    <ButtonComponent
                      onClick={ handleAddWithdrawalMethod }
                      variant='outlined'
                      customInlineStyles={{ border: 'none', padding: '8px' }}
                    >
                      <AddIcon style={{ color: 'var(--CFD-theme-System-Tertiary)' }}/>
                      {t('labels.add_card')}
                    </ButtonComponent>
                  </div> }
                </div>
                { (cards.length !== 0)
                  ? <div className={ style.savedCardsWrapper }>
                      <p className={ style.savedCardsText }>{t('labels.saved_card')}</p>
                      <SavedCardWithdrawalOptions selectedOptions={ cardSelect } setSelectedOptions={ setCardSelect } options={ cards }/>
                    </div>
                  : <div className={ style.infoContainer }>
                      <p className={ style.infoText }>{t('withdrawal_modal.add_bank_form_modal.section.subtitle')}</p>
                      <div className={ style.infoBtn }>
                        <ButtonComponent
                          variant='outlined'
                          onClick={ handleAddWithdrawalMethod }
                          customInlineStyles={{ border: 'none', padding: '8px', color: 'var(--CFD-theme-System-Inverse-primary)' }}
                        >{t('withdrawal_modal.add_card_form_modal.title')}</ButtonComponent>
                      </div>
                    </div>
                }
              </div>
              {
                cardSelect.length > 0 && (
                  <div className={ style.cardWrapper }>
                    <p className={ style.cardWrapperText }>2. {t('labels.amount')}</p>
                    <div className={ style.inputWrapper }>
                      <label htmlFor='amount' className={ style.label }>{t('labels.amount')}</label>
                      <AssetAmountInputComponent
                        value={ amount }
                        setValue={ setAmount }
                        id='amount'
                        currency={selectedCard?.currencyId.symbol ?? ''}
                        maxBtn={ false }
                        placeholder='0.00'
                      />
                    </div>
                  </div>
                )
              }
            </div>
            <footer className={ style.footer }>
              <div className={ style.btnContainer }>
                <ButtonComponent onClick={() => { handleWithdrawlClick(); } } disabled={ !formComplete } loading={isBtnLoading}>
                  <span className={ style.btnText }>
                    {t('withdrawal_modal.add_bank_form_modal.footer.action_button_text')}
                  </span>
                </ButtonComponent>
              </div>
            </footer>
          </>
        : <SuccessWithdrawalSection
          title={t('withdrawal_modal.success_title')}
          amount={`${toFixed(Number(amount), 2)} ${selectedCard?.currencyId.symbol ?? ''}`}
          withdrawalTo={selectedCard?.card ?? ''}
          onClick={ handleCloseWithdrawal }
        />
      }
    </>
  )
}
