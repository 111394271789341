/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type FC, useState, useEffect, useMemo } from 'react'
import { InputComponent, SelectComponent, PhoneInputComponent, ButtonComponent, useSnackbar } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { settingsUpdateFirstName, settingsUpdateLastName, settingsUpdateCountry, settingsUpdatePhoneNumber, updateUserInfo, setActualUserInfo, settingsUpdateFullAddress } from '../../../../redux/reducers/profileSettings';
import { getCountries } from '../../../../redux/reducers/countries';
import { type OptionInterface } from '../../../../interfaces';
import { selectIsUserDataAvailable } from '../../../../redux/selectors/user';
import { matchIsValidTel } from 'mui-tel-input';
import { validateInputs } from './validateInputs';
import style from './ProfileSettings.module.css';
import { t } from 'i18next';
import { profileSettingsSelector } from '../../../../redux/selectors/profileSettings';

export const ProfileSettings: FC = () => {
  const dispatch = useAppDispatch()
  const { handleOpen } = useSnackbar()

  const isUserDataAvailable = useAppSelector(selectIsUserDataAvailable)

  const {
    firstName,
    lastName,
    country,
    fullAddress,
    phoneNumber
  } = useAppSelector(profileSettingsSelector);
  const countries = useAppSelector(({ countries }) => countries);

  const countiesOptions: OptionInterface[] = useMemo((): OptionInterface[] => {
    return countries.map(({ iso, nicename }) => ({ label: nicename, value: iso }));
  }, [countries]);

  const [firstNameInputErrorText, setFirstNameInputErrorText] = useState<string>('');
  const [lastNameInputErrorText, setLastNameInputErrorText] = useState<string>('');
  const [phoneNumberInputErrorText, setPhoneNumberInputErrorText] = useState<string>('');

  const [isFirstNameInputError, setFirstNameInputError] = useState<boolean>(false);
  const [isLastNameInputError, setLastNameInputError] = useState<boolean>(false);
  const [isPhoneNumberInputError, setIsPhoneNumberInputError] = useState<boolean>(false);

  const setFirstName = (value: string): void => { dispatch(settingsUpdateFirstName(value)) }
  const setLastName = (value: string): void => { dispatch(settingsUpdateLastName(value)) }
  const setFullAddress = (value: string): void => { dispatch(settingsUpdateFullAddress(value)) }
  const setCountry = (value: string): void => { dispatch(settingsUpdateCountry(value)) }
  const setPhoneNumber = (value: string): void => {
    const phoneValidationError = !matchIsValidTel(value)

    setIsPhoneNumberInputError(phoneValidationError)
    setPhoneNumberInputErrorText(phoneValidationError ? 'Phone pattern is not correct' : '')

    dispatch(settingsUpdatePhoneNumber(value))
  }

  const isFormActive = !isFirstNameInputError && !isLastNameInputError && !isPhoneNumberInputError

  useEffect(() => {
    if (isUserDataAvailable) {
      dispatch(getCountries())
      dispatch(setActualUserInfo())
    }
  }, [isUserDataAvailable])

  // input validation Effect
  useEffect(() => {
    const errors = validateInputs({
      firstName,
      lastName,
      country
    }, {
      firstName: { required: true, min: 2, max: 50 },
      lastName: { required: true, min: 2, max: 50 },
      country: { required: true }
    });

    setFirstNameInputError(errors && !!errors.firstName)
    setLastNameInputError(errors && !!errors.lastName)

    setFirstNameInputErrorText(errors ? errors?.firstName ?? '' : '')
    setLastNameInputErrorText(errors ? errors?.lastName ?? '' : '')
  }, [firstName, lastName])

  const completeForm = (): void => {
    dispatch(updateUserInfo({
      firstName,
      lastName,
      countryCode: country,
      phone: phoneNumber,
      fullAddress,
      handleOpen
    }))
  }

  return (
    <>
      <div className={ style.profileSettingsContainer }>
          <div className={ style.profileSettingsMain }>
              <div className={ style.profileContainer }>
                  <label className={ style.profileTitle } htmlFor='firstName'>{t('labels.first_name')} *</label>
                  <div className={ style.profileInputContainer } >
                      <InputComponent
                          id='firstName'
                          value={firstName}
                          setValue={setFirstName}
                          error={isFirstNameInputError}
                          errorText={firstNameInputErrorText}
                          placeholder={t('labels.first_name')}
                      />
                  </div>
                  <div style={{ width: '30px' }}/>
              </div>
              <div className={ style.profileContainer }>
                  <label className={ style.profileTitle } htmlFor='lastName'>{t('labels.last_name')} *</label>
                  <div className={ style.profileInputContainer } >
                      <InputComponent
                          id='lastName'
                          value={lastName}
                          setValue={setLastName}
                          error={isLastNameInputError}
                          errorText={lastNameInputErrorText}
                          placeholder={t('labels.last_name')}
                      />
                  </div>
                  <div style={{ width: '30px' }}/>
              </div>
              <div className={ style.profileContainer }>
                  <label className={ style.profileTitle }>{t('profile_page.settings.profile_settings.labels.country')} *</label>
                  <div className={ style.profileInputContainer } >
                      <SelectComponent
                          value={country}
                          onChange={(value) => { setCountry(value.target.value as string) }}
                          options={countiesOptions}
                      />
                  </div>
                  <div style={{ width: '30px' }}/>
              </div>
              <div className={ style.profileContainer }>
                  <label className={ style.profileTitle } htmlFor='fullAddress'>{t('profile_page.settings.profile_settings.labels.full_address')}</label>
                  <div className={ style.profileInputContainer } >
                      <InputComponent
                          id='fullAddress'
                          value={fullAddress}
                          setValue={setFullAddress}
                          placeholder={t('labels.full_address')}
                      />
                  </div>
                  <div style={{ width: '30px' }}/>
              </div>
              <div className={ style.profileContainer }>
                  <label className={ style.profileTitle } htmlFor='phoneNumber'>{t('profile_page.settings.profile_settings.labels.phone_number')} *</label>
                  <div className={ style.profileInputContainer } >
                      <PhoneInputComponent
                        id='phoneNumber'
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        error={isPhoneNumberInputError}
                        errorText={phoneNumberInputErrorText}
                      />
                  </div>
                  <div style={{ width: '30px' }}/>
              </div>
          </div>
          <div className={ style.profileSettingsFooter }>
              <div className={ style.profileSettingsFooterBtns }>
                  <div style={{ width: '130px' }}/>
                  <div className={ style.mainBtnContainer }>
                      <ButtonComponent onClick={completeForm} variant='contained' disabled={!isFormActive}>{t('profile_page.settings.profile_settings.save_button_text')}</ButtonComponent>
                  </div>
                  <div style={{ width: '30px' }}/>
              </div>
          </div>
      </div>
    </>
  )
}
