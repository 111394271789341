import React, { type ReactNode, type FC } from 'react';
import styles from './Container.module.css';
import { useTranslation } from 'react-i18next';

interface ContainerInterface {
  subtitle: ReactNode
  content: ReactNode
}

export const Container: FC<ContainerInterface> = ({ subtitle, content }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.containerHeader}>
        <h3>{t('login_page.welcome', { brand_name: process.env.REACT_APP_BRAND_NAME })}</h3>
        <div className={styles.containerHeaderActions}>
          { subtitle }
        </div>
      </div>
      <div className={styles.containerBody}>
        {content}
      </div>
    </div>
  )
}
