import { type BaseQueryFn } from '@reduxjs/toolkit/query';
import { type AxiosRequestConfig, type AxiosError } from 'axios';
import { apiHelper } from './apiHelper';

const axiosBaseQuery =
  (): BaseQueryFn<
  {
    method: AxiosRequestConfig['method']
    path: string
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  },
  unknown,
  unknown
  > =>
    async ({ method = 'GET', path, data, params }) => {
      try {
        const result = await apiHelper({
          method,
          path,
          data,
          params
        });
        return { data: result.data };
      } catch (axiosError) {
        const err = axiosError as AxiosError;
        return {
          error: {
            status: err.response?.status,
            data: typeof err.response?.data !== 'undefined' ? err.response.data : err.message
          }
        };
      }
    };

export default axiosBaseQuery;
