/* eslint-disable @typescript-eslint/strict-boolean-expressions */
const userToken: string = localStorage.getItem('userToken') ?? '{}';

export const ENV = {
  serverUrl: process.env.REACT_APP_SERVER_URL,
  Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
  x_access_token: JSON.parse(userToken),
  x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,
  adminPrivateKey: process.env.REACT_APP_ADMIN_PRIVATE_KEY,
  minTradeAmtInUSD: 10,
  profileCompletionCriteria: {
    phoneNumber: 15,
    email: 15,
    address: 15,
    profilePicture: 15,
    cardForDeposit: 15,
    bankOrCardForWithdrawal: 15,
    firstDeposit: 10
  },
  saveItem (name: string, value: any) {
    localStorage.setItem(`${name}`, JSON.stringify(value));
  },
  removeItem (name: string) {
    localStorage.removeItem(name);
  },
  encryptUserData (data: any, token: string, id: string) {
    if (data && id) {
      localStorage.setItem('userInfo', JSON.stringify(data));
      localStorage.setItem('uId', JSON.stringify(id));
    }
    if (token) {
      localStorage.setItem('uToken', JSON.stringify(token));
    }
    return true;
  },
  getUserKeys () {
    const userData = localStorage.getItem('userInfo');
    return userData;
  },
  getToken () {
    const userData = localStorage.getItem('uToken');
    if (userData) {
      return userData;
    }
    return {};
  },
  getHeaders () {
    const uToken: string = localStorage.getItem('uToken') ?? '{}';
    const token = JSON.parse(uToken);

    const headers: any = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
      headers['access-token'] = token;
    }

    return headers;
  },
  logout () {
    localStorage.removeItem('uToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('uId');
    window.location.href = '/login';
  }
};
