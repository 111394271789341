import React, { type FC } from 'react';
import { type SavingOption } from '../../../../../interfaces';
import { SectionSuccessModal, TimelineProgressComponent } from '../../../../../components';
import style from './Step2SectionModalAssetsSubscribe.module.css';
import { formatDate } from '../../../../../helpers/util';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

interface InterfaceStep2SectionModalAssetsSubscribe {
  reqData: {
    selectedAsset?: string
    currencyId: string
    releaseTime?: Date
    investedAmount?: number
    userId?: string
    selectedOption: SavingOption
    currencySymbol: string
  }
}

export const Step2SectionModalAssetsSubscribe: FC<InterfaceStep2SectionModalAssetsSubscribe> = ({ reqData }) => {
  const { t } = useTranslation();
  const [widthScreen] = useWindowSize();

  const finalEarnings = Number(reqData.investedAmount) + (Number(reqData.investedAmount) * (reqData.selectedOption.percent / 100))

  const isMobile = (widthScreen <= 905);

  return (
    <section className={ style.sectionWrapper }>
      <SectionSuccessModal
        content={`${t('savings_page.subscribe_modal.Your_Savings_Wallet_will_now_accumulate_Real-Time_APR')}`}
        title={ `${t('savings_page.subscribe_modal.Subscription_Successful')}` }
      />
      <div className={ style.detailsWrapper }>
        <div className={ style.details }>
          <p className={ style.detailsText1 }>{ t('savings_page.subscribe_modal.Investment') }</p>
          <p className={ style.detailsText }>{`${reqData.investedAmount} ${reqData.currencySymbol}`}</p>
        </div>
        <div className={ style.details }>
          <p className={ style.detailsText1 }>{ t('savings_page.subscribe_modal.Locked_period') }</p>
          {
            reqData.selectedOption !== undefined && reqData.selectedOption.isFlexible
              ? <p className={ style.detailsText }>{ t('savings_page.subscribe_modal.Flexible') }</p>
              : <p className={ style.detailsText }>{`${reqData.selectedOption?.period} ${reqData.selectedOption?.durationType}`}</p>
          }
        </div>
        <div className={ style.details }>
          <p className={ style.detailsText1 }>{ t('savings_page.subscribe_modal.Rate') }</p>
          <p className={ style.detailsText }>{reqData.selectedOption?.percent} %</p>
        </div>
        <div className={ style.detailsCreated }>
          <p className={ style.detailsText1 }>{ t('savings_page.subscribe_modal.Created') }</p>
          <p className={ style.detailsText }>{formatDate(new Date())}</p>
        </div>
      </div>
      <div className={ style.detailsInfo }>
        { t('savings_page.subscribe_modal.Real-time_rate_represents') }
        <br/>
        { t('savings_page.subscribe_modal.Earnings_are_accrued_and') }
      </div>
      <div className={ style.summaryContainer }>
        {reqData.releaseTime !== undefined
          ? <TimelineProgressComponent
            heigth={ isMobile ? 30 : 40 }
            amountOfTimelines={ 1 }
            percentageOfFill={ 100 }
            paddingBetweenTimeline={ 20 }
            timeLines={[
              { label: `${t('savings_page.subscribe_modal.Subscription_date')}`, value: formatDate(new Date()) },
              { label: `${t('savings_page.subscribe_modal.Release_date')}`, value: formatDate(reqData.releaseTime) }
            ]}
          />
          : <div className={ style.emptySummaryContainer }/> }
        {reqData.selectedOption !== undefined && reqData.investedAmount !== undefined && !reqData.selectedOption.isFlexible && (
          <div className={ style.summaryAmountContainer }>
            <span className={ style.summaryAmountTitle }>{ t('savings_page.subscribe_modal.You_will_receive') }</span>
            <span className={ style.summaryAmount }>{`${finalEarnings} ${reqData.currencySymbol}`}</span>
          </div>
        )}
      </div>
    </section>
  )
}
