import React, { type FC, useState, useEffect } from 'react';

import { ModalContext } from '../../components';
import { HeaderModalRedeem } from './Header/HeaderModalRedeem';
import { SectionModalRedeem } from './Section/SectionModalRedeem';
import { FooterModalRedeem } from './Footer/FooterModalRedeem';
import { type SavingInvestment } from '../../interfaces';
import { type TSavingSection } from '../../pages/Savings/interface/savingsPageInterfaces';
import { useAppSelector } from '../../hooks/redux';
import { useBodyOverflow } from '../../hooks/useBodyOverflow';

interface ModalRedeemInterface {
  open: boolean
  savingAccountId: string
  setOpen: (el: boolean) => void
  setSection?: (el: TSavingSection) => void
  selectedInvestment: SavingInvestment | null
  isFlexible: boolean | null
  step?: number
}

export const ModalRedeem: FC<ModalRedeemInterface> = ({ open, setOpen, selectedInvestment, setSection = () => {}, savingAccountId, isFlexible }) => {
  useBodyOverflow(open);
  const accounts = useAppSelector((state) => state.accounts);
  const [currency, setCurrency] = useState<string>('');
  const [step, setStep] = useState<number>(1);

  useEffect(() => {
    if (accounts.length > 0) setCurrency(accounts[0]._id)
  }, [accounts]);

  return (
    <ModalContext
      open={ open }
      setOpen={ setOpen }
      headerComponent={ <HeaderModalRedeem setOpen={ setOpen } setStep={ setStep }/> }
      sectionComponent={
        <SectionModalRedeem
          step={ step }
          selectedInvestment={ selectedInvestment }
          currency={ currency }
          setCurrency={ setCurrency }
          isFlexible={ isFlexible }
        /> }
      footerComponent={
        <FooterModalRedeem
          step={ step }
          setStep={ setStep }
          currency={ currency }
          selectedInvestment={ selectedInvestment }
          setOpen={ setOpen }
          setSection={ setSection }
          savingAccountId={ savingAccountId }
          isFlexible={ isFlexible }
        /> }
    />
  )
}
