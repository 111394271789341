import React, { type FC } from 'react';
import darkLogo from '../../assets/images/logos//darkLogoLong2.svg';
import lightLogo from '../../assets/images/logos/lightLogoLong.svg';
import { useTheme } from '../ThemeProvider/ThemeProvider';

export const DefaultLogo: FC = () => {
  const { theme } = useTheme();
  const logoHref = theme === 'dark' ? `${darkLogo}#dark-logo-long2` : `${lightLogo}#light-logo-long`;

  return (
    <svg width="350" height="40" viewBox="135 0 300 32">
      <use xlinkHref={logoHref} />
    </svg>
  );
};
