import React, { type FC, useState, type ReactElement, useEffect } from 'react';

import { DepositHeaderComponent } from './DepositHeaderComponent/DepositHeaderComponent';
import { DepositSubHeaderComponent } from './DepositSubHeaderComponent/DepositSubHeaderComponent';
import { CryptoDepositFormComponent } from './CryptoDepositFormComponent/CryptoDepositFormComponent';
import { CardDepositFormComponent } from './CardDepositFormComponent/CardDepositFormComponent';
import { BankDepositFormComponent } from './BankDepositFormComponent/BankDepositFormComponent';
import { OtherDepositFormComponent } from './OtherDepositComponent/OtherDepositComponent';
import { setShowFooter } from '../../../redux/reducers/showFooter';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { type IAccount } from '../../../redux/reducers/accounts';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { modalsAssetsSpacing } from '../helper';

import style from './DepositComponent.module.css';

interface InterfaceDepositComponent {
  setOpen: (el: boolean) => void
  selectedAccount: IAccount | null
}

export type TypesubHeaderOptions = 'Crypto' | 'Card' | 'Bank' | 'Other'

const options: Array<{ ticker: TypesubHeaderOptions }> = [
  { ticker: 'Crypto' },
  { ticker: 'Card' },
  { ticker: 'Bank' },
  { ticker: 'Other' }
];

const DepositNodes = {
  Crypto: CryptoDepositFormComponent,
  Card: CardDepositFormComponent,
  Bank: BankDepositFormComponent,
  Other: OtherDepositFormComponent
};

const getMarginBottom = (asset: TypesubHeaderOptions, isMobile: boolean): string => {
  if (asset === 'Other') {
    return isMobile ? '50px' : '220px';
  }
  return isMobile ? `${modalsAssetsSpacing[asset]}px` : '220px';
};

export const DepositComponent: FC<InterfaceDepositComponent> = ({ setOpen, selectedAccount }) => {
  const dispatch = useAppDispatch();

  const [selectedAsset, setSelectedAsset] = useState<TypesubHeaderOptions>(options[0].ticker);
  const [step, setStep] = useState<number>(1);
  const [screenWidth] = useWindowSize();
  const { showFooter } = useAppSelector((state) => state.showFooter);

  const isMobile = screenWidth <= 599;
  const marginBottomPx = getMarginBottom(selectedAsset, isMobile);

  useEffect(() => {
    if (isMobile && showFooter) dispatch(setShowFooter(false));
  }, []);

  const renderSelectedDepositForm = (): ReactElement => {
    const Component = DepositNodes[selectedAsset];

    const props = { setOpen, step, setStep, selectedAccount };

    return <Component {...props} />;
  };

  return (
    <div className={ style.mainWrapper }>
      <DepositHeaderComponent setOpen={ setOpen } selectedAccount={selectedAccount}/>
      <div
        className={ style.bodyWrapper }
        style={{ maxHeight: isMobile ? `calc(100dvh - ${marginBottomPx})` : `calc(100vh - ${marginBottomPx})` }}
      >
        {
          step === 1
            ? (
                <DepositSubHeaderComponent
                  options={ options }
                  selectedOptions={ selectedAsset }
                  setSelectedOptions={ setSelectedAsset }
                />
              )
            : null
        }
        {renderSelectedDepositForm()}
      </div>
    </div>
  )
}
