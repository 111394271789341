import React from 'react';
import { Link } from 'react-router-dom';
import { type IMarketToAssetSocketData } from '../../../../../../interfaces';
import { type IOrder } from '../../../../../../redux/reducers/orders';
import { type IColumn } from '../../../../../../interfaces/ColumnInterface';
import { getIconByName } from '../../../../helper';
import styles from '../DataTable.module.css';
import { toFixed, formatDate, getCurrencySymbol, getTradeTypeNameByIndex } from '../../../../../../helpers/util';
import { ButtonComponent } from '../../../../../../components';
import { t } from 'i18next';

interface IActiveOrderProps {
  onEditClick: (row: IOrder) => void
  onStopClick: (row: IOrder) => void
  marketToAssets: IMarketToAssetSocketData[]
  currencyRates: Record<string, number>
  activeAccountSymbol: string
}

export const activeOrderSchema = ({ onEditClick, onStopClick, marketToAssets, currencyRates, activeAccountSymbol }: IActiveOrderProps): IColumn[] => {
  return [
    {
      name: t('labels.num'),
      cell: (row: IOrder, idx: number) => String(idx + 1)
    },
    {
      name: t('labels.symbol'),
      align: 'left',
      cell: (row: IOrder): React.ReactNode => (
          <Link to={`/trade?asset=${row.setting?.symbol}`} className={styles.symbolCell}>
            <img src={getIconByName(row.setting?.label ?? '')} alt={row.setting?.label}/>
            <span>{row.setting?.label}</span>
          </Link>
      )
    },
    {
      name: t('labels.id'),
      align: 'left',
      cell: (row: IOrder): string => (String(row.customId))
    },
    {
      name: t('labels.type'),
      align: 'left',
      cell: (row: IOrder): React.ReactNode => {
        const tradeTypeName = getTradeTypeNameByIndex(row.tradeType ?? 0);
        const isBuy = tradeTypeName.includes('Buy');

        return <span className={isBuy ? styles.typeLabelBuy : styles.typeLabelSell}>{tradeTypeName}</span>
      }
    },
    {
      name: t('labels.value'),
      align: 'left',
      cell: (row: IOrder): string => (String(toFixed((row.position ?? 0) * (row.tradeStartPrice ?? 0), 2)))
    },
    {
      name: t('labels.open_price'),
      align: 'left',
      cell: (row: IOrder): string => (`${toFixed(row.tradeStartPrice ?? 0, 2)} ${getCurrencySymbol('USD')}`)
    },
    {
      name: t('labels.tp'),
      align: 'left',
      cell: (row: IOrder): string => (row.takeProfitPrice !== undefined && Number(row.takeProfitPrice) > 0 ? String(row.takeProfitPrice) : '-')
    },
    {
      name: t('labels.sl'),
      align: 'left',
      cell: (row: IOrder): string => (row.stopLossPrice !== undefined && Number(row.stopLossPrice) > 0 ? String(row.stopLossPrice) : '-')
    },
    {
      name: t('labels.open_time'),
      align: 'left',
      cell: (row: IOrder): string => {
        const date = new Date(row.timeOpened ?? '');

        return formatDate(date);
      }
    },
    {
      name: t('labels.price'),
      align: 'left',
      cell: (row: IOrder): string => {
        const data = marketToAssets.find((item) => item.market === row.type?.name) ?? { prices: [] };
        const assetQuote = data?.prices.find(({ symbol }) => symbol === row.brokerSymbol);

        if (assetQuote !== undefined) {
          return (`${toFixed(assetQuote.bid, 5)} ${getCurrencySymbol('USD')}`);
        }

        return ''
      },
      sx: {
        width: '60px',
        minWidth: '60px',
        maxWidth: '60px'
      }
    },
    {
      name: t('labels.commission'),
      cell: (row: IOrder): string => {
        if (row.status === 'pending') {
          return '-';
        }

        const valueInUsd = toFixed(row.userInvestedAmount ?? 0, 2)

        if (activeAccountSymbol !== undefined) {
          // convert to selected currency
          const value = valueInUsd * currencyRates[activeAccountSymbol]
          const commission = row.commission ?? 0

          const commissionAmount = value * (commission / 100)
          return `${toFixed(commissionAmount, 2)} ${getCurrencySymbol(activeAccountSymbol)}`
        }

        return `0 ${getCurrencySymbol(activeAccountSymbol)}`;
      }
    },
    {
      name: t('labels.swap'),
      cell: (row: IOrder): string => {
        const valueInUsd = +(row.userInvestedAmount ?? 0)

        if (activeAccountSymbol !== undefined) {
          // convert to selected currency
          const value = valueInUsd * currencyRates[activeAccountSymbol]
          const actualSwap = row.endSwap ?? 0

          const swapFee = toFixed((value * (actualSwap / 100)), 2)
          return `${swapFee} ${getCurrencySymbol(activeAccountSymbol)}`;
        }

        return `0 ${getCurrencySymbol(activeAccountSymbol)}`;
      }
    },
    {
      name: t('labels.pnl'),
      align: 'left',
      cell: (row: IOrder): React.ReactNode => {
        if (row.status === 'pending') {
          return '-';
        }

        const data = marketToAssets.find((item) => item.market === row.type?.name) ?? { prices: [] };
        const assetQuote = data?.prices.find(({ symbol }) => symbol === row.brokerSymbol);

        if (assetQuote !== undefined && row.position !== undefined && row.tradeStartPrice !== undefined) {
          const marketPrice = row.tradeType === 1 ? assetQuote.bid : assetQuote.ask;
          const pnl = row.tradeType === 1 ? (row.position * (marketPrice - row.tradeStartPrice)) : (row.position * (row.tradeStartPrice - marketPrice));

          const PNLinUsd = toFixed(pnl, 2);

          if (activeAccountSymbol !== undefined) {
            // convert PNL to selected currency
            const value = toFixed((PNLinUsd * currencyRates[activeAccountSymbol]), 2)

            return (
              <span style={{ color: pnl > 0 ? 'var(--CFD-theme-Custom-Green)' : 'var(--CFD-theme-Custom-Red)' }}>
                {`${value} ${getCurrencySymbol(activeAccountSymbol)}`}
              </span>
            );
          }
        }

        return `0 ${getCurrencySymbol(activeAccountSymbol)}`;
      },
      sx: {
        widht: '60px',
        minWidth: '60px',
        maxWidth: '60px'
      }
    },
    {
      name: t('labels.actions'),
      cell: (row: IOrder): React.ReactNode => {
        const data = marketToAssets.find((item) => item.market === row.type?.name) ?? { prices: [] };
        const assetQuote = data?.prices.find(({ symbol }) => symbol === row.brokerSymbol);

        return (
          (assetQuote !== undefined && !assetQuote.marketHolidays) && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <ButtonComponent variant='outlined' btnstyle="on-surface" onClick={() => { onEditClick(row); }}>{t('labels.edit')}</ButtonComponent>
            <ButtonComponent variant='outlined' btnstyle="on-surface" onClick={() => { onStopClick(row); }}>{t('labels.stop')}</ButtonComponent>
          </div>
          )
        )
      }
    }
  ]
};
