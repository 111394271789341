export const getBrowser = (): string => {
  let currentBrowser = 'unknown';
  if ((navigator.userAgent.includes('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    currentBrowser = 'Opera';
  } else if (navigator.userAgent.includes('Edg')) {
    currentBrowser = 'Edge';
  } else if (navigator.userAgent.includes('Chrome')) {
    currentBrowser = 'Chrome';
  } else if (navigator.userAgent.includes('Safari')) {
    currentBrowser = 'Safari';
  } else if (navigator.userAgent.includes('Firefox')) {
    currentBrowser = 'Firefox';
  } else if (navigator.userAgent.includes('MSIE')) {
    currentBrowser = 'IE';
  }

  return currentBrowser;
};

export const getOperatingSystem = (): string => {
  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.includes('Win')) {
    operatingSystem = 'Windows OS';
  } else if (window.navigator.appVersion.includes('Mac')) {
    operatingSystem = 'MacOS';
  } else if (window.navigator.appVersion.includes('X11')) {
    operatingSystem = 'UNIX OS';
  } else if (window.navigator.appVersion.includes('Linux')) {
    operatingSystem = 'Linux OS';
  }

  return operatingSystem;
};
