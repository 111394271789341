/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useState, useEffect } from 'react';
import styles from '../Auth.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header, Wrapper, Container } from '../components';
import { ButtonComponent, PasswordInputComponent, useSnackbar, type HandleOpenInterface } from '../../../components';
import { type PayloadInterface } from '../../../interfaces';
import { AuthData } from '../../../auth/AuthWrapper';
import { recoverPassword } from '../../../redux/reducers/user';
import { useAppDispatch } from '../../../hooks/redux';

export const PasswordRecovery: React.FC = (): JSX.Element => {
  const { token } = useParams();
  const { t } = useTranslation();
  const { handleOpen } = useSnackbar();
  const { loginByToken } = AuthData();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [password, setPassword] = useState({ value: '', showPassword: false });
  const [confirmPassword, setConfirmPassword] = useState({ value: '', showPassword: false });
  const [inputsData, setInputsData] = useState({
    password: {
      isError: false,
      errorMsg: ''
    },
    confirmPassword: {
      isError: false,
      errorMsg: ''
    }
  });

  useEffect(() => {
    if (inputsData.password.isError || inputsData.confirmPassword.isError) {
      setInputsData(
        {
          password: {
            isError: false,
            errorMsg: ''
          },
          confirmPassword: {
            isError: false,
            errorMsg: ''
          }
        }
      )
    }
  }, [password, confirmPassword]);

  const submit = async (): Promise<void> => {
    setIsLoading(true);
    const response = await dispatch(recoverPassword(
      {
        token,
        data: {
          password: password.value,
          confirmPassword: confirmPassword.value
        }
      }
    ));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      if (typeof payload.error === 'object') {
        const [key, value] = Object.entries(payload.error)[0];

        setInputsData((prev) => ({ ...prev, [key]: { isError: true, errorMsg: value } }))
      }

      if (typeof payload.error === 'string') {
        const openParams: HandleOpenInterface = {
          message: payload.error,
          actionText: '',
          severity: 'error',
          autoClose: false
        }

        handleOpen(openParams);
      }

      setIsLoading(false);

      return;
    }

    setIsLoading(false);
    loginByToken(payload.data as string);
  }

  return (
    <Wrapper>
        <Header />
        <Container
            subtitle={<h2>{t('login_page.password_recovery')}</h2>}
            content={
              <>
                <div className={styles.containerBody__column}>
                  <div className={styles.containerBody}>
                    <label htmlFor="password">{t('login_page.enter_new_password')}</label>
                    <div className={styles.inputContainer}>
                      <PasswordInputComponent
                        id="password"
                        sizeInput="small"
                        value={password}
                        setValue={setPassword}
                        error={inputsData.password.isError}
                        errorText={inputsData.password.errorMsg}
                      />
                    </div>
                    <div className={ styles.emptyBox }></div>
                  </div>
                  <div className={styles.containerBody}>
                    <label htmlFor="confirmPassword">{t('login_page.confirm_new_password')}</label>
                    <div className={styles.inputContainer}>
                      <PasswordInputComponent
                        id="confirmPassword"
                        sizeInput="small"
                        value={confirmPassword}
                        setValue={setConfirmPassword}
                        error={inputsData.confirmPassword.isError}
                        errorText={inputsData.confirmPassword.errorMsg}
                      />
                    </div>
                    <div className={ styles.emptyBox }></div>
                  </div>
                  <div className={styles.containerBodyBtn}>
                    <div className={ styles.emptyBoxLeft }></div>
                      <div className={ styles.containerBtn }>
                        <ButtonComponent
                          variant='contained'
                          loading={isLoading}
                          onClick={() => { submit(); }}
                        >{t('login_page.set_password')}</ButtonComponent>
                      </div>
                    <div className={ styles.emptyBox }></div>
                  </div>
                  <div className={styles.containerBodyBtn}>
                    <div className={ styles.emptyBoxLeft }></div>
                      <div className={ styles.containerBtn }>
                        <ButtonComponent
                            variant='text'
                            onClick={() => { navigate('/login'); }}
                          >
                            {t('login_page.sign_in')}
                        </ButtonComponent>
                      </div>
                    <div className={ styles.emptyBox }></div>
                  </div>
                </div>
              </>
            }
        />
    </Wrapper>
  )
}
