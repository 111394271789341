import React, { type ReactNode, type FC } from 'react';

import { Step1SectionModalAssetsSubscribe } from '../Steps/Step1/Section/Step1SectionModalAssetsSubscribe';
import { Step2SectionModalAssetsSubscribe } from '../Steps/Step2/Section/Step2SectionModalAssetsSubscribe';
import { type SavingOption, type Saving } from '../../../interfaces/SavingInterface';

interface InterfaceSectionModalAssetsSubscribe {
  step: number
  icon: ReactNode
  saving: Saving
  open: boolean
  reqData: {
    selectedOption: SavingOption
    selectedAsset?: string
    currencyId: string
    releaseTime?: Date
    investedAmount?: number
    userId?: string
    currencySymbol: string
  }
  handleChangeReqData: ({ field, value }: { field: string, value: any }) => void
}

export const SectionModalAssetsSubscribe: FC<InterfaceSectionModalAssetsSubscribe> = ({ step, icon, saving, reqData, handleChangeReqData, open }) => {
  if (step === 1) {
    return (
      <Step1SectionModalAssetsSubscribe icon={icon} saving={saving} reqData={reqData} handleChangeReqData={handleChangeReqData} open={ open }/>
    )
  }
  return (
    <Step2SectionModalAssetsSubscribe reqData={reqData} />
  )
}
