import React, { useEffect, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { type RouteLayoutInterface } from '../interfaces';

export const AuthLayout: FC<RouteLayoutInterface> = ({ isUserAuth, children }): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserAuth) navigate('/trade?asset=EURUSD');
  }, [isUserAuth]);

  return (
    <>
      {children}
    </>
  );
}
