import React from 'react';
import { CircularProgress, LinearProgress, Box } from '@mui/material';
import { type Theme, styled } from '@mui/material/styles';
import { type SxProps } from '@mui/system';

interface ProgressInterface {
  type: 'linear' | 'circular'
  variant?: 'indeterminate' | 'determinate'
  value?: number
  sx?: SxProps<Theme> | undefined
}

export const ProgressComponent: React.FC<ProgressInterface> = ({ variant = 'indeterminate', type, sx = { width: 'auto' }, ...rest }) => {
  const CustomizedCircularProgress = styled(CircularProgress)({
    color: 'var(--CFD-theme-System-Tertiary)'
  });

  const CustomizedLinearProgress = styled(LinearProgress)({
    color: 'var(--CFD-theme-System-Tertiary)',
    width: '100%',
    '&.MuiLinearProgress-root': {
      backgroundColor: 'var(--CFD-theme-System-TertiaryContainer)',
      '& .MuiLinearProgress-bar': {
        backgroundColor: 'var(--CFD-theme-System-Tertiary)',
        borderRadius: '12px'
      }
    }
  });

  const setProgress = (): JSX.Element => {
    const typeToProgressComponent = {
      linear: <CustomizedLinearProgress variant={variant} sx={sx} {...rest} />,
      circular: <CustomizedCircularProgress variant={variant} sx={sx} {...rest} />
    }

    return typeToProgressComponent[type];
  }

  return (
    <Box>
      { setProgress() }
    </Box>
  );
}
