import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface InitState {
  isFetching: boolean
  isErrorOccur: boolean
  is2FAPinCodeModalOpen: boolean
  is2FACodeIncorrect: boolean
}

const initialState: InitState = {
  isFetching: false,
  isErrorOccur: false,
  is2FAPinCodeModalOpen: false,
  is2FACodeIncorrect: false
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setIsFetchingLogin (state, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },
    setIsLoginErrorOccur (state, action: PayloadAction<boolean>) {
      state.isErrorOccur = action.payload;
    },
    setIs2FAPinCodeModalOpen (state, action: PayloadAction<boolean>) {
      state.is2FAPinCodeModalOpen = action.payload;
    },
    setIs2FACodeIncorrect (state, action: PayloadAction<boolean>) {
      state.is2FACodeIncorrect = action.payload;
    }
  }
});

export const { setIsFetchingLogin, setIsLoginErrorOccur, setIs2FAPinCodeModalOpen, setIs2FACodeIncorrect } = loginSlice.actions;

interface LoginDataType {
  email: string
  password: string
}

export const loginAsyncThunk = createAsyncThunk('login/login', async (loginData: LoginDataType, { dispatch }) => {
  const { email, password } = loginData;

  dispatch(setIsFetchingLogin(true));
  dispatch(setIsLoginErrorOccur(false));

  try {
    // do something...
    console.log(email, password)
  } catch (error) {
    // set to store flag that mean "something went wrong"
    dispatch(setIsLoginErrorOccur(true));
    console.log(error)
  }

  dispatch(setIsFetchingLogin(false));
});

export default loginSlice.reducer;
