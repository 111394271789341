import React, {
  type HTMLAttributes,
  type FC
} from 'react';

import { Step1SectionModalVerifications } from '../Steps/Step1/Section/Step1SectionModalVerifications';
import { Step2SectionModalVerifications } from '../Steps/Step2/Section/Step2SectionModalVerifications';
import { Step3SectionModalVerifications } from '../Steps/Step3/Section/Step3SectionModalVerifications';
import { SectionSuccessModal } from '../../../components';
import { t } from 'i18next';
import {
  AddressSectionModalVerifications
} from '../Steps/AddressSection/AddressSectionModalVerifications';
import { type KycType } from '../../../redux/reducers/kycTypes';

interface ISectionModalVerification {
  setFrontSideDocsPicked: (el: boolean) => void
  setBackSideDocsPicked: (el: boolean) => void
  setFrontSideCardPicked: (el: boolean) => void
  setBackSideCardPicked: (el: boolean) => void
  setSelfiePicked: (el: boolean) => void
  setAddressPicked: (el: boolean) => void
  currentKycType: KycType
}

type TSectionModalVerification = ISectionModalVerification & HTMLAttributes<HTMLElement>

const kycTypeToStepComponentMap = new Map<string, FC<any>>([
  ['verifyDocument', Step1SectionModalVerifications],
  ['verifyCard', Step2SectionModalVerifications],
  ['verifySelfie', Step3SectionModalVerifications],
  ['verifyAddress', AddressSectionModalVerifications]
]);

export const SectionModalVerifications: FC<TSectionModalVerification> = ({
  setBackSideDocsPicked,
  setFrontSideDocsPicked,
  setFrontSideCardPicked,
  setBackSideCardPicked,
  setSelfiePicked,
  setAddressPicked,
  currentKycType
}) => {
  const StepComponent = kycTypeToStepComponentMap.get(currentKycType?.name);

  if (!StepComponent) {
    return <SectionSuccessModal content={t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.success.title')} />;
  }

  return (
      <StepComponent
          setBackSideDocsPicked={setBackSideDocsPicked}
          setFrontSideDocsPicked={setFrontSideDocsPicked}
          setFrontSideCardPicked={setFrontSideCardPicked}
          setBackSideCardPicked={setBackSideCardPicked}
          setSelfiePicked={setSelfiePicked}
          setAddressPicked={setAddressPicked}
      />
  );
}
