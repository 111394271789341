import React, { type FC, type ReactElement } from 'react';
import { styled, alpha } from '@mui/system';
import { Slider as BaseSlider, sliderClasses } from '@mui/base/Slider';

interface SliderInterface {
  value: number | number[]
  setValue: (el: number | number[]) => void
  ariaLabelledby?: string
  valueLabelDisplay?: string
  min?: number
  max?: number
  orientation?: 'horizontal' | 'vertical'
  step?: number
  disable?: boolean
  defaultValue?: number
  marks?: Array<{ value: number, label?: string }>
}

interface SliderValueLabelProps {
  children: ReactElement
}

const SliderValueLabel = ({ children }: SliderValueLabelProps): JSX.Element => {
  return (
    <span className="label">
      <div className="value">{children}</div>
    </span>
  );
}

const greyThumbColor = '#6B7A90'

const Slider = styled(BaseSlider)`
  color: var(--CFD-theme-System-Tertiary);
  height: 10px;
  width: 100%;
  padding: 10px 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &.${sliderClasses.disabled} { 
    pointer-events: none;
    cursor: default;
    color: var(--CFD-theme-System-OnSurface);
    opacity: 0.4;  

    & .${sliderClasses.rail} {
      background-color: var(--CFD-theme-System-Inverseonsurface); 
    } 

    & .${sliderClasses.mark} {
      background-color: var(--CFD-theme-System-OnSurface); 
    } 
  }

  & .${sliderClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 10px;
    border-radius: 6px;
    padding-right: 4px;
    background-color: var(--CFD-theme-System-TertiaryContainer);
    opacity: 0.3;
  }

  & .${sliderClasses.track} {
    display: block;
    position: absolute;
    height: 10px;
    border-radius: 6px;
    background-color: currentColor;
  }

  & .${sliderClasses.thumb} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -4px;
    width: 4px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    outline: 0;
    background-color: var(--CFD-theme-System-Tertiary);
    transition-property: box-shadow, transform;
    transition-timing-function: ease;
    transition-duration: 120ms;
    transform-origin: center;

    &.${sliderClasses.focusVisible} {
      box-shadow: 0 0 0 6px ${alpha(
        greyThumbColor,
        0.3
      )};
      outline: none;
    }

    &.${sliderClasses.active} {
      box-shadow: 0 0 0 6px ${alpha(
        greyThumbColor,
        0.6
      )};
      outline: none;
      transform: scale(1.2);
    }
    
    &.${sliderClasses.disabled} {
      background-color: var(--CFD-theme-System-OnSurface);
    }

    & .label {
      font-family: IBM Plex Sans;
      font-weight: 600;
      font-size: 14px;
      background: unset;
      background-color: var(--CFD-theme-System-OnSurface);
      width: 32px;
      height: 32px;
      padding: 0px;
      visibility: hidden;
      color: var(--CFD-theme-System-Inverseonsurface);
      border-radius: 50%;
      position: absolute;
      transform: translate(0%, -140%) rotate(-45deg) scale(0);
      transition: transform 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :hover .label {
      visibility: visible;
      transform: translate(0%, -140%) rotate(-45deg) scale(1);
    }

    :hover .value {
      transform: rotate(45deg);
      text-align: center;
    }
  }

  & .${sliderClasses.mark} {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 99%;
    background-color: var(--CFD-theme-System-Tertiary);
    transform: translateX(-50%);
  }

  & .${sliderClasses.markActive} {
    background-color: var(--CFD-theme-System-TertiaryContainer);
  }

  & .${sliderClasses.markLabel} {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 12px;
    position: absolute;
    top: 24px;
    transform: translateX(-50%);
    margin-top: 8px;
  }
  `
;

export const SliderComponent: FC<SliderInterface> = ({
  value,
  setValue,
  ariaLabelledby = 'input-slider',
  valueLabelDisplay = 'auto',
  min = 0,
  max = 100,
  orientation = 'horizontal',
  step = 1,
  disable = false,
  defaultValue = 50,
  marks
}) => {
  const handleChange = (event: Event, newValue: number | number[]): void => {
    setValue(newValue as number);
  };

  const defaultMark = [{ value: 100 }];

  console.log(value)
  return (
    <>
      <Slider
       value={ value }
       onChange={ handleChange }
       aria-labelledby={ ariaLabelledby }
       valueLabelDisplay={ valueLabelDisplay }
       orientation={ orientation }
       defaultValue={ defaultValue }
       step={ step }
       min={ min }
       max={ max }
       disabled={ disable }
       marks={ marks ?? defaultMark }
       slots={{ valueLabel: SliderValueLabel }}
      />
    </>
  );
};
