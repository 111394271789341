import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterTableIcon } from '../../../../../../components/icons/FilterTableIcon';
import { type SortStatusTypeAndNull, type ITableHead } from '../../../../interface/savingsPageInterfaces';

import style from './TableHead.module.css';

export const TableHead: FC<ITableHead> = ({ isFlexible, sortField, setSortField, sortOrder, setSortOrder }) => {
  const { t } = useTranslation();

  const filterButtonHandler = (id: string): void => {
    setSortField(id);
    setSortOrder(sortField === id ? (sortOrder === 'desc' ? 'asc' : 'desc') : 'asc');
  }

  const investmentSortStatus: SortStatusTypeAndNull = sortField === 'investment' ? sortOrder : 'idle';
  const rateSortStatus: SortStatusTypeAndNull = sortField === 'rate' ? sortOrder : 'idle';
  const periodInvestmentSortStatus: SortStatusTypeAndNull = sortField === 'periodInvestment' ? sortOrder : 'idle';
  const beforeReleaseSortStatus: SortStatusTypeAndNull = sortField === 'beforeRelease' ? sortOrder : 'idle';
  const cumulativeTotalSortStatus: SortStatusTypeAndNull = sortField === 'cumulativeTotal' ? sortOrder : 'idle';

  return (
    <div className={ style.cellContainer }>
      <div className={ style.tableHeadersTitleWrapper } onClick={() => { filterButtonHandler('investment'); } }>
        <p className={ `${style.tableHeadersTitle} ${style.paddingLeft}` }>
          { t('savings_page.table_body.accounts_tab.accordion_details.investment_title') }
        </p>
        <button className={ style.cellTextBtnIcon }>
          <FilterTableIcon status={ investmentSortStatus } />
        </button>
      </div>
      {
        (isFlexible ?? false)
          ? (
              <>
                <div className={ style.tableHeadersTitleWrapper } onClick={() => { filterButtonHandler('rate'); } }>
                  <p className={ style.tableHeadersTitle }>
                    { t('savings_page.table_body.accounts_tab.accordion_details.rate_title') }
                  </p>
                  <button className={ style.cellTextBtnIcon }>
                    <FilterTableIcon status={ rateSortStatus } />
                  </button>
                </div>
                <div className={ style.tableHeadersTitleWrapper } onClick={() => { filterButtonHandler('periodInvestment'); } }>
                  <p className={ style.tableHeadersTitle }>
                    { t('savings_page.table_body.accounts_tab.accordion_details.period_title') +
                      ' ' + t('savings_page.table_body.accounts_tab.accordion_details.investment_title') }
                  </p>
                  <button className={ style.cellTextBtnIcon }>
                    <FilterTableIcon status={ periodInvestmentSortStatus } />
                  </button>
                </div>
              </>
            )
          : (
              <>
                <div className={ style.tableHeadersTitleWrapper }>
                  <p className={ style.tableHeadersTitle }>
                    { t('savings_page.subscribe_modal.Locked_period') }
                  </p>
                </div>
                <div className={ style.tableHeadersTitleWrapper } onClick={() => { filterButtonHandler('rate'); } }>
                  <p className={ style.tableHeadersTitle }>
                    { t('savings_page.table_body.accounts_tab.accordion_details.rate_title') }
                  </p>
                  <button className={ style.cellTextBtnIcon }>
                    <FilterTableIcon status={ rateSortStatus } />
                  </button>
                </div>
              </>
            )
      }
      {
        !(isFlexible ?? false) &&
          <div className={ style.tableHeadersTitleWrapper } onClick={() => { filterButtonHandler('beforeRelease'); } }>
            <p className={ style.tableHeadersTitle }>
              { t('savings_page.table_body.accounts_tab.accordion_details.Before_the_release') }
            </p>
            <button className={ style.cellTextBtnIcon }>
              <FilterTableIcon status={ beforeReleaseSortStatus } />
            </button>
          </div>
      }
      <div className={ style.tableHeadersStatusWrapper }>
        <p className={ style.tableHeadersTitle }>
          { t('savings_page.table_body.accounts_tab.accordion_details.status_title') }
        </p>
      </div>
      <div className={ style.tableHeadersTitleWrapper } onClick={() => { filterButtonHandler('cumulativeTotal'); } }>
        <p className={ style.tableHeadersTitle }>
          { t('savings_page.table_head.accounts_tab.total_title') }
        </p>
        <button className={ style.cellTextBtnIcon }>
          <FilterTableIcon status={ cumulativeTotalSortStatus } />
        </button>
      </div>
    </div>
  )
}
