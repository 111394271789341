import React, { type FC, useState } from 'react';
import { PlusIcon } from '../../../../../components/icons/PlusIcon';
import { MinusIcon } from '../../../../../components/icons/MinusIcon';
import styles from './NumberInput.module.css';

interface INumberInput {
  label?: string
  id?: string
  value?: string | number
  isIncBtnPresent?: boolean
  onChange?: (value: string) => void
  onInc?: () => void
  onDec?: () => void
}

export const NumberInput: FC<INumberInput> = ({
  label = '',
  id = '',
  value,
  isIncBtnPresent = false,
  onChange = (value: string) => {},
  onInc = () => {},
  onDec = () => {}

}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleChange = (value: string): void => {
    if (Number(value) < 0) value = '0';

    onChange(
      value
        .replace(/[^\d.]/g, '')
        .replace(/^0+(\d)/, '0$1')
    );
  }
  return (
        <>
            {label.length > 0 && <label htmlFor={id} className={styles.numberInputContainerLabel}>{label}</label>}
            <div className={`${styles.numberInputContainer} ${isFocused ? styles.focused : ''}`}>
                <input
                  type='text'
                  id={id}
                  value={value}
                  onChange={({ target: { value } }) => { handleChange(value); }}
                  onFocus={() => { setIsFocused(true); }}
                  onBlur={() => { setIsFocused(false); }}
                  maxLength={18}
                />
                {
                    isIncBtnPresent && (
                        <div className={styles.numberInputContainer__btnRow}>
                            <button onClick={onInc}>
                                <PlusIcon color='var(--CFD-theme-System-Tertiary)' />
                            </button>
                            <button onClick={onDec}>
                                <MinusIcon color='var(--CFD-theme-System-Tertiary)' />
                            </button>
                        </div>
                    )
                }
            </div>
        </>
  )
}
