import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionSuccessModal, ButtonComponent } from '../../../../components';
import { formatDate, toFixed } from '../../../../helpers/util';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { setShowFooter } from '../../../../redux/reducers/showFooter';

import style from './SuccessMobileTransactionsBetweenAccounts.module.css';

interface InterfaceSuccessMobileTransactionsBetweenAccounts {
  fromValue: number
  toValue: number
  fromSymbol: string
  toSymbol: string
  setOpen: (el: boolean) => void
}

export const SuccessMobileTransactionsBetweenAccounts: FC<InterfaceSuccessMobileTransactionsBetweenAccounts> = ({ fromSymbol, fromValue, toSymbol, toValue, setOpen }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [screenWidth] = useWindowSize();
  const { showFooter } = useAppSelector((state) => state.showFooter);

  const isMobile = screenWidth <= 599;

  const closeBtmHandler = (): void => {
    if (isMobile && !showFooter) dispatch(setShowFooter(true));
    setOpen(false);
  }

  const viewHistoryBtnHandler = (): void => {
    if (isMobile && !showFooter) dispatch(setShowFooter(true));
    setOpen(false);
  }

  return (
    <>
      <div className={ style.successWrapper }>
        <SectionSuccessModal title={ `${t('labels.transfer_successful')}` } />
        <div className={ style.successSummary }>
          <div className={ style.summuryContainer }>
            <p className={ style.summaryLabel }>{ t('labels.from_account') }</p>
            <p className={ style.summaryValue }>{`Account ${fromSymbol}`}</p>
          </div>
          <div className={ style.summuryContainer }>
            <p className={ style.summaryLabel }>{ t('labels.transfer') }</p>
            <p className={ style.summaryValue }>{`${fromValue} ${fromSymbol}`}</p>
          </div>
          <div className={ style.summuryContainer }>
            <p className={ style.summaryLabel }>{ t('labels.exchange_rate') }</p>
            <p className={ style.summaryValue }>{`${fromValue} ${fromSymbol} = ${toFixed(toValue, 4)} ${toSymbol}`}</p>
          </div>
          <div className={ style.summuryContainer }>
            <p className={ style.summaryLabel }>{ t('labels.date') }</p>
            <p className={ style.summaryValue }>{formatDate(new Date())}</p>
          </div>
        </div>
      </div>
      <footer className={ style.footerWrapper }>
        <div className={ style.btnWrapper }>
          <div className={ style.btnContainer }>
            <ButtonComponent onClick={ viewHistoryBtnHandler }>{ t('labels.view_history') }</ButtonComponent>
          </div>
          <div className={ style.btnContainer }>
            <ButtonComponent onClick={ closeBtmHandler } variant='outlined'>{ t('labels.close') }</ButtonComponent>
          </div>
        </div>
      </footer>
    </>
  )
}
