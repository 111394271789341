import { TableCell } from '@mui/material';
import { type TableCellProps, tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

interface StyledTableCellProps extends TableCellProps {
  borderBottom?: string
}

export const StyledTableCell = styled(TableCell)<StyledTableCellProps>(({ borderBottom = 'none' }) => ({
  borderBottom,
  boxSizing: 'border-box',
  width: '100%',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--CFD-theme-Surface-2)',
    color: 'var(--CFD-theme-System-OnSurface)'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: 'var(--CFD-theme-Surface-2)',
    color: 'var(--CFD-theme-System-OnSurface)'
  },
  [`&.${tableCellClasses.root}`]: {
    fontSize: 14,
    backgroundColor: 'var(--CFD-theme-Surface-2)',
    color: 'var(--CFD-theme-System-OnSurface)'
  }
}));
