import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHead } from '@mui/material';

import { FilterTableIcon } from '../../../../../../components/icons/FilterTableIcon';
import { type SortStatusTypeAndNull, type ITableHeadHistory } from '../../../../interface/savingsPageInterfaces';
import { StyledTableRow } from '../../../StyledTableRow/StyledTableRow';
import { StyledTableCell } from '../../../StyledTableCell/StyledTableCell';

import style from './TableHeadHistory.module.css';

const styleForTableCell = {
  width: 'unset',
  padding: '8px 16px',
  borderBottom: '1px solid var(--CFD-theme-System-OutlineVariant)',
  backgroundColor: 'var(--CFD-theme-Surface-1) !important'
}

export const TableHeadHistory: FC<ITableHeadHistory> = ({ sortField, setSortField, sortOrder, setSortOrder }) => {
  const { t } = useTranslation();

  const filterButtonHandler = (id: string): void => {
    setSortField(id);
    setSortOrder(sortField === id ? (sortOrder === 'desc' ? 'asc' : 'desc') : 'asc');
  }

  const investmentSortStatus: SortStatusTypeAndNull = sortField === 'createdAt' ? sortOrder : 'idle';
  const rateSortStatus: SortStatusTypeAndNull = sortField === 'rate' ? sortOrder : 'idle';
  const redeemDateSortStatus: SortStatusTypeAndNull = sortField === 'redeemDate' ? sortOrder : 'idle';
  const cumulativeTotalSortStatus: SortStatusTypeAndNull = sortField === 'cumulativeTotal' ? sortOrder : 'idle';

  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell sx={ styleForTableCell }>
          <div className={ style.btnContainer }>
            <p className={ style.tableHeadersTitle }>
              { `${t('labels.savings')}  ${t('labels.account_lower')}` }
            </p>
          </div>
        </StyledTableCell>
        <StyledTableCell sx={ styleForTableCell }>
          <div className={ style.btnContainer } onClick={() => { filterButtonHandler('createdAt'); } }>
            <p className={ style.tableHeadersTitle }>
              { t('savings_page.subscribe_modal.Subscription_date') }
            </p>
            <button className={ style.cellTextBtnIcon }>
              <FilterTableIcon status={ investmentSortStatus } />
            </button>
          </div>
        </StyledTableCell>
        <StyledTableCell sx={ styleForTableCell }>
          <div className={ style.btnContainer } onClick={() => { filterButtonHandler('rate'); } }>
            <p className={ style.tableHeadersTitle }>
              { t('savings_page.table_body.accounts_tab.accordion_details.rate_title') }
            </p>
            <button className={ style.cellTextBtnIcon }>
              <FilterTableIcon status={ rateSortStatus } />
            </button>
          </div>
        </StyledTableCell>
        <StyledTableCell sx={ styleForTableCell }>
          <div className={ style.btnContainer } onClick={() => { filterButtonHandler('cumulativeTotal'); } }>
            <p className={ style.tableHeadersTitle }>
              { t('savings_page.table_head.accounts_tab.total_title') }
            </p>
            <button className={ style.cellTextBtnIcon }>
              <FilterTableIcon status={ cumulativeTotalSortStatus } />
            </button>
          </div>
        </StyledTableCell>
        <StyledTableCell sx={ styleForTableCell }>
          <div className={ style.btnContainer } onClick={() => { filterButtonHandler('redeemDate'); } }>
            <p className={ style.tableHeadersTitle }>
              { t('labels.redeem_date') }
            </p>
            <button className={ style.cellTextBtnIcon }>
              <FilterTableIcon status={ redeemDateSortStatus } />
            </button>
          </div>
        </StyledTableCell>
        <StyledTableCell sx={ styleForTableCell }>
          <div className={ style.btnContainer }>
            <p className={ style.tableHeadersTitle }>
              { t('labels.redeem_to_account') }
            </p>
          </div>
        </StyledTableCell>
      </StyledTableRow>
    </TableHead>
  )
}
