/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';
import { type RootState } from '../store';
import { settingsEnableGoogleTwoFactorAuthentication, settingsSetTwoFactorAuthDateAdded } from './profileSettings';

interface TwoFactorAuthenticationState {
  step: number
  verifyCode: string
  isCodeCorrect: boolean
  isLoading: boolean
  isError: boolean
}

// Initial state with types applied
const initialState: TwoFactorAuthenticationState = {
  step: 1,
  verifyCode: '',
  isCodeCorrect: false,
  isLoading: false,
  isError: false
};

const twoFactorAuthenticationSlice = createSlice({
  name: 'twoFactorAuthentication',
  initialState,
  reducers: {
    // Actions to handle state updates
    setStep2FA (state, action: PayloadAction<number>) {
      state.step = action.payload;
    },
    setVerifyCode2FA (state, action: PayloadAction<string>) {
      state.verifyCode = action.payload;
    },
    setIsCodeCorrect (state, action: PayloadAction<boolean>) {
      state.isCodeCorrect = action.payload;
    },
    setIsError2FA (state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(verify2FA.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(verify2FA.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verify2FA.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const {
  setStep2FA,
  setVerifyCode2FA,
  setIsCodeCorrect,
  setIsError2FA
} = twoFactorAuthenticationSlice.actions;

export default twoFactorAuthenticationSlice.reducer;

export const verify2FA = createAsyncThunk(
  'profile/verify2FA',
  async ({ code, handleOpen }: { code: string, handleOpen: any }, { dispatch, getState }) => {
    try {
      const { user: { email } } = getState() as RootState

      const response = await apiHelper({
        method: 'post',
        path: '/user/verify-tfa',
        data: { email, code }
      });

      if (!response.data) {
        throw new Error('Some error happened');
      }

      dispatch(settingsEnableGoogleTwoFactorAuthentication(true))
      dispatch(setStep2FA(3))
    } catch (error: any) {
      handleOpen({
        message: error.response.data.message,
        actionText: '',
        severity: 'error'
      });
    }
  }
);

export const switchOtpStatus2FA = createAsyncThunk(
  'profile/switchOtpStatus2FA',
  async (handleOpen: any, { dispatch, getState }) => {
    try {
      const { user: { _id }, profileSettings: { isGoogleTwoFactorAuthenticationEnabled } } = getState() as RootState

      const response = await apiHelper({
        method: 'post',
        path: '/user/change-otp-status',
        data: { userId: _id }
      });

      if (!response.data) {
        throw new Error('Some error happened');
      }

      dispatch(settingsEnableGoogleTwoFactorAuthentication(!isGoogleTwoFactorAuthenticationEnabled))
    } catch (error: any) {
      handleOpen({
        message: error.response.data.message,
        actionText: '',
        severity: 'error'
      });
    }
  }
);

export const fetchUser2FAData = createAsyncThunk(
  'profile/fetchUser2FAData',
  async (_: undefined, { dispatch, getState }) => {
    try {
      const { user: { otpStatus, otpVerifiedAt } } = getState() as RootState

      if (otpVerifiedAt) {
        dispatch(settingsSetTwoFactorAuthDateAdded(
          new Date(otpVerifiedAt).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          }))
        )
      }

      if (otpStatus) {
        dispatch(settingsEnableGoogleTwoFactorAuthentication(otpStatus))
      }
    } catch (error: any) {
      console.log(error.response.message)
    }
  }
);
