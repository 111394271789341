import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';

interface ICountry {
  _id: string
  iso: string
  iso3: string
  nicename: string
  name: string
};

const initialState: ICountry[] = [];

export const getCountries = createAsyncThunk(
  'countries/get',
  async () => {
    const response = await apiHelper({ method: 'get', path: '/country' });

    return response.data.countries;
  }
)

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCountries.fulfilled, (state, action: PayloadAction<ICountry[]>) => {
      return action.payload;
    })
  }
});

export default countriesSlice.reducer;
