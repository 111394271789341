import React, { type FC, type ChangeEvent } from 'react';
import { RadioGroup, FormControlLabel, FormControl, styled } from '@mui/material';

import { RadioButtonComponent } from '../../../../../components';

import styles from './CardRadioOptionsComponent.module.css';

interface ICardRadioOptionsComponent {
  options: Array<{ ticker: string }>
  selectedOptions: string
  setSelectedOptions: (el: string) => void
}

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  width: '100%',
  paddingBottom: '8px',
  boxSizing: 'border-box'
}))

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'start',
  justifyContent: 'center',
  boxSizing: 'border-box'
}))

export const CardRadioOptionsComponent: FC<ICardRadioOptionsComponent> = ({ options, selectedOptions, setSelectedOptions }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedOptions(event.target.value);
  };

  return (
    <StyledFormControl>
      <StyledRadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={ selectedOptions }
        onChange={ handleChange }
      >
        {options.map((el) => (
          <FormControlLabel
            sx={{
              borderRadius: '6px',
              marginLeft: '0',
              marginRight: '0',
              width: '100%'
            }}
            key={ el.ticker }
            value={ el.ticker }
            control={
              <RadioButtonComponent
                checked={ selectedOptions === el.ticker }
                onChange={ handleChange }
                value={ el.ticker }
              />
            }
            label={
              <div className={ styles.labelContainer }>
                <p className={ styles.labelText }>{ el.ticker }</p>
              </div>
            }
          />
        ))}
      </StyledRadioGroup>
    </StyledFormControl>
  )
}
