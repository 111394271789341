import React, { type FC } from 'react';

interface IProps {
  color?: string
}

export const ChangeIcon: FC<IProps> = ({ color = 'var(--CFD-theme-System-OnSurface)' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M8.73538 4.46405C9.03138 4.75381 9.03644 5.22865 8.74668 5.52465L6.50214 7.81754C6.33444 7.98885 6.19762 8.12916 6.08662 8.2469C6.24235 8.24964 6.43006 8.25 6.65838 8.25H18.9998C19.414 8.25 19.7498 8.58579 19.7498 9C19.7498 9.41421 19.414 9.75 18.9998 9.75L6.60537 9.75001C6.1484 9.75007 5.72537 9.75014 5.40399 9.70371C5.0945 9.65901 4.55461 9.52834 4.3295 8.97315C4.10758 8.42584 4.39253 7.95571 4.5778 7.70411C4.77168 7.44081 5.06965 7.1365 5.39374 6.80552L7.67478 4.47535C7.96453 4.17935 8.43938 4.17429 8.73538 4.46405Z" fill={color} />
    <path d="M17.913 15.7531C17.7573 15.7504 17.5696 15.75 17.3413 15.75H4.99982C4.58561 15.75 4.24982 15.4142 4.24982 15C4.24982 14.5858 4.58561 14.25 4.99982 14.25L17.3943 14.25C17.8512 14.2499 18.2743 14.2499 18.5957 14.2963C18.9052 14.341 19.445 14.4717 19.6702 15.0268C19.892 15.5741 19.6071 16.0444 19.4218 16.2959C19.2279 16.5592 18.9299 16.8636 18.6057 17.1947L16.3249 19.5246C16.0352 19.8206 15.5603 19.8257 15.2643 19.536C14.9683 19.2462 14.9632 18.7714 15.253 18.4754L17.4975 16.1825C17.6652 16.0112 17.802 15.8708 17.913 15.7531Z" fill={color} />
  </svg>
  )
}
