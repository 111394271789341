import React, { type FC, useState, useEffect, useMemo } from 'react';
import { getFiatCurrencies } from '../../../../redux/reducers/currencies';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ButtonComponent, SelectComponent, CreditCardInputComponent, useSnackbar } from '../../../../components';
import { addCard } from '../../../../redux/reducers/withdrawalMethods';
import style from './AddCardFormComponent.module.css';
import { type PayloadInterface } from '../../../../interfaces';
import { handleError } from '../../../../helpers';
import { t } from 'i18next';

interface InterfaceAddCardFormComponent {
  setAddWithdrawalMethod: (el: boolean) => void
}

export const AddCardFormComponent: FC<InterfaceAddCardFormComponent> = ({ setAddWithdrawalMethod }) => {
  const dispatch = useAppDispatch();
  const { handleOpen: handleOpenSnackbar } = useSnackbar();
  const [currencySelected, setCurrencySelected] = useState<any>('');
  const [cardNumber, setCardNumber] = useState<string>('');
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const currencies = useAppSelector((state) => state.currencies);
  const { _id: userId, firstName, lastName } = useAppSelector((state) => state.user);

  const options = useMemo(() => {
    return currencies.map((currency) => ({ label: currency.name, value: currency._id, symbol: currency.symbol }))
  }, [currencies]);

  useEffect(() => {
    dispatch(getFiatCurrencies());
  }, []);

  const handleAddMethod = async (): Promise<void> => {
    if (userId === undefined) return;
    setIsBtnLoading(true);
    const data = {
      name: `${firstName} ${lastName}`,
      card: cardNumber,
      currencyId: currencySelected,
      userId
    };

    const response = await dispatch(addCard(data));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      handleError(payload, handleOpenSnackbar);
      setIsBtnLoading(false);

      return;
    }

    setIsBtnLoading(false);
    setAddWithdrawalMethod(false);
  }
  const formComplete = (cardNumber.length > 0 && currencySelected.length > 0)

  return (
  <>
    <div className={ style.wrapper }>
      <h3 className={ style.mainTitle }>{t('withdrawal_modal.add_card_form_modal.title')}</h3>
      <div className={ style.formContainer }>
        <div className={ style.inputContainer }>
          <label htmlFor='creditCard' className={ style.label }>{t('withdrawal_modal.add_card_form_modal.card_label')}</label>
          <CreditCardInputComponent value={ cardNumber } setValue={ setCardNumber } id='creditCard' />
        </div>
        <div className={ style.inputContainer }>
          <label htmlFor='selectCurrency' className={ style.label }>{t('labels.currency')}</label>
          <SelectComponent options={ options } value={ currencySelected } onChange={({ target: { value } }) => { setCurrencySelected(value); } } id='selectCurrency'/>
        </div>
      </div>
    </div>
    <footer className={ style.footer }>
      <div className={ style.btnContainer }>
        <ButtonComponent onClick={() => { handleAddMethod(); } } disabled={ !formComplete } loading={isBtnLoading}>
          <span className={ style.btnText }>
            {t('withdrawal_modal.add_card_form_modal.action_button_text')}
          </span>
        </ButtonComponent>
      </div>
    </footer>
  </>
  )
}
