import React, { type FC, useEffect } from 'react'
import { PieChart } from '@mui/x-charts/PieChart';
import { Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type TabInterface, getPieChartData } from '../../../../redux/reducers/profile'
import EmptyTableIcon from '../../../../assets/images/emptyTable.svg';
import {
  getProfileSelectedTab,
  selectProfileIsPieChartErrorOccur,
  selectProfileIsPieChartLoading,
  selectProfilePieData
} from '../../../../redux/selectors/profile'
import { ProgressComponent } from '../../../../components';
import style from './CustomPieChart.module.css'

interface ICustomPieChart {
  calcHeight: number
}

export const CustomPieChart: FC<ICustomPieChart> = ({ calcHeight }) => {
  const dispatch = useAppDispatch()

  const data = useAppSelector(selectProfilePieData)
  const selectedTab: TabInterface | null = useAppSelector(getProfileSelectedTab)
  const isPieChartLoading: boolean = useAppSelector(selectProfileIsPieChartLoading)
  const isPieChartErrorOccur: boolean = useAppSelector(selectProfileIsPieChartErrorOccur)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (selectedTab) {
      dispatch(getPieChartData())
    }
  }, [selectedTab])

  if (isPieChartErrorOccur) {
    return <div>Some error happened</div>
  }

  if (data !== null && data.length === 0) {
    return (
      <div className={style.noDataWrapper}>
        <div className={style.emptyContainer}>
          <img src={EmptyTableIcon} alt="no_data" />
          <span>No data</span>
          <span>Nothing Found</span>
        </div>
      </div>
    )
  }

  if (data === null || isPieChartLoading) {
    return (
      <div className={style.loaderWrapper}>
        <ProgressComponent type='circular' />
      </div>
    )
  }

  const textColor = 'var(--CFD-theme-System-OnSurfaceVariant)'

  return (
    <div className={style.wrapper}>
      <Stack direction="column" spacing={0} alignItems="center" sx={{ width: '100%', minHeight: '230px' }}>
        <PieChart
          margin={{ left: 100, bottom: 45 }}
          series={[
            {
              data,
              arcLabel: (chartData) => {
                const total = data.reduce((acc: any, next: any) => acc + next.value, 0);
                const percentage = ((chartData.value / total) * 100).toFixed(0);
                return `${percentage}%`;
              },
              innerRadius: 60,
              outerRadius: 85,
              paddingAngle: 0,
              cornerRadius: 0,
              startAngle: -90,
              endAngle: 270,
              color: 'black'
            }
          ]}
          slotProps={getSlotProps(textColor)}
          sx={{
            '.MuiPieArcLabel-root': {
              fontSize: '10px',
              fill: textColor
            },
            '.MuiPieArc-root': {
              strokeWidth: 0
            },
            '.MuiChartsLegend-mark': {
              rx: 15,
              ry: 15
            }
          }}
        />
      </Stack>
    </div>
  )
}

const getSlotProps = (textColor: string): any => {
  return {
    legend: {
      direction: 'row',
      position: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      labelStyle: {
        fontSize: 12,
        fill: textColor
      },
      itemMarkWidth: 9,
      itemMarkHeight: 9,
      markGap: 10,
      itemGap: 18
    }
  }
}
