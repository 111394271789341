import React, { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledTableRow } from '../../StyledTableRow/StyledTableRow';
import { StyledTableCell } from '../../StyledTableCell/StyledTableCell';
import { type IHistoryTableCell } from '../../../interface/savingsPageInterfaces';
import { formatDate } from '../../../../../helpers/util';
import { AccardionMobileMainContent } from '../components/AccardionMobileMainContent/AccardionMobileMainContent';

import style from './HistoryTableCell.module.css';

const styleForTableCell = {
  width: 'unset',
  borderTop: '1px solid var(--CFD-theme-System-SecondaryContainer)',
  backgroundColor: 'var(--CFD-theme-Surface-1) !important'
}

export const HistoryTableCell: FC<IHistoryTableCell> = ({ row, isTable }) => {
  const { t } = useTranslation();
  const [expandedRow, setExpandedRow] = useState<any>(null);
  const { createdAt, pct, cumulativeTotal, releaseTime, currency, cfdAccountTypeName, cfdAccountCurrencySymbol, _id } = row;
  return (
    <>
      {
        !isTable
          ? (
              <StyledTableRow sx={{ boxSizing: 'border-box' }}>
                <StyledTableCell sx={styleForTableCell}>
                  <p className={ style.tableText }>{ `(${currency.symbol}) ${currency.name}` }</p>
                </StyledTableCell>
                <StyledTableCell sx={styleForTableCell}>
                  <p className={ style.tableText }>{ formatDate(new Date(createdAt)) }</p>
                </StyledTableCell>
                <StyledTableCell sx={styleForTableCell}>
                  <p className={ style.tableText }>{ `${pct}%` }</p>
                </StyledTableCell>
                <StyledTableCell sx={styleForTableCell}>
                  <p className={ style.tableText }>{ `${cumulativeTotal} ${currency.symbol}` }</p>
                </StyledTableCell>
                <StyledTableCell sx={styleForTableCell}>
                  <p className={ style.tableText }>{ formatDate(new Date(releaseTime)) }</p>
                </StyledTableCell>
                <StyledTableCell sx={styleForTableCell}>
                  <p className={ style.tableText }>
                    { (cfdAccountTypeName && cfdAccountTypeName)
                      ? `${cfdAccountTypeName} ${t('labels.account')} ${cfdAccountCurrencySymbol}`
                      : '-'
                    }
                  </p>
                </StyledTableCell>
              </StyledTableRow>
            )
          : (
              <StyledTableRow sx={{
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%'
              }}
              >
                <StyledTableCell colSpan={ 100 } sx={{ padding: '0 !important', backgroundColor: 'var(--CFD-theme-Surface-1) !important' }}>
                  <AccardionMobileMainContent
                    row={ row }
                    expanded={ expandedRow === _id }
                    onClick={ () => { _id === expandedRow ? setExpandedRow(null) : setExpandedRow(_id) } }
                  />
                </StyledTableCell>
              </StyledTableRow>
            )
      }

    </>
  );
}
