import { type OptionInterface, type PayloadInterface } from '../../../interfaces';

export const getCountryCode = (country: string | OptionInterface | null): string => {
  if (country === null) {
    return '';
  } else if (typeof country === 'object') {
    return country.value;
  } else if (typeof country === 'string') {
    return country;
  }

  return '';
}

export const handleError = (payload: PayloadInterface): string => {
  const { error } = payload;

  if (typeof error === 'object') {
    const [value] = Object.values(error);

    return value;
  }

  if (typeof error === 'string') return error;

  return '';
}
