import React, {
  type HTMLAttributes,
  type FC
} from 'react';

import { Success2FAIcon } from '../icons/Success2FAIcon';

import styles from './SectionSuccessModal.module.css';

interface ISectionSuccessModal {
  title?: string
  content?: string | JSX.Element | boolean
}
type TSectionSuccessModal = ISectionSuccessModal & HTMLAttributes<HTMLElement>

export const SectionSuccessModal: FC<TSectionSuccessModal> = ({ title, content }) => {
  return (
    <section className={ styles.dialogContent }>
      <div className={ styles.sectionContainer }>
        <Success2FAIcon width="100px"/>
        {(title != null) && <h3 className={ styles.sectionTitle }>{ title }</h3> }
        <p className={ styles.sectionText }>{ content }</p>
      </div>
    </section>
  )
}
