import React, {
  type HTMLAttributes,
  type FC,
  useState,
  useRef,
  useEffect
} from 'react';
import { VerificationIcon } from '../../../../icons/VerificationIcon';
import { VerificationFilePicker } from '../../../VerificationFilePicker/VerificationFilePicker';
import { BinIcon } from '../../../../icons/BinIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { addVerificationRequest, setIdCardBackData, setIdCardFrontData } from '../../../../../redux/reducers/kycVerification';
import {
  kycVerificationSelector
} from '../../../../../redux/selectors/kycVerification';
import styles from './Step1SectionModalVerifications.module.css';
import { t } from 'i18next';

interface InterfaceStep1SectionModalVerifications {
  setFrontSideDocsPicked: (el: boolean) => void
  setBackSideDocsPicked: (el: boolean) => void
}

type TStep1SectionModalVerifications = InterfaceStep1SectionModalVerifications & HTMLAttributes<HTMLElement>

export const Step1SectionModalVerifications: FC<TStep1SectionModalVerifications> = ({ setBackSideDocsPicked, setFrontSideDocsPicked }) => {
  const dispatch = useAppDispatch()

  const [docsFront, setDocsFront] = useState<File | null>(null);
  const [docsBack, setDocsBack] = useState<File | null>(null);
  const frontSideDocsRef = useRef<HTMLInputElement | null>(null);
  const backSideDocsRef = useRef<HTMLInputElement | null>(null);

  const { idCardFrontData: uploadedIdCardFront, idCardBackData: uploadedIdCardBack } = useAppSelector(kycVerificationSelector);
  const formatAddedDate = (date?: string): string => {
    const dateToFormat = date !== undefined ? new Date(date) : new Date();
    return dateToFormat.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }

  const idCardFrontName = uploadedIdCardFront !== null ? uploadedIdCardFront.fileUrl : docsFront?.name
  const idCardBackName = uploadedIdCardBack !== null ? uploadedIdCardBack.fileUrl : docsBack?.name
  const idCardFrontDateAdded = uploadedIdCardFront !== null ? formatAddedDate(uploadedIdCardFront.updatedAt) : formatAddedDate()
  const idCardBackDateAdded = uploadedIdCardBack !== null ? formatAddedDate(uploadedIdCardBack.updatedAt) : formatAddedDate()

  const uploadFiles = (): void => {
    if ((docsFront != null) && (docsBack != null)) {
      const formData = new FormData();

      formData.append('idCardFront', docsFront, 'idCardFrontFile');
      formData.append('idCardBack', docsBack, 'idCardBackFile');

      dispatch(addVerificationRequest(formData));
    }
  };

  useEffect(() => {
    uploadFiles()
  }, [docsFront, docsBack])

  const handleDocsPicker = (inputElement: HTMLInputElement | null): void => {
    if (inputElement !== null) {
      inputElement.click();
    }
  }

  const removeFrontSideDocs = (): void => {
    setDocsFront(null);
    setFrontSideDocsPicked(false);
    dispatch(setIdCardFrontData(null))
    dispatch(setIdCardBackData(null))
  }

  const removeBackSideDocs = (): void => {
    setDocsBack(null)
    setBackSideDocsPicked(false);
    dispatch(setIdCardFrontData(null))
    dispatch(setIdCardBackData(null))
  }

  return (
    <section className={ styles.dialogContent }>
      <div className={ styles.dialogContentTopContainer }>
        <VerificationIcon />
        <h3 className={ styles.dialogContentTitle }>
          {t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step1.title')}
        </h3>
      </div>
      <div className={ styles.dialogContentTextContainer }>
        <p className={ styles.dialogContentText }>
          {t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step1.subtitle')}
        </p>
      </div>
      <div className={ styles.dialogContentBottomContainer }>
        {
          docsFront === null && uploadedIdCardFront === null
            ? <VerificationFilePicker
                ref={ frontSideDocsRef }
                title={t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step1.upload_titles.front')}
                handleFileChange={ setDocsFront }
                handleDocsPicker={ handleDocsPicker }
                docs={ docsFront }
                isDocsPicked={ setFrontSideDocsPicked }
              />
            : <div className={ styles.dialogUploadFiles }>
                <div className={ styles.dialogUploadFilesInfo }>
                  <p className={ styles.dialogUploadFilesInfoName }>{idCardFrontName}</p>
                  <p className={ styles.dialogUploadFilesInfoTime }>
                  {t('labels.added')}: {idCardFrontDateAdded}
                  </p>
                </div>
                <button className={ styles.dialogUploadFilesBinBtn } onClick={ removeFrontSideDocs }>
                  <BinIcon />
                </button>
              </div>
        }
        {
          docsBack === null && uploadedIdCardBack === null
            ? <VerificationFilePicker
                ref={ backSideDocsRef }
                title={t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step1.upload_titles.back')}
                handleFileChange={ setDocsBack }
                handleDocsPicker={ handleDocsPicker }
                docs={ docsBack }
                isDocsPicked={ setBackSideDocsPicked }
              />
            : <div className={ styles.dialogUploadFiles }>
                <div className={ styles.dialogUploadFilesInfo }>
                  <p className={ styles.dialogUploadFilesInfoName }>{idCardBackName}</p>
                  <p className={ styles.dialogUploadFilesInfoTime }>
                    {t('labels.added')}: {idCardBackDateAdded}
                  </p>
                </div>
                <button className={ styles.dialogUploadFilesBinBtn } onClick={ removeBackSideDocs }>
                  <BinIcon />
                </button>
              </div>
        }
        <p className={ styles.dialogContentBottomFileSizeText }>{t('labels.file_size')}: 10MB in JPG, JPEG, PNG</p>
      </div>
    </section>
  )
}
