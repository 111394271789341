import React, { useState, useEffect, useRef, type FC, useLayoutEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { type SimpleOptionInterface } from '../../pages/Savings/interface/savingsPageInterfaces';

import styles from './SimpleSelectComponent.module.css';

interface ISimpleSelectComponent {
  height?: string
  placeholder?: string
  disabled?: boolean
  value: string
  setValue: (el: any) => void
  options: SimpleOptionInterface[]
}

export const SimpleSelectComponent: FC<ISimpleSelectComponent> = ({
  placeholder,
  disabled,
  value: propsValue,
  setValue,
  options = []
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [prettyOutput, setPrettyOutput] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);

  const handleClickOutside = (event: MouseEvent): void => {
    if ((containerRef.current != null) && !containerRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (options.length > 0 && propsValue.length > 0) {
      const neededOption = options.find(({ value }) => value === propsValue);
      setPrettyOutput(neededOption?.label ? neededOption?.label : '');
    }
    if (propsValue.length === 0) setPrettyOutput('');
  }, [options, propsValue])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const clickHandle = (value: string, label: string): void => {
    setValue(value);
    setPrettyOutput(label);
    setIsOpen(false);
  }

  useLayoutEffect(() => {
    if (isOpen && (dropdownRef.current != null)) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const dropdownStyles: React.CSSProperties = {};
      const footerHeight = 70;

      if (dropdownRect.right > window.innerWidth) {
        dropdownStyles.right = '0';
        dropdownStyles.left = 'auto';
      } else {
        dropdownStyles.left = '0';
        dropdownStyles.right = 'auto';
      }

      if (dropdownRect.bottom > window.innerHeight - footerHeight) {
        dropdownStyles.bottom = '100%';
        dropdownStyles.top = 'auto';
      } else {
        dropdownStyles.top = '100%';
        dropdownStyles.bottom = 'auto';
      }

      Object.assign(dropdownRef.current.style, dropdownStyles);
    }
  }, [isOpen]);

  return (
    <div
      ref={ containerRef }
      className={`${styles.customSelect} ${(disabled ?? false) ? styles.disabled : ''}`}
      onClick={() => { !(disabled ?? false) && setIsOpen(!isOpen) }}
    >
      <div
        className={`${styles.selectValue} ${isOpen ? styles.open : ''}`}
        style={{
          backgroundColor: `${prettyOutput.length > 0 ? 'var(--CFD-theme-System-SecondaryContainer)' : ''}`
        }}
        tabIndex={ 0 }
      >
        <span
          className={ styles.selectValueText }
          style={{ color: `${prettyOutput.length > 0 ? 'var(--CFD-theme-System-OnSecondaryContainer)' : ''}` }}
        >{ prettyOutput || placeholder }</span>
        <span className={ styles.icon }>
          {isOpen ? <ArrowDropUpIcon sx={{ width: '18px', height: '18px' }} /> : <ArrowDropDownIcon sx={{ width: '18px', height: '18px' }}/>}
        </span>
      </div>
      { isOpen && (
        <ul className={ styles.optionsList } ref={dropdownRef}>
          {options.map(({ label, value }) => (
            <li
              key={ value }
              className={`${styles.option} ${value === propsValue ? styles.selected : ''}`}
              onClick={() => { clickHandle(value, label); } }
            >
              { label }
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
