import React, { useEffect, useState, type FC, type Dispatch, type SetStateAction, type SyntheticEvent, useMemo } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { styled, Tab, Tabs } from '@mui/material';
import style from './ProfitCalculator.module.css';
import { NumberInput } from './NumberInput';
import dollarSign from '../../../../../assets/images/dollarSign.svg';
import percentSign from '../../../../../assets/images/percentSign.svg';
import { t } from 'i18next';
import Big from 'big.js';
import { getCurrencySymbol } from '../../../../../helpers/util';
import { calcInitialMargin, calcMaxPosition, calcNetProfit, calcPipSize, calcPipValue, calcRequiredMargin, calcRoe, calcSlLoss, calcTpProfit } from '../helper';
import { useAppSelector } from '../../../../../hooks/redux';

interface ProfitCalculatorProps {
  setOpen: (isOpen: boolean) => void
  currentMarketPrice: {
    bid: number
    ask: number
  }
  currentVolume: number
  currentTakeProfit: number | string
  currentStopLoss: number | string
  secondary: string
  primary: string
  leverage: number
  lotSize: number
  freeMargin: number
  precision: number
}

type ValueHandlerFunction = (value: number, setValue: Dispatch<SetStateAction<number | string>>) => void;

const CustomTabs = styled(Tabs)(() => ({
  width: '100%',
  '& .MuiTabs-flexContainer': {
    height: '100%',
    width: '100%'
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'var(--CFD-theme-System-Tertiary)'
  }
}));

const CustomTab = styled(Tab)(() => ({
  color: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
  fontSize: '14px !important',
  fontStyle: 'normal !important',
  fontWeight: '400 !important',
  lineHeight: '20px !important',
  letterSpacing: '0.1px !important',
  padding: '14px 16px !important',
  minWidth: '50%',
  '&.Mui-selected': {
    color: 'var(--CFD-theme-System-OnSurface) !important'
  }
}));

export const ProfitCalculator: FC<ProfitCalculatorProps> = ({
  setOpen, currentMarketPrice, currentVolume, currentTakeProfit, currentStopLoss,
  primary, secondary, leverage, lotSize, freeMargin, precision
}) => {
  const { bid, ask } = currentMarketPrice;
  const [tableSelected, setTableSelected] = useState<number>(0);
  const [price, setPrice] = useState<number | string>(bid);
  const [volume, setVolume] = useState<number | string>(currentVolume);
  const [takeProfit, setTakeProfit] = useState<number | string>(currentTakeProfit);
  const [stopLoss, setStopLoss] = useState<number | string>(currentStopLoss);

  const tpProfit = calcTpProfit(volume, tableSelected === 0, takeProfit, price);
  const slLoss = calcSlLoss(volume, tableSelected === 0, stopLoss, price);
  const requiredMargin = calcRequiredMargin(price, volume, lotSize, leverage);
  const maxPosition = calcMaxPosition(freeMargin, leverage);

  const openingPrice = tableSelected === 0 ? bid : ask;
  const netProfit = calcNetProfit(volume, price, openingPrice);
  const initialMargin = calcInitialMargin(openingPrice, volume, lotSize, leverage);
  const roe = calcRoe(netProfit, initialMargin);
  const pipSize = calcPipSize(precision);
  const pip = calcPipValue(pipSize, Big(volume), Big(price));

  const { activeAccount } = useAppSelector((state) => state.cfd);
  const symbolIcon = useMemo(() => (getCurrencySymbol(activeAccount?.symbol ?? '')), [activeAccount]);

  const handleInc: ValueHandlerFunction = (value, setValue) => {
    const newValue = new Big(value).plus(1).toString();
    setValue(newValue);
  };

  const handleDec: ValueHandlerFunction = (value, setValue) => {
    const newValue = new Big(value).minus(1);

    if (newValue.lt(0)) {
      setValue('0');
    } else {
      setValue(newValue.toString());
    }
  };

  const handleCloseModal = (): void => {
    setOpen(false);
  };

  const handleChangeTable = (_event: SyntheticEvent, newValue: number): void => {
    setTableSelected(newValue);
  };

  const handleChange = (setter: Dispatch<SetStateAction<number | string>>) => (value: string) => {
    if (value.length === 0 || Number(value) < 0) {
      setter(0);
    } else {
      try {
        const trimmedValue = value.trim();
        const isValid = /^\d*\.?\d*$/.test(trimmedValue);
        if (isValid) {
          const parsedValue = trimmedValue.replace(/^0+(\d)/, '$1');
          setter(parsedValue);
        }
      } catch (e) {
        console.error('Invalid number', e);
      }
    }
  };

  useEffect(() => {
    setPrice(tableSelected !== 0 ? ask.toString() : bid.toString());
  }, [tableSelected, ask, bid]);

  return (
    <div className={style.mainWrapper}>
      <header className={style.header}>
        <div className={style.contentWrapper}>
          <h2 className={style.contentTitle}>{t('labels.profit_calculator')}</h2>
          <button className={style.closeButton} onClick={handleCloseModal}>
            <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />
          </button>
        </div>
      </header>
      <div className={style.bodyWrapper}>
        <CustomTabs value={tableSelected} onChange={handleChangeTable}>
          <CustomTab label={t('labels.buy')} />
          <CustomTab label={t('labels.sell')} />
        </CustomTabs>
        <div className={style.inputListWrapper}>
          <span className={style.maxPositionSpan}>{t('labels.max_position')}: {maxPosition.toString()} {primary}/{secondary}</span>
          <div className={style.inputGroup}>
            <div>
              <NumberInput
                id="volume"
                label={t('labels.volume')}
                isIncBtnPresent
                value={volume.toString()}
                onChange={handleChange(setVolume)}
                onInc={() => { handleInc(Number(volume), setVolume); }}
                onDec={() => { handleDec(Number(volume), setVolume); }}
              />
            </div>
            <div>
              <NumberInput
                id="price"
                label={t('labels.price')}
                isIncBtnPresent
                value={price.toString()}
                onChange={handleChange(setPrice)}
                onInc={() => { handleInc(Number(price), setPrice); }}
                onDec={() => { handleDec(Number(price), setPrice); }}
              />
            </div>
          </div>
          <div className={style.inputGroup}>
            <div>
              <NumberInput
                id="takeProfit"
                label={t('labels.take_profit')}
                isIncBtnPresent
                value={takeProfit.toString()}
                onChange={handleChange(setTakeProfit)}
                onInc={() => { handleInc(Number(takeProfit), setTakeProfit); }}
                onDec={() => { handleDec(Number(takeProfit), setTakeProfit); }}
              />
            </div>
            <div>
              <NumberInput
                id="stopLoss"
                label={t('labels.stop_loss')}
                isIncBtnPresent
                value={stopLoss.toString()}
                onChange={handleChange(setStopLoss)}
                onInc={() => { handleInc(Number(stopLoss), setStopLoss); }}
                onDec={() => { handleDec(Number(stopLoss), setStopLoss); }}
              />
            </div>
          </div>
        </div>
        <div className={style.results}>
          <span className={style.resultsHeader}>{t('labels.calculations_for')} {primary}/{secondary}</span>
          <div className={style.resultsBody}>
            <div className={style.resultsItem}>
              <span>{t('labels.leverage')}</span>
              <span className={style.leverageContainer}>1:{leverage}</span>
            </div>
            <div className={style.resultsItem}>
              <span>{t('labels.required')} {t('labels.margin')}</span>
              <div className={style.resultsItemContainer}>
                <span>{requiredMargin.toString()}</span>
                <img src={dollarSign} alt="dollarSign" />
              </div>
            </div>
            <div className={style.resultsItem}>
              <span>{t('labels.profit_from_TP')}</span>
              <div className={style.resultsItemContainer}>
                <span>{tpProfit.toString()}</span>
                <img src={dollarSign} alt="dollarSign" />
              </div>
            </div>
            <div className={style.resultsItem}>
              <span>{t('labels.loss_from_SL')}</span>
              <div className={style.resultsItemContainer}>
                <span>{slLoss.toString()}</span>
                <img src={dollarSign} alt="dollarSign" />
              </div>
            </div>
            <div className={style.resultsItem}>
              <span>{t('labels.roe')}</span>
              <div className={style.resultsItemContainer}>
                <span>{roe.toString()}</span>
                <img src={percentSign} alt="percentSign" />
              </div>
            </div>
            <div className={style.resultsItem}>
              <span>{t('labels.pip')}</span>
              <div className={style.resultsItemContainer}>
                <span>{pip.toString()}</span>
                <span className={style.symbolIcon}>{symbolIcon}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
