import React from 'react';
import './i18n';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';
import './index.css';
import { ThemeProvider } from './components';

const rootEl = document.getElementById('root');

if (rootEl !== null) {
  const root = ReactDOM.createRoot(rootEl);

  root.render(
      <Provider store={store}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Provider>
  );
}
