import React, { type FC, useState, useEffect, useMemo } from 'react';
import { socket } from '../../../../web/socket';
// import DoneIcon from '@mui/icons-material/Done';
import QRCode from 'react-qr-code';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { type IDefaultAddress, getDefaultAddresses } from '../../../../redux/reducers/psp';
import { SelectComponent, ButtonComponent, useSnackbar } from '../../../../components';
import style from './CryptoDepositFormComponent.module.css';
import { getBTCWalletByUserId, clearWalletData } from '../../../../redux/reducers/wallet';
import { t } from 'i18next';
import { type SelectChangeEvent } from '@mui/material';
import { getUniqueByKey } from '../../../../helpers/getUniqueByKey';

export const CryptoDepositFormComponent: FC = () => {
  const dispatch = useAppDispatch();
  const [cryptoSelect, setCryptoSelect] = useState<string>('');
  const [networkSelect, setNetworkSelectValue] = useState<string>('');
  const [isBTC, setIsBTC] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<IDefaultAddress>();

  const { defaultAddresses } = useAppSelector((state) => state.psp);
  const userWallet = useAppSelector((state) => state.wallet.wallet);
  const isTemporaryWallet = useAppSelector((state) => state.wallet.temporary);
  const { _id: userId } = useAppSelector((state) => state.user);

  const { handleOpen } = useSnackbar();

  useEffect(() => {
    if (isBTC && isTemporaryWallet) {
      socket.on(`btcWalletListener/${userId}`, () => {
        dispatch(clearWalletData());
      });
    }

    return () => {
      socket.off(`btcWalletListener/${userId}`);
    };
  }, [isBTC, userId, isTemporaryWallet]);

  const options = useMemo(() => {
    return getUniqueByKey(defaultAddresses.map(({ currency }) => ({
      value: currency._id,
      label: currency.name,
      symbol: currency.symbol
    })), 'label');
  }, [defaultAddresses]);

  const buildNetworks = (): Record<string, string[]> => {
    const networks: Record<string, string[]> = {};

    defaultAddresses.forEach(({ network, currency }) => {
      if (network !== undefined) {
        if (networks[currency.name] === undefined) {
          networks[currency.name] = [];
        }
        if (!networks[currency.name].includes(network)) {
          networks[currency.name].push(network);
        }
      }
    });

    return networks;
  }

  const networks = useMemo(() => {
    return buildNetworks();
  }, [defaultAddresses]);

  useEffect(() => {
    dispatch(getDefaultAddresses());
  }, []);

  useEffect(() => {
    if (isBTC && userId !== undefined) {
      dispatch(getBTCWalletByUserId(userId));
    }
  }, [isBTC, userId]);

  const handleSelectAddress = (value: string): void => {
    setCryptoSelect(value);
    setNetworkSelectValue('');

    const selectedAddress = defaultAddresses.find(({ currency }) => currency._id === value);

    if (selectedAddress === undefined) return;

    if (selectedAddress.address.startsWith('xpub') && selectedAddress.currency?.symbol === 'BTC') {
      setIsBTC(true);
      setSelectedAddress(undefined);

      return;
    }

    setIsBTC(false);
    setSelectedAddress(selectedAddress);
  };

  const handleBtnClick = (address: string): void => {
    navigator.clipboard.writeText(address);

    const openParams = {
      message: 'Text Copied',
      actionText: '',
      autoClose: true
    }

    handleOpen(openParams);
  }

  const getCurrencyNetwork = (currencyName: string | undefined): string[] => {
    if (currencyName === undefined) return [];
    if (Object.keys(networks).length === 0) return [];

    return networks[currencyName] ?? [];
  }

  const networkOptions = useMemo(() => {
    return getCurrencyNetwork(selectedAddress?.currency.name).map((network) => {
      return {
        value: network,
        label: network
      }
    })
  }, [selectedAddress]);

  const setNetworkSelect = (value: SelectChangeEvent<unknown>): void => {
    const network = value.target.value as string
    setNetworkSelectValue(network)

    const networkAddress = defaultAddresses.find((addressItem: IDefaultAddress) => {
      return addressItem.network === network;
    })
    setSelectedAddress(networkAddress)
  }

  return (
    <>
      <div className={ style.wrapper }>
        <div className={ style.currencySection }>
          <p className={ style.currencySectionText }>
            1. {t('deposit_modal.crypto_form.subtitle1')}
          </p>
          <div className={ style.selectWrapper }>
            <label htmlFor='coin' className={ style.labelCoin }>{t('labels.currency')}</label>
            <SelectComponent options={ options } value={ cryptoSelect } onChange={({ target: { value } }) => { handleSelectAddress(value as string); }} id='coin'/>
          </div>
        </div>
        {
          networkOptions.length > 0 && (
            <div className={ style.networkSection }>
              <p className={ style.currencySectionText }>
                2. Select the deposit network
              </p>
              <div className={ style.selectWrapper }>
                <label htmlFor='network' className={ style.labelCoin }>Network</label>
                <SelectComponent
                  options={ networkOptions }
                  value={ networkSelect }
                  onChange={ setNetworkSelect }
                  id='network'
                />
              </div>
            </div>
          )
        }
        <div className={ style.scanWalletSection }>
          {
            networkSelect.length > 0 && (
              <div className={ style.info }>
                <InfoOutlinedIcon style={{ color: 'var(--CFD-theme-System-Inverseonsurface)' }}/>
                  <p className={ style.infoText }>
                    {t('deposit_modal.crypto_form.info_msg')}
                  </p>
              </div>
            )
          }
          {
            cryptoSelect.length > 0 && (networkOptions.length > 0 ? networkSelect.length > 0 : true) && (
              <>
                <p className={ style.currencySectionText }>2. {t('deposit_modal.crypto_form.subtitle2')}</p>
                <div className={ style.qrCodeWrapper }>
                  <div className={ style.qrCode }>
                    <QRCode
                      size={185}
                      value={!isBTC ? (selectedAddress?.address ?? '') : userWallet.address}
                      className="img-fluid"
                    />
                  </div>
                  <p className={ style.qrCodeNumber }>{!isBTC ? (selectedAddress?.address ?? '') : userWallet.address}</p>
                </div>
              </>
            )
          }
        </div>
      </div>
      {
        cryptoSelect.length > 0 && (networkOptions.length > 0 ? networkSelect.length > 0 : true) && (
          <footer className={ style.footer }>
            <div className={ style.btnContainer }>
              <ButtonComponent onClick={() => { handleBtnClick(!isBTC ? (selectedAddress?.address ?? '') : userWallet.address); } }>
                <span className={ style.btnText }>
                  <ContentCopyOutlinedIcon style={{ color: 'var(--CFD-theme-System-OnTertiary)' }} fontSize='small'/>
                    {t('deposit_modal.crypto_form.action_button_text')}
                </span>
              </ButtonComponent>
            </div>
          </footer>
        )
      }
  </>
  )
}
