import React, { type FC } from 'react';

export const WithdrawIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M20.5 18.0001V20.0001H4.5V18.0001H20.5Z" fill="var(--CFD-theme-System-OnTertiary)"/>
      <path d="M7.55003 13.5357L13.214 7.87181L11.0926 5.75049L16.7424 5.75756L16.7495 11.4073L14.6282 9.28603L8.96424 14.95L7.55003 13.5357Z" fill="var(--CFD-theme-System-OnTertiary)"/>
    </svg>
  )
};
