import React, { type FC, useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getNetworks } from '../../../../redux/reducers/networks';
import { addWallet } from '../../../../redux/reducers/withdrawalMethods';
import { getCryptoCurrencies } from '../../../../redux/reducers/currencies';
import { ButtonComponent, SelectComponent, InputComponent, useSnackbar } from '../../../../components';
import { handleError } from '../../../../helpers';

import style from './AddCryptoFormComponent.module.css';
import { type PayloadInterface } from '../../../../interfaces';
import { t } from 'i18next';

interface InterfaceAddCryptoFormComponent {
  setAddWithdrawalMethod: (el: boolean) => void
}

export const AddCryptoFormComponent: FC<InterfaceAddCryptoFormComponent> = ({ setAddWithdrawalMethod }) => {
  const dispatch = useAppDispatch();
  const { handleOpen: handleOpenSnackbar } = useSnackbar();
  const [cryptoSelect, setCryptoSelect] = useState<string>('');
  const [networkSelect, setNetworkSelect] = useState<string>('');
  const [walletAdress, setWalletAdress] = useState<string>('');
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const currencies = useAppSelector((state) => state.currencies);
  const networks = useAppSelector((state) => state.networks);
  const { _id: userId, firstName, lastName } = useAppSelector((state) => state.user);

  const currencyOptions = useMemo(() => {
    return currencies.map((currency) => ({ label: currency.name, value: currency._id, symbol: currency.symbol }));
  }, [currencies]);

  const networkOptions = useMemo(() => {
    return networks.map((network) => ({ label: network.name, value: network._id, symbol: network.symbol }));
  }, [networks]);

  useEffect(() => {
    dispatch(getCryptoCurrencies());
  }, []);

  useEffect(() => {
    if (cryptoSelect.length === 0) return;

    dispatch(getNetworks(cryptoSelect));
  }, [cryptoSelect])

  const handleAddMethod = async (): Promise<void> => {
    if (userId === undefined) return;

    setIsBtnLoading(true);
    const data = {
      name: `${firstName} ${lastName}'s Wallet`,
      address: walletAdress,
      currencyId: cryptoSelect,
      userId
    };

    const response = await dispatch(addWallet(data));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      handleError(payload, handleOpenSnackbar);
      setIsBtnLoading(false);

      return;
    }

    setIsBtnLoading(false);
    setAddWithdrawalMethod(false);
  }

  return (
  <>
    <div className={ style.wrapper }>
      <h3 className={ style.mainTitle }>{t('withdrawal_modal.add_crypto_form_modal.title')}</h3>
      <div className={ style.formContainer }>
        <div className={ style.inputContainer }>
          <label htmlFor='coinCurrency' className={ style.label }>{t('labels.coin')}</label>
          <SelectComponent options={ currencyOptions } value={ cryptoSelect } onChange={({ target: { value } }) => { setCryptoSelect(value as string); } } id='coinCurrency'/>
        </div>
        {
          (cryptoSelect.length > 0 && networks.length > 0) && (
            <div className={ style.inputContainer }>
              <label htmlFor='networkCurrency' className={ style.label }>{t('labels.network')}</label>
              <SelectComponent options={ networkOptions } value={ networkSelect } onChange={({ target: { value } }) => { setNetworkSelect(value as string); } } id='networkCurrency'/>
            </div>
          )
        }
        {
          cryptoSelect.length > 0 && (
            <div className={ style.inputContainer }>
              <label htmlFor='walletAdress' className={ style.label }>{t('labels.wallet_address')}</label>
              <InputComponent value={ walletAdress } setValue={ setWalletAdress } id='walletAdress' />
            </div>
          )
        }
      </div>
    </div>
    <footer className={ style.footer }>
      <div className={ style.btnContainer }>
        <ButtonComponent onClick={() => { handleAddMethod(); } } disabled={ walletAdress.length === 0 } loading={isBtnLoading}>
          <span className={ style.btnText }>
            {t('withdrawal_modal.add_crypto_form_modal.action_button_text')}
          </span>
        </ButtonComponent>
      </div>
    </footer>
  </>
  )
}
