import React, { type FC } from 'react';
import { t } from 'i18next';
import { RadioGroup, FormControl, styled } from '@mui/material';

import { RadioButtonDepositComponent } from '../../../../components';
import { type TypesubHeaderOptions } from '../DepositComponent';

import style from './DepositSubHeaderComponent.module.css';

interface InterfaceDepositSubHeaderComponent {
  options: Array<{ ticker: TypesubHeaderOptions }>
  selectedOptions: TypesubHeaderOptions
  setSelectedOptions: (el: TypesubHeaderOptions) => void
}

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  paddingBottom: '8px',
  boxSizing: 'border-box'
}));

const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: '20px',
  height: '70px',
  overflow: 'auto',
  maxWidth: '38vw',

  '@media (max-width: 1239px)': {
    maxWidth: '100vw'
  }
}));

export const DepositSubHeaderComponent: FC<InterfaceDepositSubHeaderComponent> = ({
  options,
  selectedOptions,
  setSelectedOptions
}) => {
  return (
    <div className={style.subHeader}>
      <h3 className={style.subHeaderTitle}>{t('labels.deposit_method')}</h3>
      <StyledFormControl>
        <StyledRadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={ selectedOptions }
        >
          {options.map((el) => (
            <div
              key={el.ticker}
              onClick={() => { setSelectedOptions(el.ticker); }}
              className={`${style.radioBtnWrapper} ${selectedOptions === el.ticker ? style.selected : style.deselected}`}
              style={{
                border: `1px solid ${selectedOptions === el.ticker ? 'var(--CFD-theme-System-Primary)' : 'var(--CFD-theme-System-Outline)'}`
              }}
            >
              <RadioButtonDepositComponent
                checked={ selectedOptions === el.ticker }
                value={ el.ticker }
              />
              <p className={style.assetsCheckContainerTitle}>{t(`deposit_methods.${el.ticker}`)}</p>
            </div>
          ))}
        </StyledRadioGroup>
      </StyledFormControl>
    </div>
  );
};
