import React, { type FC } from 'react';

export const DepositIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M20 18.0001V20.0001H4V18.0001H20Z" fill="var(--CFD-theme-System-OnTertiary)"/>
      <path d="M16.25 7.16475L10.5861 12.8287L12.7074 14.95L7.05761 14.9429L7.05054 9.29315L9.17186 11.4145L14.8358 5.75054L16.25 7.16475Z" fill="var(--CFD-theme-System-OnTertiary)"/>
    </svg>
  )
};
