import React, { type FC } from 'react';

export const WithdrawalMethodIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path d="M15.5 13.5V15H3.5V13.5H15.5Z" fill="var(--CFD-theme-System-OnPrimary)"/>
      <path d="M5.78752 10.1518L10.0355 5.90386L8.44448 4.31287L12.6818 4.31817L12.6871 8.55551L11.0961 6.96452L6.84818 11.2125L5.78752 10.1518Z" fill="var(--CFD-theme-System-OnPrimary)"/>
    </svg>
  )
}
