import { apiHelper } from './apiHelper';

export type KycRequestType = 'idCardFront' | 'idCardBack' | 'creditCardFront' | 'creditCardBack' | 'selfie' | 'address'

export interface KycRequestIdInterface {
  createdAt: string
  fileUrl: string
  info: string
  type: KycRequestType
  updatedAt: string
  verifiedStatus: 'verified' | 'declined' | 'pending'
  __v: number
  _id: string
}

export interface UserVerificationResultInterface {
  createdAt: string
  kycRequestIds: KycRequestIdInterface[]
  updatedAt: string
  userId: string
  verifiedStatus: 'verified' | 'not verified' | 'partially verified'
  __v: number
  _id: string
}

export const kycVerificationAPI = {
  async getUserVerificationData (userId: string): Promise<any> {
    if (userId === '') {
      throw new Error('kycVerificationAPI -> getUserVerificationData:  userId is not provided');
    }

    const response = await apiHelper({
      method: 'get',
      path: `/kyc-verification/${userId}`
    });

    const data: UserVerificationResultInterface = response.status === 200 ? response.data : [];
    return data;
  },
  async deleteUserVerificationData (userId: string): Promise<any> {
    if (userId === '') {
      throw new Error('kycVerificationAPI -> getUserVerificationData:  userId is not provided');
    }

    const response = await apiHelper({
      method: 'delete',
      path: `/kyc-verification/${userId}`
    });

    const data: UserVerificationResultInterface = response.status === 200 ? response.data : [];
    return data;
  }
};
