import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';
import { type ICurrency } from './currencies';

interface INetwork {
  _id: string
  name: string
  symbol: string
  currencies: ICurrency[]
}

const initialState: INetwork[] = [];

export const getNetworks = createAsyncThunk(
  'networs/get',
  async (currencyId: string) => {
    const response = await apiHelper({ method: 'get', path: `/Network/by-currency?currencyId=${currencyId}` });

    return response.data.networks;
  }
)

export const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNetworks.fulfilled, (state, action: PayloadAction<INetwork[]>) => {
      return action.payload;
    });
  }
});

export default networksSlice.reducer;
