import React, {
  type HTMLAttributes,
  type FC
} from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import styles from './HeaderModal2FA.module.css';

interface IHeaderModal2FA {
  setIsOpen: (el: boolean) => void
}

type THeaderModal2FA = IHeaderModal2FA & HTMLAttributes<HTMLElement>

export const HeaderModal2FA: FC<THeaderModal2FA> = ({ setIsOpen }) => {
  const handleBtnClick = (): void => { setIsOpen(false) }

  return (
    <header className={ styles.dialogHeader }>
      <div className={ styles.dialogHeaderContainer }>
        <div className={ styles.dialogHeaderContent }>
          <h3 className={ styles.dialogHeaderTitle }>Two factor authentication</h3>
        </div>
        <div className={ styles.dialogHeaderBtnContainer }>
          <CloseOutlinedIcon
            onClick={handleBtnClick}
            sx={{ color: 'var(--CFD-theme-System-OnSurface)', cursor: 'pointer' }}
          />
        </div>
      </div>
    </header>
  )
}
