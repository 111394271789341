import React, { type FC } from 'react';

export const SavingsIcon: FC = () => {
  const fillColor = 'var(--CFD-theme-System-OnSecondaryContainer)';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M19.7642 3.50003L3.64437 19.6199C3.44001 19.8242 3.44001 20.1556 3.64437 20.3599C3.84884 20.5644 4.18039 20.5643 4.38471 20.3597L20.4926 4.22783C20.6933 4.02673 20.6932 3.70097 20.4923 3.50003C20.2912 3.29898 19.9653 3.29898 19.7642 3.50003Z" fill={fillColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.46649 9.93216C4.55667 9.93216 3 8.37819 3 6.4661C3 5.46137 3.42908 4.55554 4.11375 3.92208C4.73211 3.34997 5.55896 3.00003 6.46649 3.00003C6.54041 3.00003 6.61379 3.00235 6.68657 3.00693C8.49671 3.12069 9.93299 4.62786 9.93299 6.4661C9.93299 7.12709 9.74728 7.74527 9.42527 8.27126C9.10131 8.80042 8.63938 9.23628 8.08979 9.52852C7.60533 9.78613 7.05275 9.93216 6.46649 9.93216ZM6.46649 9.10215C5.01309 9.10215 3.83015 7.91936 3.83015 6.4661C3.83015 5.74142 4.1243 5.08399 4.59946 4.60695C5.07717 4.12734 5.73784 3.83005 6.46649 3.83005C6.70764 3.83005 6.94135 3.86261 7.16344 3.92357C8.27988 4.23 9.10284 5.25396 9.10284 6.4661C9.10284 6.77782 9.04841 7.07711 8.94856 7.35494C8.71331 8.00953 8.22591 8.54505 7.60416 8.84376C7.25953 9.00933 6.87363 9.10215 6.46649 9.10215Z" fill={fillColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.5335 20.9992C15.6237 20.9992 14.067 19.4452 14.067 17.5331C14.067 16.5284 14.4961 15.6226 15.1808 14.9891C15.7991 14.417 16.626 14.067 17.5335 14.067C17.6074 14.067 17.6808 14.0694 17.7536 14.0739C19.5637 14.1877 21 15.6949 21 17.5331C21 18.1941 20.8143 18.8123 20.4923 19.3383C20.1683 19.8674 19.7064 20.3033 19.1568 20.5955C18.6723 20.8531 18.1198 20.9992 17.5335 20.9992ZM17.5335 20.1692C16.0801 20.1692 14.8972 18.9864 14.8972 17.5331C14.8972 16.8084 15.1913 16.151 15.6665 15.674C16.1442 15.1944 16.8049 14.8971 17.5335 14.8971C17.7747 14.8971 18.0084 14.9296 18.2305 14.9906C19.3469 15.297 20.1699 16.321 20.1699 17.5331C20.1699 17.8448 20.1154 18.1441 20.0156 18.422C19.7803 19.0765 19.2929 19.6121 18.6712 19.9108C18.3265 20.0763 17.9406 20.1692 17.5335 20.1692Z" fill={fillColor} />
    </svg>
  )
}
