import React, { type HTMLAttributes, type FC } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ButtonComponent } from '../../../../../components';
import { useAppDispatch } from '../../../../../hooks/redux';
import { setStep2FA } from '../../../../../redux/reducers/twoFactorAuthentication';
import styles from './Step1FooterModal2FA.module.css';

type TStep1FooterModal2FA = HTMLAttributes<HTMLElement>;

export const Step1FooterModal2FA: FC<TStep1FooterModal2FA> = () => {
  const dispatch = useAppDispatch()

  const handleClick = (): void => { dispatch(setStep2FA(2)); }

  return (
    <footer className={ styles.dialogFooter }>
      <div className={ styles.dialogFooterSupportWindow }>
        <InfoOutlinedIcon sx={{ color: 'var(--CFD-theme-System-Inverseonsurface)', cursor: 'help' }} />
        <p className={ styles.dialogFooterSupportText }>
          Please make sure that you have finished
          <br/>
          STEP 3 before you click next
        </p>
      </div>
      <div className={ styles.dialogFooterButton }>
        <ButtonComponent onClick={handleClick}>Next</ButtonComponent>
      </div>
    </footer>
  )
}
