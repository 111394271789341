import React, { type InputHTMLAttributes, type FC, type ChangeEvent, useState } from 'react'

import InputAdornment from '@mui/material/InputAdornment';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

import { CustomizedInput } from '../InputComponent/InputComponent';

import styles from './ExpiryDateInput.module.css';

interface ExpiryDateInputInterface {
  id?: string
  value: string
  setValue: (el: string) => void
  placeholder?: string
  disabled?: boolean
  sizeInput?: 'small' | 'medium'
  required?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
}

type TExpiryDateInput = ExpiryDateInputInterface & InputHTMLAttributes<HTMLInputElement>

export const ExpiryDateInputComponent: FC<TExpiryDateInput> = ({
  id,
  value,
  setValue,
  disabled,
  sizeInput = 'small',
  required,
  variant = 'outlined'
}) => {
  const [invalidDate, setInvalidDate] = useState<boolean>(false);
  let fontSize: 'small' | 'medium' = 'medium';
  if (sizeInput === 'small') fontSize = 'small';

  const hanleClear = (): void => {
    if (setValue != null) setValue('');
  }

  const limitLength = (value: string, maxLength: number): string => {
    return value.slice(0, maxLength);
  }

  const extractMonthAndYear = (inputValue: string): Array<number | null> => {
    const month = inputValue.length >= 2 ? parseInt(inputValue.substring(0, 2), 10) : null;
    const year = inputValue.length === 4 ? parseInt(inputValue.substring(2, 4), 10) : null;
    return [month, year];
  }

  const validateMonth = (month: number | null): boolean => {
    return month !== null && month >= 1 && month <= 12;
  }

  const validateYear = (year: number | null, currentYear: number): boolean => {
    if (year !== null) {
      return year >= currentYear;
    }
    return true;
  }

  const formatInputValue = (inputValue: string, isMonthValid: boolean, isYearValid: boolean): string => {
    if (isMonthValid && isYearValid && inputValue.length === 4) {
      return `${inputValue.substring(0, 2)}/${inputValue.substring(2, 4)}`;
    }
    return inputValue;
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let inputValue = event.target.value.replace(/[^0-9]/g, '');

    inputValue = limitLength(inputValue, 4);

    const [month, year] = extractMonthAndYear(inputValue);

    const isMonthValid = validateMonth(month);
    const isYearValid = validateYear(year, new Date().getFullYear() % 100);

    setInvalidDate(!(isMonthValid && isYearValid));

    setValue(formatInputValue(inputValue, isMonthValid, isYearValid));
  };

  return (
    <div className={ styles.inputContainer }>
      <CustomizedInput
        id={ id }
        value={ value }
        onChange={ handleChange}
        placeholder='MM/YY'
        error={ invalidDate }
        disabled={ disabled }
        size={ sizeInput }
        variant={ variant }
        required={ required }
        sx={{
          '& input': {
            backgroundColor: 'transparent',
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px inherit inset',
              WebkitTextFillColor: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
              WebkitBackgroundClip: 'text'
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              { invalidDate && <ErrorOutlinedIcon color='error' fontSize={ fontSize } />}
              { (!invalidDate && value.length > 0) && <CancelOutlinedIcon onClick={ hanleClear } sx={{ cursor: 'pointer' }} fontSize={ fontSize }/> }
            </InputAdornment>
          )
        }}
      />
      { invalidDate ? <p className={ styles.supportingTextError }>Please use the MM/YY format for the expiry date</p> : null }
    </div>
  );
};
