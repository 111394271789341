import React, { type FC } from 'react';
import { ModalContext } from '../ModalContext/ModalContext';
import { HeaderModal2FA } from './Header/HeaderModal2FA';
import { SectionModal2FA } from './Section/SectionModal2FA';
import { FooterModal2FA } from './Footer/FooterModal2FA';
import { useBodyOverflow } from '../../hooks/useBodyOverflow';

interface IModal2FA {
  open: boolean
  setOpen: (el: boolean) => void
}

export const Modal2FA: FC<IModal2FA> = ({ open, setOpen }) => {
  useBodyOverflow(open);
  return (
    <ModalContext
      setOpen={ setOpen }
      open={ open }
      headerComponent={ <HeaderModal2FA setIsOpen={setOpen} /> }
      sectionComponent = { <SectionModal2FA /> }
      footerComponent={ <FooterModal2FA /> }
    />
  )
}
