import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';

export interface INew {
  _id?: string
  date?: string
  title?: string
  content?: string
  link?: string
}

const initialState: INew[] = [];

export const getNews = createAsyncThunk(
  'news/get',
  async () => {
    const response = await apiHelper({ method: 'get', path: '/new' });

    return response.data.news;
  }
);

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNews.fulfilled, (state, action: PayloadAction<INew[]>) => {
      return action.payload;
    });
  }
});

export default newsSlice.reducer;
