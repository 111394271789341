import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../../../helpers/util';
import { ButtonComponent } from '../../../../../../components';
import { type IMobileAccardionExpandContent } from '../../../../interface/savingsPageInterfaces';

import style from './MobileAccardionExpandContent.module.css';

export const MobileAccardionExpandContent: FC<IMobileAccardionExpandContent> = ({
  earnings, cumulativeTotal, createdAt, isFlexible, releaseTime, isReleased, row, setSelectedInvestment, setOpenRedeemModal, period
}) => {
  const { t } = useTranslation();

  const redeemModalBtnHandler = (): void => {
    setOpenRedeemModal(true);
    setSelectedInvestment(row)
  }

  return (
    <div className={ style.accardionWrapper }>
      <div className={ style.accardionTopCell }>
        { !(isFlexible ?? false) &&
          <div className={ style.accardionCellRow }>
            <p className={ style.accardionTopCellTitle }>
              { t('savings_page.subscribe_modal.Locked_period') }
            </p>
            <p className={ style.accardionTopCellText }>
              { period }
            </p>
          </div>
        }
        <div className={ style.accardionCellRow }>
          <p className={ style.accardionTopCellTitle }>
            { t('savings_page.table_body.accounts_tab.accordion_details.earnings_title') }
          </p>
          <p className={ style.accardionTopCellText }>
            { earnings }
          </p>
        </div>
        <div className={ style.accardionCellRow }>
          <p className={ style.accardionTopCellTitle }>
            { t('savings_page.table_head.accounts_tab.total_title') }
          </p>
          <p className={ style.accardionTopCellText }>
            { cumulativeTotal }
          </p>
        </div>
      </div>
      <div className={ style.accardionBottomCell }>
        <div className={ style.accardionCellRow }>
          <p className={ style.accardionTopCellTitle }>
            { t('labels.investment_created') }
          </p>
          <p className={ style.accardionTopCellText }>
            { formatDate(new Date(createdAt as string)) }
          </p>
        </div>
        { !(isFlexible ?? false) &&
            <div className={ style.accardionCellRow }>
              <p className={ style.accardionTopCellTitle }>
                { t('savings_page.table_body.accounts_tab.accordion_details.timeline_labels.release_date') }
              </p>
              <p className={ style.accardionTopCellText }>
                { formatDate(new Date(releaseTime as string)) }
              </p>
            </div>
        }
      </div>
      <div className={ style.accardionBtnCell }>
        <ButtonComponent btnstyle='on-surface' variant='outlined' disabled={ !(isFlexible ?? false) ? !isReleased : false } onClick={ redeemModalBtnHandler }>
          {t('savings_page.table_body.accounts_tab.accordion_details.button.redeem')}
        </ButtonComponent>
      </div>
    </div>
  )
}
