import React, { type FC } from 'react';
import { RadioGroup, FormControl, styled } from '@mui/material';

import { RadioButtonDepositComponent } from '../../../../components';
import { type TypeSubHeaderWithdrawalsOptions } from '../WithdrawalComponent'

import style from './WithdrawalSubHeaderComponent.module.css';
import { t } from 'i18next';

interface InterfaceWithdrawalSubHeaderComponent {
  options: Array<{ ticker: TypeSubHeaderWithdrawalsOptions }>
  selectedOptions: TypeSubHeaderWithdrawalsOptions
  setSelectedOptions: (el: TypeSubHeaderWithdrawalsOptions) => void
}

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  paddingBottom: '8px'
}))

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  gap: '20px',
  height: '70px',
  overflowX: 'scroll',
  flexWrap: 'nowrap',
  maxWidth: '38vw',

  '@media (max-width: 1239px)': {
    maxWidth: '100vw',
    gap: '16px'
  }
}))

export const WithdrawalSubHeaderComponent: FC<InterfaceWithdrawalSubHeaderComponent> = ({ options, selectedOptions, setSelectedOptions }) => {
  return (
    <div className={ style.wrapper }>
      <div className={ style.subHeader }>
        <h3 className={ style.subHeaderTitle }>{t('withdrawal_modal.subheader.title')}</h3>
          <StyledFormControl>
            <StyledRadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={ selectedOptions }
            >
              {options.map((el) => (
                <div
                  key={ el.ticker }
                  onClick={() => { setSelectedOptions(el.ticker); }}
                  className={`${style.radioBtnWrapper} ${selectedOptions === el.ticker ? style.selected : style.deselected}`}
                  style={{
                    border: `1px solid ${selectedOptions === el.ticker ? 'var(--CFD-theme-System-Primary)' : 'var(--CFD-theme-System-Outline)'}`
                  }}
                >
                  <RadioButtonDepositComponent
                    checked={selectedOptions === el.ticker}
                    value={ el.ticker }
                  />
                  <p className={style.assetsCheckContainerTitle}>{t(`deposit_methods.${el.ticker}`)}</p>
                </div>
              ))}
            </StyledRadioGroup>
          </StyledFormControl>
      </div>
    </div>
  )
}
