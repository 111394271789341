import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../../../helpers/util';
import { type IAccardionExpandContent } from '../../../../interface/savingsPageInterfaces';
import { ButtonComponent } from '../../../../../../components';

import style from './AccardionExpandContent.module.css';

export const AccardionExpandContent: FC<IAccardionExpandContent> = ({
  earnings, cumulativeTotal, createdAt, isFlexible, releaseTime, isReleased, row, setSelectedInvestment, setOpenRedeemModal, setSavingAccountId
}) => {
  const { t } = useTranslation();

  const redeemModalBtnHandler = (): void => {
    setOpenRedeemModal(true);
    setSavingAccountId(row.currencyId);
    setSelectedInvestment(row)
  }

  return (
    <div className={ style.accardionDropDownWrapper }>
      <div className={ style.accardionDropDownInfo }>
        <div className={ style.accardionInfoWrapper }>
          <p className={ style.accardionInfoTitle }>
            { t('savings_page.table_body.accounts_tab.accordion_details.earnings_title') }
          </p>
          <p className={ style.accardionInfoText }>{earnings}</p>
        </div>
        <div className={ `${style.accardionInfoWrapper} ${style.accardionInfoWrapperBorder}` }>
          <p className={ style.accardionInfoTitle }>
            { t('savings_page.table_body.accounts_tab.accordion_details.total_title') }
          </p>
          <p className={ style.accardionInfoText }>{ cumulativeTotal }</p>
        </div>
        <div className={ `${style.accardionInfoWrapper} ${style.paddingLeft}` }>
          <p className={ style.accardionInfoTitle }>
            { t('labels.investment_created') }
          </p>
          <p className={ style.accardionInfoText }>{ formatDate(new Date(createdAt as string))}</p>
        </div>
        {
          !(isFlexible ?? false)
            ? (
                <div className={ style.accardionInfoWrapper }>
                  <p className={ style.accardionInfoTitle }>
                  { t('savings_page.table_body.accounts_tab.accordion_details.timeline_labels.release_date') }
                  </p>
                  <p className={ style.accardionInfoText }>{ formatDate(new Date(releaseTime as string))}</p>
                </div>
              )
            : null
        }
      </div>
      <div className={ style.accardionDropDownBtn }>
        <ButtonComponent btnstyle='on-surface' variant='outlined' disabled={(isFlexible ?? false) ? false : !isReleased} onClick={ redeemModalBtnHandler }>
          {t('savings_page.table_body.accounts_tab.accordion_details.button.redeem')}
        </ButtonComponent>
      </div>
    </div>
  )
}
