import React, { type HTMLAttributes, type FC, useEffect, useState } from 'react';
import { ButtonComponent } from '../../../components';
import styles from './FooterModalVerifications.module.css';
import { t } from 'i18next';

type SetStateAction<T> = T | ((prevState: T) => T);

interface IFooterModalModalVerifications {
  currentIndex: number
  totalSteps: number
  frontSideDocsPicked: boolean
  backSideDocsPicked: boolean
  frontSideCardPicked: boolean
  backSideCardPicked: boolean
  selfiePicked: boolean
  addressPicked: boolean
  setCurrentIndex: (el: SetStateAction<number>) => void
};

type TFooterModalVerifications = IFooterModalModalVerifications & HTMLAttributes<HTMLElement>;

export const FooterModalVerifications: FC<TFooterModalVerifications> = ({
  currentIndex,
  totalSteps,
  frontSideDocsPicked,
  backSideDocsPicked,
  frontSideCardPicked,
  backSideCardPicked,
  selfiePicked,
  addressPicked,
  setCurrentIndex
}) => {
  const [nextBtnDisabled, setNextBtnDisabled] = useState<boolean>(false);
  const [skipBtnDisabled, setSkipBtnDisabled] = useState<boolean>(true);

  const handleNextBtn = (): void => {
    setCurrentIndex((prevState: number) => (prevState += 1));
  };
  const handleSkipBtn = (): void => {
    setCurrentIndex((prevState: number) => (prevState += 1));
  };

  useEffect(() => () => { setCurrentIndex(0) }, [])

  useEffect(() => {
    const isNextBtnDisabled = (frontSideDocsPicked && backSideDocsPicked) ||
    (frontSideCardPicked && backSideCardPicked) || selfiePicked || addressPicked;

    setNextBtnDisabled(isNextBtnDisabled);
  }, [
    frontSideDocsPicked,
    backSideDocsPicked,
    frontSideCardPicked,
    backSideCardPicked,
    selfiePicked,
    addressPicked
  ])

  return (
      <footer className={styles.dialogFooter}>
        {currentIndex < totalSteps
          ? (
            <>
              <div className={styles.dialogFooterButton}>
                <ButtonComponent onClick={handleNextBtn} disabled={!nextBtnDisabled}>
                  {t('labels.next')}
                </ButtonComponent>
              </div>
              <div className={styles.dialogFooterButton}>
                <ButtonComponent customInlineStyles={{ fontSize: '13px' }} onClick={handleSkipBtn} variant='text' disabled={!skipBtnDisabled}>
                  {t('labels.skip_for_now')}
                </ButtonComponent>
              </div>
              <p className={styles.dialogFooterSupportText}>
                {t('profile_page.settings.complete_profile.identity_verifications.verification_modal.footer.subtitle')}
              </p>
            </>
            )
          : null}
      </footer>
  )
}
