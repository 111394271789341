import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface InitState {
  isInit: boolean
}

const initialState: InitState = {
  isInit: false
};

export const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    setInit: (state, action: PayloadAction<boolean>) => {
      state.isInit = action.payload;
    }
  }
});

export const { setInit } = initSlice.actions;

export default initSlice.reducer;
