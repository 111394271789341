import { type PayloadInterface } from '../interfaces';
import { type HandleOpenInterface } from '../components';

export const handleError = (payload: PayloadInterface, handleOpen: (props: HandleOpenInterface) => void): void => {
  const openErrorParams: HandleOpenInterface = {
    message: getErrorMsg(payload),
    actionText: '',
    severity: 'error',
    autoClose: false
  }

  handleOpen(openErrorParams);
}

const getErrorMsg = (payload: PayloadInterface): string => {
  const { error } = payload;

  if (typeof error === 'object') {
    const [value] = Object.values(error);

    return value;
  }

  if (typeof error === 'string') return error;

  return '';
}
