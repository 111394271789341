import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInputComponent } from '../../../../../components';
import { type ISavingsTableHeader } from '../../../interface/savingsPageInterfaces';

import style from './SavingsTableHeader.module.css';

export const SavingsTableHeader: FC<ISavingsTableHeader> = ({ searchInputValue, setSearchInputValue }) => {
  const { t } = useTranslation();

  return (
    <div className={ style.headerWrapper }>
      <div className={ style.mainContentWrapper }>
        <div className={ style.titleWrapper }>
          <h3 className={ style.title }>
            {t('labels.savings')}
          </h3>
          <p className={ style.subTitle }>
            {t('savings_page.header.subtitle')}
          </p>
        </div>
        <div className={ style.searchWrapper }>
          <SearchInputComponent
            value={ searchInputValue }
            setValue={ setSearchInputValue }
            placeholder={ `${t('savings_page.header.placeholder')}` }
          />
        </div>
      </div>
      <div className={ style.subHeader }>
        <p className={ style.subHeaderTitle }>
          {t('savings_page.table_sub_header.savings_tab.subtitle')}
        </p>
      </div>
    </div>
  );
}
