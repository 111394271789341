import React, { useState, type FC, useEffect } from 'react'
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { type SvgIconComponent } from '@mui/icons-material';

type Props = Record<string, any>;

/**
 * Usage example

  const handleClick = (isSelected: boolean): void => {
    console.info('You clicked the Chip. selected: ', isSelected);
  };

  <Stack direction="row" spacing={1}>
    <ChipsComponent icon={AddTask} label="AddTask" onClick={handleClick} onSelected={(status: boolean) => { console.log('selected | ', status) }} />
    <ChipsComponent label="Clickable" variant="outlined" onClick={handleClick} />
    <ChipsComponent label="Clickable" disabled={true} variant="outlined" onClick={handleClick} />
    <ChipsComponent label="Clickable" disabled={true} variant="outlined" onClick={handleClick} />
  </Stack>

 */

export const ChipsComponent: FC<Props> = ({ icon, onClick, onSelected, isSelected, ...rest }) => {
  const [selected, setSelected] = useState<boolean>(false);

  useEffect(() => {
    const isSelectedState = isSelected !== undefined ? isSelected : false;
    setSelected(isSelectedState as boolean)
  }, [isSelected]);

  const handleClick = (): void => {
    setSelected((prevValue) => !prevValue)
    if (onSelected !== undefined) onSelected(!selected)
    if (onClick !== undefined) onClick(!selected)
  }

  const CustomChip = styled(Chip)(() => ({
    textTransform: 'capitalize',
    background: selected ? 'var(--CFD-theme-System-SecondaryContainer)' : 'var(--CFD-theme-System-Surface)',
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    maxHeight: '35px',
    minHeight: '35px',
    borderRadius: '8px',
    ' svg': {
      marginLeft: '8px'
    },
    '&:hover': {
      backgroundColor: 'var(--CFD-theme-State-layers-on-surface-variant-opacity_08)'
    },
    '&.MuiChip-outlined': {
      borderColor: 'var(--CFD-theme-System-Outline)'
    },
    '&:hover.MuiChip-outlined': {
      borderColor: 'var(--CFD-theme-State-layers-primary-opacity_08)'
    },
    '&.Mui-disabled': {
      backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_12)',
      color: 'var(--CFD-theme-System-OnSurface)',
      '&.MuiChip-outlined': {
        borderColor: 'var(--CFD-theme-State-layers-on-surface-opacity_12)'
      }
    }
  }))

  const PropsIcon: SvgIconComponent = icon
  const Icon = selected
    ? <CheckIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }} />
    : icon !== undefined ? <PropsIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }} /> : <></>;

  return (
    <CustomChip
      icon={Icon}
      onClick={handleClick}
      {...rest}
    />
  );
}
