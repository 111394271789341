import React, { type FC } from 'react';

export const VerificationIcon: FC = () => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.25 9C13.25 8.58579 13.5858 8.25 14 8.25H18C18.4142 8.25 18.75 8.58579 18.75 9C18.75 9.41421 18.4142 9.75 18 9.75H14C13.5858 9.75 13.25 9.41421 13.25 9Z" fill="var(--CFD-theme-System-OnSurface)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.25 12.5C13.25 12.0858 13.5858 11.75 14 11.75H17C17.4142 11.75 17.75 12.0858 17.75 12.5C17.75 12.9142 17.4142 13.25 17 13.25H14C13.5858 13.25 13.25 12.9142 13.25 12.5Z" fill="var(--CFD-theme-System-OnSurface)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7 3.75C4.65279 3.75 2.75 5.65279 2.75 8V16C2.75 18.3472 4.65279 20.25 7 20.25H17C19.3472 20.25 21.25 18.3472 21.25 16V8C21.25 5.65279 19.3472 3.75 17 3.75H7ZM1.25 8C1.25 4.82436 3.82436 2.25 7 2.25H17C20.1756 2.25 22.75 4.82436 22.75 8V16C22.75 19.1756 20.1756 21.75 17 21.75H7C3.82436 21.75 1.25 19.1756 1.25 16V8Z" fill="var(--CFD-theme-System-OnSurface)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.48144 14.7504C7.24064 14.7687 6.12793 15.3599 5.67943 16.318C5.50381 16.6931 5.05734 16.8549 4.68219 16.6792C4.30705 16.5036 4.14531 16.0572 4.32092 15.682C5.08074 14.059 6.82412 13.2747 8.4593 13.2506C10.1036 13.2263 11.8767 13.9673 12.6794 15.682C12.855 16.0572 12.6933 16.5036 12.3182 16.6792C11.943 16.8549 11.4965 16.6931 11.3209 16.318C10.8358 15.2818 9.71311 14.7322 8.48144 14.7504Z" fill="var(--CFD-theme-System-OnSurface)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.5 7.75C7.80964 7.75 7.25 8.30964 7.25 9C7.25 9.69038 7.80964 10.25 8.5 10.25C9.19036 10.25 9.75 9.69038 9.75 9C9.75 8.30964 9.19036 7.75 8.5 7.75ZM5.75 9C5.75 7.48122 6.98122 6.25 8.5 6.25C10.0188 6.25 11.25 7.48122 11.25 9C11.25 10.5188 10.0188 11.75 8.5 11.75C6.98122 11.75 5.75 10.5188 5.75 9Z" fill="var(--CFD-theme-System-OnSurface)"/>
        </svg>
  )
}
