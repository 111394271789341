import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';

export interface ICurrency {
  _id: string
  name: string
  symbol: string
  iso_4217: string
}

const initialState: ICurrency[] = [];

export const getFiatCurrencies = createAsyncThunk(
  'currency/fiat/get',
  async () => {
    const response = await apiHelper({ method: 'get', path: '/currency/fiat' });

    return response.data.allCurrencies;
  }
);

export const getCryptoCurrencies = createAsyncThunk(
  'currency/crypto/get',
  async () => {
    const response = await apiHelper({ method: 'get', path: '/currency/crypto' });

    return response.data.allCurrencies;
  }
);

export const currenciesSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFiatCurrencies.fulfilled, (state, action: PayloadAction<ICurrency[]>) => {
      return action.payload;
    });
    builder.addCase(getCryptoCurrencies.fulfilled, (state, action: PayloadAction<ICurrency[]>) => {
      return action.payload;
    });
  }
});

export default currenciesSlice.reducer;
