import React, { type FC } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { setShowFooter } from '../../../../redux/reducers/showFooter';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';

import style from './DepositHeaderComponent.module.css';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { t } from 'i18next';

interface InterfaceDepositHeaderComponent {
  setOpen: (el: boolean) => void
  selectedAccount: IAccount | null
}
export const DepositHeaderComponent: FC<InterfaceDepositHeaderComponent> = ({ setOpen, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const [screenWidth] = useWindowSize();
  const { showFooter } = useAppSelector((state) => state.showFooter);

  const isMobile = screenWidth <= 599;

  const handleBtnClick = (): void => {
    if (isMobile && !showFooter) dispatch(setShowFooter(true));
    setOpen(false);
  }

  const selectedCurrencySymbol = selectedAccount?.cfdAccountCurrency.symbol ?? '';
  const contentTitle = `${t('labels.deposit')} ${selectedCurrencySymbol}`

  return (
    <header className={ style.header }>
      <div className={ style.contentWrapper }>
        <h2 className={ style.contentTitle }>{ contentTitle }</h2>
          <button className={ style.btn } onClick={ handleBtnClick }>
            <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
          </button>
      </div>
    </header>
  )
}
