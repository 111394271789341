import React, { type FC } from 'react';

// import { DatePicker, SelectComponent, ButtonComponent } from '../../../../components';

import style from './AccountsTableSubHeader.module.css';
import { t } from 'i18next';

export const AccountsTableSubHeader: FC = () => {
  return (
    <div className={ style.subHeaderWrapper }>
      <p className={ style.subHeaderTitle }>{t('labels.history')}</p>
      {/* <div className={ style.optionsContainer }>
        <div className={ style.options }>
          <div className={ style.datePickerSelect }>
            <SelectComponent options={[{ value: 'FilterName', label: 'FilterName' }]} height={ 43 } value='FilterName'/>
          </div>
          <div className={ style.datePickerContainer }>
            <DatePicker labelAvailable={ false }/>
          </div>
        </div>
        <div className={ style.btnContainer }>
          <ButtonComponent variant='text' disabled>Reset</ButtonComponent>
        </div>
      </div> */}
    </div>
  )
}
