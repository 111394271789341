import React, { type FC } from 'react';

export const ApproximetlyEqualIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M6.40286 6.26101C5.53449 6.18362 4.60534 6.50671 3.59206 7.64622C3.36269 7.90417 2.96764 7.92734 2.70969 7.69797C2.45175 7.46859 2.42858 7.07355 2.65795 6.8156C3.8669 5.45603 5.15997 4.89528 6.51382 5.01594C7.81557 5.13196 9.04781 5.87029 10.1888 6.80912C11.27 7.69881 12.26 8.24044 13.1805 8.32248C14.0488 8.39987 14.978 8.07678 15.9913 6.93726C16.2207 6.67932 16.6157 6.65615 16.8737 6.88552C17.1316 7.1149 17.1548 7.50995 16.9254 7.76789C15.7164 9.12746 14.4234 9.68821 13.0695 9.56754C11.7678 9.45152 10.5355 8.7132 9.39456 7.77437C8.31331 6.88469 7.32334 6.34305 6.40286 6.26101Z" fill="var(--CFD-theme-System-Outline)"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.40286 12.0943C5.53449 12.0169 4.60534 12.34 3.59206 13.4796C3.36269 13.7375 2.96764 13.7607 2.70969 13.5313C2.45175 13.3019 2.42858 12.9069 2.65795 12.6489C3.8669 11.2894 5.15997 10.7286 6.51382 10.8493C7.81557 10.9653 9.04781 11.7036 10.1888 12.6425C11.27 13.5321 12.26 14.0738 13.1805 14.1558C14.0488 14.2332 14.978 13.9101 15.9913 12.7706C16.2207 12.5126 16.6157 12.4895 16.8737 12.7189C17.1316 12.9482 17.1548 13.3433 16.9254 13.6012C15.7164 14.9608 14.4234 15.5215 13.0695 15.4009C11.7678 15.2849 10.5355 14.5465 9.39456 13.6077C8.31331 12.718 7.32334 12.1764 6.40286 12.0943Z" fill="var(--CFD-theme-System-Outline)"/>
    </svg>
  )
}
