import * as React from 'react'
import { IconButtonComponent, useTheme } from '../../components';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

export const ThemeToggle: React.FC = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div style={{
      width: '100px',
      height: '100px',
      position: 'absolute',
      bottom: '0px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '10'
    }}>
      <IconButtonComponent onClick={toggleTheme} variant='solid'>
        {theme === 'light' ? <DarkModeIcon /> : <LightModeIcon/>}
      </IconButtonComponent>
    </div>
  )
}
