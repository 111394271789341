import React, { type FC } from 'react';

import { getAccountIconBySymbol } from '../../../helpers/util';
import { type OptionInterface } from '../../../interfaces';

import styles from '../SelectComponent.module.css';

interface ISelectComponentValueView {
  selectedOption: OptionInterface
}

export const SelectComponentValueView: FC<ISelectComponentValueView> = ({ selectedOption }) => {
  return (
    <div className={styles.currencyContainer}>
      {selectedOption.symbol?.length > 0 && (
        <img
          src={getAccountIconBySymbol((selectedOption.symbol as string) ?? '')}
          className={styles.currencyIcon}
        />
      )}
      <p className={styles.currencyTitle}>{(selectedOption.label as string) ?? ''}</p>
    </div>
  )
}
