import React, { type FC } from 'react';
import { type IconInterface } from '../../interfaces';

export const MinusIcon: FC<IconInterface> = ({ width, height, color }) => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
            <path d="M7 13V11H17V13H7Z" fill={color ?? 'var(--CFD-theme-System-OnSurfaceVariant)'} />
        </svg>
  )
}
