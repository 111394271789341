import React, { type FC } from 'react';

interface InterfaceVisaIcon {
  active?: boolean
}

export const VisaIcon: FC<InterfaceVisaIcon> = ({ active }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="16" viewBox="0 0 50 16" fill="none">
      <g opacity={ (active ?? false) ? '1' : '0.4' }>
        <path d="M21.3801 15.7333H17.3558L19.8709 0.276197H23.8956L21.3801 15.7333ZM13.9703 0.276197L10.1337 10.9077L9.67972 8.61834L9.68013 8.61917L8.32602 1.66793C8.32602 1.66793 8.16228 0.276197 6.41703 0.276197H0.0744245L0 0.537922C0 0.537922 1.93958 0.941467 4.20952 2.30467L7.70582 15.7337H11.8988L18.3014 0.276197H13.9703ZM45.6234 15.7333H49.3185L46.0968 0.275784H42.8618C41.368 0.275784 41.0041 1.42771 41.0041 1.42771L35.0022 15.7333H39.1972L40.0362 13.4373H45.152L45.6234 15.7333ZM41.1951 10.2656L43.3096 4.48117L44.4991 10.2656H41.1951ZM35.3168 3.99328L35.8911 0.673953C35.8911 0.673953 34.119 0 32.2716 0C30.2746 0 25.5321 0.872832 25.5321 5.11709C25.5321 9.11037 31.0982 9.15999 31.0982 11.2575C31.0982 13.355 26.1056 12.9792 24.4579 11.6565L23.8596 15.1272C23.8596 15.1272 25.6566 16 28.402 16C31.1482 16 35.2912 14.5781 35.2912 10.708C35.2912 6.6891 29.6751 6.31491 29.6751 4.56759C29.6755 2.81986 33.5947 3.04437 35.3168 3.99328Z"
          fill={ (active ?? false) ? 'var(--CFD-theme-System-OnSurface)' : 'var(--CFD-theme-System-OnSurfaceVariant)'}
        />
      </g>
    </svg>
  )
}
