import React, { type SyntheticEvent, type ReactNode } from 'react';
import { Tabs, styled } from '@mui/material';

const CustomizedTabs = styled(Tabs)({
  '.MuiTab-textColorPrimary': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant)'
  },
  '.Mui-selected': {
    color: 'var(--CFD-theme-System-Tertiary) !important'
  }
})

interface Props {
  onChange: (event: SyntheticEvent<Element, Event>, newValue: number) => void
  children: ReactNode
  centered?: boolean
  value: number
}

export const TabListComponent: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <CustomizedTabs
        variant='scrollable'
        scrollButtons='auto'
        TabIndicatorProps={{
          style: {
            backgroundColor: 'var(--CFD-theme-System-Tertiary)'
          }
        }}
        { ...rest}
    >
        {children}
    </CustomizedTabs>
  );
}
