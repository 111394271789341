import React, { type InputHTMLAttributes, type FC, type ChangeEvent, useState } from 'react'
import { InputAdornment } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { CustomizedInput } from '../InputComponent/InputComponent';

import { VisaIcon } from '../icons/VisaIcon';
import { MasterCardIcon } from '../icons/MasterCardIcon';

import style from './CreditCardInputComponent.module.css';

interface InterfaceCreditCardInputComponent {
  id?: string
  value: string
  setValue: (el: string) => void
  placeholder?: string
  error?: boolean
  disabled?: boolean
  sizeInput?: 'small' | 'medium'
  errorText?: string
  required?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
}

type TCreditCardInputComponent = InterfaceCreditCardInputComponent & InputHTMLAttributes<HTMLInputElement>

export const CreditCardInputComponent: FC<TCreditCardInputComponent> = ({
  id,
  value,
  setValue,
  placeholder,
  error,
  disabled,
  sizeInput = 'small',
  errorText,
  required,
  variant = 'outlined'
}) => {
  const [activeCard, setActiveCard] = useState<'Visa' | 'MasterCard' | null>(null)
  let fontSize: 'small' | 'medium' = 'medium';
  if (sizeInput === 'small') fontSize = 'small';

  const handleClear = (): void => {
    if (setValue != null) setValue('');
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target: { value } } = event;
    if (value[0] === '4') setActiveCard('Visa');
    else if (value[0] === '5') setActiveCard('MasterCard');
    else setActiveCard(null)
    let sanitizedValue = value.replace(/\D/g, '');
    if (sanitizedValue.length <= 19) {
      sanitizedValue = formatCreditCardNumber(sanitizedValue);
      setValue(sanitizedValue);
    }
  };

  const formatCreditCardNumber = (value: string): string => {
    return value.replace(/(\d{4})(?=\d)/g, '$1 ');
  };

  return (
    <div className={ style.inputContainer }>
      <CustomizedInput
        id={ id }
        value={ value }
        onChange={ handleChange }
        placeholder={ placeholder }
        error={ error }
        disabled={ disabled }
        size={ sizeInput }
        variant={ variant }
        required={ required }
        sx={{
          '& input': {
            backgroundColor: 'transparent',
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px inherit inset',
              WebkitTextFillColor: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
              WebkitBackgroundClip: 'text'
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <div className={ style.iconWrapper }>
                { (error ?? false) && <CancelOutlinedIcon color='error' fontSize={ fontSize } onClick={ handleClear } sx={{ cursor: 'pointer' }} />}
                { (!(error ?? false) && value.length > 0) &&
                  <CancelOutlinedIcon onClick={ handleClear } sx={{ cursor: 'pointer' }} fontSize={ fontSize }/>
                }
                <div className={ style.cardIconWrapper } style={{ width: activeCard !== null ? '60px' : '110px' }}>
                  { activeCard === 'Visa' && <VisaIcon active /> }
                  { activeCard === 'MasterCard' && <MasterCardIcon active /> }
                  { (activeCard === null || value.length === 0) &&
                    <>
                      <VisaIcon />
                      <MasterCardIcon />
                    </>
                  }
                </div>
              </div>
            </InputAdornment>
          )
        }}
      />
      {((error ?? false) && (errorText != null)) ? <p className={ style.supportingTextError }>{errorText}</p> : null }
    </div>
  );
};
