import React, {
  type ReactPortal,
  type ReactNode,
  type DialogHTMLAttributes,
  type FC
} from 'react';
import { createPortal } from 'react-dom';

import styles from './ModalContext.module.css';

interface IModalContext {
  open: boolean
  setOpen: (el: boolean) => void
  headerComponent?: ReactNode
  sectionComponent?: ReactNode
  footerComponent?: ReactNode
  isNotMobileFullScreen?: boolean
  setStep?: (el: number) => void
}

type TModalContext = IModalContext & DialogHTMLAttributes<HTMLDialogElement>;

const modalRoot = document.getElementById('modal-window');

export const ModalContext: FC<TModalContext> = ({
  headerComponent,
  sectionComponent,
  footerComponent,
  isNotMobileFullScreen,
  setOpen,
  setStep,
  open,
  ...rest
}): ReactPortal | null => {
  const handleBackgroundClick = (): void => {
    setOpen(false);
    if (setStep != null) setStep(1);
  };

  const stopPropagation = (e: React.MouseEvent): void => {
    e.stopPropagation();
  };

  if (modalRoot !== null && open) {
    return createPortal(
      <div className={ styles.modalBackground } onClick={ handleBackgroundClick }>
        <dialog
          className={ `${(isNotMobileFullScreen ?? false) ? styles.notFullScreen : styles.dialogWrapper}` }
          open={ open }
          onClick={ stopPropagation }
          {...rest}
        >
          { headerComponent ?? null }
          { sectionComponent ?? null }
          { footerComponent ?? null }
        </dialog>
      </div>,
      modalRoot
    );
  }
  return null;
};
