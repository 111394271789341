import React, { type FC } from 'react';
import { ModalContext } from '../../components';
import { HeaderModalTransferFunds } from './Header/HeaderModalTransferFunds';
import { SectionModalTransferFunds } from './Section/SectionModalTransferFunds';
import { FooterModalTransferFunds } from './Footer/FooterModalTransferFunds';

interface InterfaceModalTransferFunds {
  open: boolean
  fromValue: number
  toValue: number
  fromSymbol: string
  toSymbol: string
  isLoading: boolean
  step: number
  onSubmit: () => Promise<void>
  setOpen: (el: boolean) => void
  handleStateAfterTransfer: (value: boolean) => void
}

export const ModalTransferFunds: FC<InterfaceModalTransferFunds> = ({ open, setOpen, onSubmit, fromValue, toValue, fromSymbol, toSymbol, isLoading, step, handleStateAfterTransfer }) => {
  return (
    <ModalContext
      open={ open }
      setOpen={ setOpen }
      headerComponent={ <HeaderModalTransferFunds setOpen={ setOpen }/> }
      sectionComponent={
        <SectionModalTransferFunds
          step={ step }
          fromValue={fromValue}
          toValue={toValue}
          fromSymbol={fromSymbol}
          toSymbol={toSymbol}
         />
      }
      footerComponent={ <FooterModalTransferFunds step={ step } setOpen={ setOpen } onSubmit={onSubmit} isLoading={isLoading} handleStateAfterTransfer={handleStateAfterTransfer} /> }
    />
  )
}
