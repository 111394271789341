import React, { type FC } from 'react';

import { ButtonComponent } from '../../../components';

import style from './FooterModalTransferFunds.module.css';
import { t } from 'i18next';

interface InterfaceFooterModalTransferFunds {
  step: number
  isLoading: boolean
  onSubmit: () => Promise<void>
  setOpen: (el: boolean) => void
  handleStateAfterTransfer: (value: boolean) => void
}

export const FooterModalTransferFunds: FC<InterfaceFooterModalTransferFunds> = ({ step, setOpen, onSubmit, handleStateAfterTransfer, isLoading }) => {
  const confirmBtnHandler = (): void => {
    onSubmit();
  }

  const closeBtmHandler = (): void => {
    handleStateAfterTransfer(false);
    setOpen(false);
  }

  const viewHistoryBtnHandler = (): void => {
    handleStateAfterTransfer(true);
    setOpen(false);
  }

  return (
    <footer className={ style.footerWrapper }>
      { step === 1
        ? <div className={ style.confirmContainer }>
            <ButtonComponent onClick={ confirmBtnHandler } loading={isLoading}>{t('labels.confirm')}</ButtonComponent>
          </div>
        : <div className={ style.btnsWrapper }>
            <div className={ style.btnContainer }>
              <ButtonComponent onClick={ closeBtmHandler } variant='outlined'>{t('labels.close')}</ButtonComponent>
            </div>
            <div className={ style.btnContainer }>
              <ButtonComponent onClick={ viewHistoryBtnHandler }>{t('labels.view_history')}</ButtonComponent>
            </div>
          </div> }
    </footer>
  )
}
