import React, { type FC } from 'react';
import { navigation } from './navigation';
import { AuthData } from '../auth/AuthWrapper';
import { Route, Routes } from 'react-router-dom';

export const RenderNavigation: FC = (): JSX.Element => {
  const { token } = AuthData();
  const isUserAuth = (token !== null && token.length > 0);

  return (
    <Routes>
      { navigation.map((r, i) => {
        return (
          <Route
            key={i}
            path={r.path}
            element={
              <r.layout isUserAuth={isUserAuth}>{r.element}</r.layout>
            }
          />
        );
      })}
    </Routes>
  );
}
