import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';

interface IAlertMessageResponse {
  _id: string
  actions: string
  type: string
  value: string
  brandId: string[]
  brandName: string[]
  createdAt: string | Date
  updatedAt: string | Date
  variant: 'default' | 'warning' | 'error'
  managerNames: string[]
}

interface InitState {
  isAlertMessage: boolean
  alertMessageData: IAlertMessageResponse[]
}

const initialState: InitState = {
  isAlertMessage: false,
  alertMessageData: []
};

export const getAlertMessage = createAsyncThunk(
  'alertMessage/get',
  async () => {
    const url = '/variables/alert-message';

    const response = await apiHelper({ method: 'get', path: `${url}` });

    return response.data.variables;
  }
);

export const alertMessageSlice = createSlice({
  name: 'alertMessage',
  initialState,
  reducers: {
    setShowAlertMessage: (state, action: PayloadAction<boolean>) => {
      state.isAlertMessage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAlertMessage.fulfilled, (state, action: PayloadAction<IAlertMessageResponse[]>) => {
      state.alertMessageData = action.payload;
    });
  }
});

export const { setShowAlertMessage } = alertMessageSlice.actions;

export default alertMessageSlice.reducer;
