import React, {
  type HTMLAttributes,
  type FC,
  useEffect,
  useRef
} from 'react';
import styles from './SectionModal2FA.module.css';
import { CodeInput2FA } from '../2FACodeInput/CodeInput2FA';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { setIs2FACodeIncorrect } from '../../../../../redux/reducers/login';
import { selectIs2FACodeIncorrect } from '../../../../../redux/selectors/login';

type TSectionModal2FA = HTMLAttributes<HTMLElement> & {
  code2FA: string[]
  setCode2FA: any
  setIsCodeCorrectLength: any
}

export const SectionModal2FA: FC<TSectionModal2FA> = ({
  code2FA,
  setCode2FA,
  setIsCodeCorrectLength
}) => {
  const dispatch = useAppDispatch()
  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

  const isError = useAppSelector(selectIs2FACodeIncorrect)

  useEffect(() => {
    dispatch(setIs2FACodeIncorrect(false))

    if (code2FA.filter(v => v !== '').length === 6) {
      setIsCodeCorrectLength(true)
    } else {
      setIsCodeCorrectLength(false)
    }
  }, [code2FA])

  return (
    <section className={styles.sectionStep2}>
      <h3 className={ styles.sectionStep2Title }>Verify Authenticator</h3>
      <CodeInput2FA code={code2FA} setCode={setCode2FA} ref={inputsRef} errorInput={isError}/>
      <p className={styles.sectionStep2Text}>Enter the 6-digit code generated</p>
    </section>
  )
}
