import { useEffect } from 'react';

interface IDebounceParams {
  value: string | null
  delay: number
  action: any
}

export const useDebounce = ({ value = null, delay, action }: IDebounceParams): void => {
  useEffect(() => {
    if (typeof value === 'string') {
      const handler = setTimeout(() => {
        action(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);
};
