/* eslint-disable @typescript-eslint/no-floating-promises */
// TODO: Integrate TFA
import React, { useState, useEffect } from 'react';
import styles from '../Auth.module.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header, Wrapper, Container } from '../components';
import { ButtonComponent, InputComponent, PasswordInputComponent } from '../../../components';
import { getOperatingSystem, getBrowser } from './helper';
import useQuery from '../../../hooks/useQuery';
import { AuthData } from '../../../auth/AuthWrapper';
import { Modal2FAPinCode } from './Modal2FAPinCode/Modal2FAPinCode';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { selectIs2FAPinCodeModalOpen } from '../../../redux/selectors/login';
import { setIs2FAPinCodeModalOpen } from '../../../redux/reducers/login';

export const Login: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation();
  const queryParams = useQuery();
  const token = queryParams.get('token');
  const navigate = useNavigate();
  const { login, loginByToken } = AuthData();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState({ value: '', showPassword: false });
  const [emailData, setEmailData] = useState({ isError: false, inputErrorMsg: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const open2FAModal = useAppSelector(selectIs2FAPinCodeModalOpen)

  useEffect(() => {
    if (emailData.isError) {
      setEmailData({ isError: false, inputErrorMsg: '' });
    }
  }, [email]);

  useEffect(() => {
    if (token !== null && token.length > 0) loginByToken(token);
  }, [token])

  const Subtitle = (): JSX.Element => (
    <>
        <h2>{t('login_page.sign_in')}</h2>
        <div className={styles.containerHeaderActions__row}>
            <span>{t('login_page.do_not_have_acc')}</span>
            <ButtonComponent
                variant='outlined'
                onClick={() => { navigate('/registration'); }}
            >
                {t('login_page.register')}
            </ButtonComponent>
        </div>
    </>
  );

  const submit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await login({
        email,
        password: password.value,
        host: window.location.host,
        currentBrowser: getBrowser(),
        currentOS: getOperatingSystem()
      });
    } catch (error: any) {
      setEmailData({ isError: true, inputErrorMsg: error.message as string });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Wrapper>
      <Modal2FAPinCode
        open={open2FAModal}
        setOpen={(status: boolean) => { dispatch(setIs2FAPinCodeModalOpen(status)) }}
        login={login}
        loginData={{
          email,
          password: password.value,
          host: window.location.host,
          currentBrowser: getBrowser(),
          currentOS: getOperatingSystem()
        }}
      />
        <Header />
        <Container
            subtitle={<Subtitle />}
            content={
              <>
                <div className={styles.containerBody__column}>
                  <div className={styles.containerBody}>
                    <label htmlFor="emailInput">{t('login_page.enter_email')}</label>
                    <div className={styles.inputContainer}>
                      <InputComponent
                        id="emailInput"
                        value={email}
                        error={emailData.isError}
                        errorText={emailData.inputErrorMsg}
                        setValue={setEmail}
                      />
                    </div>
                    <div className={ styles.emptyBox }></div>
                  </div>
                  <div className={styles.containerBody}>
                    <label htmlFor="passwordInput">{t('login_page.enter_password')}</label>
                    <div className={styles.inputContainer}>
                      <PasswordInputComponent id="passwordInput" sizeInput="small" value={password} setValue={setPassword} />
                    </div>
                    <div className={ styles.emptyBox }></div>
                  </div>
                  <div className={styles.containerBodyBtn}>
                    <div className={ styles.emptyBoxLeft}></div>
                    <div className={ styles.containerBtn }>
                      <ButtonComponent disabled={ !password.value.length } variant='contained' loading={isLoading} onClick={() => { submit(); }}>{t('login_page.login')}</ButtonComponent>
                    </div>
                    <div className={ styles.emptyBox }></div>
                  </div>
                  <div className={styles.containerBodyBtn}>
                    <div className={ styles.emptyBoxLeft }></div>
                    <div className={ styles.containerBtn }>
                      <ButtonComponent
                        variant='text'
                        onClick={() => { navigate('/forgot-password'); }}
                      >
                        {t('login_page.forgot_password')}
                      </ButtonComponent>
                    </div>
                    <div className={ styles.emptyBox }></div>
                  </div>
                </div>
              </>
            }
        />
    </Wrapper>
  )
}
