import React, { forwardRef, type ReactNode } from 'react';
import IconButton, { type IconButtonProps } from '@mui/joy/IconButton';
import { styled } from '@mui/material/styles';

interface CustomIconButtonProps extends IconButtonProps {
  'selectable-class'?: string
  selected?: boolean
}

interface IconButtonComponentProps {
  children?: ReactNode
  loadingIndicator?: ReactNode
  variant?: 'plain' | 'outlined' | 'soft' | 'solid'
  disabled?: boolean
  loading?: boolean
  selected?: boolean
  selectableClass?: string
  onClick?: () => void
}

const CustomIconButton = styled(IconButton)<CustomIconButtonProps>(({ selected, 'selectable-class': selectableClass }) => {
  if (selectableClass != null && selectableClass.length > 0) {
    if (selected === true) {
      return ({
        display: 'flex',
        width: '48px',
        height: '48px',
        borderRadius: '24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        flexShrink: 0,
        color: 'var(--CFD-theme-System-OutlineVariant)',
        backgroundColor: 'transparent',

        '&:hover': {
          opacity: '0.92'
        },
        '&:active': {
          color: 'var(--CFD-theme-System-OutlineVariant)',
          opacity: '0.82'
        },
        '&:disabled > *': {
          opacity: '0.38'
        },

        '&.MuiIconButton-variantSolid': {
          backgroundColor: 'var(--CFD-theme-System-OutlineVariant)',
          color: 'var(--CFD-theme-System-OnSurfaceVariant)'
        },
        '&.MuiIconButton-variantSolid:hover': {
          opacity: '0.92',
          color: 'var(--CFD-theme-System-OnSurfaceVariant)'
        },
        '&.MuiIconButton-variantSolid:active': {
          backgroundColor: 'color-mix(in srgb, var(--CFD-theme-System-OutlineVariant), transparent 12%)',
          color: 'var(--CFD-theme-System-OnSurfaceVariant)'
        },
        '&.MuiIconButton-variantSolid:disabled': {
          backgroundColor: 'color-mix(in srgb, var(--CFD-theme-System-OnSurface) 12%, transparent)',
          color: 'var(--CFD-theme-System-OnSurface)'
        },

        '&.MuiIconButton-variantSoft': {
          backgroundColor: 'var(--CFD-theme-System-SecondaryContainer)',
          color: 'var(--CFD-theme-System-OnSecondaryContainer)'
        },
        '&.MuiIconButton-variantSoft:hover': {
          opacity: '0.92',
          color: 'var(--CFD-theme-System-OnSecondaryContainer)'
        },
        '&.MuiIconButton-variantSoft:active': {
          backgroundColor: 'color-mix(in srgb, var(--CFD-theme-System-SecondaryContainer), transparent 12%)',
          color: 'var(--CFD-theme-System-OnSecondaryContainer)'
        },
        '&.MuiIconButton-variantSoft:disabled': {
          backgroundColor: 'color-mix(in srgb, var(--CFD-theme-System-OnSurface) 12%, transparent)',
          color: 'var(--CFD-theme-System-OnSurface)'
        },

        '&.MuiIconButton-variantOutlined': {
          backgroundColor: 'var(--CFD-theme-System-Inversesurface)',
          color: 'var(--CFD-theme-System-Inverseonsurface)'
        },
        '&.MuiIconButton-variantOutlined:hover': {
          opacity: '0.92'
        },
        '&.MuiIconButton-variantOutlined:active': {
          backgroundColor: 'color-mix(in srgb, var(--CFD-theme-System-Inversesurface), transparent 12%)'
        },
        '&.MuiIconButton-variantOutlined:disabled': {
          backgroundColor: 'color-mix(in srgb, var(--CFD-theme-State-layers-on-surface-variant-opacity_12), transparent 12%)',
          color: 'var(--CFD-theme-System-OnSurface)',
          border: '0px'
        }
      })
    }

    return ({
      display: 'flex',
      width: '48px',
      height: '48px',
      borderRadius: '24px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      flexShrink: 0,
      color: 'var(--CFD-theme-System-OnSurface)',
      backgroundColor: 'transparent',

      '&:hover': {
        opacity: '0.92',
        color: 'var(--CFD-theme-System-OnSurface)'
      },
      '&:active': {
        opacity: '0.88',
        color: 'var(--CFD-theme-System-OnSurface)'
      },
      '&:disabled > *': {
        opacity: '0.38'
      },

      '&.MuiIconButton-variantSolid': {
        backgroundColor: 'var(--CFD-theme-Surface-5)',
        color: 'var(--CFD-theme-System-OutlineVariant)'
      },
      '&.MuiIconButton-variantSolid:hover': {
        opacity: '0.92',
        color: 'var(--CFD-theme-System-OutlineVariant)'
      },
      '&.MuiIconButton-variantSolid:active': {
        backgroundColor: 'color-mix(in srgb, var(--CFD-theme-System-OutlineVariant) 12%, transparent)',
        color: 'var(--CFD-theme-System-OutlineVariant)'
      },
      '&.MuiIconButton-variantSolid:disabled': {
        backgroundColor: 'color-mix(in srgb, var(--CFD-theme-System-OnSurface) 12%, transparent)',
        color: 'var(--CFD-theme-System-OnSurface)'
      },

      '&.MuiIconButton-variantSoft': {
        backgroundColor: 'var(--CFD-theme-Surface-5)',
        color: 'var(--CFD-theme-System-OnSurfaceVariant)'
      },
      '&.MuiIconButton-variantSoft:hover': {
        opacity: '0.92'
      },
      '&.MuiIconButton-variantSoft:active': {
        backgroundColor: 'var(--CFD-theme-State-layers-on-secondary-container-opacity_12)'
      },
      '&.MuiIconButton-variantSoft:disabled': {
        backgroundColor: 'color-mix(in srgb, var(--CFD-theme-System-SecondaryContainer), var(--CFD-theme-State-layers-on-secondary-container-opacity_12))'
      },

      '&.MuiIconButton-variantOutlined': {
        border: '1px solid var(--CFD-theme-System-Outline)',
        color: 'var(--CFD-theme-System-OnSurfaceVariant)'
      },
      '&.MuiIconButton-variantOutlined:hover': {
        opacity: '0.92'
      },
      '&.MuiIconButton-variantOutlined:active': {
        backgroundColor: 'color-mix(in srgb, var(--CFD-theme-System-OnSurfaceVariant) 12%, transparent)'
      },
      '&.MuiIconButton-variantOutlined:disabled': {
        border: '1px solid var(--CFD-theme-State-layers-on-surface-variant-opacity_12)',
        backgroundColor: 'transperent'
      }

    })
  }

  return (
    {
      display: 'flex',
      width: '48px',
      height: '48px',
      borderRadius: '24px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid var(--CFD-theme-System-OutlineVariant)',
      gap: '10px',
      flexShrink: 0,
      color: 'var(--CFD-theme-System-OnSurface)',
      backgroundColor: 'transparent',

      '&:hover': {
        opacity: '0.98'
      },
      '&:active': {
        color: 'var(--CFD-theme-System-OnSurface)',
        opacity: '0.88'
      },
      '&:disabled > *': {
        opacity: '0.38'
      },

      '&.MuiIconButton-variantOutlined': {
        border: '1px solid var(--CFD-theme-System-OnSurfaceVariant)',
        color: 'var(--CFD-theme-System-OnSurfaceVariant)'
      },
      '&.MuiIconButton-variantOutlined:hover': {
        opacity: '0.98'
      },
      '&.MuiIconButton-variantOutlined:active': {
        backgroundColor: 'var(--CFD-theme-System-OnSurfaceVariant)'
      },

      '&.MuiIconButton-variantOutlined:disabled': {
        border: '1px solid var(--CFD-theme-State-layers-on-surface-variant-opacity_12)',
        backgroundColor: 'transperent'
      },

      '&.MuiIconButton-variantSolid': {
        backgroundColor: '--CFD-theme-System-OnSurfaceVariant',
        color: 'var(--CFD-theme-System-OnSurfaceVariant)'
      },
      '&.MuiIconButton-variantSolid:hover': {
        opacity: '0.98'
      },
      '&.MuiIconButton-variantSolid:active': {
        backgroundColor: 'color-mix(in srgb, var(--CFD-theme-System-OnSurfaceVariant), var(--CFD-theme-State-layers-on-tertiary-opacity_12) 12%)'
      },
      '&.MuiIconButton-variantSolid:disabled': {
        backgroundColor: 'var(--CFD-theme-System-OnSurfaceVariant)',
        color: 'var(--CFD-theme-System-OnSurface)'
      },

      '&.MuiIconButton-variantSoft': {
        backgroundColor: 'var(--CFD-theme-System-SecondaryContainer)',
        color: 'var(--CFD-theme-System-OnSecondaryContainer)'
      },
      '&.MuiIconButton-variantSoft:hover': {
        opacity: '0.98'
      },
      '&.MuiIconButton-variantSoft:active': {
        backgroundColor: 'color-mix(in srgb, var(--CFD-theme-System-OnSurfaceVariant), var(--CFD-theme-State-layers-on-secondary-container-opacity_12))'
      },
      '&.MuiIconButton-variantSoft:disabled': {
        backgroundColor: 'color-mix(in srgb, var(--CFD-theme-System-OnSurfaceVariant), var(--CFD-theme-State-layers-on-secondary-container-opacity_12))'
      }
    }
  )
})

export const IconButtonComponent = forwardRef<HTMLButtonElement, IconButtonComponentProps>((props, ref) => {
  const { children, disabled = false, loading = false, variant = 'plain', selectableClass = '', selected = false, ...rest } = props

  return (
    <CustomIconButton
      variant={variant}
      disabled={disabled}
      loading={loading}
      selectable-class={selectableClass}
      selected={selected}
      {...rest}
    >
      {children}
    </CustomIconButton>
  )
}
)

IconButtonComponent.displayName = 'IconButtonComponent';
