import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';

interface ISavingOptionsResponse {
  _id: string
  label: string
  period: number
  durationType: string
  percent: number
  order: number
  isFlexible: boolean
  status: boolean
  __v: number
}

const initialState: ISavingOptionsResponse[] = [];

export const getSavingOptions = createAsyncThunk(
  'saving-options/get',
  async () => {
    const response = await apiHelper({ method: 'get', path: `/saving-option?isFlexible=${false}` });

    return response.data.options;
  }
);

export const savingOptionsSlice = createSlice({
  name: 'saving-options',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSavingOptions.fulfilled, (state, action: PayloadAction<ISavingOptionsResponse[]>) => {
      return action.payload;
    });
  }
});

export default savingOptionsSlice.reducer;
