import Big from 'big.js';

export const getSpread = (ask: number, bid: number): number => {
  return ask - bid;
};

export const getPosition = (lotSize: number, volume: number): number => {
  return lotSize * volume;
};

export const getMargin = (position: number, bid: number, leverage: number): number => {
  return (position * bid) / leverage;
};

export const getEquity = (avalibleBalance: number, pnl: number, credit: number): number => avalibleBalance + pnl + credit;

export const getFreeMargin = (equity: number, investedBalance: number): number => equity - investedBalance;

export const getCurrencyVolume = (volume: number, leverage: number, bid: number): number => {
  return ((volume / leverage) / bid) * leverage;
};

export const getUnitsVolume = (volume: number, lotSize: number): number => {
  return volume / lotSize;
};

export const getLots = (volume: number): number => volume ?? 0;

export const calcTpProfit = (volume: number | string, tradeMode: boolean, takeProfit: number | string, price: number | string): Big => {
  if (volume === null || volume === undefined || takeProfit === undefined || price === undefined) {
    return Big(0);
  }
  return Big(volume).times(tradeMode ? Big(takeProfit).minus(price) : Big(price).minus(takeProfit));
};

export const calcSlLoss = (volume: number | string, tradeMode: boolean, stopLoss: number | string, price: number | string): Big => {
  if (volume === null || volume === undefined || stopLoss === undefined || price === undefined) {
    return Big(0);
  }
  return Big(volume).times(tradeMode ? Big(price).minus(stopLoss) : Big(stopLoss).minus(price));
};

export const calcRequiredMargin = (price: number | string, volume: number | string, lotSize: number, leverage: number): Big => {
  if (price === null || price === undefined || volume === undefined || lotSize === undefined || leverage === undefined) {
    return Big(0);
  }
  return Big(price).times(volume).times(lotSize).div(leverage);
};

export const calcMaxPosition = (freeMargin: number, leverage: number): Big => {
  if (freeMargin === null || freeMargin === undefined || leverage === undefined) {
    return Big(0);
  }
  return Big(freeMargin).times(leverage);
};

export const calcNetProfit = (volume: number | string, price: number | string, openingPrice: number): Big => {
  if (volume === null || volume === undefined || price === undefined || openingPrice === undefined) {
    return Big(0);
  }
  return Big(volume).times(Big(price).minus(openingPrice));
};

export const calcInitialMargin = (openingPrice: number, volume: number | string, lotSize: number, leverage: number): Big => {
  if (openingPrice === null || openingPrice === undefined || volume === undefined || lotSize === undefined || leverage === undefined) {
    return Big(0);
  }
  return Big(openingPrice).times(volume).times(lotSize).div(leverage);
};

export const calcRoe = (netProfit: Big, initialMargin: Big): Big => {
  if (initialMargin.eq(0)) return Big(0);
  return netProfit.div(initialMargin).times(100);
};

export const calcPipSize = (precision: number): Big => {
  if (precision <= 0) return Big(0);
  return Big(1).div(Big(10).pow(precision - 1));
};

export const calcPipValue = (pipSize: Big, volume: Big, price: Big): Big => {
  if (price.eq(0)) return Big(0);
  return pipSize.times(volume).div(price);
};
