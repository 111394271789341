import React, { type FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import { calcSavingFlexibleEarnings, calculateSavingFlexibleLockPeriod } from '../../../../../helpers/util';
import { type StyledAccordionSummaryProps, type IAccountsTableCell } from '../../../interface/savingsPageInterfaces';
import { AccardionMainContent } from '../components/AccardionMainContent/AccardionMainContent';
import { AccardionExpandContent } from '../components/AccardionExpandContent/AccardionExpandContent';
import { MobileAccardionMainContent } from '../components/MobileAccardionMainContent/MobileAccardionMainContent.';
import { MobileAccardionExpandContent } from '../components/MobileAccardionExpandContent/MobileAccardionExpandContent';

const StyledAccordionSummary = styled(AccordionSummary)<StyledAccordionSummaryProps>(({ expanded = 'false', istable = 'false' }) => {
  if (expanded === 'true') {
    return {
      backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_08)',
      color: 'var(--CFD-theme-System-OnSurface)',
      borderRadius: '6px',
      padding: istable === 'true' ? '12px' : '0 24px',
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      '& .MuiAccordionSummary-expandIconWrapper': {
        display: 'flex',
        alignSelf: istable === 'true' ? 'flex-start' : 'unset',
        paddingTop: istable === 'true' ? '10px' : '0'
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
        height: istable === 'true' ? '100%' : 'unset'
      },
      '&:hover': {
        backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_08)'
      }
    }
  }

  return {
    backgroundColor: 'var(--CFD-theme-Surface-3)',
    color: 'var(--CFD-theme-System-OnSurface)',
    borderRadius: '6px',
    padding: istable === 'true' ? '12px' : '0 24px',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    '& .MuiAccordionSummary-expandIconWrapper': {
      display: 'flex',
      alignSelf: istable === 'true' ? 'flex-start' : 'unset'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      height: istable === 'true' ? '100%' : 'unset'
    },
    '&:hover': {
      backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_08)'
    }
  }
})

export const AccountsTableCell: FC<IAccountsTableCell> = ({
  row, expanded = false, onClick, isFlexible, isTable, setOpenRedeemModal, setSelectedInvestment, setSavingAccountId
}) => {
  const { createdAt, investedAmount, pct, currency, isReleased, releaseTime, period, cumulativeTotal } = row;

  const fixedRangeFiat = 3;
  const fixedRangeCrypto = 4;
  const lockedPeriodInDays = calculateSavingFlexibleLockPeriod(createdAt);
  const earnings = (currency.isFiat
    ? Number(calcSavingFlexibleEarnings(investedAmount, pct, lockedPeriodInDays, fixedRangeFiat))
    : Number(calcSavingFlexibleEarnings(investedAmount, pct, lockedPeriodInDays, fixedRangeCrypto)));

  return (
    <>
      <Accordion
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          border: 'none',
          borderRadius: '6px',
          backgroundColor: 'transparent',
          '&.MuiPaper-root': {
            backgroundColor: 'var(--CFD-theme-Surface-3)'
          },
          '@media (max-width: 900px)': {
            width: '100% !important',
            maxWidth: 'calc(100vw - 180px)'
          },
          '@media (max-width: 599px)': {
            width: '100% !important',
            maxWidth: 'calc(100vw - 67px)'
          }
        }}
      >
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          onClick={ onClick }
          expanded={`${expanded}`}
          istable={ `${isTable}` }
        >
          { !isTable
            ? <AccardionMainContent
                investedAmount={ investedAmount }
                currencySymbol={ currency.symbol }
                isFlexible={ isFlexible }
                period={ period }
                pct={ pct }
                createdAt={ createdAt }
                releaseTime={ releaseTime }
                isReleased={ isReleased }
                cumulativeTotal={ cumulativeTotal }
              />
            : <MobileAccardionMainContent
                investedAmount={ investedAmount }
                currencySymbol={ currency.symbol }
                isFlexible={ isFlexible }
                period={ period }
                pct={ pct }
                createdAt={ createdAt }
                releaseTime={ releaseTime }
                isReleased={ isReleased }
                cumulativeTotal={ cumulativeTotal }
              />
          }
        </StyledAccordionSummary>
        <AccordionDetails sx={{ padding: 0, borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }}>
          {
            !isTable
              ? (
                <AccardionExpandContent
                  earnings={ earnings }
                  cumulativeTotal={ cumulativeTotal }
                  createdAt={ createdAt }
                  isFlexible={ isFlexible }
                  releaseTime={ releaseTime }
                  isReleased={ isReleased }
                  row={ row }
                  setSelectedInvestment={ setSelectedInvestment }
                  setOpenRedeemModal={ setOpenRedeemModal }
                  setSavingAccountId={ setSavingAccountId }
                />
                )
              : (
                  <MobileAccardionExpandContent
                    earnings={ earnings }
                    cumulativeTotal={ cumulativeTotal }
                    createdAt={ createdAt }
                    period={ period }
                    isFlexible={ isFlexible }
                    releaseTime={ releaseTime }
                    isReleased={ isReleased }
                    row={ row }
                    setSelectedInvestment={ setSelectedInvestment }
                    setOpenRedeemModal={ setOpenRedeemModal }
                    setSavingAccountId={ setSavingAccountId }
                  />
                )
          }
        </AccordionDetails>
      </Accordion>
    </>
  )
}
