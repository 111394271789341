import React, { type FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getStatistic } from '../../../redux/reducers/profile';
import { getProfileSelectedTab } from '../../../redux/selectors/profile';
import { type TabInterface } from '../../../redux/reducers/profile';
import { ProgressComponent } from '../../../components';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ProfitableOrdersIcon from '../../../assets/images/Monitoring.svg';
import FinanceIcon from '../../../assets/images/Finance Mode.svg';
import DepositIcon from '../../../assets/images/deposit.svg';
import styles from './Statistic.module.css';
import { t } from 'i18next'

export const Statistic: FC = () => {
  const dispatch = useAppDispatch();
  const { statistics, isStatiscticLoading } = useAppSelector((state) => state.profile);
  const activeAccount: TabInterface | null = useAppSelector(getProfileSelectedTab)

  useEffect(() => {
    if (activeAccount !== null) {
      dispatch(getStatistic(activeAccount.accountId))
    }
  }, [activeAccount]);

  return (
    <div className={styles.statisticsContent}>
        <div className={styles.statisticBlock}>
            <div className={styles.statisticBlockHeader}>
                <div className={styles.statisticBlockHeaderIcon}>
                    <AccountBalanceWalletOutlinedIcon />
                </div>
                {t('profile_page.labels.total_balance')}
            </div>
            <div className={styles.statisticBlockRow}>
                <div className={styles.statisticBlockColumn}>
                    <span className={styles.statisticBlockValue}>{activeAccount?.currency ?? ''}</span>
                    <span className={styles.statisticBlockSubValue}>{t('profile_page.labels.in_usd')}</span>
                </div>
                <div className={styles.statisticBlockColumn}>
                    {
                        isStatiscticLoading
                          ? <ProgressComponent type='circular' />
                          : (
                            <>
                                <span className={styles.statisticBlockValue}>{statistics?.totalBalance.value ?? '0.00'}</span>
                                <span className={styles.statisticBlockSubValue}>{statistics?.totalBalance.valueInUSD ?? '0.00'}</span>
                            </>
                            )
                    }
                </div>
            </div>
        </div>
        <div className={styles.statisticBlock}>
            <div className={styles.statisticBlockHeader}>
                <div className={styles.statisticBlockHeaderIcon}>
                    <svg>
                        <use xlinkHref={`${DepositIcon}#deposit`}></use>
                    </svg>
                </div>
                {t('profile_page.labels.total_deposit')}
            </div>
            <div className={styles.statisticBlockRow}>
                <div className={styles.statisticBlockColumn}>
                    <span className={styles.statisticBlockValue}>{activeAccount?.currency ?? ''}</span>
                    <span className={styles.statisticBlockSubValue}>{t('profile_page.labels.in_usd')}</span>
                </div>
                <div className={styles.statisticBlockColumn}>
                    {
                        isStatiscticLoading
                          ? <ProgressComponent type='circular' />
                          : (
                            <>
                                <span className={styles.statisticBlockValue}>{statistics?.totalDeposits.value ?? '0.00'}</span>
                                <span className={styles.statisticBlockSubValue}>{statistics?.totalDeposits.valueInUSD ?? '0.00'}</span>
                            </>
                            )
                    }
                </div>
            </div>
        </div>
        <div className={styles.statisticBlock}>
            <div className={styles.statisticBlockHeader}>
                <div className={styles.statisticBlockHeaderIcon}>
                    <svg>
                        <use xlinkHref={`${ProfitableOrdersIcon}#Monitoring`}></use>
                    </svg>
                </div>
                {t('profile_page.labels.profitable_orders')}
            </div>
            <div className={styles.statisticBlockRow}>
                <span className={styles.statisticBlockSubValue}>{t('profile_page.labels.profitable_total_orders')}</span>
                <span className={styles.statisticBlockValue}>
                    {
                        isStatiscticLoading
                          ? <ProgressComponent type='circular' />
                          : (
                            `${statistics?.profitableOrders.profitable ?? 0} / ${statistics?.profitableOrders.total ?? 0}`
                            )
                    }
                </span>
            </div>
        </div>
        <div className={styles.statisticBlock}>
            <div className={styles.statisticBlockHeader}>
                <div className={styles.statisticBlockHeaderIcon}>
                    <svg>
                        <use xlinkHref={`${FinanceIcon}#Finance Mode`}></use>
                    </svg>
                </div>
                {t('profile_page.labels.total_pnl')}
            </div>
            <div className={styles.statisticBlockRow}>
                <div className={styles.statisticBlockColumn}>
                    <span className={styles.statisticBlockValue}>{activeAccount?.currency ?? ''}</span>
                    <span className={styles.statisticBlockSubValue}>{t('profile_page.labels.in_usd')}</span>
                </div>
                <div className={styles.statisticBlockColumn}>
                    {
                        isStatiscticLoading
                          ? <ProgressComponent type='circular' />
                          : (
                            <>
                                <span className={styles.statisticBlockValue}>{statistics?.totalPnL.value ?? '0.00'}</span>
                                <span className={styles.statisticBlockSubValue}>{statistics?.totalPnL.valueInUSD ?? '0.00'}</span>
                            </>
                            )
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
