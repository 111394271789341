import React, { type FC, type ChangeEvent, useContext } from 'react';
import { RadioGroup, FormControlLabel, FormControl, styled, Typography } from '@mui/material';
import { ButtonComponent, RadioButtonComponent } from '../../../../../components';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { t } from 'i18next';
import { deleteWallet, type IWallet } from '../../../../../redux/reducers/withdrawalMethods';
import { useAppDispatch } from '../../../../../hooks/redux';
import style from './SavedCryptoWithdrawalOptions.module.css';
import { DialogContext } from '../../../context/DialogContextProvider';

interface InterfaceSavedCryptoWithdrawalOptions {
  options: IWallet[]
  selectedOptions: string
  setSelectedOptions: (el: string) => void
}

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: 'var(--CFD-theme-Surface-3)',
  borderRadius: '6px'
}))

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center'
}))

export const SavedCryptoWithdrawalOptions: FC<InterfaceSavedCryptoWithdrawalOptions> = ({ options, selectedOptions, setSelectedOptions }) => {
  const dispatch = useAppDispatch()
  const { showDialog, hideDialog } = useContext(DialogContext);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedOptions(event.target.value);
  };

  const handleDeleteBtn = (cardId: string): void => {
    dispatch(deleteWallet(cardId));
    setSelectedOptions('');
    hideDialog();
  }

  return (
    <StyledFormControl>
      <StyledRadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={ selectedOptions ?? '' }
        onChange={ handleChange }
      >
        {options.map((el, index) => (
          <FormControlLabel
            sx={{
              display: 'flex',
              border: `1px solid ${selectedOptions === el._id ? 'var(--CFD-theme-System-Tertiary)' : 'var(--CFD-theme-System-OutlineVariant)'}`,
              borderRadius: index === 0 ? '6px 6px 0 0' : index === options.length - 1 ? '0 0 6px 6px' : '0',
              paddingTop: '8px',
              paddingLeft: '8px',
              paddingBottom: '8px',
              paddingRight: '16px',
              marginLeft: '0',
              marginRight: '0',
              width: '100%',
              '&:hover': {
                backgroundColor: 'var(--CFD-theme-State-layers-on-surface-variant-opacity_08)'
              }
            }}
            key={ el._id }
            value={ el._id }
            control={
              <RadioButtonComponent
                checked={ selectedOptions === el._id }
                onChange={ handleChange }
                value={ el._id ?? ''}
              />
            }
            label={
              <Typography component='div' sx={{ width: '100%' }}>
                <div className={ style.assetsCheckContainer }>
                  <div className={ style.assetsContainer }>
                    <div className={ style.ibanSwiftContainer }>
                      <span className={ style.ibanSwift }>
                        <p className={ style.ibanSwiftTitle }>Coin</p>
                        <p className={ style.bankName }>{ el.currency.symbol }</p>
                      </span>
                      {/* <span className={ style.ibanSwift }>
                        <p className={ style.ibanSwiftTitle }>Network</p>
                        <p className={ style.ibanSwiftText }>{ el.network }</p>
                      </span> */}
                    </div>
                    <span className={ style.ibanSwift }>
                      <p className={ style.ibanSwiftText }>{ el.address }</p>
                    </span>
                  </div>
                  <div className={ style.binWrapper }>
                    {
                      <DeleteOutlineOutlinedIcon onClick={(e) => {
                        e.preventDefault()

                        showDialog({
                          headerContent: <div className={style.dialogHeader}>
                            {t('accounts_page.are_you_sure_question')}
                          </div>,
                          footerContent: <div className={style.dialogFooter}>
                            <ButtonComponent btnstyle='primary' variant="text" onClick={hideDialog}>
                              {t('accounts_page.cancel')}
                            </ButtonComponent>
                            <ButtonComponent btnstyle='primary' variant="text" onClick={() => { handleDeleteBtn(el._id); }}>
                              {t('accounts_page.confirm')}
                            </ButtonComponent>
                          </div>
                        });
                      }}
                      sx={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }} />
                    }
                    </div>
                </div>
              </Typography>
            }
          />
        ))}
      </StyledRadioGroup>
    </StyledFormControl>
  )
}
