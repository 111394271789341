import React, { type FC, useState, type SyntheticEvent, useEffect, useMemo } from 'react';
import { type PayloadInterface, type OptionInterface } from '../../../interfaces';
import Accordion from '@mui/material/Accordion';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import { AccordionSummary, AccordionDetails, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getAccounts, transferAmount, type IAccount } from '../../../redux/reducers/accounts';
import { BetweenAcountsForm } from './BetweenAccountsForm/BetweenAccountsForm';
import { ChangeIcon } from '../../../components/icons/ChangeIcon';
import { ButtonComponent, ModalTransferFunds, useSnackbar } from '../../../components';

import style from './BetweenAcountsSection.module.css';
import { getCurrencyRates } from '../../../redux/reducers/currencyRates';
import { handleError } from '../../../helpers';
import { getCurrencySymbol, toFixed } from '../../../helpers/util';
import { t } from 'i18next';

export const StyledAccardionWrapper = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'var(--CFD-theme-Surface-2)',
  color: 'var(--CFD-theme-System-OnSurface)',
  border: '1px solid var(--CFD-theme-State-layers-outline-opacity_12)',
  borderRadius: '6px',
  width: '100%'
}));

export const StyledAccardionSummary = styled(AccordionSummary)(({ theme }) => ({
  height: '70px',
  display: 'flex',
  flexDirection: 'row',
  fontFamily: 'Arial',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '32px'
}))

export const StyledDetailsSummary = styled(AccordionDetails)(({ theme }) => ({
  height: 'auto',
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: '16px',
  paddingBottom: '0'
}))

const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
  color: 'var(--CFD-theme-System-OnSurface)'
});

interface InterfaceBetweenAcountsSection {
  accardionId: string
  setShowTableBody: (el: boolean) => void
}

export const BetweenAcountsSection: FC<InterfaceBetweenAcountsSection> = ({ accardionId, setShowTableBody }) => {
  const dispatch = useAppDispatch();
  const { handleOpen: handleOpenSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState<boolean | string>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [fromAccount, setFromAccount] = useState<IAccount | null>(null);
  const [toAccount, setToAccount] = useState<IAccount | null>(null);
  const [toAccountValue, setToAccountValue] = useState<number>(0);
  const [fromAccountValue, setFromAccountValue] = useState<number>(0);
  const [step, setStep] = useState<number>(1);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
  const [errorAmount, setErrorAmount] = useState<boolean>(false);
  const [amountFromValue, setAmountFromValue] = useState('');
  const [amountToValue, setAmountToValue] = useState('');
  const accounts = useAppSelector((state) => state.accounts);
  const { rates } = useAppSelector(state => state.currencyRates);
  const { _id: userId } = useAppSelector((state) => state.user);

  const options: OptionInterface[] = useMemo((): OptionInterface[] => {
    return accounts.map((account) => ({
      label: `${account?.type?.name ?? ''} ${t('labels.account')} ${account?.cfdAccountCurrency?.symbol ?? ''}`,
      value: account._id,
      symbol: account?.cfdAccountCurrency?.symbol ?? '',
      amount: `${getCurrencySymbol(account?.cfdAccountCurrency?.symbol ?? '')} ${toFixed((account?.balance ?? 0), 2)}`
    }))
  }, [accounts]);

  useEffect(() => {
    if (fromAccount === null) return;

    const getRates = async (): Promise<void> => {
      setIsBtnDisabled(true);
      await dispatch(getCurrencyRates(fromAccount.cfdAccountCurrency.symbol));
      setIsBtnDisabled(false);
    }

    getRates();
  }, [fromAccount]);

  useEffect(() => {
    const toRate = rates?.[toAccount?.cfdAccountCurrency.symbol ?? ''];

    if (toRate === undefined) {
      setToAccountValue(0);

      return;
    }

    setToAccountValue(Number(fromAccountValue) * toRate)
  }, [rates, fromAccountValue, toAccount]);

  useEffect(() => {
    accounts.forEach((account) => {
      if (account.isActive) {
        setFromAccount(account)
      } else if (toAccount === null) {
        setToAccount(account);
      }
    })
  }, [accounts]);

  useEffect(() => {
    if (fromAccount?.balance !== undefined) setErrorAmount(Number(amountFromValue) > fromAccount?.balance);
  }, [amountFromValue, fromAccount])

  useEffect(() => {
    if (typeof expanded === 'string' && expanded.length > 0) setShowTableBody(expanded.length === 0);

    const timeoutId = setTimeout(() => {
      if (expanded === accardionId) {
        const documentHeight = document.documentElement.scrollHeight;
        window.scrollTo({
          top: documentHeight,
          behavior: 'smooth'
        });
      }
    }, 300);

    return () => { clearTimeout(timeoutId); };
  }, [expanded, accardionId]);

  const changeBtnHandle = (): void => {
    setFromAccount(toAccount);
    setToAccount(fromAccount);
  }

  const handleStateAfterTransfer = (shouldOpenTable: boolean): void => {
    if (shouldOpenTable) {
      setShowTableBody(true)
      setExpanded(false);
    }

    setFromAccountValue(0);
    setStep(1);
  }

  const handleAccardionChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean): void => {
    if (isExpanded) {
      setShowTableBody(false)
    }

    setShowTableBody(true)
    setExpanded(isExpanded ? panel : false);
  };

  const transferFundsBtnHandler = (): void => {
    setOpenModal(true);
  }

  const findToRate = (): number => {
    const rate = rates?.[toAccount?.cfdAccountCurrency.symbol ?? ''];

    return rate ?? 0
  }

  const handleChangeToAmount = (value: string): void => {
    setFromAccountValue(Number(value));
    const toRate = rates?.[toAccount?.cfdAccountCurrency.symbol ?? ''];

    if (toRate === undefined) {
      setToAccountValue(0);

      return;
    }

    setToAccountValue(Number(value) * toRate)
  }

  const handleTransfer = async (): Promise<void> => {
    if (userId !== undefined && fromAccount !== null && toAccount !== null) {
      setIsBtnLoading(true);

      const response = await dispatch(transferAmount({ userId, amount: fromAccountValue, fromAccountId: fromAccount._id, toAccountId: toAccount._id }));
      const payload = response.payload as PayloadInterface;

      if (payload.isError) {
        handleError(payload, handleOpenSnackbar);
        setIsBtnLoading(false);

        return;
      }

      await dispatch(getAccounts(userId));

      const openParams = {
        message: payload.data,
        actionText: '',
        autoClose: true
      }

      handleOpenSnackbar(openParams);
      setIsBtnLoading(false);
      setStep(2);
    }
  }

  return (
    <>
      <ModalTransferFunds
        open={ openModal }
        setOpen={ setOpenModal }
        fromSymbol={fromAccount?.cfdAccountCurrency.symbol ?? ''}
        toSymbol={toAccount?.cfdAccountCurrency.symbol ?? ''}
        fromValue={fromAccountValue}
        toValue={toAccountValue}
        isLoading={isBtnLoading}
        step={step}
        onSubmit={handleTransfer}
        handleStateAfterTransfer={handleStateAfterTransfer}
      />
      <StyledAccardionWrapper
        expanded={expanded === accardionId}
        onChange={handleAccardionChange(accardionId)}
      >
        <StyledAccardionSummary
          expandIcon={<StyledExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-content"
        >
          {t('accounts_page.transfer_component.title')}
        </StyledAccardionSummary>
        <StyledDetailsSummary>
          <div className={ style.transferWrapper }>
            <div className={ style.transferForm }>
              <p className={ style.label } >{t('labels.from')}:</p>
              <BetweenAcountsForm
                options={options}
                fromAccount={fromAccount}
                toAccount={toAccount}
                changeToValue={handleChangeToAmount}
                direction='from'
                error={ errorAmount }
                value={ amountFromValue }
                setValue={ setAmountFromValue }
                changeOption={(value) => { setFromAccount(value); }}
              />
            </div>
            <button className={ style.changeBtn } onClick={ changeBtnHandle } disabled={isBtnDisabled}>
              <ChangeIcon color={!isBtnDisabled ? 'var(--CFD-theme-System-OnSurface)' : 'var(--CFD-theme-State-layers-on-surface-opacity_12)'}/>
            </button>
            <div className={ style.transferForm }>
              <p className={ style.label } >{t('labels.to')}:</p>
              <BetweenAcountsForm
                options={options}
                fromAccount={fromAccount}
                toAccountValue={toAccountValue}
                toAccount={toAccount}
                direction='to'
                value={ amountToValue }
                setValue={ setAmountToValue }
                changeOption={(value) => { setToAccount(value); }}
               />
            </div>
          </div>
          <div className={ style.footerWrapper }>
            <div className={ style.rateContainer }>
              <div className={ style.rate }>
              <span className={ style.rateFrom }>1</span>
              <span className={ style.rateFrom }>{fromAccount?.cfdAccountCurrency.symbol}</span>
              <span className={ style.rateFrom }>=</span>
              <span className={ style.rateTo }>{findToRate()}</span>
              <span className={ style.rateTo }>{toAccount?.cfdAccountCurrency.symbol}</span>
            </div>
              {/* <span className={ style.feeContainer }>Exchange Fee: 0%</span> */}
            </div>
            <div>
              <ButtonComponent onClick={ transferFundsBtnHandler } disabled={toAccountValue === 0 || isBtnDisabled}>
                {t('accounts_page.transfer_component.transfer_button_text')}
              </ButtonComponent>
            </div>
          </div>
        </StyledDetailsSummary>
      </StyledAccardionWrapper>
    </>
  );
}
