import React, { type FC } from 'react';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

import { CurrencySelect } from '../../../components';

import style from './AccountsHeaderComponent.module.css';
import { type IAccount } from '../../../redux/reducers/accounts';
import { t } from 'i18next';

interface InterfaceAccountsHeaderComponent {
  currency: string
  setCurrency: (el: string) => void
  accounts: IAccount[]
}

export const AccountsHeaderComponent: FC<InterfaceAccountsHeaderComponent> = ({ currency, setCurrency, accounts }) => {
  return (
    <header className={ style.header }>
      <div className={ style.headerContainer }>
        <h1 className={ style.headerTitle }>{t('labels.account')}</h1>
        <p className={ style.headerSubTitle }>
          {t('accounts_page.subtitle')}
        </p>
      </div>
      <div className={ style.totalSavingsContainer }>
        <div className={ style.totalSavings }>
          <p className={ style.totalSavingsParagraph }>{t('labels.your_selected_account')}:</p>
        </div>
        <div className={ style.currencyContainer }>
          <DoneOutlinedIcon fontSize='small' sx={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }} />
          <CurrencySelect id={ 'currencyselect' } currency={ currency } setCurrency={ setCurrency } accounts={accounts} />
        </div>
      </div>
    </header>
  )
}
