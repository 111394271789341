import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';

interface IWallet {
  _id: string
  adminAddressId: string
  currencyId: string
  address: string
}

export interface IWalletPayload {
  wallet: IWallet
  temporary: boolean
  success: boolean
}

const initialState: IWalletPayload = {
  wallet: {
    _id: '',
    adminAddressId: '',
    currencyId: '',
    address: ''
  },
  temporary: true,
  success: false
};

export const getBTCWalletByUserId = createAsyncThunk(
  'wallet/get-btc',
  async (userId: string) => {
    const response = await apiHelper({ method: 'get', path: `/wallet/btc-wallet/${userId}` });

    return response.data;
  }
);

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    clearWalletData () {
      return {
        wallet: {
          _id: '',
          adminAddressId: '',
          currencyId: '',
          address: ''
        },
        temporary: true,
        success: false
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBTCWalletByUserId.fulfilled, (state, action: PayloadAction<IWalletPayload>) => {
      return action.payload;
    });
  }
});
export const { clearWalletData } = walletSlice.actions
export default walletSlice.reducer;
