import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';

export interface IPaymentService {
  _id?: string
  type?: string
  name?: string
  currencies?: [string]
}

const initialState: IPaymentService[] = [];

export const getPaymentServices = createAsyncThunk(
  'paymentServices/get',
  async ({ type, brandId }: { type: string, brandId: string }) => {
    const response = await apiHelper({ method: 'get', path: `/payment-service/query?type=${type}&brandId=${brandId}` });

    return response.data.paymentServices;
  }
)

export const createPRMPayment = createAsyncThunk(
  'paymentServices/create/prm',
  async ({ accountId, amount, currency, paymentId }: { accountId: string, amount: number, currency: string, paymentId: string }) => {
    try {
      const response = await apiHelper({ method: 'post', path: '/payment-service/create-prm-payment', data: { accountId, amount, currency, paymentId } });

      window.open(response.data.url as string)

      return response.data.url;
    } catch (error) {
      console.error(error)
    }
  }
)

export const paymentServicesSlice = createSlice({
  name: 'paymentServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentServices.fulfilled, (state, action: PayloadAction<IPaymentService[]>) => {
      return action.payload;
    });
  }
});

export default paymentServicesSlice.reducer;
