import React, { type FC, useState, type ChangeEvent, useMemo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ButtonComponent, InputComponent, SelectComponent, useSnackbar } from '../../../../components';
import { getFiatCurrencies } from '../../../../redux/reducers/currencies';
import { addBank } from '../../../../redux/reducers/withdrawalMethods';
import style from './AddBankFormComponent.module.css';
import { type PayloadInterface } from '../../../../interfaces';
import { handleError } from '../../../../helpers';
import { t } from 'i18next';

interface InterfaceAddBankFormComponent {
  setAddWithdrawalMethod: (el: boolean) => void
}

export const AddBankFormComponent: FC<InterfaceAddBankFormComponent> = ({ setAddWithdrawalMethod }) => {
  const dispatch = useAppDispatch();
  const { handleOpen: handleOpenSnackbar } = useSnackbar();
  const [bankName, setBankName] = useState<string>('')
  const [iban, setIban] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [bankAddress, setBankAddress] = useState<string>('');
  const [swift, setSwift] = useState<string>('');
  const [currencySelected, setCurrencySelected] = useState<string>('');
  const [ibanError, setIbanError] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const currencies = useAppSelector((state) => state.currencies);
  const { _id: userId } = useAppSelector((state) => state.user);

  const options = useMemo(() => {
    return currencies.map((currency) => ({ label: currency.name, value: currency._id, symbol: currency.symbol }))
  }, [currencies]);

  useEffect(() => {
    dispatch(getFiatCurrencies());
  }, []);

  const handleAddMethod = async (): Promise<void> => {
    const data = {
      name: bankName,
      iban,
      accountNumber,
      bankAddress,
      swiftCode: swift,
      currencyId: currencySelected,
      userId
    };

    const response = await dispatch(addBank(data));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      handleError(payload, handleOpenSnackbar);
      setIsBtnLoading(false);

      return;
    }

    setIsBtnLoading(false);
    setAddWithdrawalMethod(false);
  }

  const ibanHandleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target: { value: ibanValue } } = event;

    if (ibanValue.length <= 34) {
      setIban(ibanValue);
      setIbanError(false);
    } else {
      setIbanError(true);
    }
  };
  const formComplete = (bankName.length > 0 && iban.length > 0 && accountNumber.length > 0 && bankAddress.length > 0 && swift.length > 0 && currencySelected.length > 0)

  return (
  <>
    <div className={ style.wrapper }>
      <h3 className={ style.mainTitle }>{t('withdrawal_modal.add_bank_form_modal.title')}</h3>
      <div className={ style.formContainer }>
        <div className={ style.inputContainer }>
          <label className={ style.label }>{t('labels.bank_name')}</label>
          <InputComponent value={ bankName } setValue={ setBankName }/>
        </div>
        <div className={ style.inputContainer }>
          <label className={ style.label }>{t('labels.iban')}</label>
          <InputComponent value={ iban } setValue={ setIban } errorText='IBAN contains 34 letters and numbers' error={ ibanError } onChange={ ibanHandleChange }/>
        </div>
        <div className={ style.inputContainer }>
          <label className={ style.label }>{t('labels.account_number')}</label>
          <InputComponent value={ accountNumber } setValue={ setAccountNumber }/>
        </div>
        <div className={ style.inputContainer }>
          <label className={ style.label }>{t('labels.bank_address')}</label>
          <InputComponent value={ bankAddress } setValue={ setBankAddress}/>
        </div>
        <div className={ style.inputContainer }>
          <label className={ style.label }>{t('labels.swift')}</label>
          <InputComponent value={ swift } setValue={ setSwift }/>
        </div>
        <div className={ style.inputContainer }>
          <label htmlFor='selectCurrency' className={ style.label }>{t('labels.currency')}</label>
          <SelectComponent options={ options } value={ currencySelected } onChange={({ target: { value } }) => { setCurrencySelected(value as string); } } id='selectCurrency'/>
        </div>
      </div>
    </div>
    <footer className={ style.footer }>
      <div className={ style.btnContainer }>
        <ButtonComponent onClick={() => { handleAddMethod(); } } disabled={ !formComplete } loading={isBtnLoading}>
          <span className={ style.btnText }>
            {t('withdrawal_modal.add_bank_form_modal.action_button_text')}
          </span>
        </ButtonComponent>
      </div>
    </footer>
  </>
  )
}
