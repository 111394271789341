import React, { type FC } from 'react';

import { Step1SectionModalRedeem } from '../Steps/Step1/Section/Step1SectionModalRedeem';
import { Step2SectionModalRedeem } from '../Steps/Step2/Section/Step2SectionModalRedeem';
import { type SavingInvestment } from '../../../interfaces';

interface InterfaceSectionModalRedeem {
  step: number
  currency: string
  setCurrency: (el: string) => void
  selectedInvestment: SavingInvestment | null
  isFlexible: boolean | null
}

export const SectionModalRedeem: FC<InterfaceSectionModalRedeem> = ({ step, currency, setCurrency, selectedInvestment, isFlexible }) => {
  if (step === 1) {
    return (
      <Step1SectionModalRedeem selectedInvestment={ selectedInvestment } currency={ currency } setCurrency={ setCurrency } isFlexible={ isFlexible }/>
    )
  }
  return (
    <Step2SectionModalRedeem selectedInvestment={selectedInvestment} currency={currency} isFlexible={ isFlexible } />
  )
}
