import React from 'react';
import { Tab, styled } from '@mui/material';

interface TabInterface {
  label?: string
  icon?: JSX.Element
}

const CustomizedTab = styled(Tab)({
  color: 'var(--CFD-theme-System-OnSurfaceVariant)',
  'text-transform': 'capitalize',
  background: 'var(--CFD-theme-System-Surface)',
  '&.Mui-selected': {
    color: 'var(--CFD-theme-System-Tertiary)'
  },
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_08)',
    color: 'var(--CFD-theme-System-Tertiary-fixed)'
  }
});

export const TabComponent: React.FC<TabInterface> = ({ label, ...rest }) => {
  return (
    <CustomizedTab label={label} {...rest}/>
  );
}
