import { useState, useEffect } from 'react';

type TypeUseWindowSize = [number, number];

export const useWindowSize = (): TypeUseWindowSize => {
  const [size, setSize] = useState<TypeUseWindowSize>([0, 0]);

  useEffect(() => {
    const updateSize = (): void => {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => { window.removeEventListener('resize', updateSize); };
  }, []);

  return size;
}
