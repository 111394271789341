import React, { type FC } from 'react'
import style from './CompleteProgress.module.css'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { ProgressComponent } from '../../../../components';
import { t } from 'i18next';

interface Props {
  completedPercent?: number
}

export const CompleteProgress: FC<Props> = (props) => {
  const { completedPercent = 0 } = props

  return (
    <div className={style.profileCompiled}>
      <div className={style.completeTitle}>
        <span>
          {t('profile_page.labels.profile_completed', { completedPercent })}
        </span>
      </div>
      <div className={style.completeProgress}>
        <div className={style.progressBarLine}>
          <ProgressComponent type='linear' variant="determinate" value={completedPercent} sx={{
            width: 'auto',
            '@media screen and (max-width: 600px)': {
              width: '325px'
            },
            '@media screen and (max-width: 420px)': {
              width: '270px'
            },
            '@media screen and (max-width: 370px)': {
              width: '220px'
            }
          }} />
        </div>
        <div className={style.verifyIconBlock}>
          <div className={style.verifyIconWrapper}>
            <VerifiedUserOutlinedIcon sx={{ width: '15px', height: '15px' }} />
          </div>
        </div>
      </div>
    </div>
  )
}
