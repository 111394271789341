import React, { type ReactNode, type FC } from 'react';
import styles from './Wrapper.module.css';

interface WrapperInterface {
  children: ReactNode
}

export const Wrapper: FC<WrapperInterface> = ({ children }): JSX.Element => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.gradientWrapper}>
        { children }
      </div>
    </div>
  )
}
