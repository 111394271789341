import React, { type FC } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import style from './HeaderModalAutoTrader.module.css';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { t } from 'i18next';

interface HeaderModalAutoTraiderInterface {
  setOpen: (el: boolean) => void
}

export const HeaderModalAutoTrader: FC<HeaderModalAutoTraiderInterface> = ({
  setOpen
}) => {
  const handleBtnClick = (): void => {
    setOpen(false)
  }

  const [widthScreen] = useWindowSize();

  const isMobile = (widthScreen <= 905);

  return (
    <header className={ style.dialogHeader }>
      <div className={ style.dialogHeaderContainer }>
        <div className={ style.dialogHeaderContent }>
          {!isMobile
            ? (
                <h3 className={style.dialogHeaderTitle}>
                  {t('trading_page.labels.autoTrader')}
                </h3>
              )
            : (
              <>
                <button className={ style.buySellContainerBackBtn } onClick={ handleBtnClick }>
                  <ArrowBackIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                </button>
                <h3 className={style.dialogHeaderTitle}>
                  {t('trading_page.labels.trading')}
                </h3>
              </>
              )}
        </div>
        <div className={ style.dialogHeaderBtnContainer }>
          <CloseOutlinedIcon onClick={ handleBtnClick } sx={{ color: 'var(--CFD-theme-System-OnSurface)', cursor: 'pointer' }}/>
        </div>
      </div>
    </header>
  )
}
