import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterTableIcon } from '../../../../../../components/icons/FilterTableIcon';
import { type SortStatusTypeAndNull, type IMobileTableHead } from '../../../../interface/savingsPageInterfaces';

import style from './MobileTableHead.module.css';

export const MobileTableHead: FC<IMobileTableHead> = ({
  isFlexible, sortField, setSortField, sortOrder, setSortOrder, screenWidth
}) => {
  const { t } = useTranslation();

  const isMobile = (screenWidth <= 680);

  const filterButtonHandler = (id: string): void => {
    setSortField(id);
    setSortOrder(sortField === id ? (sortOrder === 'desc' ? 'asc' : 'desc') : 'asc');
  }

  const investmentSortStatus: SortStatusTypeAndNull = sortField === 'investment' ? sortOrder : 'idle';
  const cumulativeTotalSortStatus: SortStatusTypeAndNull = sortField === 'cumulativeTotal' ? sortOrder : 'idle';
  const beforeReleaseSortStatus: SortStatusTypeAndNull = sortField === 'beforeRelease' ? sortOrder : 'idle';
  const periodInvestmentSortStatus: SortStatusTypeAndNull = sortField === 'periodInvestment' ? sortOrder : 'idle';

  return (
    <div className={ style.cellContainer }>
      <div className={ style.tableHeadersTitleWrapper } onClick={() => { filterButtonHandler('investment'); } }>
        <p className={ style.tableHeadersTitle }>
          { t('savings_page.table_body.accounts_tab.accordion_details.investment_title') }
        </p>
        <button className={ style.cellTextBtnIcon }>
          <FilterTableIcon status={ investmentSortStatus } />
        </button>
      </div>
      { !isMobile &&
        <div className={ style.tableHeadersCenterTitleWrapper } onClick={() => { filterButtonHandler('cumulativeTotal'); } }>
          <p className={ style.tableHeadersTitle }>
            { t('savings_page.table_head.accounts_tab.total_title') }
          </p>
          <button className={ style.cellTextBtnIcon }>
            <FilterTableIcon status={ cumulativeTotalSortStatus } />
          </button>
        </div>
      }
      <div
        className={ style.tableHeaderslastTitleWrapper }
        onClick={() => { filterButtonHandler(!(isFlexible ?? false) ? 'beforeRelease' : 'periodInvestment'); } }
      >
        <p className={ style.tableHeadersTitle }>
          { !(isFlexible ?? false)
            ? (t('savings_page.table_body.accounts_tab.accordion_details.Before_the_release'))
            : (t('savings_page.table_body.accounts_tab.accordion_details.period_title') +
                ' ' + t('savings_page.table_body.accounts_tab.accordion_details.investment_title'))
          }
        </p>
        <button className={ style.cellTextBtnIcon }>
          <FilterTableIcon status={ !(isFlexible ?? false) ? beforeReleaseSortStatus : periodInvestmentSortStatus } />
        </button>
      </div>
      </div>
  )
}
