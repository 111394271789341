import React, { type FC, type ChangeEvent } from 'react'

import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

interface SearchInputInterface {
  value: string | null
  setValue: (el: string) => void
  placeholder?: string
  sizeInput?: 'small' | 'medium' | undefined
  menuIcon?: boolean
}

const CustomizedInput = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    height: '48px',
    borderRadius: '26px',
    backgroundColor: 'var(--CFD-theme-Surface-4)',
    outline: 'none',
    '&.Mui-focused': {
      border: '1px solid var(--CFD-theme-System-Outline)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'none'
    },

    ':hover': {
      border: '1px solid var(--CFD-theme-System-Outline)',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important'
      }
    }
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant)'
  },
  '& .MuiInputBase-root .MuiInputBase-input': {
    padding: '4px',
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left'
  }
}))

export const SearchInputComponent: FC<SearchInputInterface> = ({
  value,
  placeholder,
  setValue,
  sizeInput
}) => {
  let fontSize: 'small' | 'medium' = 'medium';
  let spanSize: string = '24px';
  if (sizeInput === 'small') {
    fontSize = 'small';
    spanSize = '20px'
  }

  const hanleClear = (e: any): void => {
    if (setValue != null) setValue('');
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (setValue != null) setValue(event.target.value);
  };

  return (
    <>
      <CustomizedInput
        value={ value ?? '' }
        onChange={ handleChange }
        placeholder={placeholder ?? ''}
        sx={{
          '& input': {
            backgroundColor: 'transparent',
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px inherit inset',
              WebkitTextFillColor: 'var(--CFD-theme-System-OnSurfaceVariant)',
              WebkitBackgroundClip: 'text'
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" >
                { value !== null && value.length > 0
                  ? <CloseOutlinedIcon sx={{ cursor: 'pointer' }} onClick={ hanleClear } fontSize={ fontSize }/>
                  : <span style={{ width: spanSize }} /> }
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
                <SearchOutlinedIcon sx={{ cursor: 'pointer' }} fontSize={ fontSize }/>
          </InputAdornment>)
        }}
      />
    </>
  )
}
