import React, { type FC, type MouseEvent } from 'react';

interface InterfaceBinIcon {
  onClick?: (e: MouseEvent<SVGSVGElement>) => void
}

export const BinIcon: FC<InterfaceBinIcon> = ({ onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ cursor: 'pointer' }} onClick={ onClick }>
        <path fillRule="evenodd" clipRule="evenodd" d="M15 3V4H20V6H19V19C19 20.1 18.1 21 17 21H7C5.9 21 5 20.1 5 19V6H4V4H9V3H15ZM7 19H17V6H7V19ZM9 8H11V17H9V8ZM15 8H13V17H15V8Z" fill="var(--CFD-theme-System-OnSurface)"/>
    </svg>
  )
}

export const BinIconDelete: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" style={{ cursor: 'pointer' }}>
        <path fillRule="evenodd" clipRule="evenodd" d="M15 3V4H20V6H19V19C19 20.1 18.1 21 17 21H7C5.9 21 5 20.1 5 19V6H4V4H9V3H15ZM7 19H17V6H7V19ZM9 8H11V17H9V8ZM15 8H13V17H15V8Z" fill="var(--CFD-theme-System-OnSurface)"/>
    </svg>
  )
}
