import React, { type FC } from 'react';
import { type IconInterface } from '../../interfaces';

export const AddFundsIcon: FC<IconInterface> = ({ color = '#B7C4FF' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2039_11573)">
            <path d="M4.93044 4.92893C1.03019 8.82918 1.03019 15.1708 4.93044 19.0711C8.83069 22.9713 15.1723 22.9713 19.0726 19.0711C22.9728 15.1708 22.9728 8.82919 19.0726 4.92893C15.1723 1.02868 8.83069 1.02868 4.93044 4.92893ZM17.3011 10.4518L17.3011 13.5483L13.5348 13.5334L13.5498 17.2996L10.4533 17.2996L10.4682 13.5334L6.70193 13.5483V10.4518L10.4682 10.4667L10.4533 6.70047L13.5498 6.70047L13.5348 10.4667L17.3011 10.4518Z" fill={color}/>
        </g>
        <defs>
            <clipPath id="clip0_2039_11573">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  )
}
