import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { setAutotraderId } from './user';
import { apiHelper } from '../api/apiHelper';

export interface IAutotrader {
  _id: string
  userId: string
  riskLevel: string
  isEnabled: boolean
}

const initialState: IAutotrader = {
  _id: '',
  userId: '',
  riskLevel: 'low',
  isEnabled: false
};

export const getAutotrader = createAsyncThunk(
  'autotrader/get',
  async (userId: string) => {
    const response = await apiHelper({ method: 'get', path: `/autotrader/query?userId=${userId}` });

    return response.data.autotrader;
  }
);

export const createAutotrader = createAsyncThunk(
  'autotrader/create',
  async ({ userId, riskLevel }: { userId: string, riskLevel: string }, { dispatch }) => {
    const response = await apiHelper({ method: 'post', path: '/autotrader', data: { userId, riskLevel } });

    dispatch(setAutotraderId(response.data.autotrader._id as string));
    return response.data.autotrader as IAutotrader;
  }
);

export const updateAutotrader = createAsyncThunk(
  'autotrader/update',
  async ({ id, riskLevel, isEnabled }: { id: string, riskLevel: string, isEnabled: boolean }) => {
    const response = await apiHelper({ method: 'patch', path: `/autotrader/${id}`, data: { isEnabled, riskLevel } });

    return response.data.autotrader;
  }
);

export const autotraderSlice = createSlice({
  name: 'autotrader',
  initialState,
  reducers: {
    setAutotrader (state, action: PayloadAction<IAutotrader>) {
      return action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createAutotrader.fulfilled, (state, action: PayloadAction<IAutotrader>) => {
      return action.payload;
    });
    builder.addCase(updateAutotrader.fulfilled, (state, action: PayloadAction<IAutotrader>) => {
      return action.payload;
    });
    builder.addCase(getAutotrader.fulfilled, (state, action: PayloadAction<IAutotrader>) => {
      return action.payload;
    });
  }
});

export const { setAutotrader } = autotraderSlice.actions;

export default autotraderSlice.reducer;
