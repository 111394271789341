import React, { type FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getDefaultBank } from '../../../../redux/reducers/psp';
import {
  // AssetAmountInputComponent,
  InputComponent
  // ButtonComponent
} from '../../../../components';
import { SuccessDepositFormComponent } from '../SuccessDepositFormComponent/SuccessDepositFormComponent';

import style from './BankDepositFormComponent.module.css';
import { t } from 'i18next';

interface InterfaceBankDepositFormComponent {
  setStep: (el: number) => void
  step: number
  setOpen: (el: boolean) => void
}

export const BankDepositFormComponent: FC<InterfaceBankDepositFormComponent> = ({ setOpen, step, setStep }) => {
  const dispatch = useAppDispatch();
  // const [bankName, setBankName] = useState<string>('')
  // const [iban, setIban] = useState<string>('');
  // const [accountNumber, setAccountNumber] = useState<string>('');
  // const [bankAddress, setBankAddress] = useState<string>('');
  // const [swift, setSwift] = useState<string>('');
  // const [amount, setAmount] = useState<string>('');
  // const [ibanError, setIbanError] = useState<boolean>(false);

  const { defaultBank } = useAppSelector((state) => state.psp);

  useEffect(() => {
    dispatch(getDefaultBank());
  }, [])

  // const formComplete = (bankName.length && iban.length && accountNumber.length && bankAddress.length && swift.length && amount.length)

  // const ibanHandleChange = (event: ChangeEvent<HTMLInputElement>): void => {
  //   const { target: { value: ibanValue } } = event;

  //   if (ibanValue.length <= 34) {
  //     setIban(ibanValue);
  //     setIbanError(false);
  //   } else {
  //     setIbanError(true);
  //   }
  // };

  // const handleBtnClick = (): void => {
  //   console.log('Click ')
  //   setStep(2);
  // }

  const handleCloseDepositBtn = (): void => {
    setOpen(false);
  }

  return (
    <>
     { (step === 1)
       ? <>
          <div className={ style.wrapper }>
            <div className={ style.bankWrapper }>
              <p className={ style.bankWrapperText }>1. {t('deposit_modal.bank_form.subtitle')}</p>
              <div className={ style.inputContainer }>
                <label className={ style.label }>{t('labels.bank_name')}</label>
                <InputComponent value={ defaultBank.name ?? '' } isCopy/>
              </div>
              <div className={ style.inputContainer }>
                <label className={ style.label }>{t('labels.iban')}</label>
                <InputComponent value={ defaultBank.iban ?? '' } isCopy/>
              </div>
              <div className={ style.inputContainer }>
                <label className={ style.label }>{t('labels.account_number')}</label>
                <InputComponent value={ defaultBank.accountNumber ?? '' } isCopy/>
              </div>
              <div className={ style.inputContainer }>
                <label className={ style.label }>{t('labels.bank_address')}</label>
                <InputComponent value={ defaultBank.bankAddress ?? '' } isCopy/>
              </div>
              <div className={ style.inputContainer }>
                <label className={ style.label }>{t('labels.swift')}</label>
                <InputComponent value={ defaultBank.swiftCode ?? '' } isCopy/>
              </div>
            </div>
            {/* <div className={ style.bankWrapper }>
              <p className={ style.bankWrapperText }>2. Enter Amount</p>
              <div className={ style.inputContainer }>
                <label htmlFor='amount' className={ style.label }>Amount</label>
                <AssetAmountInputComponent value={ amount } setValue={ setAmount } id='amount' currency='USD' maxBtn={ false } placeholder='0.00'/>
              </div>
            </div> */}
          </div>
          {/* <footer className={ style.footer }>
          <div className={ style.btnContainer }>
            <ButtonComponent onClick={ handleBtnClick } disabled={ !formComplete }>
              <span className={ style.btnText }>
                Confirm
              </span>
            </ButtonComponent>
          </div>
        </footer> */}
      </>
       : <SuccessDepositFormComponent title='Deposit account has been successfully created' onClick={ handleCloseDepositBtn } amount='10000.00 USDT' account='VIP Account USDT'/>}

    </>
  )
}
