import React, {
  type ReactPortal,
  type PropsWithChildren,
  type DialogHTMLAttributes,
  type FC
} from 'react';
import { createPortal } from 'react-dom';

import styles from './BuySellModalMobile.module.css';

interface IBuySellModalMobile {
  open: boolean
  setOpen: (el: boolean) => void
}

type TBuySellModalMobile = IBuySellModalMobile & DialogHTMLAttributes<HTMLDialogElement>;

const modalRoot = document.getElementById('modal-window');

export const BuySellModalMobile: FC<PropsWithChildren<TBuySellModalMobile>> = ({
  setOpen,
  open,
  children,
  ...rest
}): ReactPortal | null => {
  const stopPropagation = (e: React.MouseEvent): void => {
    e.stopPropagation();
  };

  if (modalRoot !== null && open) {
    return createPortal(
      <dialog
        className={ styles.dialogWrapper }
        open={ open }
        onClick={ stopPropagation }
        {...rest}
      >
        { children }
      </dialog>,
      modalRoot
    );
  }
  return null;
};
