import React, { type FC, type Dispatch, type SetStateAction } from 'react';
import { useAppSelector } from '../../../../hooks/redux';
import { getAccountIconBySymbol } from '../../../../helpers/util';
import style from './AccountsTableNavigation.module.css';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { t } from 'i18next';

interface IProps {
  selectedAccount: IAccount | null
  setSelectedAccount: (el: IAccount | null) => void
}

export const AccountsTableNavigation: FC<IProps> = ({ selectedAccount, setSelectedAccount }) => {
  const accounts = useAppSelector((state) => state.accounts);

  return (
    <div className={ style.headerWrapper }>
      <div className={ style.header }>
        {
          accounts.map((account) => (
            <button
              key={account._id}
              className={ selectedAccount?._id === account._id ? `${style.headerAccountsWrapper} ${style.active}` : `${style.headerAccountsWrapper}`}
              onClick={() => { setSelectedAccount(account) }}
            >
            <img src={ getAccountIconBySymbol(account?.cfdAccountCurrency?.symbol ?? '') } />
            <p className={ style.headerAccountsParagraph }>{`${account?.type?.name ?? ''} ${t('labels.account')} ${account?.cfdAccountCurrency?.symbol ?? ''}`}</p>
          </button>
          ))
        }
      </div>
    </div>
  )
}
