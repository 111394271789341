import React, { type FC, useState } from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion, { type AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  type AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import { ProfileElementHeight } from '../helper';
import { CompleteProfileSettings } from './CompleteProfile/CompleteProfileSettings';
import { ProfileSettings } from './ProfileSettings/ProfileSettings';
import { PasswordSettings } from './PasswordSettings/PasswordSettings';
import { DividerComponent, ModalVerifications, Modal2FA } from '../../../components';

import style from './Settings.module.css'
import { t } from 'i18next';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: 'var(--CFD-theme-Surface-1)',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />}
    {...props}
  />
))(() => ({
  backgroundColor: 'var(--CFD-theme-Surface-1)',
  flexDirection: 'row',
  borderBottom: '1px solid var(--CFD-theme-System-OutlineVariant)',
  padding: '8px 16px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    marginRight: '14px'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginRight: '14px'
  },
  '& .MuiAccordionSummary-content': {
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  backgroundColor: 'var(--CFD-theme-Surface-1)',
  borderBottom: '1px solid var(--CFD-theme-System-OutlineVariant)'
}));

const AccordionDetailsWithoutBorder = styled(MuiAccordionDetails)(() => ({
  backgroundColor: 'var(--CFD-theme-Surface-1)'
}));

interface ISettings {
  calcHeight: number
}

export const Settings: FC<ISettings> = ({ calcHeight }) => {
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [open2FAModal, setOpen2FAModal] = useState(false);
  const [openVerificationModal, setOpenVerificationModal] = useState(false);

  const settingsHeight = ((calcHeight / 2) - (ProfileElementHeight.Gap / 2))

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={ style.settingWrapper } style={{ height: `${settingsHeight}px` }}>
      <ModalVerifications open={ openVerificationModal } setOpen={ setOpenVerificationModal } />
      <Modal2FA open={ open2FAModal } setOpen={ setOpen2FAModal }/>
      <header className={ style.settingHeader }>
        <h2 className={ style.settingHeaderTitle }>
          {t('profile_page.labels.settings')}
        </h2>
      </header>
      <DividerComponent />
      <div>
        <Accordion expanded={expanded === 'completeProfile'} onChange={handleChange('completeProfile')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className={ style.settingsFirstSection }>
              <VerifiedUserOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />
              <h3 className={ style.settingsFirstSectionTitle }>{t('profile_page.settings.subtitles.complete_profile')}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <CompleteProfileSettings setOpen2FAModal={ setOpen2FAModal } setOpenVerificationModal={ setOpenVerificationModal } />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'profile'} onChange={handleChange('profile')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <div className={ style.settingsSecondSection }>
              <PersonIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />
              <h3 className={ style.settingsSecondSectionTitle }>{t('profile_page.settings.subtitles.profile')}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <ProfileSettings />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'password'} onChange={handleChange('password')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <div className={ style.settingsSecondSection }>
              <PasswordOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />
              <h3 className={ style.settingsSecondSectionTitle }>{t('profile_page.settings.subtitles.password')}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetailsWithoutBorder>
            <PasswordSettings />
          </AccordionDetailsWithoutBorder>
        </Accordion>
      </div>
    </div>
  )
}
