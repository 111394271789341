import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface InitState {
  tradingMode: boolean
}

const initialState: InitState = {
  tradingMode: true
};

export const tradingModeSlice = createSlice({
  name: 'tradingMode',
  initialState,
  reducers: {
    setTradingMode: (state, action: PayloadAction<boolean>) => {
      state.tradingMode = action.payload;
    }
  }
});

export const { setTradingMode } = tradingModeSlice.actions;

export default tradingModeSlice.reducer;
