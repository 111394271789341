import React, {
  type HTMLAttributes,
  type FC,
  useState,
  useEffect
} from 'react';

import { Step1SectionModal2FA } from '../Steps/Step1/Section/Step1SectionModal2FA';
import { Step2SectionModal2FA } from '../Steps/Step2/Section/Step2SectionModal2FA';
import { SectionSuccessModal } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { selectStep2FA } from '../../../redux/selectors/twoFactorAuthentication';
import { setIsCodeCorrect, setVerifyCode2FA } from '../../../redux/reducers/twoFactorAuthentication';

type TSectionModal2FA = HTMLAttributes<HTMLElement>

export const SectionModal2FA: FC<TSectionModal2FA> = () => {
  const dispatch = useAppDispatch()

  const [code2FA, setCode2FA] = useState<string[]>(Array(6).fill(''));

  const step = useAppSelector(selectStep2FA)

  useEffect(() => {
    dispatch(setVerifyCode2FA(code2FA.join('')))

    if (code2FA.filter(v => v !== '').length === 6) {
      dispatch(setIsCodeCorrect(true))
    } else {
      dispatch(setIsCodeCorrect(false))
    }
  }, [code2FA])

  if (step === 1) {
    return (
        <Step1SectionModal2FA />
    )
  }

  if (step === 2) {
    return (
        <Step2SectionModal2FA code={ code2FA } setCode={ setCode2FA } />
    )
  }

  return (
    <SectionSuccessModal content='Google authentication linked to your account successfully' />
  )
}
