import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';

interface IRates {
  headerRates?: Record<string, number>
  rates?: Record<string, number>
}

const initialState: IRates = {};

export const getCurrencyRates = createAsyncThunk(
  'rates/get',
  async (symbol: string) => {
    const response = await apiHelper({ method: 'get', path: `/currency-rate?symbol=${symbol}` });

    return response.data.rates;
  }
)

export const getHeaderCurrencyRates = createAsyncThunk(
  'rates/header/get',
  async () => {
    const response = await apiHelper({ method: 'get', path: '/currency-rate?symbol=USD' });

    return response.data.rates;
  }
)

export const currencyRatesSlice = createSlice({
  name: 'currencyRates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCurrencyRates.fulfilled, (state, action: PayloadAction<Record<string, number>>) => {
      return { ...state, rates: action.payload };
    });
    builder.addCase(getHeaderCurrencyRates.fulfilled, (state, action: PayloadAction<Record<string, number>>) => {
      return { ...state, headerRates: action.payload };
    });
  }
});

export default currencyRatesSlice.reducer;
