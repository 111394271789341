import React, { type FC } from 'react';

export const TradeIcon: FC = () => {
  const fillColor = 'var(--CFD-theme-System-OnSecondaryContainer)';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill={fillColor} fillRule="evenodd" clipRule="evenodd" d="M6.70602 21.5969H2.3382C2.16811 21.5969 2 21.4403 2 21.2068V12.9387C2 12.7052 2.16811 12.5485 2.3382 12.5485H6.70602C6.87611 12.5485 7.04422 12.7052 7.04422 12.9387V21.2068C7.04422 21.4403 6.87611 21.5969 6.70602 21.5969ZM2.6764 20.8166V13.3288H6.36782V20.8166H2.6764Z" />
      <path fill={fillColor} fillRule="evenodd" clipRule="evenodd" d="M14.3225 21.2097C14.3225 21.4246 14.1803 21.5743 14.025 21.5969H9.61652C9.44643 21.5969 9.27832 21.4403 9.27832 21.2068V7.8586C9.27832 7.6251 9.44643 7.46844 9.61652 7.46844H13.9843C14.1544 7.46844 14.3225 7.6251 14.3225 7.8586V21.2097ZM9.95472 8.24875H13.6461V20.8166H9.95472V8.24875Z" />
      <path fill={fillColor} fillRule="evenodd" clipRule="evenodd" d="M21.2618 21.597H16.8939C16.7239 21.597 16.5557 21.4404 16.5557 21.2069V2.39015C16.5557 2.15665 16.7239 2 16.8939 2H21.2618C21.4319 2 21.6 2.15665 21.6 2.39015V21.2069C21.6 21.4404 21.4319 21.597 21.2618 21.597ZM17.2321 20.8167V2.78031H20.9236V20.8167H17.2321Z" />
    </svg>
  )
}
