import React, { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonComponent, SelectWithDatePickerComponent, SimpleSelectComponent } from '../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { getAccounts } from '../../../../../redux/reducers/cfd';
import { type SimpleOptionInterface, type IHistoryTableHeader } from '../../../interface/savingsPageInterfaces';

import style from './HistoryTableHeader.module.css';

export const HistoryTableHeader: FC<IHistoryTableHeader> = ({
  setFilterQuery, setShowTimeInSelect, showTimeInSelect, savingAccountFilter, setSavingAccountFilter, cfdAccountFilter, setCfdAccountFilter
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { _id: userId } = useAppSelector((state) => state.user);
  const cfdAccounts = useAppSelector((state) => state.cfd.accounts);
  const savingAccounts = useAppSelector((state) => state.savingAccounts);
  const [savingsAccountsOption, setSavingsAccountsOption] = useState<SimpleOptionInterface[]>([]);
  const [cfdAccountsOption, setCfdAccountsOption] = useState<SimpleOptionInterface[]>([]);

  const isResetDisabledBtn = (showTimeInSelect === t('labels.redeem_date') && cfdAccountFilter === null && savingAccountFilter === null);

  useEffect(() => {
    if (savingAccounts.length > 0) {
      const savingAccountOption = savingAccounts.map(({ _id, currency }) => ({ value: _id, label: currency.name }));
      setSavingsAccountsOption(savingAccountOption);
    }
  }, [savingAccounts]);

  useEffect(() => {
    if (cfdAccounts.length > 0) {
      const cfdAccountOption = cfdAccounts.map(({ symbol, type, _id }) => ({
        value: _id,
        label: `${type} ${t('labels.account')} ${symbol}`
      }));
      setCfdAccountsOption(cfdAccountOption);
    }
  }, [cfdAccounts]);

  useEffect(() => {
    if (userId !== undefined) dispatch(getAccounts(userId));
  }, [userId]);

  const resetBtnHandler = (): void => {
    setShowTimeInSelect(t('labels.redeem_date'));
    setCfdAccountFilter(null);
    setSavingAccountFilter(null);
    setFilterQuery({});
  }

  const savingAccountHandler = (el: string): void => {
    if (el !== null) {
      setSavingAccountFilter(el);
      setFilterQuery((prevState) => ({ ...prevState, savingAccount: el }));
    }
  }

  const cfdAccountHandler = (el: string): void => {
    if (el !== null) {
      setCfdAccountFilter(el);
      setFilterQuery((prevState) => ({ ...prevState, redeemToAccount: el }));
    }
  }

  return (
    <div className={ style.historyTableHeadWrapper }>
      <div className={ style.historyTableHeadTitle }>
        <p className={ style.historyTableHeadText }>{ t('labels.filter_redemption_history') }</p>
        <div className={ style.historyBtnWrapperHeader }>
          <ButtonComponent
            btnstyle='primary'
            variant='text'
            disabled={ isResetDisabledBtn }
            onClick={ resetBtnHandler }
          >{ t('labels.reset') }</ButtonComponent>
        </div>
      </div>
      <div className={ style.historyTableFilterWrapper }>
        <div className={ style.historyTableFilters }>
          <div className={ style.historyFilterCell }>
            <SimpleSelectComponent
              placeholder={ t('labels.savings_account') }
              height='44px'
              value={ savingAccountFilter ?? '' }
              setValue={ savingAccountHandler }
              options={ savingsAccountsOption }
            />
          </div>
          <div className={ style.historyFilterCell }>
            <SimpleSelectComponent
              placeholder={ t('labels.redeem_to_account') }
              height='44px'
              value={ cfdAccountFilter ?? '' }
              setValue={ cfdAccountHandler }
              options={ cfdAccountsOption }
            />
          </div>
          <div className={ style.historyFilterCell }>
            <SelectWithDatePickerComponent
              filterPointer='redeemDate'
              setFilterQuery={ setFilterQuery }
              setSelectLabel={ setShowTimeInSelect }
              selectLabel={ showTimeInSelect }
            />
          </div>
        </div>
        <div className={ style.historyBtnWrapper }>
          <ButtonComponent
            btnstyle='primary'
            variant='text'
            disabled={ isResetDisabledBtn }
            onClick={ resetBtnHandler }
          >{ t('labels.reset') }</ButtonComponent>
        </div>
      </div>
    </div>
  )
}
