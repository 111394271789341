/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Defining the structure for our input values
type InputValues = Record<string, string>;

// Defining the structure for individual validation rules
interface ValidationRule {
  required?: boolean
  min?: number
  max?: number
}

// Mapping each input field to its validation rule
type ValidationConfig = Record<string, ValidationRule>;

// Defining the structure for validation errors
type ValidationErrors = Record<string, string>;

// The function signature
export function validateInputs (inputs: InputValues, validations: ValidationConfig): ValidationErrors {
  const errors: ValidationErrors = {};

  Object.keys(inputs).forEach((key) => {
    const value = inputs[key];
    const validation = validations[key];

    if (validation.required && !value.trim()) {
      errors[key] = 'This field is required.';
    }

    if (validation.min && value.trim().length < validation.min) {
      errors[key] = `This field must be at least ${validation.min} characters long.`;
    }

    if (validation.max && value.length > validation.max) {
      errors[key] = `This field must be no more than ${validation.max} characters long.`;
    }
  });
  return errors;
}
